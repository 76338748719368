import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-dialog-comment-confirm',
  templateUrl: './dialog-comment-confirm.component.html',
  styleUrls: ['./dialog-comment-confirm.component.css']
})
export class DialogCommentConfirmComponent {

  commentCtrl: FormControl<string>;

  constructor(private dialogRef: MatDialogRef<DialogCommentConfirmComponent, DialogCommentConfirmResult>,
              @Inject(MAT_DIALOG_DATA) public data: DialogCommentConfirmData) {
    this.commentCtrl = new FormControl<string>(null);
    if (data.commentRequired) {
      this.commentCtrl.setValidators(Validators.required);
    }
  }

  cancel() {
    this.dialogRef.close({confirm: false});
  }

  confirm() {
    if (this.commentCtrl.valid) {
      this.dialogRef.close({confirm: true, comment: this.commentCtrl.value});
    }
  }
}

export class DialogCommentConfirmData {
  title: string;
  body: string;
  commentFieldName: string = 'Commentaire';
  commentRequired: boolean = false;
  commentMaxLength: number = 255;
  cancelLabel: string = 'Annuler';
  confirmLabel: string = 'Confirmer';

  constructor(data: {title: string, body: string}) {
    this.title = data.title;
    this.body = data.body;
  }
}

export class DialogCommentConfirmResult {
  confirm: boolean;
  comment?: string = null;
}
