import {DTOWithLieu} from "./DTOWithLieu";

export class EntreeSortieQueryParams extends DTOWithLieu {
  idEntreeSortie: number;
  codeTypeMouvement: string;
  valide: boolean;
  codesStatut: string[];
  codeBL: string;
  dateMin: string;
  lieuEvenement: string;
  dateEvenement: string;
  referenceEvenement: string;
  utilisateur: string;
}
