import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {Lieu} from '../../model/epona-api/Lieu';
import {TableauBordService} from "../../services/epona/tableau-bord.service";
import {UserService} from "../../services/user.service";
import {CodeDroit} from "../../commons/constants/CodeDroit";

@Component({
  selector: 'epona-tableau-bord',
  templateUrl: './tableau-bord.component.html',
  styleUrls: ['./tableau-bord.component.css']
})
export class TableauBordComponent implements OnInit, AfterViewChecked {
  lieu: Lieu;

  constructor(private tdbService: TableauBordService,
              private cdr: ChangeDetectorRef,
              private userService: UserService) {

  }

  ngOnInit(): void {
    this.lieu = new Lieu();
    this.lieu.initWithLocalStorage();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieu = lieu;
  }

  /* ****************** */
  /* Droits d'affichage */
  /* ****************** */

  visuStocks(): boolean {
    return this.possedeDroit(CodeDroit.STOCKS_CONSULTATION);
  }

  visuCommandes(): boolean {
    return this.possedeDroit(CodeDroit.COMMANDES_CONSULTATION);
  }

  visuSorties(): boolean {
    return this.possedeDroit(CodeDroit.GESTION_PERTES)
        || this.possedeDroit(CodeDroit.GESTION_TRANSFERTS)
        || this.possedeDroit(CodeDroit.GESTION_CONSOMMATIONS_UTILISATIONS);
  }

  visuLivraisons(): boolean {
    return this.possedeDroit(CodeDroit.LIVRAISONS_CONSULTATION);
  }

  visuInventaires(): boolean {
    return this.possedeDroit(CodeDroit.GESTION_INVENTAIRES);
  }

  private possedeDroit(codeDroit: string): boolean {
    return this.userService.utilisateurCourant && this.userService.utilisateurCourant.possedeDroit(codeDroit);
  }


}
