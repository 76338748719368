<mat-card class="card-width tdb-matcard">
  <mat-card-header class="card-title">
    <mat-card-title>Alertes sur les stocks</mat-card-title>

    <a aria-label="État des stocks"
       title="État des stocks"
       class="envoi-link"
       routerLink="/stocks-temps-reel">
      <mat-icon>open_in_new</mat-icon>
    </a>
  </mat-card-header>

<!--  TABLEAU -->
  <table mat-table class="mat-elevation-z8 tdb-table" [dataSource]="dataSource"
         matSort matSortActive="batiment" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Nombre d'articles à stock faible ou DLC proche par lieu">

    <ng-container matColumnDef="batiment">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bâtiment</th>
      <td mat-cell *matCellDef="let liste">{{liste.lieu?.nomRu}}</td>
    </ng-container>

    <ng-container matColumnDef="lieu">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Lieu</th>
      <td mat-cell *matCellDef="let liste">{{liste.lieu?.nomUd}}</td>
    </ng-container>

    <ng-container matColumnDef="nbArticlesStockFaible">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col"
          matTooltip="Nombre d'articles dont le stock est inférieur au stock mini paramétré" mat-sort-header>Stock faible</th>
      <td mat-cell *matCellDef="let element">
        <div [hidden]="element.nbArticlesStockFaible != 0"> - </div>
        <a [routerLink]="['/etat-stocks']" [queryParams]="{idLieu: element.lieu.idLieu, stockFaible: true, masquerQuantitesNulles: false}" [hidden]="element.nbArticlesStockFaible == 0">{{element.nbArticlesStockFaible}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nbArticlesDlcProche">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>DLC proche</th>
      <td mat-cell *matCellDef="let element">
        <div [hidden]="element.nbArticlesDlcProche != 0"> - </div>
        <a [routerLink]="['/etat-stocks']" [queryParams]="{idLieu:element.lieu.idLieu, nombreJoursAvantDlc: nbJoursDlcExpiree.value}" [hidden]="element.nbArticlesDlcProche == 0">{{element.nbArticlesDlcProche}}</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
  </table>

  <mat-paginator class="tdb-table" #paginator [pageSize]="3" hidePageSize></mat-paginator>
  <!--  decommente si besoin [pageSizeOptions]="[3, 5, 10]">-->

  <label>
    DLC expirées dans
    <input matInput
           id="nbJoursDLCExpiree"
           type="number"
           [formControl]="nbJoursDlcExpiree"
           aria-describedby="Nombre de jours avant l'expiration de la DLC"
           (change)="rempliListeStocksAlertes()">
    jours
  </label>
</mat-card>
