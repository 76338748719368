import {Component, Inject} from '@angular/core';
import {DocumentJoint} from "../../model/epona-api/DocumentJoint";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MessageTool} from "../../commons/MessageTool";

export class DialogDataAddDocument {
  document: DocumentJoint;
  postDocumentFn: (document: DocumentJoint, file: File) => Observable<any>;
  putDocumentFn: (document: DocumentJoint, file: File) => Observable<any>;
}

@Component({
  selector: 'app-dialog-add-documents',
  templateUrl: './dialog-add-documents.component.html',
  styleUrls: ['./dialog-add-documents.component.css']
})
export class DialogAddDocumentsComponent {

  selectedFiles?: FileList;
  isFileSelected: boolean = true;
  currentFile?: File;
  preview: string|null = null;
  document: DocumentJoint | null;
  isLoading: boolean = false;
  isPdfFile: boolean = false;
  creationMode: boolean = false;
  selectedFilename: string = '';
  formDocument! : FormGroup;

  constructor(public dialogRef: MatDialogRef<DialogAddDocumentsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataAddDocument,
              private messageService: MessageTool,
              private fb: FormBuilder) {

    this.document = this.data.document;

    this.initForm();

    this.creationMode = this.document == null;

    if (!this.creationMode) {
      this.selectedFilename = this.document.nomFichier;
      this.setFormValues();
    }
  }

  ngOnInit(): void {
  }

  private initForm(): void {
    this.formDocument = this.fb.group({
      libelle  : this.fb.control<string|null>(null, Validators.required),
      document : this.fb.control<File|null>(null)
    })
  }

  private setFormValues(){
    this.formDocument.get('libelle').setValue(this.document.libelle);
  }

  close(res: boolean = false) {
    this.dialogRef.close(res);
  }

  selectFile(event: any): void {
    this.preview = null;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles !== undefined) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.isFileSelected = true;
        this.currentFile = file;

        if (!this.formDocument.get('libelle').value) {
          let nameSansExtension = file.name.split('.')[0];
          this.formDocument.get('libelle').patchValue(nameSansExtension);
          this.formDocument.updateValueAndValidity();
        }

        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.preview = e.target.result;
          let filenameSplit = file.name.split('.');
          let extension = filenameSplit[filenameSplit.length -1];
          this.isPdfFile = extension === 'pdf';
        };
        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  upload(): void {
    if (this.selectedFiles !== undefined && this.formDocument.valid) {
      this.isLoading = true;

      let doc = new DocumentJoint();
      doc.libelle = this.formDocument.get('libelle').value;

      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.data.postDocumentFn(doc, file).subscribe({
          next: (event: any) => {
            this.isLoading= false;
            this.close(true);
          },
          error: (err: any) => {
            this.isLoading= false;
            this.messageService.sendError(err);
          }
        });
      }

    } else {
      if (this.selectedFiles === undefined) {
        this.isLoading = false;
        this.isFileSelected = false;
      }

      if (!this.formDocument.valid) {
        this.formDocument.markAsTouched();
        this.formDocument.updateValueAndValidity();
      }
    }
  }


  update(): void {
    if (this.formDocument.valid) {
      this.isLoading = true;

      let doc = new DocumentJoint();
      doc.idDocument = this.document.idDocument;
      doc.libelle = this.formDocument.get('libelle').value;

      let arrayOfBlob = new Array<Blob>();
      let file = new File(arrayOfBlob, "");
      if (this.selectedFiles !== undefined) {
        file = this.selectedFiles.item(0);
        this.currentFile = file;
      }

      this.data.putDocumentFn(doc, file).subscribe({
        next: (event: any) => {
          this.isLoading= false;
          this.close(true);
        },
        error: (err: any) => {
          this.isLoading= false;
          this.messageService.sendError(err);
        }
      });

    } else {
      if (this.selectedFiles === undefined) {
        this.isLoading = false;
        this.isFileSelected = false;
      }

      if (!this.formDocument.valid) {
        this.formDocument.markAsTouched();
        this.formDocument.updateValueAndValidity();
      }
    }
  }
}
