import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {LieuFilter} from "../../model/epona-ui/lieu-filter";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Lieu} from "../../model/epona-api/Lieu";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
  selector: 'epona-filtre-lieux',
  templateUrl: './filtre-lieux.component.html',
  styleUrls: ['./filtre-lieux.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class FiltreLieuxComponent {

  @Input() filter: LieuFilter;

  @Output() readonly filterSubmitted = new EventEmitter<LieuFilter>();

  form: UntypedFormGroup;
  listeLieu: Lieu[];

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      masque: null,
      priseEnCompteVentes: null,
      lieuLivraison: null,
      parametrePourOrion: null
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }
  }

  private initFieldsFilter() {
    this.form.get('masque').setValue(this.filter.masque);
    this.form.get('priseEnCompteVentes').setValue(this.filter.priseEnCompteVentes);
    this.form.get('lieuLivraison').setValue(this.filter.lieuLivraison);
    this.form.get('parametrePourOrion').setValue(this.filter.parametrePourOrion);
  }

  onSubmit() {
    this.filter.masque              = this.form.get('masque').value;
    this.filter.priseEnCompteVentes = this.form.get('priseEnCompteVentes').value;
    this.filter.lieuLivraison       = this.form.get('lieuLivraison').value;
    this.filter.parametrePourOrion  = this.form.get('parametrePourOrion').value;

    this.filterSubmitted.emit(this.filter);
  }
}
