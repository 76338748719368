import {Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from "@angular/material/form-field";
import {Departement} from "../../../model/Departement";
import {Subject} from "rxjs";
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Const} from "../../constants/Const";
import {CacheService} from "../../../services/cache.service";
import {W3Service} from "../../../services/w3.service";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";

@Component({
  selector: 'epona-select-departement',
  templateUrl: './select-departement.component.html',
  styleUrls: ['./select-departement.component.css'],
  providers: [{provide: MatFormFieldControl, useExisting: SelectDepartementComponent}]
})
export class SelectDepartementComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Departement> {

  @Input() defaultLabel: string = '- Aucun -';

  public readonly DEFAULT = Const.DEFAULT;
  private readonly CACHE_KEY: string = 'departements';

  formCtrl: UntypedFormControl;
  liste: Departement[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private w3Service: W3Service,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: Departement): void {
    this.formCtrl.setValue(obj ? obj.codeDepartement : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Departement {
    return this.getCurrentItem();
  }
  set value(value: Departement) {
    this.formCtrl.setValue(value ? value.codeDepartement : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): Departement {
    return this.liste.find(item => item.codeDepartement === this.formCtrl.value)
  }

  private loadListe() {
    // Si la liste a déjà été chargée
    if (this.cacheService.has(this.CACHE_KEY)) {
      this.setAndSortListeFromCache();

      // Sinon récupération de la liste via l'API
    } else {
      this.w3Service.getDepartements().subscribe(data => {
        this.cacheService.set(this.CACHE_KEY, data);
        this.setAndSortListeFromCache();

      }, err => {
        this.messageTool.sendErrorMessage('Erreur lors de la récupération des départements');
        console.error(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = this.cacheService.get(this.CACHE_KEY);
    this.tri(this.liste);
  }

  tri(liste: Array<Departement>) {
    liste.sort((a, b) => {
      return a.libelle.localeCompare(b.libelle);
    });
  }
}
