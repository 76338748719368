<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field>
      <mat-label>Nom (contient)</mat-label>

      <input matInput formControlName="nom" epoHighlightIfNotEmpty>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Siret</mat-label>

      <input matInput formControlName="siret" epoHighlightIfNotEmpty>
    </mat-form-field>


    <mat-form-field>
      <mat-label>Marché courant</mat-label>

      <epona-select-marche formControlName="marche"
                           defaultLabel="- Tous -"
                           [afficherPasses]="false"
                           epoHighlightIfNotEmpty></epona-select-marche>
    </mat-form-field>


    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon>
        Rechercher
      </button>
    </div>
  </mat-card>
</form>
