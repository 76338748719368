import {UtilisateurAuth} from "../model/UtilisateurAuth";

export class SessionStorageHelper {

  static getRememberMe(): UtilisateurAuth|null {
    const storedValue = sessionStorage.getItem('rememberMe');
    return storedValue == null ? null : JSON.parse(storedValue);
  }
  static setRememberMe(authUser: UtilisateurAuth) {
    sessionStorage.setItem('rememberMe', JSON.stringify(authUser));
  }

  static getDevToken(): string|null {
    return sessionStorage.getItem('devToken');
  }
  static setDevToken(devToken: string) {
    sessionStorage.setItem('devToken', devToken.replace(/[\n\r\t ]/g, ''));
  }
}
