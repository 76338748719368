<mat-tab-group [mat-stretch-tabs]="false">
  <mat-tab label="Entête">
    <epona-engagement-juridique-entete [commande]="commande"
                                       [entete]="engagementJuridique"
                                       [modifiable]="modifiable"
                                       [envoyable]="envoyable"
                                       (isDirty)="onFormEnteteIsDirty($event)"
                                       class="entete">
    </epona-engagement-juridique-entete>
  </mat-tab>

  <mat-tab label="Lignes">
    <epona-engagement-juridique-lignes [commande]="commande"
                                       [entete]="engagementJuridique"
                                       [modifiable]="modifiable"
                                       (isDirty)="onFormLignesIsDirty($event)"
                                       class="lignes">
    </epona-engagement-juridique-lignes>
  </mat-tab>

  <mat-tab label="Suivi">
    <epona-engagement-juridique-suivis-envois [commande]="commande"
                                              [entete]="engagementJuridique"
                                              (closeDialog)="onCloseDialog()"
                                              class="lignes">
    </epona-engagement-juridique-suivis-envois>
  </mat-tab>
</mat-tab-group>
