export class ContexteFdnc {

  idContexteFdnc: number;
  libelle: string;
  presenceLivraison: boolean;
  presenceArticle: boolean;
  articleCommandePossible: boolean;
  ordreAffichage: number;
  exemples: string;

  static sort(a: ContexteFdnc, b: ContexteFdnc): number {
    return a.ordreAffichage < b.ordreAffichage ? -1 : a.ordreAffichage === b.ordreAffichage ? 0 : 1;
  }
}
