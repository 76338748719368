import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../http.service';
import {Observable} from 'rxjs';
import {FdncBnaSearch} from '../../model/bna-api/fdnc-bna-search';
import {StatutFdnc} from '../../model/bna-api/statut-fdnc';
import {EtapeFdnc} from '../../model/bna-api/etape-fdnc';
import {FdncBna} from "../../model/bna-api/fdnc-bna";
import {ContexteFdnc} from "../../model/bna-api/contexte-fdnc";
import {TypeNonConformite} from "../../model/bna-api/type-non-conformite";
import {TypeNonConformiteSearch} from "../../model/bna-api/type-non-conformite-search";
import {EchangeFdnc} from "../../model/bna-api/echange-fdnc";
import {EchangeFdncPOST} from "../../model/bna-api/echange-fdnc-post";
import {TempsTraitementFdnc} from "../../model/bna-api/temps-traitement-fdnc";
import {TraitementNonConformite} from "../../model/bna-api/traitement-non-conformite";
import {CacheService} from '../cache.service';

@Injectable({
  providedIn: 'root'
})
export class BnaFdncService {

  private env = environment;
  private baseUrl: string;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) {
    this.baseUrl = this.env.bnaApiUrl + '/fdnc';
  }

  /* ********************* */
  /* Consultation des FDNC */
  /* ********************* */

  getListeFdnc(search: FdncBnaSearch): Observable<FdncBna[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<FdncBna[]>(this.baseUrl, {params});
  }

  getFdnc(idFdnc: number, fields: string): Observable<FdncBna> {
    const params = this.httpService.buildParams({fields});
    return this.httpClient.get<FdncBna>(this.baseUrl + '/' + idFdnc, {params});
  }

  /* ******************** */
  /* Gestion des échanges */
  /* ******************** */

  getListeEchanges(idFdnc: number, fields: string): Observable<EchangeFdnc[]> {
    const params = this.httpService.buildParams({fields});
    return this.httpClient.get<EchangeFdnc[]>(this.baseUrl + '/' + idFdnc + '/echanges', {params});
  }

  postEchangeDeclarant(idFdnc: number, echange: EchangeFdnc): Observable<EchangeFdnc> {
    return this.httpClient.post<EchangeFdnc>(this.baseUrl + '/' + idFdnc + '/echanges-declarant', new EchangeFdncPOST(echange));
  }

  resultatToString(resultat: number): string {
    switch (resultat) {
      case 1:  return '1 - Difficile à apprécier';
      case 2:  return '2 - Très satisfaisant';
      case 3:  return '3 - Satisfaisant/correct';
      case 4:  return '4 - Non satisfaisant/insuffisant';
      case 5:  return '5 - Mauvais';
      default: return '';
    }
  }

  /* ************************************* */
  /* Consultation des données de référence */
  /* ************************************* */

  getListeContextesFdnc(): Observable<ContexteFdnc[]> {
    return this.httpClient.get<ContexteFdnc[]>(this.env.bnaApiUrl + '/contextes-fdnc');
  }

  getContextesFdnc(idContexte: number): Observable<ContexteFdnc> {
    return this.httpClient.get<ContexteFdnc>(this.env.bnaApiUrl + '/contextes-fdnc/' + idContexte)
  }

  getListeTypesNonConformite(search: TypeNonConformiteSearch): Observable<TypeNonConformite[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<TypeNonConformite[]>(this.env.bnaApiUrl + '/types-non-conformite', {params});
  }

  getListeTraitementsNonConformite(): Observable<TraitementNonConformite[]> {
    return this.httpClient.get<TraitementNonConformite[]>(this.env.bnaApiUrl + '/traitements-fdnc');
  }

  getListeStatutsFdnc(): Observable<StatutFdnc[]> {
    return this.httpClient.get<StatutFdnc[]>(this.env.bnaApiUrl + '/statuts-fdnc');
  }

  getListeEtapesFdnc(): Observable<EtapeFdnc[]> {
    return this.httpClient.get<EtapeFdnc[]>(this.env.bnaApiUrl + '/etapes-fdnc');
  }

  getListeTempsTraitementFdnc(): Observable<TempsTraitementFdnc[]> {
    return this.httpClient.get<TempsTraitementFdnc[]>(this.env.bnaApiUrl + '/temps-traitement-fdnc');
  }
}
