<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field *ngIf="!params.commonFieldsBLDisplayed">
      <mat-label>{{params.filterValideLabel}}</mat-label>
      <select-boolean formControlName="valide"
                            defaultLabel="- {{params.filterValideDefaultLabel}} -"
                            epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field *ngIf="params.commonFieldsBLDisplayed">
      <mat-label>Statut</mat-label>

      <mat-select formControlName="codesStatut"
                  [multiple]="true"
                  epoHighlightIfNotEmpty>
        <mat-option [value]="CodeStatutES.NON_VALIDE">Non validé</mat-option>
        <mat-option [value]="CodeStatutES.VALIDE_SANS_SF" matTooltip="BL validé mais SF non envoyé vers Orion">Validé sans SF</mat-option>
        <mat-option [value]="CodeStatutES.VALIDE_AVEC_SF" matTooltip="BL validé et SF envoyé vers Orion (ou BL interne)">Validé avec SF</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Identifiant interne</mat-label>
      <input type="number" matInput formControlName="idEntreeSortie" epoHighlightIfNotEmpty>
    </mat-form-field>

    <mat-form-field *ngIf="params.commonTypeMouvementDisplayed">
      <mat-label>{{ params.filterTypeMouvementLabel }}</mat-label>

      <epona-select-type-mouvement formControlName="typeMouvement"
                                   defaultLabel="- Tous -"
                                   [values]="params.commonTypeMouvementList"
                                   epoHighlightIfNotEmpty>
      </epona-select-type-mouvement>
    </mat-form-field>


    <mat-form-field *ngIf="params.commonFieldsBLDisplayed">
      <mat-label>Code-BL</mat-label>
      <input matInput formControlName="codeBL" epoHighlightIfNotEmpty>
    </mat-form-field>


    <mat-form-field *ngIf="params.commonFieldsEvtDisplayed">
      <mat-label>Lieu de l'évènement (contient)</mat-label>
      <input matInput formControlName="lieuEvenement" epoHighlightIfNotEmpty>
    </mat-form-field>

    <mat-form-field *ngIf="params.commonFieldsEvtDisplayed">
      <mat-label>Date de l'évènement</mat-label>
      <input matInput
             [matDatepicker]="pickerDateEvenement"
             formControlName="dateEvenement"
             (click)="pickerDateEvenement.open()"
             (focus)="pickerDateEvenement.open()"
             autocomplete="off"
             epoHighlightIfNotEmpty>
      <mat-datepicker-toggle matSuffix [for]="pickerDateEvenement"></mat-datepicker-toggle>
      <mat-datepicker #pickerDateEvenement disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="params.commonFieldsEvtDisplayed">
      <mat-label>Référence de l'évènement</mat-label>
      <input matInput formControlName="referenceEvenement" epoHighlightIfNotEmpty>
    </mat-form-field>


    <mat-form-field>
      <mat-label>{{params.filterDateMinLabel}}</mat-label>
      <input matInput
             [matDatepicker]="pickerDateMin"
             formControlName="dateMin"
             (click)="pickerDateMin.open()"
             (focus)="pickerDateMin.open()"
             autocomplete="off"
             epoHighlightIfNotEmpty>
      <mat-datepicker-toggle matSuffix [for]="pickerDateMin"></mat-datepicker-toggle>
      <mat-datepicker #pickerDateMin disabled="false" ></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Utilisateur</mat-label>
      <input matInput formControlName="utilisateur" epoHighlightIfNotEmpty>
      <aide matSuffix>
        Utilisateur de création, modification ou validation
      </aide>
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon> Rechercher
      </button>
    </div>
  </mat-card>
</form>
