import {Component, Input, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ArticleFilter} from "../../model/epona-ui/article-filter";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {Const} from "../../commons/constants/Const";
import {CodeOrigineAchat} from "../../commons/constants/CodeOrigineAchat";
import {TypeReferentielOrion} from "../../commons/constants/TypeReferentielOrion";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
  selector: 'epona-filtre-articles',
  templateUrl: './filtre-articles.component.html',
  styleUrls: ['./filtre-articles.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class FiltreArticlesComponent {

  @Input() filter: ArticleFilter;
  @Input() utilisation: string;

  @Output() readonly filterSubmitted = new EventEmitter<ArticleFilter>();

  form: UntypedFormGroup;
  afficherAchat: boolean = true;
  afficherVente: boolean = true;

  public readonly DEFAULT = Const.DEFAULT;
  CodeOrigineAchat = CodeOrigineAchat;
  TypeReferentielOrion = TypeReferentielOrion;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      groupe: null,
      sousGroupe: null,
      departement: null,
      famille: null,
      sousFamille: null,
      codeArticle: null,
      designation: null,
      commandableExterne: this.DEFAULT,
      stockGere: null,
      stockGereOperationnellement: null,
      marche: null,
      comparaisonPrixHt: null,
      origineAchat: this.DEFAULT,
      actifVente: null,
      colisage: null,
      articleDlc: null,
      delaiReceptionDlc: null
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }
    if (changes['utilisation'] && this.utilisation) {
      this.afficherAchat = this.utilisation === UtilisationArticle.ACHAT;
      this.afficherVente = this.utilisation === UtilisationArticle.VENTE;
    }
  }

  private initFieldsFilter() {
    this.form.get('groupe').setValue(this.filter.groupe);
    this.form.get('sousGroupe').setValue(this.filter.sousGroupe);
    this.form.get('departement').setValue(this.filter.departement);
    this.form.get('famille').setValue(this.filter.famille);
    this.form.get('sousFamille').setValue(this.filter.sousFamille);
    this.form.get('codeArticle').setValue(this.filter.codeArticle);
    this.form.get('designation').setValue(this.filter.designation);
    this.form.get('commandableExterne').setValue(this.filter.commandableExterne === undefined || this.filter.commandableExterne === null ? this.DEFAULT : this.filter.commandableExterne);
    this.form.get('stockGere').setValue(this.filter.stockGere);
    this.form.get('stockGereOperationnellement').setValue(this.filter.stockGereOperationnellement);
    this.form.get('marche').setValue(this.filter.marche);
    this.form.get('comparaisonPrixHt').setValue(this.filter.comparaisonPrixHt);
    this.form.get('origineAchat').setValue(this.filter.origineAchat ? this.filter.origineAchat : this.DEFAULT);
    this.form.get('actifVente').setValue(this.filter.actifVente);
    this.form.get('colisage').setValue(this.filter.colisage);
    this.form.get('articleDlc').setValue(this.filter.articleDlc);
    this.form.get('delaiReceptionDlc').setValue(this.filter.delaiReceptionDlc);
  }

  onSubmit() {
    this.filter.groupe                      = this.form.get('groupe').value;
    this.filter.sousGroupe                  = this.form.get('sousGroupe').value;
    this.filter.departement                 = this.form.get('departement').value;
    this.filter.famille                     = this.form.get('famille').value;
    this.filter.sousFamille                 = this.form.get('sousFamille').value;
    this.filter.codeArticle                 = this.form.get('codeArticle').value;
    this.filter.designation                 = this.form.get('designation').value;
    this.filter.commandableExterne          = this.form.get('commandableExterne').value !== Const.DEFAULT ? this.form.get('commandableExterne').value : null;
    this.filter.stockGere                   = this.form.get('stockGere').value;
    this.filter.stockGereOperationnellement = this.form.get('stockGereOperationnellement').value;
    this.filter.marche                      = this.form.get('marche').value;
    this.filter.comparaisonPrixHt           = this.form.get('comparaisonPrixHt').value;
    this.filter.origineAchat                = this.form.get('origineAchat').value !== Const.DEFAULT ? this.form.get('origineAchat').value : null;
    this.filter.actifVente                  = this.form.get('actifVente').value;
    this.filter.colisage                    = this.form.get('colisage').value;
    this.filter.articleDlc                  = this.form.get('articleDlc').value;
    this.filter.delaiReceptionDlc           = this.form.get('delaiReceptionDlc').value;

    this.filterSubmitted.emit(this.filter);
  }
}
