export enum TypeMetaData {
  CREATION     = 'creation',
  MODIFICATION = 'modification',

  // Entrées/sorties
  VALIDATION     = 'validation',
  ENVOI_SF       = 'envoiSf',
  SUPPRESSION_SF = 'suppressionSf',

  // Commandes
  PREPARATION_EJ             = 'preparation_EJ',
  ATTENTE_RETOUR_ORION       = 'attente_retour_orion',
  REFUS_ORION                = 'refus_orion',
  EN_PREPARATION_FOURNISSEUR = 'preparation_fournisseur',
  EN_PREPARATION_MAGASIN     = 'preparation_magasin',
  REFUS_MAGASIN              = 'refus_magasin',
  EN_ACHEMINEMENT            = 'acheminement',
  EN_RECEPTION               = 'reception',
  RECEPTIONNEE               = 'receptionnee',

  // Inventaires
  CLOTURE = 'cloture',

  // FDNC
  EMISSION = 'emission'
}
