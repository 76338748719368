export class CodeEtatCommande {
  public static BROUILLON               = 10;
  public static PREPARATION_EJ          = 13;
  public static EN_ATTENTE_RETOUR_ORION = 15;
  public static REFUSEE_ORION           = 18;
  public static EN_PREPARATION          = 20;
  public static REFUSEE_MAGASIN         = 30;
  public static EN_ACHEMINEMENT         = 35;
  public static EN_RECEPTION            = 40;
  public static RECEPTIONNEE            = 50;
}
