import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {EntreeSortieLigne} from "../model/epona-api/EntreeSortieLigne";
import {TypeNonConformite} from "../model/bna-api/type-non-conformite";

export class CustomValidators {
  // Si zeroInterdit == true et que la valeur saisie est 0
  //  alors une erreur est retournée
  static zeroInterdit = (zeroInterdit: boolean) => {
    return (formControl: UntypedFormControl) => {
      if (zeroInterdit && formControl.value === 0) {
        return {'zeroInterdit': true};
      } else {
        return  null;
      }
    }
  };

  // TODO voir pour fusionner avec zeroInterdit() en ajoutant une valeur par défaut
  static notZero(formControl: UntypedFormControl) {
    if (formControl.value === 0) {
      return {'not_zero': true};
    } else {
      return null;
    }
  }

  // Validation d'une liste d'adresses mails en utilisant le validateur natif
  static emails(control: AbstractControl): ValidationErrors | null {
    if (control.value === '' || !control.value || CustomValidators.validateEmails(control.value)) {
      return null;
    }
    return { emails: true };
  }

  private static validateEmails(emails: string) {
    return (emails.split(';')
      .map(email => Validators.email(<AbstractControl>{ value: email }))
      .find(_ => _ !== null) === undefined);
  }

  static nbMaxDecimals = (nbDecimals: number) => {
    return (formControl: UntypedFormControl) => {
      if (formControl.value && CustomValidators.countDecimals(formControl.value) > nbDecimals) {
        return {'nbMaxDecimals': true};
      } else {
        return  null;
      }
    }
  };

  static validateQuantite = (ligne: EntreeSortieLigne) => {
    return (formControl: UntypedFormControl) => {
      if(formControl.value && ligne && formControl.value < ligne.quantiteLots) {
        return {'quantiteTropPetite': true};
      } else {
        return null;
      }
    }
  }

  // https://stackoverflow.com/questions/27082377/get-number-of-decimal-places-with-javascript
  private static countDecimals = function(value) {
    const text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
      let [base, trail] = text.split('e-');
      return parseInt(trail, 10);
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
      return value.toString().split(".")[1].length || 0;
    }
    return 0;
  }

  static nbMin = (nbMin: number) => {
    return (formControl: FormControl<TypeNonConformite[]>) => {
      if (formControl.value && formControl.value.length < nbMin) {
        return {'nbMin': true};
      } else {
        return  null;
      }
    }
  };

  static nbMax = (nbMax: number) => {
    return (formControl: FormControl<TypeNonConformite[]>) => {
      if (formControl.value && formControl.value.length > nbMax) {
        return {'nbMax': true};
      } else {
        return  null;
      }
    }
  };
}
