import {Article} from "./Article";
import {SousLotZgDTO} from "./SousLotZgDTO";

export class ArticleDTO {
  idArticle: number;

  sousLotZg: SousLotZgDTO;

  constructor(article: Article){
    this.idArticle = article.idArticle;
    this.sousLotZg = article.sousLotZg ? new SousLotZgDTO(article.sousLotZg) : null;
  }
}
