import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpService} from "../http.service";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {ParametrageLieuArticle} from "../../model/epona-api/ParametrageLieuArticle";
import {ParametrageLieuFournisseur} from "../../model/epona-api/parametrage-lieu-fournisseur";
import {ParametrageLieuArticlePOST} from "../../model/epona-api/parametrageLieuArticlePOST";
import {ParametrageLieuFournisseurPost} from "../../model/epona-api/parametrage-lieu-fournisseur-post";
import {ParametrageLieuFournisseurPut} from "../../model/epona-api/parametrage-lieu-fournisseur-put";

@Injectable({
  providedIn: 'root'
})
export class ParametrageLieuFournisseurService {

  private env = environment;
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) {
    this.baseUrl = this.env.eponaApiUrl + '/parametrages-lieu-fournisseur';
  }

  getListeParametragesLieuFournisseur(idFournisseur: number, fields: string): Observable<ParametrageLieuFournisseur[]>{
    const params = this.httpService.buildParams({
      idFournisseur: idFournisseur.toString(),
      fields: fields
    });

    return this.httpClient.get<ParametrageLieuFournisseur[]>(this.baseUrl, {params});
  }


  postParametrageLieuFournisseur(parametrageLieuArticle: ParametrageLieuFournisseurPost): Observable<ParametrageLieuFournisseur>{
    return this.httpClient.post<ParametrageLieuFournisseur>(this.baseUrl, parametrageLieuArticle);
  }

  putParametrageLieuFournisseur(idParametrageLieuFournisseur: number, parametrageLieuArticle: ParametrageLieuFournisseurPut): Observable<ParametrageLieuFournisseur>{
    return this.httpClient.put<ParametrageLieuFournisseur>(this.baseUrl + '/' + idParametrageLieuFournisseur, parametrageLieuArticle);
  }

  deleteParametrageLieuFournisseur(idParametrageLieuFournisseur: number) {
    return this.httpClient.delete (this.baseUrl + '/' + idParametrageLieuFournisseur);
  }

}
