import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogErrorsData} from "../../model/epona-ui/DialogErrorsData";

@Component({
  selector: 'app-dialog-errors',
  templateUrl: './dialog-errors.component.html',
  styleUrls: ['./dialog-errors.component.css']
})
export class DialogErrorsComponent {

  constructor(private dialogRef: MatDialogRef<DialogErrorsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogErrorsData) { }

}
