import {Article} from "../model/epona-api/Article";
import {UtilisationArticle} from "./constants/UtilisationArticle";
import {Tools} from "./Tools";

export class ArticleTools {
  public static readonly PROP_CODE        = 'codeArticle';
  public static readonly PROP_DESIGNATION = 'designation';

  static getProperty(property: string, article: Article, utilisation: string = UtilisationArticle.ACHAT, strict: boolean = false): string {
    if (!article) {
      return '';
    }

    // Le type de l'article correspondant à l'utilisation demandée doit être renseigné
    if (utilisation === UtilisationArticle.ACHAT && Tools.isEmpty(article.articleAchat)) {
      return '(propriété articleAchat non renseignée)';
    }
    if (utilisation === UtilisationArticle.VENTE && Tools.isEmpty(article.articleVente)) {
      return '(propriété articleVente non renseignée)';
    }

    const estArticleAchat = article.articleAchat === true;
    const estArticleVente = article.articleVente === true;

    // En cas d'affichage strict, le type de l'article doit correspondre à l'utilisation demandée
    if (strict && utilisation === UtilisationArticle.ACHAT && !estArticleAchat) {
      return '';
    }
    if (strict && utilisation === UtilisationArticle.VENTE && !estArticleVente) {
      return '';
    }

    // La propriété 'à la vente' est retournée uniquement
    //  s'il s'agit de l'affichage demandé et qu'il s'agit d'un article à la vente
    //  ou qu'il ne s'agit pas d'un article à l'achat
    if (utilisation === UtilisationArticle.VENTE && estArticleVente || !estArticleAchat) {
      return ''+article[property+'Vente'];
    } else {
      return ''+article[property+'Achat'];
    }
  }

  static getCodeArticle(article: Article, utilisation: string = UtilisationArticle.ACHAT, strict: boolean = false): string {
    return ArticleTools.getProperty(ArticleTools.PROP_CODE, article, utilisation, strict);
  }
  static getDesignation(article: Article, utilisation: string = UtilisationArticle.ACHAT, strict: boolean = false): string {
    return ArticleTools.getProperty(ArticleTools.PROP_DESIGNATION, article, utilisation, strict);
  }
}
