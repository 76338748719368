import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {MessageTool} from "../../commons/MessageTool";
import {CommandeService} from "../../services/epona/commande.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DialogDataModificationCourrielCommande} from "../../model/epona-ui/DialogDataModificationCourrielCommande";
import {CommandeCourriel} from "../../model/epona-api/CommandeCourriel";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {CustomValidators} from "../../commons/CustomValidators";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-dialog-modification-courriel-commande',
  templateUrl: './dialog-modification-courriel-commande.component.html',
  styleUrls: ['./dialog-modification-courriel-commande.component.css']
})
export class DialogModificationCourrielCommandeComponent implements OnInit {

  // Clé du mail demandeur dans le localStorage
  private CODE_MAIL = 'mailDemandeurCommande';

  dialogForm: UntypedFormGroup;
  courriel: CommandeCourriel;
  nomFichier: string;
  estEnvironnementProduction: boolean = false;

  constructor(public dialog: MatDialogRef<DialogModificationCourrielCommandeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataModificationCourrielCommande,
              private messageTool: MessageTool,
              public dialogConf: MatDialog,
              private commandeService: CommandeService,
              private fb: UntypedFormBuilder) {

    this.dialogForm =      fb.group({
      mailDemandeur:       fb.control('', Validators.email),
      mailsDemandeursSupp: fb.control('', CustomValidators.emails),
      mailFournisseur:     fb.control('', Validators.email),
      sujet:               fb.control(''),
      message:             fb.control('')
    });

    this.estEnvironnementProduction = environment.env === 'PRD';
  }

  estBrouillon(): boolean {
    return this.commandeService.estBrouillon(this.data.entete);
  }

  estPartiellementModifiable(): boolean {
    return this.commandeService.estPartiellementModifiable(this.data.entete);
  }

  ngOnInit(): void {
    if (this.data.entete) {
      // Chargement du courriel correspondant
      this.commandeService.getCourriel(this.data.entete.idCommandeEntete, null).subscribe(data => {
        if (data) {
          this.courriel = data;

          if (!this.courriel.mailDemandeur && localStorage.getItem(this.CODE_MAIL)) {
            this.courriel.mailDemandeur = localStorage.getItem(this.CODE_MAIL);
          }

          this.dialogForm.get('mailDemandeur').setValue(data.mailDemandeur);
          this.dialogForm.get('mailsDemandeursSupp').setValue(DialogModificationCourrielCommandeComponent.cleanEmails(data.mailsDemandeursSupp));
          this.dialogForm.get('mailFournisseur').setValue(data.mailFournisseur);
          this.dialogForm.get('sujet').setValue(data.sujet);
          this.dialogForm.get('message').setValue(data.message);

          if (this.courriel.interne) {
            this.dialogForm.get('mailFournisseur').disable();
            this.dialogForm.get('sujet').disable();
            this.dialogForm.get('message').disable();
          }

          this.nomFichier = this.commandeService.defaultFilenamePdf(this.data.entete);

        } else {
          this.courriel = new CommandeCourriel();
        }

        if (!this.estBrouillon() && !this.estPartiellementModifiable() || !this.commandeService.droitSaisie()) {
          this.dialogForm.disable();
        }

        if (!this.data.entete.sousLotZg) {
          this.dialogForm.get('mailFournisseur').disable();
        }

      }, error => {
        this.messageTool.sendError(error);
      });
    }
  }

  // Permet de supprimer les pint-virgules superflux
  private static cleanEmails(emails: string): string {
    if (!emails) {
      return null;
    }
    return emails.split(';').filter(email => email.length > 0).join(';');
  }


  downloadPdf(): void {
    this.commandeService.downloadPdf(this.data.entete);
  }

  downloadMail(): void {
    this.commandeService.downloadMail(this.data.entete);
  }

  close() {
    this.data.changement = false;
    this.dialog.close();
  }

  save() {
    this.courriel.mailDemandeur       = this.dialogForm.get('mailDemandeur').value;
    this.courriel.mailsDemandeursSupp = this.dialogForm.get('mailsDemandeursSupp').value;
    this.courriel.mailFournisseur     = this.dialogForm.get('mailFournisseur').value;
    this.courriel.sujet               = this.dialogForm.get('sujet').value;
    this.courriel.message             = this.dialogForm.get('message').value;

    // Stockage du mailDemandeur dans localStorage
    if (this.courriel.mailDemandeur) {
      localStorage.setItem(this.CODE_MAIL, this.courriel.mailDemandeur);
    }

    this.commandeService.putCourriel(this.data.entete.idCommandeEntete, this.courriel).subscribe(() => {
      this.messageTool.sendSuccess("Le courriel a été modifié avec succès");
      this.data.changement = true;
      this.dialog.close();

    }, err => {
      this.messageTool.sendError(err);
    })
  }

  envoyerDeNouveau(): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Nouvel envoi du courriel",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: "Confirmez-vous le nouvel envoi de ce courriel ?"};

    const dialogRef = this.dialogConf.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commandeService.postNouvelEnvoiCourriel(this.data.entete.idCommandeEntete).subscribe(() => {
          this.messageTool.sendSuccess("Le courriel a été envoyé de nouveau avec succès");
          this.data.changement = false;
          this.dialog.close();
        }, err => {
          this.messageTool.sendError(err);
        });
      }
    });
  }

  estEnvoyableDeNouveau(): boolean {
    return this.commandeService.estEnPreparation(this.data.entete);
  }
}
