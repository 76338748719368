import {Lieu} from './Lieu';
import {Extra} from './Extra';

export class InventaireEntete {
  idInventaireEntete: number;
  libelle: string;
  commentaire: string;
  dateCreation: Date;
  utilisateur: string;
  dateModification: Date;
  utilisateurModification: string;
  dateCloture: Date;
  utilisateurCloture: string;
  lieu: Lieu;
  extra: Extra;
}
