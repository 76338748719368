<epona-select-lieu [lieu]="lieuCourant"
                   [livraisonUniquement]="true"
                   lieuLabel="Lieu de livraison"
                   [disabled]="!!entete"
                   (lieuSubmitted)="onLieuSubmitted($event)"></epona-select-lieu>
<div style="height: 10px"></div>
<h1 *ngIf="!entete">Création d'un panier</h1>
<h1 *ngIf="entete && droitModification">Modification du panier</h1>
<h1 *ngIf="entete && !droitModification">Consultation du panier</h1>

<div class="fiche">
  <epona-panier-entete [entete]="entete"
                       [lignes]="lignes"
                       [lieuCourant]="lieuCourant"
                       (suppressionSubmitted)="doSuppression($event)"
                       (generationCommandeSubmitted)="doGenerationCommandes($event)"
                       (enteteSubmitted)="doActionEntete($event)"
                         class="entete">
  </epona-panier-entete>

  <epona-panier-lignes
                        [entete]="entete"
                        [lignes]="lignes"
                        (lignesDeleted)="doHandleLignesDeleted($event)"
                        (debutModification)="doDebutModificationLigne()"
                        (finModification)="doFinModificationLigne()"
                         class="lignes">
  </epona-panier-lignes>
</div>
