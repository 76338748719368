import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {MessageTool} from "../../commons/MessageTool";
import {DomSanitizer} from "@angular/platform-browser";
import {FormBuilder} from "@angular/forms";
import * as FileSaver from 'file-saver';

export class DialogDataSeeDocument {
  file: Blob;
  filename: string;
}

@Component({
  selector: 'app-dialog-see-documents',
  templateUrl: './dialog-see-documents.component.html',
  styleUrls: ['./dialog-see-documents.component.css']
})
export class DialogSeeDocumentsComponent {

  currentFile?: File;
  message = '';
  preview = '';
  previewImg : any;
  idFicheTechniqueEntete: number;
  file: Blob;
  filename: string;
  isLoading: boolean = false;
  isPdfFile: boolean = false;


  constructor(  public dialogRef: MatDialogRef<DialogSeeDocumentsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogDataSeeDocument,
                private entreeSortieService: EntreeSortieService,
                private messageService: MessageTool,
                private sanitizer: DomSanitizer,
                private fb: FormBuilder) {
    this.file = data.file;
    this.filename = data.filename;
  }
  ngOnInit(): void {
    let filenameSplit = this.filename.split('.');
    let extension = filenameSplit[filenameSplit.length -1];
    if(extension === 'pdf'){
      this.isPdfFile = true;
    } else {
      this.isPdfFile = false;
    }
    this.readBlob();
  }

  readBlob(){
    this.preview = URL.createObjectURL(this.file);
    if(!this.isPdfFile){
      this.previewImg = this.sanitizer.bypassSecurityTrustUrl(this.preview);
    }

  }


  close() {
    this.dialogRef.close();
  }

  upload() {
    FileSaver.saveAs(this.file, this.filename);
  }

}
