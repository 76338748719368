import {Const} from "./constants/Const";

export class Tools {
  static removeDiacritics(str: String) : string {
    /**
     * normalize("NFD") : décompose les caractères avec accents. Ex : é devient e'
     * replace(/[\u0300-\u036f]/g, "") : remplace les caractères avec accents par une chaîne vide
     * Exemple d'utilisation : rené ==> rene' ==> rene
     */
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") ;
  }

  static transformDefaultAsNull<T>(obj: T): T {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'number' && +obj[key] === Const.DEFAULT) {
          obj[key] = null;
        }
      }

    return obj;
  }

  public static datesAreEquals(date1: Date, date2: Date) {
    return Tools.isEmpty(date1) && Tools.isEmpty(date2)                                                                     // les deux dates sont vides
      || !Tools.isEmpty(date1) && !Tools.isEmpty(date2) && new Date(date1).toISOString() === new Date(date2).toISOString()  // les deux dates sont non vides et identiques
  }

  public static isEmpty(value: Date | number | string | Object | boolean): boolean {
    if (value instanceof Object) {
      for(const prop in value) {
        if(value.hasOwnProperty(prop)) {
          return false;
        }
      }

      return JSON.stringify(value) === JSON.stringify({});

    } else {
      return value === undefined || value === null;
    }
  }

  public static stringToBoolean(value: string): boolean {
    if (value) {
      return value.toLocaleLowerCase() === "true";
    }

    return false;
  }

  public static removeItemFromStringArray(item: string, array: string[]) {
    const index = array.indexOf(item, 0);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  public static signerValeur(value: number, quantitesPotitives: boolean): number {
    const multiplicateur = quantitesPotitives ? 1 : -1;
    return !value ? value : multiplicateur * value;
  }
}
