import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {MessageTool} from '../../commons/MessageTool';
import {HttpService} from "../http.service";
import {StockCompact} from "../../model/epona-api/StockCompact";
import {StockCompactSearch} from "../../model/epona-api/StockCompactSearch";
import {MouvementStock} from "../../model/epona-api/MouvementStock";
import {MouvementStockSearch} from "../../model/epona-api/MouvementStockSearch";
import {StockSearch} from "../../model/epona-api/StockSearch";
import {Stock} from "../../model/epona-api/Stock";
import {ValorisationStock} from "../../model/epona-api/ValorisationStock";
import {ValorisationStockSearch} from "../../model/epona-api/ValorisationStockSearch";

@Injectable()
export class StockService {

  private env = environment;

  constructor(private httpClient: HttpClient,
              private messageTool: MessageTool,
              private httpService: HttpService) {
  }

  getListeStocks(search: StockSearch): Observable<Stock[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<Stock[]>(this.env.eponaApiUrl + '/stocks', {params});
  }

  getListeStocksCompacts(search: StockCompactSearch): Observable<StockCompact[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<StockCompact[]>(this.env.eponaApiUrl + '/stocks-compacts', {params});
  }

  getListeMouvementsStocks(search: MouvementStockSearch): Observable<MouvementStock[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<MouvementStock[]>(this.env.eponaApiUrl + '/mouvements-stocks', {params});
  }

  getListeValorisationsStocks(search: ValorisationStockSearch): Observable<ValorisationStock[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<ValorisationStock[]>(this.env.eponaApiUrl + '/valorisations-stocks', {params});
  }
}
