import {IdStatutFdnc} from "../../commons/constants/IdStatutFdnc";

export class StatutFdnc {
  static readonly STATUT_EPONA = new StatutFdnc(IdStatutFdnc.NON_EMISE, 'Non émise');

  idStatutFdnc: number;
  libelle!: string;

  constructor(idStatutFdnc: number, libelle?: string) {
    this.idStatutFdnc = idStatutFdnc;
    this.libelle      = libelle;
  }
}
