import {CommandeEntete} from "./CommandeEntete";
import {CommandeEntetePATCH} from "./CommandeEntetePATCH";

export class CommandeEntetePUT extends CommandeEntetePATCH {
  dateLivraisonSouhaitee: Date;
  codeUf: string;
  nombreClientsEstimatif: number;
  fraisPortPrevisionnels: number;

  constructor(commandeEntete: CommandeEntete) {
    super(commandeEntete);
    this.dateLivraisonSouhaitee = commandeEntete.dateLivraisonSouhaitee;
    this.codeUf                 = commandeEntete.codeUf;
    this.nombreClientsEstimatif = commandeEntete.nombreClientsEstimatif;
    this.fraisPortPrevisionnels = commandeEntete.fraisPortPrevisionnels;
    this.commentaire            = commandeEntete.commentaire;
    this.codeClientDemandeur    = commandeEntete.codeClientDemandeur;
  }
}
