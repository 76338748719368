export class TempsTraitementFdnc {

  idTempsTraitementFdnc: number;
  libelle: string;

  static sort(a: TempsTraitementFdnc, b: TempsTraitementFdnc): number {
    return a.idTempsTraitementFdnc < b.idTempsTraitementFdnc ? -1 : a.idTempsTraitementFdnc === b.idTempsTraitementFdnc ? 0 : 1;
  }

  constructor(idTempsTraitementFdnc: number) {
    this.idTempsTraitementFdnc = idTempsTraitementFdnc;
  }
}
