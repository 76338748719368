import {Lieu} from "./Lieu";
import {Fournisseur} from "./Fournisseur";

export class ParametrageLieuFournisseur {
  idParametrageLieuFournisseur: number;
  lieu: Lieu;
  fournisseur: Fournisseur;
  codeClient: string;
  mail: string;
}
