<div class='alert alert-warning' *ngIf="afficherAvertissementSnapshot">
  <mat-icon>warning</mat-icon>
  Une phase de test est en cours sur cet environnement. Il n'est donc plus à l'image de l'environnement de production.
</div>

<div class='alert alert-warning' *ngIf="afficherAvertissementRelease">
  <mat-icon>warning</mat-icon>
  La version déployée sur cet environnement est la version qui sera déployée sous peu en production.
</div>

<mat-card class="etablissement-card">
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <mat-label>Établissement</mat-label>
        <select-etablissement formControlName="etablissement" [required]="true"></select-etablissement>
        <mat-error *ngIf="form.get('etablissement')!.hasError('required')">Merci de sélectionner un établissement</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" [disabled]="form.invalid">Valider</button>
    </form>
  </mat-card-content>
</mat-card>
