<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Ajout d'une ligne</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <div class="question">Article {{ data.article | codeArticle }} - {{ data.article | designation }}</div>

  <mat-radio-group [formControl]="typeAjoutLigneCtrl" class="radio-group" (change)="updateStatusDlc()">
    <mat-radio-button [value]="typeAjoutLigne.SANS_DLC">Sans DLC</mat-radio-button>
    <mat-radio-button [value]="typeAjoutLigne.AVEC_DLC">Avec DLC</mat-radio-button>
  </mat-radio-group>

  <mat-form-field (click)="enableFieldDlc()">
    <mat-label>Choisir une DLC</mat-label>
    <input #dlc matInput
           [matDatepicker]="picker"
           [formControl]="dlcCtrl"
           required
           (click)="picker.open()"
           (focus)="picker.open()"
           autocomplete="off">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="dlcCtrl.hasError('required')">DLC obligatoire</mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Annuler</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="dlcCtrl.invalid">Ajouter</button>
</mat-dialog-actions>
