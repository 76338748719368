<div>
  <div class="table-actions">
    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogCreationFdnc()"
            *ngIf="droitSaisie">
      <fa-icon icon="plus"></fa-icon> Créer…
    </button>

    <button type="button" mat-raised-button color="primary"
            (click)="cnousTableExporter.exportTable({nomFichier: 'liste-fdnc.xlsx'})"
            [disabled]="listeFDNC.length === 0">
      <fa-icon icon="file-excel"></fa-icon> Exporter
    </button>
  </div>

  <epona-tableau-fdnc [listeFdnc]="listeFDNC"
                      [colonnesAffichees]="colonnesAffichees"></epona-tableau-fdnc>
</div>
