import {InventaireLigne} from "./InventaireLigne";

export class InventaireLignePUT {

  idInventaireLigne: number;
  commentaire: string
  quantiteStockConstatee: number;
  pmpHtConstate: number;
  pmpTtcConstate: number;

  constructor(inventaireLigne: InventaireLigne) {
    this.idInventaireLigne = inventaireLigne.idInventaireLigne;
    this.commentaire = inventaireLigne.commentaire;
    this.quantiteStockConstatee = inventaireLigne.quantiteStockConstatee;
    this.pmpHtConstate = inventaireLigne.pmpHtConstate;
    this.pmpTtcConstate = inventaireLigne.pmpTtcConstate;
  }
}
