import { Component, OnInit } from '@angular/core';
import {HttploaderService} from './httploader.service';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-httploader',
  templateUrl: './httploader.component.html',
  styleUrls: ['./httploader.component.css']
})
export class HttploaderComponent implements OnInit{

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  eventsSubscription: Subscription;
  isLoading: boolean;

  constructor(private loaderService: HttploaderService){  }

  ngOnInit(): void {

    this.eventsSubscription = this.loaderService.isLoading.subscribe(res => {
      this.isLoading = res;
    });
  }

}
