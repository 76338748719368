import {EngagementJuridiqueCumul} from "./EngagementJuridiqueCumul";
import {TypeTva} from "./TypeTva";
import {ServiceGestionnaire} from "./ServiceGestionnaire";
import {Destination} from "./Destination";
import {Operation} from "./Operation";
import {ElementAxeComplementaire} from "./ElementAxeComplementaire";
import {Evenement} from "./Evenement";

export class EngagementJuridiqueLigne {
  idEngagementJuridiqueLigne: number;
  cumul: EngagementJuridiqueCumul;
  numeroLigne: number;
  destination: Destination;
  operation: Operation;
  serviceGestionnaire: ServiceGestionnaire;
  elementAxeComplementaire: ElementAxeComplementaire;
  evenement: Evenement;
  typeTva: TypeTva;
  montantHt: number;
}
