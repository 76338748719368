import {Directive, ElementRef, Input} from '@angular/core';
import * as XLSX from "xlsx";
import {TableColumn} from "../inputs/form-displayed-columns/form-displayed-columns.component";
import {Format} from "../constants/Format";
import {TypeExport} from "../constants/type-export";
import {CellObject} from "xlsx";

@Directive({
  selector: '[cnousTableExporter]',
  exportAs: "cnousTableExporter"
})
export class CnousTableExporterDirective {

  @Input() columns :{[key: string]: TableColumn};

  constructor(private element: ElementRef) {}

  exportTable(infos: ExportInfos = null) {
    // initialisation des valeurs par defaut
    infos.nomFichier = infos.nomFichier ? infos.nomFichier : "export.xlsx";
    const hiddenColumns = this.buildHiddenColumnsExport(this.columns);
    this.setDisplayOfHiddenColumnsExport(hiddenColumns,true);
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(
        this.element.nativeElement, {display: true, raw: true}
    );
    this.setDisplayOfHiddenColumnsExport(hiddenColumns, false);
    const tabHeaders:HTMLCollection = this.element.nativeElement.getElementsByTagName('th');
    let indexDisplayedColumns: {index: number, type: {code: string, type: string}}[];
    if (tabHeaders.length !== 0) {
      indexDisplayedColumns = this.getDisplayedColumnsFromHTMLTableHeaders(tabHeaders, hiddenColumns);
      this.formatWorkSheet(ws, indexDisplayedColumns);
    }
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'EXPORT');
    /* save to file */
    XLSX.writeFile(wb,  infos.nomFichier);
  }

  private setDisplayOfHiddenColumnsExport(hiddenColumnsExport: number[], hidden: boolean): void {
    const tr: HTMLCollection = this.element.nativeElement.getElementsByTagName('tr');
    if (tr.length > 0) {
      hiddenColumnsExport.forEach(index => {
        Array.from(tr).forEach((element) => {
          let th = element.getElementsByTagName('th')[index];
          if (th) {
            th.hidden = hidden;
          } else {
            let td = element.getElementsByTagName('td')[index];
            if (td) {
              td.hidden = hidden;
            }
          }
        })
      });
    }
  }

  buildHiddenColumnsExport(availableColumns: {[key: string]: TableColumn}): number[] {
    const tab:HTMLCollection = this.element.nativeElement.getElementsByTagName('th');
    let displayedColumns = [].slice.call(tab).filter(o => !o.getAttribute('colspan')).map((o) => {
      return o.getAttribute('class').split(' ').find(s => s.startsWith('mat-column-')).substring(11);
    });
    const hiddenCols: number[] = [];
    for (let i = 0; i < displayedColumns.length; i++) {
      if (availableColumns[displayedColumns[i]].export === false) {
        hiddenCols.push(i);
      }
    }
    return hiddenCols;
  }

  getDisplayedColumnsFromHTMLTableHeaders(tabHeaders:HTMLCollection, hiddenColumns: number[]): {index: number, type: {code: string, type: string}}[] {
    return [].slice.call(tabHeaders).filter(o => !o.getAttribute('colspan')).map((o, index) => {
      let columnName = o.getAttribute('class').split(' ').find(s => s.startsWith('mat-column-')).substring(11);
      if (undefined === hiddenColumns.find(ho => index === ho)) return {
        index: index,
        type: Object.entries(this.columns).map((key) => {
          return {code: key[0], type: key[1].exportFormat}
        }).find(o => o.code == columnName)
      };
    }).filter(o => o !== undefined);
  }

  formatCell(cell: CellObject, indexDisplayedColumn: {index: number, type: {code: string, type: string}}) {
    switch (indexDisplayedColumn['type']['type']) {
      case 'integer':
        const regex = /^[0-9\s]+$/g;
        if (cell.v.toString().match(regex)) {
          cell.v = this.stringToNumberFormat(cell.v);
          cell.t = TypeExport.INTEGER;
        }
        break;
      case 'decimal':
        cell.v = this.decimalToEnglishFormat(cell.v);
        cell.t = TypeExport.INTEGER;
        cell.z = Format.FORMAT_DECIMAL;
        break;
      case 'date':
        if (cell.v) {
          cell.v = this.dateToEnglishFormat(cell.v);
          cell.t = TypeExport.DATE;
        }
        break;
      case 'datetime':
        if (cell.v) {
          cell.v = this.dateTimeToEnglishFormat(cell.v);
          cell.t = TypeExport.DATE;
          cell.z = Format.FORMAT_DATE_TIME;
        }
        break;
      default:
        cell.t = TypeExport.STRING;
    }
  }

  formatWorkSheet(ws: XLSX.WorkSheet, indexDisplayedColumns: {index: number, type: {code: string, type: string}}[]) {
    const nbLignesEntetes = this.element.nativeElement.getElementsByTagName('thead')[0].getElementsByTagName('tr').length;
    const nbLignes = this.element.nativeElement.getElementsByTagName('tbody')[0].getElementsByTagName('tr').length;

    const range = { s: { r: 0, c: 0 }, e: { r: nbLignes, c: indexDisplayedColumns.length } };
    for (let C = range.s.c; C <= range.e.c; ++C) { // 0 > 12
      for (let R = range.s.r+nbLignesEntetes; R <= range.e.r+nbLignesEntetes-1; ++R) { // 2 > 2
        let cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        if (cell) {
          this.formatCell(cell, indexDisplayedColumns[C])
        }
      }
    }
  }

  stringToNumberFormat(value: CellValue): string {
    // enlève l'espace des milliers dans les nombres
    return value.toString().replace(/\s/g, '');
  }

  decimalToEnglishFormat(value: CellValue): string {
    value = this.stringToNumberFormat(value);
    return value.replace(',', '.');
  }

  dateToEnglishFormat(d: CellValue): string {
    let tab = d.toString().split('/');
    const temp = tab[0]
    tab[0] = tab[1];
    tab[1] = temp;
    return tab.join('/');
  }

  dateTimeToEnglishFormat(dt: CellValue): string {
    const datetime: string[] = dt.toString().split(' ');
    return this.dateToEnglishFormat(datetime[0]) + ' ' + datetime[1];
  }

}

type CellValue = string | number | boolean | Date;

interface ExportInfos {
  nomFichier?: string;
}
