import {EntreeSortieLigne} from "../epona-api/EntreeSortieLigne";
import {EntreeSortieEntete} from "../epona-api/EntreeSortieEntete";
import {EntreeSortieParametrage} from "./EntreeSortieParametrage";

export class DialogDataLigneLotEntreeSortie {

  entete: EntreeSortieEntete;
  ligne: EntreeSortieLigne;
  params: EntreeSortieParametrage;
}
