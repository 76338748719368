import {Fournisseur} from "./Fournisseur";

export class FournisseurPUT {

  codeClient: string;
  telephone: string;
  fax: string;
  mail: string;

  constructor(fournisseur: Fournisseur) {
    this.codeClient = fournisseur.codeClient;
    this.telephone = fournisseur.telephone;
    this.fax = fournisseur.fax;
    this.mail = fournisseur.mail;
  }
}
