<mat-select multiple="true"
            [formControl]="formCtrl"
            (selectionChange)="selectionChanged()"
            [placeholder]="defaultLabel"
            *ngIf="displayType === 'select'"
            #select>
  <mat-select-trigger>
    <ul *ngIf="select.value.length > 0">
      <li *ngFor="let item of value">{{ item.libelle }}</li>
    </ul>
  </mat-select-trigger>

  <mat-option *ngFor="let item of liste" [value]="item.code" [disabled]="!item.actif">{{ item.libelle }}</mat-option>
</mat-select>

<form [formGroup]="formGroup" *ngIf="displayType === 'checkbox'">
  <ul *ngIf="liste.length > 0">
    <li formArrayName="types" *ngFor="let item of formArray.controls; let i = index">
      <mat-checkbox [formControlName]="i" (change)="selectionChanged()">{{ liste[i].libelle }}</mat-checkbox>

      <aide *ngIf="liste[i].description">
        <dl class="aide-contenu">
          <dt>{{ liste[i].libelle }}</dt>
          <dd>{{ liste[i].description }}</dd>
        </dl>
      </aide>
    </li>
  </ul>
</form>
