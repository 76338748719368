import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {DocumentJoint} from "../../model/epona-api/DocumentJoint";

@Component({
  selector: 'app-dialog-gestion-documents',
  templateUrl: './dialog-gestion-documents.component.html',
  styleUrls: ['./dialog-gestion-documents.component.css']
})
export class DialogGestionDocumentsComponent {

  listeDocumentsModifiee: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogGestionDocumentsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataGestionDocuments) {
  }

  onListeDocumentsModifiee() {
    this.listeDocumentsModifiee = true;
  }

  close() {
    this.dialogRef.close(this.listeDocumentsModifiee);
  }
}

export class DialogDataGestionDocuments {
  loadListeDocumentsFn: () => Observable<DocumentJoint[]>;
  loadDocumentFn: (idDocument: number) => Observable<DocumentJoint>;
  downloadFileFn: (idDocument: number) => Observable<any>;
  deleteDocumentFn: (idDocument: number) => Observable<any>;
  postDocumentFn: (document: DocumentJoint, file: File) => Observable<any>;
  putDocumentFn: (document: DocumentJoint, file: File) => Observable<any>;

  titre: string;
  droitSaisie: boolean;
  modifiable: boolean;
  nbDocumentsMax: number;
}
