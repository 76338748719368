<!--Barre de navigation globale supérieure de l'application-->
<mat-toolbar color="primary" *ngIf="user != null">
  <button mat-fab
          class="application-name"
          matTooltip="Accueil ({{ nomCrous }})"
          routerLink="/accueil" [class.disabled]="!(onlineService.isOnline | async)">Épona</button>

  <!-- Début outil de petite taille -->
  <div class="menus-root-small">
    <button mat-icon-button [matMenuTriggerFor]="menusSmall">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <mat-menu #menusSmall>
    <div class="menus menus-small" (click) = "$event.stopPropagation()">
      <ng-template [ngTemplateOutlet]="menus"></ng-template>
    </div>
  </mat-menu>
  <!-- Fin outil de petite taille -->

  <!-- Début outil de grande taille -->
  <div class="menus menus-large">
    <ng-template [ngTemplateOutlet]="menus"></ng-template>
  </div>
  <!-- Fin outil de grande taille -->

  <span class="spacer"></span>
  <div *ngIf="!(onlineService.isOnline | async)" matTooltip="offline {{environment.env}}" style="margin-right: 10px" class="offline">
    Hors ligne
  </div>
  <span class="spacer"></span>

  <button type="button"
          mat-fab
          matTooltip="Déconnexion"
          aria-pressed="false"
          *ngIf="(onlineService.isOnline | async)"
          (click)="openDialogDeconnexion()">
    <span class="material-icons">exit_to_app</span>
  </button>
</mat-toolbar>

<div class="environment" *ngIf="environment.env != 'PRD'" matTooltip="Environnement {{environment.env}}">
  {{environment.env}}
</div>

<ng-template #menus>
  <a mat-button routerLink="etat-stocks"
     [ngClass]="{'activated': isActivatedM(MENUS.STOCKS)}"
     *ngIf="possedeUnDroit(CD.STOCKS_CONSULTATION)" [class.disabled]="!(onlineService.isOnline | async)">Stocks</a>

  <button mat-button [matMenuTriggerFor]="menuCommandes"
          *ngIf="possedeUnDroit([CD.COMMANDES_CONSULTATION, CD.PANIERS_CONSULTATION])"
          [ngClass]="{'activated': isActivatedM(MENUS.COMMANDES)}"  [class.disabled]="!(onlineService.isOnline | async)">Commandes</button>
  <mat-menu #menuCommandes>
    <a mat-menu-item routerLink="liste-commandes"
       *ngIf="possedeUnDroit(CD.COMMANDES_CONSULTATION)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.COMMANDES)}" [class.disabled]="!(onlineService.isOnline | async)">Commandes</a>
    <a mat-menu-item routerLink="liste-paniers"
       *ngIf="possedeUnDroit(CD.PANIERS_CONSULTATION)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.PANIERS)}" [class.disabled]="!(onlineService.isOnline | async)">Paniers</a>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="menuLivraisons"
          *ngIf="possedeUnDroit([CD.LIVRAISONS_CONSULTATION, CD.FDNC_CONSULTATION])"
          [ngClass]="{'activated': isActivatedM(MENUS.LIVRAISONS)}"  [class.disabled]="!(onlineService.isOnline | async)">Livraisons</button>
  <mat-menu #menuLivraisons>
    <a mat-menu-item routerLink="liste-bordereaux-livraison"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.LIVRAISONS)}"
       *ngIf="possedeUnDroit(CD.LIVRAISONS_CONSULTATION)" [class.disabled]="!(onlineService.isOnline | async)">Livraisons</a>

    <a mat-menu-item routerLink="recherche-lots"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.LOTS)}"
       *ngIf="possedeUnDroit(CD.LIVRAISONS_CONSULTATION)" [class.disabled]="!(onlineService.isOnline | async)">Lots</a>

    <a mat-menu-item routerLink="liste-fdnc"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.FDNC)}"
       *ngIf="possedeUnDroit(CD.FDNC_CONSULTATION)" [class.disabled]="!(onlineService.isOnline | async)">FDNC</a>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="menuMouvements"
          *ngIf="possedeUnDroit([CD.GESTION_PERTES, CD.GESTION_TRANSFERTS, CD.GESTION_CONSOMMATIONS_UTILISATIONS, CD.GESTION_REINTEGRATIONS])"
          [ngClass]="{'activated': isActivatedM(MENUS.MOUVEMENTS)}"  [class.disabled]="!(onlineService.isOnline | async)">Mouvements</button>
  <mat-menu #menuMouvements>
    <a mat-menu-item routerLink="liste-pertes"
       *ngIf="possedeUnDroit(CD.GESTION_PERTES)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.PERTES)}" [class.disabled]="!(onlineService.isOnline | async)">Pertes</a>
    <a mat-menu-item routerLink="liste-bordereaux-transfert"
       title="Bordereaux de transfert vers d'autres lieux"
       *ngIf="possedeUnDroit(CD.GESTION_TRANSFERTS)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.TRANSFERTS)}" [class.disabled]="!(onlineService.isOnline | async)">Transferts</a>
    <a mat-menu-item routerLink="liste-bordereaux-sortie"
       title="Bordereaux de sortie pour réunion, pot, etc."
       *ngIf="possedeUnDroit(CD.GESTION_TRANSFERTS)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.SORTIES_EVENEMENTIELLES)}" [class.disabled]="!(onlineService.isOnline | async)">Sorties évènementielles</a>
    <a mat-menu-item routerLink="liste-retours-sorties"
       title="Retours de sortie évènementielle"
       *ngIf="possedeUnDroit(CD.GESTION_TRANSFERTS)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.RETOURS_SORTIES)}" [class.disabled]="!(onlineService.isOnline | async)">Retours de sortie</a>
    <a mat-menu-item routerLink="liste-consommations-utilisations"
       *ngIf="possedeUnDroit(CD.GESTION_CONSOMMATIONS_UTILISATIONS)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.CONSOMMATIONS_UTILISATIONS)}" [class.disabled]="!(onlineService.isOnline | async)">Consommations/utilisations</a>
    <a mat-menu-item routerLink="liste-reintegrations"
       *ngIf="possedeUnDroit(CD.GESTION_REINTEGRATIONS)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.REINTEGRATIONS)}" [class.disabled]="!(onlineService.isOnline | async)">Réintégrations</a>
    <!--        <a mat-menu-item routerLink="liste-entrees-sorties"-->
    <!--           *ngIf="isDev()">E/S</a>-->
  </mat-menu>

  <a mat-button routerLink="liste-inventaires"
     *ngIf="possedeUnDroit(CD.GESTION_INVENTAIRES)"
     [ngClass]="{'activated': isActivatedM(MENUS.INVENTAIRES)}">Inventaires</a>

  <button mat-button [matMenuTriggerFor]="menuParametrages"
          *ngIf="possedeUnDroit([CD.GESTION_LIEUX, CD.GESTION_ARTICLES, CD.GESTION_PARAMETRAGES_LIEUX_ARTICLES, CD.GESTION_FOURNISSEURS_GLOBALE, CD.GESTION_FOURNISSEURS_LOCALE])"
          [ngClass]="{'activated': isActivatedM(MENUS.PARAMETRAGES)}" [class.disabled]="!(onlineService.isOnline | async)">Paramétrages</button>
  <mat-menu #menuParametrages>
    <a mat-menu-item routerLink="liste-lieux"
       *ngIf="possedeUnDroit(CD.GESTION_LIEUX)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.LIEUX)}" [class.disabled]="!(onlineService.isOnline | async)">Lieux</a>
   <a mat-menu-item routerLink="liste-articles-achat"
       *ngIf="possedeUnDroit(CD.GESTION_ARTICLES)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.ARTICLES_ACHAT)}" [class.disabled]="!(onlineService.isOnline | async)">Articles à l'achat</a>
    <a mat-menu-item routerLink="liste-articles-vente"
       *ngIf="possedeUnDroit(CD.GESTION_ARTICLES)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.ARTICLES_VENTE)}" [class.disabled]="!(onlineService.isOnline | async)">Articles à la vente</a>
    <a mat-menu-item routerLink="lieux-articles"
       *ngIf="possedeUnDroit(CD.GESTION_PARAMETRAGES_LIEUX_ARTICLES)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.LIEUX_ARTICLES)}" [class.disabled]="!(onlineService.isOnline | async)">Articles par lieu</a>
    <a mat-menu-item routerLink="liste-fournisseurs"
       *ngIf="possedeUnDroit(CD.GESTION_FOURNISSEURS_GLOBALE)"
       [ngClass]="{'activated': isActivatedSM(SUBMENUS.FOURNISSEURS)}" [class.disabled]="!(onlineService.isOnline | async)">Fournisseurs</a>
  </mat-menu>

  <a mat-button routerLink="ventes-temps-reel"
     *ngIf="possedeUnDroit(CD.VISUALISATION_VENTES)"
     title="Ventes en temps réel"
     [ngClass]="{'activated': isActivatedM(MENUS.VENTES)}" [class.disabled]="!(onlineService.isOnline | async)">Ventes</a>

  <a mat-button
     href="https://wiki.doc.lescrous.fr/index.php?title=Epona_-_Documentation_fonctionnelle"
     title="Documentation en ligne sur le wiki" [class.disabled]="!(onlineService.isOnline | async)">Aide</a>
</ng-template>
