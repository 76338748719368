<mat-tab-group [mat-stretch-tabs]="false">
  <mat-tab label="Entête">
    <epona-service-fait-entete [livraison]="livraison"
                               [entete]="serviceFait"
                               [modifiable]="modifiable"
                               [envoyable]="envoyable"
                               (isDirty)="onFormEnteteIsDirty($event)">
    </epona-service-fait-entete>
  </mat-tab>

  <mat-tab label="Lignes">
    <epona-service-fait-lignes [livraison]="livraison"
                               [entete]="serviceFait"
                               [modifiable]="modifiable"
                               (isDirty)="onFormLignesIsDirty($event)">
    </epona-service-fait-lignes>
  </mat-tab>

  <mat-tab label="Pièces jointes">
    <epona-service-fait-pieces-jointes [livraison]="livraison"
                                       [modifiable]="modifiable">
    </epona-service-fait-pieces-jointes>
  </mat-tab>
</mat-tab-group>
