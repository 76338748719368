export class CodeTypeMouvement {
  public static readonly APPROVISIONNEMENT        = 'APP';
  public static readonly TRANSFERT                = 'TRF';
  public static readonly SORTIE_EVENEMENTIELLE    = 'SOR';
  public static readonly RETOUR_SORTIE            = 'RSOR';
  public static readonly CASSE                    = 'CSS';
  public static readonly JETES                    = 'JT';
  public static readonly PERIMES                  = 'DLC';
  public static readonly VOL                      = 'VOL';
  public static readonly VENTE                    = 'VT';
  public static readonly CONSOMMATION_UTILISATION = 'CSV';
  public static readonly REINTEGRATION            = 'RTG';
}
