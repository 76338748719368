export class ObjectHelper {
  public static isEmpty(value: Date | number | string | Object | boolean): boolean {
    if (value instanceof Object) {
      for (const prop in value) {
        if (value.hasOwnProperty(prop)) {
          return false;
        }
      }

      return JSON.stringify(value) === JSON.stringify({});

    } else {
      return value === undefined || value === null;
    }
  }

  public static stringToBooleanOrNull(value: string | null | undefined): boolean | null {
    if (value === undefined || value === null) {
      return null;
    }
    return ObjectHelper.stringToBoolean(value);
  }

  public static stringToBoolean(value: string): boolean {
    if (value) {
      return value.toLocaleLowerCase() === "true" || value.toLocaleLowerCase() === "1";
    }
    return false;
  }

  public static stringToNumberOrNull(value: string | null | undefined): number | null {
    if (value === undefined || value === null) {
      return null;
    }
    return +value;
  }
}
