<epona-select-lieu [lieu]="lieuCourant" (lieuSubmitted)="getLieuSubmitted($event)"></epona-select-lieu>

<h1>Paramétrage des articles dans le lieu de stockage</h1>

<mat-card>
  <div class="table-actions">
    <button type="button" mat-raised-button color="primary" matTooltip="Ajouter un paramètrage"
            (click)="openDialogAjoutParametrage()">
      <fa-icon icon="plus"></fa-icon> Ajouter…
    </button>

    <input type="text" matInput (keyup)="applyFilter($event)" placeholder="Rechercher un paramétrage" />
  </div>

  <table mat-table [dataSource]="dataSource"
         class="mat-elevation-z8"
         matSort matSortActive="designation" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Liste de paramétrages d'articles sur un lieu">

    <ng-container matColumnDef="codeArticle">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Code-article</th>
      <td mat-cell *matCellDef="let parametrage">{{ parametrage.article | codeArticle }}</td>
    </ng-container>

    <ng-container matColumnDef="designation">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Désignation</th>
      <td mat-cell *matCellDef="let parametrage">{{parametrage.article | designation}}</td>
    </ng-container>

    <ng-container matColumnDef="stockMini">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Stock mini.</th>
      <td mat-cell *matCellDef="let data; let i = dataIndex">
        <mat-form-field subscriptSizing="dynamic">
          <input matInput type="number"
                  [value]="data.stockMini"
                  (blur)="updateStockMini(data, $event)"/>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="uniteExploitation">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Unité</th>
      <td mat-cell *matCellDef="let parametrage">
        {{ parametrage.article.uniteExploitation?.abreviation }}
      </td>
    </ng-container>

    <!-- Bouton 'poubelle' pour supprimer une ligne -->
    <ng-container matColumnDef="actions">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let parametrage">
        <button type="button" mat-button (click)="openDialogDelete(parametrage.idParametrageLieuArticle)">
          <fa-icon icon="trash"></fa-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    <!-- Ligne de pied de tableau en cas de liste vide -->
    <ng-container matColumnDef="noRecord">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
    </ng-container>

    <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
  </table>
</mat-card>
