import {InventaireEntete} from './InventaireEntete';
import {LieuDTO} from "./LieuDTO";

export class InventaireEnteteDTO {
  libelle: string;
  commentaire: string
  lieu: LieuDTO;

  constructor(inventaireEntete: InventaireEntete) {
    this.libelle = inventaireEntete.libelle;
    this.commentaire = inventaireEntete.commentaire;
    this.lieu = new LieuDTO(inventaireEntete.lieu);
  }
}
