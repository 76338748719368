import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Const} from "../../constants/Const";
import {Lieu} from "../../../model/epona-api/Lieu";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {UserService} from "../../../services/user.service";
import {LieuService} from "../../../services/epona/lieu.service";
import {CacheService} from "../../../services/cache.service";
import {MessageTool} from "../../MessageTool";
import {LieuSearch} from "../../../model/epona-api/LieuSearch";
import {LocalStorageHelper} from "../../../helpers/local-storage-helper";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
  selector: 'epona-select-lieu',
  templateUrl: './select-lieu.component.html',
  styleUrls: ['./select-lieu.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class SelectLieuComponent implements OnInit, OnChanges, OnDestroy {
  // Constante utilisée dans le HTML du component
  DEFAULT: number = Const.DEFAULT;

  private readonly CACHE_KEY = 'lieux';

  listeBatiments: Array<Batiment>; // Liste des bâtiments affichés
  listeLieux: Array<Lieu>;         // Liste des lieux affichés (dépend du bâtiment sélectionné)

  private lieuSelectionne: Lieu;   // Lieu partiel (seulement le bâtiment) ou complet (bâtiment et lieu)

  @Input() lieu: Lieu;
  @Input() livraisonUniquement: boolean = false;
  @Input() stockerLieuSelectionne = true;
  @Input() required = false;
  @Input() disabled: boolean = false;
  @Input() choixRestreints = true;  // Choix restreints aux bâtiments autorisés
  @Input() use = 'filter'; // 'filter' ou 'form', ajoute la classe 'in-filter' ou 'in-form' au form
  @Input() batimentLabel = 'Bâtiment';
  @Input() lieuLabel     = 'Lieu de stockage';
  @Input() batimentDefault = 'Tous';
  @Input() lieuDefault     = 'Tous';
  @Input() displayHelpTooltip: boolean = true;

  @Output() readonly lieuSubmitted = new EventEmitter<Lieu>();
  @Output() readonly lieuCorrected = new EventEmitter<Lieu>(); // TODO : est-ce vraiment utile ?

  lieuForm: UntypedFormGroup;

  userEventsSubscription: Subscription;

  private loadLieuxCompleted = new Subject<boolean>();

  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private lieuService: LieuService,
              private cacheService: CacheService,
              private messageTool: MessageTool) {
  }

  ngOnInit() {
    this.userEventsSubscription = this.userService.userEvents.subscribe(res => {
      if (res !== null) { // Si res est null c'est que l'utilisateur n'est plus connecté
        this.initLieuForm();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.lieu) {
      this.lieu = new Lieu();
      this.lieu.idCrous = +localStorage.getItem('idCrous');
    }

    // Mise à jour du lieu uniquement si la propriété a changé
    if (changes['lieu']) {
      this.loadLieuAndUpdateForm();
    }

    // On désactive le lieu uniquement si la valeur a changé et qu'elle est à true
    if (changes['disabled'] && !changes['disabled'].firstChange) {
      if (this.disabled) {
        this.lieuForm.disable();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.userEventsSubscription) {
      this.userEventsSubscription.unsubscribe();
    }
  }

  private codeBatiment(lieu: Lieu) {
    return lieu.idBatimentBns;
  }
  codeLieu(lieu: Lieu) {
    return lieu.idLieuBns;
  }

  /**
   * Méthode permettant d'initialiser le formulaire
   */
  initLieuForm() {
    // Initialisation du FormGroup
    this.lieuForm = this.fb.group({
      idRuLieu: this.fb.control( {
        value: this.codeBatiment(this.lieu) === null ? this.DEFAULT : this.codeBatiment(this.lieu),
        disabled: this.codeBatiment(this.lieu) === null
      }),
      idUdLieu: this.fb.control( {
        value: this.codeLieu(this.lieu) === null ? this.DEFAULT : this.codeLieu(this.lieu),
        disabled: this.codeLieu(this.lieu) === null
      }),
    });

    if (this.required) {
      this.lieuForm.get('idRuLieu').setValidators(SelectLieuComponent.lieuRequired);
      this.lieuForm.get('idUdLieu').setValidators(SelectLieuComponent.lieuRequired);
    }

    if (this.disabled) {
      this.lieuForm.disable();
    }

    // Si l'arborescence est en cache
    if (this.cacheService.has(this.CACHE_KEY)) {
      // Initialisation des listes depuis le cache
      this.initListesFromCache();
      this.loadLieuxCompleted.next(true);

      // Sinon, chargement de l'arborescence via l'API-VEM, mise en cache et initialisation des listes
    } else {
      const search: LieuSearch = new LieuSearch();
      search.idCrous = LocalStorageHelper.getIdEtablissement();
      search.masque = false;
      search.fields = 'idLieu,idLieuBns,idBatimentBns,idUd,nomUd,idRu,nomRu,lieuLivraison';

      this.lieuService.getListeLieux(search).subscribe(data => {
        const listeBatiments = this.buildListeBatiments(data);
        this.cacheService.set(this.CACHE_KEY, listeBatiments);
        this.initListesFromCache();
        this.loadLieuxCompleted.next(true);

      }, err => {
        this.messageTool.sendError(err);
        this.listeBatiments = [];
      });
    }
  }

  private buildListeBatiments(listeLieux: Lieu[]): Batiment[] {
    const listeBatiments: Batiment[] = [];

    for (const lieu of listeLieux) {
      let batiment = listeBatiments.find(item => item.idBatiment === this.codeBatiment(lieu));
      if (!batiment) {
        batiment = new Batiment();
        batiment.idBatiment = this.codeBatiment(lieu);
        batiment.nom = lieu.nomRu;
        batiment.listeLieux = new Array<Lieu>();
        listeBatiments.push(batiment);
      }

      batiment.listeLieux.push(lieu);
    }

    return listeBatiments;
  }

  private initListesFromCache() {
    this.listeBatiments = this.initListeLieux(this.cacheService.get(this.CACHE_KEY));
    this.listeBatiments.sort((a, b) => (a.nom ? a.nom : '').localeCompare(b.nom ? b.nom : ''));

    this.updateListeBatimentsCtrl();
    this.updateListeLieux();
  }

  private initListeLieux(listeBatiments: Batiment[]): Batiment[] {
    let listeBatimentsAutorises = listeBatiments;

    if (this.choixRestreints) {
      // Conservation des bâtiments correspondant aux bâtiments autorisés à l'utilisateur dans la BNS
      listeBatimentsAutorises = listeBatiments.filter(batiment =>
        this.userService.utilisateurCourant.listeIdBatiment.find(idBatiment => idBatiment === batiment.idBatiment)
      );
    }

    if (this.livraisonUniquement) {
      return listeBatimentsAutorises.filter(batiment => batiment.listeLieux.find(lieu => lieu.lieuLivraison));
    }

    return listeBatimentsAutorises;
  }


  private loadLieuAndUpdateForm() {
    // Si l'idLieu est connu mais pas l'idUd alors le reste de l'UD doit être chargé
    if (this.lieu.idLieu && !this.codeLieu(this.lieu)) {
      const fields = 'idLieu,idCrous,idBatimentBns,idLieuBns,idRu,idUd';
      this.lieuService.getLieu(this.lieu.idLieu, fields).subscribe(lieu => {
        this.lieu = lieu;
        this.updateFormFull();

      }, err => {
        this.messageTool.sendError(err);
        this.updateFormFull();
      });

    } else {
      this.updateFormFull();
    }
  }

  private updateFormFull() {
    // si l'arborescence des lieux n'a pas été chargée, il faut attendre qu'elle soit chargée pour mettre à jour les listes
    if (!this.listeBatiments) {
      this.loadLieuxCompleted.subscribe(res => {
        if (res) {
          this.updateForm();
        }
      })

    } else {
      this.updateForm();
    }
  }

  private updateForm() {
    this.initLieuSelectionne();
    this.updateFormControls();
    this.updateListeBatimentsCtrl();
    this.updateListeLieux()
  }

  private initLieuSelectionne() {
    this.lieuSelectionne = new Lieu();
    this.lieuSelectionne.idCrous = this.lieu.idCrous;

    const batiment = this.listeBatiments.find(b => b.idBatiment === this.codeBatiment(this.lieu));

    // Le batiment/RU du lieu en Input existe
    if (batiment) {
      this.lieuSelectionne.idBatimentBns = this.lieu.idBatimentBns;
      this.lieuSelectionne.idRu          = this.lieu.idRu;

      const lieu = batiment.listeLieux.find(l => this.codeLieu(l) === this.codeLieu(this.lieu));

      // Le lieu/UD du lieu en Input existe
      if (lieu) {
        this.lieuSelectionne.idLieuBns = this.lieu.idLieuBns;
        this.lieuSelectionne.idUd      = this.lieu.idUd;
        this.lieuSelectionne.idLieu    = this.lieu.idLieu;

      // Le lieu/UD du lieu en Input n'existe pas
      } else {
        this.lieuSelectionne.idLieuBns = null;
        this.lieuSelectionne.idUd      = null;
        this.lieuSelectionne.idLieu    = null;
        if (this.codeLieu(this.lieu)) {
          this.lieuCorrected.emit(this.lieuSelectionne);
        }
      }

    // Le batiment/RU du lieu en Input n'existe pas
    } else {
      this.lieuSelectionne.idBatimentBns = null;
      this.lieuSelectionne.idRu          = null;
      this.lieuSelectionne.idLieuBns     = null;
      this.lieuSelectionne.idUd          = null;
      this.lieuSelectionne.idLieu        = null;
      if (this.codeBatiment(this.lieu)) {
        this.lieuCorrected.emit(this.lieuSelectionne);
      }
    }
  }

  private updateFormControls() {
    this.lieuForm.get('idRuLieu').setValue(SelectLieuComponent.ifnull(this.codeBatiment(this.lieu), this.DEFAULT));
    this.lieuForm.get('idUdLieu').setValue(SelectLieuComponent.ifnull(this.codeLieu(this.lieu), this.DEFAULT));
  }

  private static ifnull(value: number, def: number): number {
    return value === null ? def : value;
  }

  private updateListeBatimentsCtrl() {
    if (this.disabled) {
      this.lieuForm.get('idRuLieu').disable();
    } else {
      this.lieuForm.get('idRuLieu').enable();
    }
  }

  selectBatiment() {
    this.lieuForm.get('idUdLieu').setValue(this.DEFAULT);

    this.lieuSelectionne.idLieuBns = null;
    this.lieuSelectionne.idUd = null;
    this.lieuSelectionne.idLieu = null;

    if (this.stockerLieuSelectionne) {
      if (this.lieuForm.get('idRuLieu').value === this.DEFAULT) {
        localStorage.removeItem('idBatimentBns');
        localStorage.removeItem('idLieuBns');
        localStorage.removeItem('idRu');
        localStorage.removeItem('idUd');
        localStorage.removeItem('idLieu');
      } else {
        localStorage.setItem('idBatimentBns', this.lieuForm.get('idRuLieu').value);
        localStorage.removeItem('idRu');
      }
    }

    this.updateListeLieux();

    this.lieuSelectionne.idBatimentBns = this.lieuForm.get('idRuLieu').value !== this.DEFAULT ? this.lieuForm.get('idRuLieu').value : null;

    // Envoi d'un événement aux composants parents qui contient le lieu
    this.lieuSubmitted.emit(this.lieuSelectionne);
  }

  updateListeLieux() {
    this.listeLieux = [];
    for (const batiment of this.listeBatiments) {
      if (batiment.idBatiment === this.lieuForm.get('idRuLieu').value) {
        this.listeLieux = batiment.listeLieux.filter(lieu => !this.livraisonUniquement || lieu.lieuLivraison);
        this.listeLieux.sort((a, b) => (a.nom ? a.nom : '').localeCompare(b.nom ? b.nom : ''));
      }
    }

    if (this.disabled || this.lieuForm.get('idRuLieu').value === this.DEFAULT) {
      this.lieuForm.get('idUdLieu').disable();
    } else {
      this.lieuForm.get('idUdLieu').enable();
    }
  }

  selectLieu() {
    if (this.stockerLieuSelectionne) {
      if (this.lieuForm.get('idUdLieu').value === this.DEFAULT) {
        localStorage.removeItem('idLieuBns');
        localStorage.removeItem('idUd');
        localStorage.removeItem('idLieu');
      } else {
        localStorage.setItem('idLieuBns', this.lieuForm.get('idUdLieu').value);
        localStorage.removeItem('idUd');
      }
    }

    this.lieuSelectionne.idLieu = null;
    this.lieuSelectionne.idLieuBns = this.lieuForm.get('idUdLieu').value !== this.DEFAULT ? this.lieuForm.get('idUdLieu').value : null;

    if (this.lieuForm.get('idUdLieu').value !== this.DEFAULT) {
      const idLieu = this.listeLieux.find(item => this.codeLieu(item) === this.codeLieu(this.lieuSelectionne)).idLieu;
      if (this.stockerLieuSelectionne) {
        localStorage.setItem('idLieu', '' + idLieu);
      }
      this.lieuSelectionne.idLieu = idLieu;

      // Envoi d'un événement aux composants parents qui contient le lieu
      this.lieuSubmitted.emit(this.lieuSelectionne);

    } else {
      this.removeIdLieu();
      // Envoi d'un événement aux composants parents qui contient le lieu
      this.lieuSubmitted.emit(this.lieuSelectionne);
    }
  }

  private removeIdLieu() {
    if (this.stockerLieuSelectionne) {
      localStorage.removeItem('idLieu');
    }
    this.lieuSelectionne.idLieu = null;
  }

  // Validators.required ne peut être utilisé car Const.DEFAULT peut prendre n'importe quelle valeur
  private static lieuRequired (control: UntypedFormControl) {
    const isEmpty = control.value === Const.DEFAULT;
    const result = {'required': isEmpty ? true : null};
    return isEmpty ? result : null;
  }
}

class Batiment {
  idBatiment: number; // idRu dans un premier temps, à terme sera l'idBatimentBNS
  nom: string;
  listeLieux: Array<Lieu>;
}
