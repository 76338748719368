export class Fournisseur {
  idFournisseur: number;

  nom: string;
  siret: string;
  codeTiersOrion: string;
  codeClient: string;
  telephone: string;
  fax: string;
  mail: string;
  adresseNumero: number;
  adresseBTQ: string;
  adresseRue: string;
  adresseComplement: string;
  adresseCodePostal: string;
  adresseVille: string;
  adressePays: string;
  possedeSitePunchout: boolean;

  // utile pour typer l'objet à partir d'un objet javascript
  static fromRaw(obj, idFournisseur: number) {

    let f = new Fournisseur();
    f.idFournisseur = idFournisseur;
    f.nom = obj.nom || null;
    f.siret = obj.siret || null;
    f.codeTiersOrion = obj.codeTiersOrion || null;
    f.codeClient = obj.codeClient || null;
    f.telephone = obj.telephone || null;
    f.fax = obj.fax || null;
    f.mail = obj.mail || null;
    f.adresseNumero = obj.adresseNumero || null;
    f.adresseBTQ = obj.adresseBTQ || null;
    f.adresseRue = obj.adresseRue || null;
    f.adresseComplement = obj.adresseComplement || null;
    f.adresseCodePostal = obj.adresseCodePostal || null;
    f.adresseVille = obj.adresseVille || null;
    f.adressePays = obj.adressePays || null;
    f.possedeSitePunchout = obj.possedeSitePunchout || null;

    return f;
  }
}
