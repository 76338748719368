import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList, Renderer2,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ClearMessages, MessageTool} from '../../commons/MessageTool';
import {InventaireLigne} from '../../model/epona-api/InventaireLigne';
import {Article} from "../../model/epona-api/Article";
import {DialogAjoutDlcComponent} from "../dialog-ajout-dlc/dialog-ajout-dlc.component";
import {InventaireEntete} from "../../model/epona-api/InventaireEntete";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {DialogDataAjoutArticleInventaire} from "../../model/epona-ui/DialogDataAjoutArticleInventaire";
import {DialogAjoutArticleComponent} from "../dialog-ajout-article/dialog-ajout-article.component";
import {DialogDataAjoutDLCInventaire} from "../../model/epona-ui/DialogDataAjoutDLCInventaire";
import {InventaireService} from "../../services/epona/inventaire.service";
import {DialogDataCommentaireInventaire} from "../../model/epona-ui/DialogDataCommentaireInventaire";
import {InventaireComponent} from "../inventaire/inventaire.component";
import {
  DialogCommentaireInventaireComponent
} from "../dialog-commentaire-inventaire/dialog-commentaire-inventaire.component";
import {Tools} from "../../commons/Tools";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {ArticleTools} from "../../commons/ArticleTools";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {OnlineService} from "../../services/online.service";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {ArticleSearch} from "../../model/epona-api/ArticleSearch";
import {ArticleService} from "../../services/epona/article.service";
import {FormTools} from "../../commons/FormTools";
import {CustomValidators} from "../../commons/CustomValidators";

@Component({
  selector: 'epona-inventaire-lignes',
  templateUrl: './inventaire-lignes.component.html',
  styleUrls: ['./inventaire-lignes.component.css']
})

export class InventaireLignesComponent implements OnInit, OnChanges {
  dataSource = new MatTableDataSource<InventaireLigne>([]);

  @Input() inventaireEntete: InventaireEntete;
  @Input() inventaireLignes: Array<InventaireLigne>;
  @Input() validationLignesErreur: boolean;

  @Output() readonly articleInserted = new EventEmitter<Article>();
  @Output() readonly ligneInserted = new EventEmitter<InventaireLigne>();
  @Output() readonly ligneUpdated = new EventEmitter<InventaireLigne>();

  @ViewChildren('quantiteStockConstateeInput') quantiteStockConstateeInputs: QueryList<ElementRef>;
  @ViewChildren('pmpHtConstateInput') pmpHtConstateInputs: QueryList<ElementRef>;
  @ViewChildren('pmpTtcConstateInput') pmpTtcConstateInputs: QueryList<ElementRef>;

  spans = [];

  formGroup: UntypedFormGroup;

  readonly COLUMNS: {[key: string]: TableColumn} = {
    groupe:                   new TableColumn({label: 'Groupe'}),
    sousGroupe:               new TableColumn({label: 'Sous-groupe'}),
    codeArticleAchat:         new TableColumn({label: 'Code achat'}),
    codeArticleVente:         new TableColumn({label: 'Code vente',                          exportFormat: 'integer'}),
    designation:              new TableColumn({label: 'Désignation',        hiddable: false}),
    ajoutLigne:               new TableColumn({label: 'Ajout',              default: false,  export: false,           tooltip: 'Ajout d\'une DLC'}),
    dlc:                      new TableColumn({label: 'DLC',                                 exportFormat: 'date'}),
    quantiteStockTheorique:   new TableColumn({label: 'Qtt. théo.',         hiddable: false, exportFormat: 'decimal', tooltip: 'Quantité théorique'}),
    pmpHtTheorique:           new TableColumn({label: 'PMP HT théo.',       default: false,  exportFormat: 'decimal', tooltip: 'Prix moyen pondéré HT théorique',   }),
    pmpTtcTheorique:          new TableColumn({label: 'PMP TTC théo.',      default: false,  exportFormat: 'decimal', tooltip: 'Prix moyen pondéré TTC théorique'}),
    valeurTotaleHtTheorique:  new TableColumn({label: 'Val. HT théo.',      default: false,  exportFormat: 'decimal', tooltip: 'Valeur totale HT théorique'}),
    valeurTotaleTtcTheorique: new TableColumn({label: 'Val. TTC théo.',     default: false,  exportFormat: 'decimal', tooltip: 'Valeur totale TTC théorique'}),
    quantiteStockConstatee:   new TableColumn({label: 'Qtt. constatée',     hiddable: false, exportFormat: 'decimal'}),
    uniteExploitation:        new TableColumn({label: 'Unité',              default: false}),
    pmpHtConstate:            new TableColumn({label: 'PMP HT',             hiddable: false, exportFormat: 'decimal'}),
    pmpTtcConstate:           new TableColumn({label: 'PMP TTC',            hiddable: false, exportFormat: 'decimal'}),
    valeurTotaleHtConstatee:  new TableColumn({label: 'Val. HT constatée',  default: false,  exportFormat: 'decimal', tooltip: 'Valeur totale HT constatée'}),
    valeurTotaleTtcConstatee: new TableColumn({label: 'Val. TTC constatée', default: false,  exportFormat: 'decimal', tooltip: 'Valeur totale TTC constatée'}),
    commentaire:              new TableColumn({label: 'Commentaire'})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.LIGNES_INVENTAIRE;

  displayedColumns: string[] = [];

  readonly UA = UtilisationArticle;
  readonly NB_MAX_DECIMALES_QUANTITE = 4;
  readonly NB_MAX_DECIMALES_PMP = 4;

  constructor(private messageTool: MessageTool,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private inventaireService: InventaireService,
              private articleService: ArticleService,
              public onlineService: OnlineService,
              private renderer: Renderer2) {

    this.formGroup = fb.group({
      formArray: fb.array([])
    });
  }

  // Les lignes de l'inventaire peuvent être récupérés avant ou après le ngOnInit.
  // Il faut donc prendre en compte ces 2 cas en récupérant les lignes avec le ngOninit et le ngOnChanges
  ngOnInit() {
    if (this.inventaireLignes) {
      this.init();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['inventaireLignes'];
    if (change && !change.firstChange) {
      if (this.inventaireLignes) {
        this.init();
      }
    }

    if (this.validationLignesErreur) {
      this.validateAllFormArrayFields();
    }
  }

  private init() {
    this.tri();
    this.initFormArray();
    this.dataSource.data = this.inventaireLignes;
    this.setDisplayedColumns();
    this.cacheSpan('Article', d => d.article.idArticle);
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  private validateAllFormArrayFields() {
    // Parcours des FormControl des FormGroup du FormArray afin de mettre les FormControl comme "touché" pour que le validator du FormControl soit exécuté.
    (<UntypedFormArray>this.formGroup.get('formArray')).controls.forEach((group: UntypedFormGroup) => {
      (<any>Object).values(group.controls).forEach((control: UntypedFormControl) => {
        control.markAsTouched({ onlySelf: true });
      })
    });
  }

  private initFormArray() {
    const formArray = this.fb.array([]);

    for (const inventaireLigne of this.inventaireLignes) {
      const formGroup = this.fb.group({
        quantiteStockConstatee: this.fb.control(
          inventaireLigne.quantiteStockConstatee,
          [Validators.min(0), CustomValidators.nbMaxDecimals(this.NB_MAX_DECIMALES_QUANTITE), InventaireLignesComponent.quantiteConstateeValidator(inventaireLigne.dlc, inventaireLigne.quantiteStockTheorique)]),

        pmpHtConstate: this.fb.control(
          inventaireLigne.pmpHtConstate,
          [Validators.min(0), CustomValidators.nbMaxDecimals(this.NB_MAX_DECIMALES_PMP)]),

        pmpTtcConstate: this.fb.control(
          inventaireLigne.pmpTtcConstate,
          [Validators.min(0), CustomValidators.nbMaxDecimals(this.NB_MAX_DECIMALES_PMP)]),
      });

     formGroup.setValidators(InventaireLignesComponent.coherencePmp);

      this.updateFormControls(formGroup);

      formGroup.get('quantiteStockConstatee').valueChanges.subscribe(() => {
        this.updateFormControls(formGroup);
      });

      formArray.push(formGroup);
    }

    this.formGroup.setControl('formArray', formArray);
  }

  private static quantiteConstateeValidator = (dlc: Date, quantiteTheorique: number) => {
    return (control: UntypedFormControl) => {

      if (!InventaireComponent.clotureEnCours) {
        return null;
      }

      if ((dlc && Tools.isEmpty(control.value))
        || (!dlc && Tools.isEmpty(control.value) && quantiteTheorique != 0)) {
        return {'required': true};
      }

      return null;
    };
  };

  // Validation que le HT est bien inférieur au TTC
  //  le validateur sera affecté à la ligne mais en cas d'erreur, celle-ci sera répercutée sur les contrôles des 2 PMP
  private static coherencePmp(formCtrl: AbstractControl): ValidationErrors {
    const pmpHt  = formCtrl.get('pmpHtConstate').value;
    const pmpTtc = formCtrl.get('pmpTtcConstate').value;

    if (pmpHt === null || pmpTtc === null) {
      InventaireLignesComponent.removeErrorFromControls('ttc_inferieur_ht', formCtrl);
      return null;
    }

    if (pmpHt > pmpTtc) {
      InventaireLignesComponent.addErrorToControls('ttc_inferieur_ht', formCtrl);
      return {'ttc_inferieur_ht': true};

    } else {
      InventaireLignesComponent.removeErrorFromControls('ttc_inferieur_ht', formCtrl);
      return null;
    }
  }

  private static addErrorToControls(errorCode: string, formCtrl: AbstractControl): void {
    FormTools.addErrorToCtrl(errorCode, formCtrl.get('pmpHtConstate'));
    FormTools.addErrorToCtrl(errorCode, formCtrl.get('pmpTtcConstate'));
  }

  private static removeErrorFromControls(errorCode: string, formCtrl: AbstractControl): void {
    FormTools.removeErrorFromCtrl(errorCode, formCtrl.get('pmpHtConstate'));
    FormTools.removeErrorFromCtrl(errorCode, formCtrl.get('pmpTtcConstate'));
  }

  private updateFormControls(formGroup: UntypedFormGroup) {
    if (!this.isEditable()) {
      formGroup.disable();
    }
  }

  isEditable() {
    return !this.inventaireEntete ? true : this.inventaireEntete.extra.editable.status;
  }

  // Tri des lignes d'inventaire par code article (nécessaire pour le merge des cellules) et par dlc antichronologique
  private tri() {
    this.inventaireLignes.sort((a, b) => {
      const familleA = a.article.sousGroupe ? a.article.sousGroupe.groupe.libelle : '';
      const familleB = b.article.sousGroupe ? b.article.sousGroupe.groupe.libelle : '';
      const compareFamille = familleA.localeCompare(familleB);

      if (compareFamille !== 0) {
        return compareFamille;

      } else {
        const sousGroupeA = a.article.sousGroupe ? a.article.sousGroupe.libelle : '';
        const sousGroupeB = b.article.sousGroupe ? b.article.sousGroupe.libelle : '';
        const comparesousGroupe = sousGroupeA.localeCompare(sousGroupeB);

        if (comparesousGroupe !== 0) {
          return comparesousGroupe;

        } else {
          const articleA = ArticleTools.getProperty(ArticleTools.PROP_DESIGNATION, a.article);
          const articleB = ArticleTools.getProperty(ArticleTools.PROP_DESIGNATION, b.article);
          const compareArticle = articleA.localeCompare(articleB);

          if (compareArticle !== 0) {
            return compareArticle;

          } else {
            if (a.dlc < b.dlc || !a.dlc) {
              return 1;
            } else if (a.dlc === b.dlc) {
              return 0;
            } else {
              return -1;
            }
          }
        }
      }
    });
  }

  openDialogAjoutArticle(): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.data = new DialogDataAjoutArticleInventaire();
    dialogConfig.data.entete = this.inventaireEntete;
    dialogConfig.data.lignes = this.inventaireLignes;

    const dialogRef = this.dialog.open(DialogAjoutArticleComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      if(dialogConfig.data.article) {
        this.articleInserted.emit(dialogConfig.data.article);
      }
    });
  }

  openDialogAjoutLigne(article: Article): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '350px';
    dialogConfig.data = new DialogDataAjoutDLCInventaire();
    dialogConfig.data.article = article;
    dialogConfig.data.entete = this.inventaireEntete;

    const dialogRef = this.dialog.open(DialogAjoutDlcComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      if(dialogConfig.data.ligne) {
        this.ligneInserted.emit(dialogConfig.data.ligne);
      }
    });
  }

  updateLigne(index: number, formControlName: string, goToNextCtrl: boolean = false) {
    InventaireComponent.clotureEnCours = false;

    const ligne = this.inventaireLignes[index];
    const ligneCtrl = this.getLigneCtrl(index);

    if (!ligneCtrl.valid) {
      this.messageTool.sendErrorMessage(`${this.messageTool.lignePrefix(ligne)} est erronée`);

    } else if (ligneCtrl.dirty) {
      ligne.quantiteStockConstatee = ligneCtrl.get('quantiteStockConstatee').value;
      ligne.pmpHtConstate          = ligneCtrl.get('pmpHtConstate').value;
      ligne.pmpTtcConstate         = ligneCtrl.get('pmpTtcConstate').value;

      this.inventaireService.putInventaireLignes(this.inventaireEntete.idInventaireEntete, ligne).subscribe(() => {
        this.messageTool.sendSuccess(`${this.messageTool.lignePrefix(ligne)} a été mise à jour avec succès`, ClearMessages.TRUE);

        // Ligne marquée comme n'ayant pas été touchée
        ligneCtrl.markAsPristine();

        // Envoi au composant parent qu'une ligne a été modifiée
        this.ligneUpdated.emit(ligne);

        if (goToNextCtrl) {
          this.goToNextCtrl(index, formControlName);
        }

      }, err => {
        ligneCtrl.get(formControlName).setErrors(FormTools.ngErrorFromHttpError(err));
        this.messageTool.sendError(err);
      });

    } else if (goToNextCtrl) {
      this.goToNextCtrl(index, formControlName);
    }
  }

  private getLigneCtrl(index: number): UntypedFormControl {
    return (this.formGroup.get('formArray') as UntypedFormArray).controls[index] as UntypedFormControl;
  }

  private goToNextCtrl(index: number, formControlName: string): void {
    let inputs: QueryList<ElementRef>;
    switch (formControlName) {
      case 'quantiteStockConstatee': inputs = this.quantiteStockConstateeInputs; break;
      case 'pmpHtConstate':          inputs = this.pmpHtConstateInputs;          break;
      case 'pmpTtcConstate':         inputs = this.pmpTtcConstateInputs;         break;
      default: return;
    }

    const nextIndex = index + 1;
    if (inputs.length > 0 && inputs.get(nextIndex) !== undefined) {
      this.renderer.selectRootElement(inputs.get(nextIndex).nativeElement).focus();
    }
  }

  estCommentaireVide(inventaireLigne: InventaireLigne): boolean {
    return inventaireLigne.commentaire === undefined
      || inventaireLigne.commentaire === null
      || inventaireLigne.commentaire.trim() === '';
  }

  openDialogCommentaire(inventaireLigne: InventaireLigne){
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '350px';
    dialogConfig.data = new DialogDataCommentaireInventaire();
    dialogConfig.data.entete = this.inventaireEntete;
    dialogConfig.data.ligne = inventaireLigne;

    const dialogRef = this.dialog.open(DialogCommentaireInventaireComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      if (dialogConfig.data.ligneUpdated) {
        this.ligneUpdated.emit(dialogConfig.data.ligneUpdated);
      }
    });
  }

  // Test si un control du FormArray des lignes a une erreur
  hasError(index: number, controlName: string, errorCode: string): boolean {
    return this.getLigneCtrl(index).get(controlName).hasError(errorCode);
  }

  // Merger des cellules :  https://stackoverflow.com/questions/53857049/angular-material-table-rowspan-columns-based-on-datasource-object-array-property
  cacheSpan(key, accessor) {
    this.spans = [];

    for (let i = 0; i < this.dataSource.data.length;) {
      let currentValue = accessor(this.dataSource.data[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.dataSource.data.length; j++) {
        if (currentValue != accessor(this.dataSource.data[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }

  openDialogHandleConnexion(deconnexion: boolean): void {
    const verb = deconnexion?'déconnecter':'reconnecter';
    const noun = deconnexion?'déconnexion':'reconnexion';

    let message = '<p>Êtes-vous sûr de vouloir vous ' + verb + '&nbsp;?</p>';
    message += deconnexion?'<p>La liste des articles et les inventaires vont être sauvegardés localement.</p>':'<p>Vos modifications vont être enregistrées sur le serveur.</p>';
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de " + noun,
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: message
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (deconnexion) {
          this.deconnexion();
        } else {
          this.reconnexion();
        }
      }
    });
  }

  deconnexion() {
    this.loadArticles().subscribe(() => {
      this.onlineService.isOnline.next(false);
    });
  }

  loadArticles() {
    const search = new ArticleSearch();
    search.idCrous = +localStorage.getItem('idCrous');
    search.stockGereOperationnellement = true;
    search.fields = '' +
      'idArticle,' +
      'idCrous,' +
      'articleAchat,' +
      'articleVente,' +
      'codeArticleAchat,' +
      'codeArticleVente,' +
      'designationAchat,' +
      'designationVente,' +
      'articleDlc';

    return this.articleService.getListeArticles(search);
  }

  reconnexion() {
    this.onlineService.testConnexion().subscribe(res => {
      if (!res) {
        this.messageTool.sendErrorMessage('La reconnexion est impossible car vous n\'êtes pas connecté au réseau');
        return
      }

      this.onlineService.isOnline.next(true);
    });
  }

  calculValeurTotaleHtConstatee(index: number): number|null {
    return this.calculValeurTotaleConstatee(index, 'ht');
  }
  calculValeurTotaleTtcConstatee(index: number): number|null {
    return this.calculValeurTotaleConstatee(index, 'ttc');
  }

  private calculValeurTotaleConstatee(index: number, type: 'ht'|'ttc'): number|null {
    const ligneCtrl = this.getLigneCtrl(index);

    const qttCtrl = ligneCtrl.get('quantiteStockConstatee');
    const pmpCtrl = type === 'ht' ? ligneCtrl.get('pmpHtConstate') : ligneCtrl.get('pmpTtcConstate');

    if ( !qttCtrl || qttCtrl.value === null || !qttCtrl.valid && !qttCtrl.disabled
      || !pmpCtrl || pmpCtrl.value === null || !pmpCtrl.valid && !pmpCtrl.disabled) {
      return null;
    }

    return qttCtrl.value * pmpCtrl.value;
  }

  openDialogConfirmationCopie() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de copie",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: "Êtes-vous sûr de vouloir copier toutes les quantités théoriques dans les quantités constatées ?"
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.copieTheoriquesDansConstatees();
      }
    });

  }

  private copieTheoriquesDansConstatees() {
    let index = 0;
    for (const ligne of this.inventaireLignes) {
      this.copieTheoriqueDansConstatee(ligne, index);
      index++;
    }
  }

  copieTheoriqueDansConstatee(ligne: InventaireLigne, index: number) {
    const ligneCtrl = this.getLigneCtrl(index);
    const formCtrl = ligneCtrl.get('quantiteStockConstatee');
    if (formCtrl.value !== ligne.quantiteStockTheorique) {
      formCtrl.setValue(ligne.quantiteStockTheorique);
      ligneCtrl.markAsDirty();
      this.updateLigne(index, 'quantiteStockConstatee');
    }
  }
}
