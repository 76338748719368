export class DTOWithLieu {
  idCrous: number;
  idBatimentBns: number;
  idLieuBns: number;
  idUg: number;
  idRu: number;
  idUd: number;
  idLieu: number;

  setIdsLieu(dto: DTOWithLieu) {
    this.idCrous       = dto.idCrous;
    this.idBatimentBns = dto.idBatimentBns;
    this.idLieuBns     = dto.idLieuBns;
    this.idRu          = dto.idRu;
    this.idUd          = dto.idUd;
    this.idLieu        = dto.idLieu;
  }
}
