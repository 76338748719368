import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EngagementJuridiqueEntete} from "../../model/epona-api/EngagementJuridiqueEntete";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TypeReferentielOrion} from "../../commons/constants/TypeReferentielOrion";
import {CommandeService} from "../../services/epona/commande.service";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'epona-engagement-juridique-entete',
  templateUrl: './engagement-juridique-entete.component.html',
  styleUrls: ['./engagement-juridique-entete.component.css']
})
export class EngagementJuridiqueEnteteComponent implements OnChanges {

  @Input() commande: CommandeEntete;
  @Input() entete: EngagementJuridiqueEntete;
  @Input() modifiable: boolean;
  @Input() envoyable: boolean;

  @Output() isDirty = new EventEmitter<boolean>();

  form: UntypedFormGroup;

  TypeReferentielOrion = TypeReferentielOrion;

  constructor(private fb: UntypedFormBuilder,
              private commandeService: CommandeService,
              public dialog: MatDialog,
              private messageTool: MessageTool) {

    this.form = fb.group({
      serviceEmetteur:    fb.control(null, Validators.required),
      dateCommande:       fb.control(null),
      fournisseur:        fb.control(null),
      objet:              fb.control(null, Validators.required),
      observation:        fb.control(null),
    });

    this.updateFormControls();

    this.form.valueChanges.subscribe(() => {
      this.isDirty.emit(this.form.dirty);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entete'] && this.entete) {
      this.form.get('serviceEmetteur').setValue(this.entete.serviceEmetteur);
      this.form.get('dateCommande').setValue(this.entete.dateCommande);
      this.form.get('objet').setValue(this.entete.objet);
      this.form.get('observation').setValue(this.entete.observation);
      this.form.get('fournisseur').setValue(this.entete.fournisseur);
    }

    if (changes['modifiable']) {
      this.updateFormControls();
    }
  }

  private updateFormControls() {
    this.form.disable();

    if (this.modifiable) {
      this.form.get('serviceEmetteur').enable();
      this.form.get('objet').enable();
      this.form.get('observation').enable();
    }
  }

  onSubmit() {
    this.entete.serviceEmetteur = this.form.get('serviceEmetteur').value;
    this.entete.objet           = this.form.get('objet').value;
    this.entete.observation     = this.form.get('observation').value;

    this.commandeService.putEngagementJuridiqueEntete(this.entete, this.commande).subscribe(() => {
      this.messageTool.sendSuccess('L\'engagement juridique a été mise à jour avec succès', ClearMessages.TRUE);
      this.form.markAsPristine();
      this.isDirty.emit(false);

    }, err => {
      this.messageTool.sendError(err);
    });
  }
}
