<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span *ngIf="!data.commande.numeroEj">Engagement juridique non envoyé</span>
    <span *ngIf="data.commande.numeroEj">Engagement juridique n<sup>o</sup> {{ data.commande.numeroEj }}</span>

    <span class="space"></span>

    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <epona-engagement-juridique [commande]="data.commande"
                              [engagementJuridique]="engagementJuridique"
                              (formEnteteIsDirty)="onFormEnteteIsDirty($event)"
                              (formLignesIsDirty)="onFormLignesIsDirty($event)"
                              (closeDialog)="onCloseDialog($event)">
  </epona-engagement-juridique>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="openDialogEnvoiEJ()" [disabled]="!!data.commande.numeroEj">
    <fa-icon icon="paper-plane"></fa-icon> Envoyer vers Orion…
  </button>

  <button type="button"
          mat-raised-button color="primary"
          [matMenuTriggerFor]="menu">
    <mat-icon>more_horiz</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button type="button"
            mat-menu-item
            (click)="openDialogRegenerationEJ()"
            [disabled]="!!data.commande.numeroEj">
      <fa-icon icon="redo"></fa-icon> Regénérer…
    </button>
  </mat-menu>
</mat-dialog-actions>
