export class ArticleVEM {
  codeArticle: number;
  designation: string;
  pcb: number;
  articleDlc: boolean;
  listeCodesEAN: Array<CodeEANArticle>
}

export class CodeEANArticle {
  codeEAN: string;
}
