export enum TopbarSubmenu {
  COMMANDES,
  PANIERS,

  LIVRAISONS,
  LOTS,
  FDNC,

  PERTES,
  TRANSFERTS,
  SORTIES_EVENEMENTIELLES,
  RETOURS_SORTIES,
  CONSOMMATIONS_UTILISATIONS,
  REINTEGRATIONS,

  LIEUX,
  BATIMENTS,
  ARTICLES_ACHAT,
  ARTICLES_VENTE,
  LIEUX_ARTICLES,
  FOURNISSEURS,
}
