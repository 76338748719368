import {Fournisseur} from "./Fournisseur";

export class FournisseurDTO {

  idFournisseur: number;
  nom: string;

  constructor(fournisseur: Fournisseur) {
    this.idFournisseur = fournisseur.idFournisseur;
    this.nom = fournisseur.nom;

  }
}
