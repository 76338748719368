<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>Prochaines révisions tarifaires</span>

    <aide>
      <p>
        Les dates de révision tarifaire sont saisies par la Centre d'Achat dans la BNA.
      </p>
      <p>
        Une révision tarifaire concerne uniquement les articles catalogue et se traduit par l'effacement des prix
        récupérés depuis le site du fournisseur.
      </p>
      <p>
        Après chaque révision tarifaire il faut donc retourner sur le site du fournisseur pour récupérer les prix
        actualisés et pouvoir de nouveau commander les articles souhaités.
      </p>
    </aide>

    <span class="space"></span>

    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <epona-tableau-revisions-tarifaires></epona-tableau-revisions-tarifaires>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="close()">Fermer</button>
</mat-dialog-actions>
