<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field>
      <mat-label>Prise en compte des ventes</mat-label>
      <select-boolean formControlName="priseEnCompteVentes"
                            defaultLabel="- Tous -"
                            epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Lieu de livraison</mat-label>
      <select-boolean formControlName="lieuLivraison"
                            defaultLabel="- Tous -"
                            epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Paramétré pour Orion</mat-label>
      <select-boolean formControlName="parametrePourOrion"
                            defaultLabel="- Tous -"
                            epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Masqué</mat-label>
      <select-boolean formControlName="masque"
                      defaultLabel="- Tous -"
                      epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon>
        Rechercher
      </button>
    </div>
  </mat-card>
</form>
