import {IdEtapeFdnc} from "../../commons/constants/IdEtapeFdnc";

export class EtapeFdnc {
  static readonly ETAPE_EPONA = new EtapeFdnc(IdEtapeFdnc.EN_COURS_DE_REDACTION_DANS_EPONA, 'En cours de rédaction dans Épona');

  idEtapeFdnc: number;
  libelle!: string;

  constructor(idEtapeFdnc: number, libelle?: string) {
    this.idEtapeFdnc = idEtapeFdnc;
    this.libelle     = libelle;
  }
}
