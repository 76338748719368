import {ArticleDTO} from './ArticleDTO';
import {ParametrageLieuArticle} from './ParametrageLieuArticle';
import {ParametrageLieuArticlePUT} from './parametrageLieuArticlePUT';
import {LieuDTO} from "./LieuDTO";

export class ParametrageLieuArticlePOST extends ParametrageLieuArticlePUT {
  lieu: LieuDTO;
  article: ArticleDTO;

  constructor(parametrageLieuArticle: ParametrageLieuArticle){
    super(parametrageLieuArticle);
    this.lieu = new LieuDTO(parametrageLieuArticle.lieu);
    this.article = new ArticleDTO(parametrageLieuArticle.article);
  }
}
