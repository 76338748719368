<epona-topbar></epona-topbar>
<app-httploader></app-httploader>

<div role="main" class="content">
  <router-outlet></router-outlet>
</div>

<footer>
  Épona  v{{version}}
</footer>
