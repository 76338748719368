<h1>Lieux</h1>

<div class="crous-recherche">
  <epona-filtre-lieux
    [filter]="filter"
    class="crous-recherche-filtre"
    (filterSubmitted)="onFilterSubmitted($event)"></epona-filtre-lieux>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Liste des lieux</mat-card-title>

        <aide class="aide-globale" maxWidth="800px">
          <dl>
            <dt>Masqué</dt>
            <dd>Indique s'il est possible de passer des commandes ou d'effectuer des mouvements de stocks sur ce lieu</dd>

            <dt>Prise en compte des ventes</dt>
            <dd>Indique si les ventes remontées des caisses doivent faire l'objet de mouvements de stocks sur ce lieu</dd>

            <dt>Lieu de livraison</dt>
            <dd>Indique si des commandes peuvent être passées pour être livrées sur ce lieu</dd>

            <dt>Nom du lieu de livraison</dt>
            <dd>S'il est renseigné, ce nom sera affiché sur les bons de commande à la place du nom du bâtiment</dd>

            <dt>Service émetteur par défaut</dt>
            <dd>Service émetteur Orion qui sera renseigné par défaut lors de la création de l'engagement juridique d'une commande pour ce lieu de livraison</dd>

            <dt>Destination par défaut</dt>
            <dd>Destination Orion qui sera renseignée par défaut lors de la création de l'engagement juridique d'une commande pour ce lieu de livraison</dd>

            <dt>Service gestionnaire par défaut</dt>
            <dd>Service gestionnaire Orion qui sera renseigné par défaut lors de la création de l'engagement juridique d'une commande pour ce lieu de livraison</dd>

            <dt>Type de TVA par défaut</dt>
            <dd>Type de TVA qui sera renseignée par défaut lors de la création de l'engagement juridique d'une commande pour ce lieu de livraison</dd>
          </dl>
        </aide>
      </mat-card-header>
      <mat-divider></mat-divider>

      <div class="table-actions">
        <button type="button" mat-raised-button color="primary" matTooltip="Ajouter un lieu"
                (click)="openDialogAjout()">
          <fa-icon icon="plus"></fa-icon> Ajouter
        </button>

        <button type="button"
                mat-raised-button
                color="primary"
                (click)="openDialogModificationMultiple()"
                [disabled]="!selection.hasValue()">
          <fa-icon icon="edit"></fa-icon> Modifier…
        </button>

        <button type="button"
                color="primary"
                mat-raised-button
                (click)="exporter.exportTable({nomFichier: 'liste-lieux.xlsx'})"
                [disabled]="dataSource.data.length === 0">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                      [storeCode]="COLUMNS_STORE_CODE"
                                      [(displayedColumns)]="displayedColumns"></form-displayed-columns>
      </div>


      <table mat-table [dataSource]="dataSource"
             cnousTableExporter #exporter="cnousTableExporter" [columns]="COLUMNS"
             class="mat-elevation-z8"
             matSort matSortActive="batiment" matSortDirection="asc" matSortDisableClear="true"
             aria-label="Liste de paramétrages de lieux">

        <ng-container matColumnDef="checkBox">
          <th mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['checkBox'].tooltip">
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let data;let i = dataIndex">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(data) : null"
                          [checked]="selection.isSelected(data)">
            </mat-checkbox>

            <div class="update-result">
              <span  class="success"><fa-icon icon="check-circle"></fa-icon></span>
              <span class="failure"><fa-icon icon="times-circle"></fa-icon></span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="idLieu">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['idLieu'].tooltip">{{ COLUMNS['idLieu'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.idLieu }}
          </td>
        </ng-container>

        <ng-container matColumnDef="batiment">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['batiment'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.nomRu }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lieu">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['lieu'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.nomUd }}
          </td>
        </ng-container>

        <ng-container matColumnDef="masque">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['masque'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <!-- L'opposé de la valeur est passé en paramètre afin que ce soit le "Oui" qui apparaisse en rouge -->
            <epona-boolean [value]="!element.masque" trueLabel="Non" falseLabel="Oui"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="priseEnCompteVentes">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['priseEnCompteVentes'].tooltip">{{ COLUMNS['priseEnCompteVentes'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <epona-boolean [value]="element.priseEnCompteVentes"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="lieuLivraison">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['lieuLivraison'].tooltip">{{ COLUMNS['lieuLivraison'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <epona-boolean [value]="element.lieuLivraison"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="nomLieuLivraison">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['nomLieuLivraison'].tooltip">{{ COLUMNS['nomLieuLivraison'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.lieuLivraison ? element.nomLieuLivraison : '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceEmetteur">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['serviceEmetteur'].tooltip">{{ COLUMNS['serviceEmetteur'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.serviceEmetteur | referentielOrion }}
          </td>
        </ng-container>

        <ng-container matColumnDef="destination">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['destination'].tooltip">{{ COLUMNS['destination'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.destination | referentielOrion }}
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceGestionnaire">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['serviceGestionnaire'].tooltip">{{ COLUMNS['serviceGestionnaire'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.serviceGestionnaire | referentielOrion }}
          </td>
        </ng-container>

        <ng-container matColumnDef="typeTva">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['typeTva'].tooltip">{{ COLUMNS['typeTva'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.typeTva | referentielOrion }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['actions'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <button type="button"
                    mat-button
                    (click)="openDialogModificationUnique(element)"
                    matTooltip="Modifier le lieu">
              <fa-icon icon="edit"></fa-icon>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'success': row.success === true, 'failure': row.success === false }"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>

    </mat-card>
  </div>
</div>
