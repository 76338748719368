import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Lieu} from "../../model/epona-api/Lieu";
import {UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {MessageTool} from "../../commons/MessageTool";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {TypeMouvement} from "../../model/TypeMouvement";
import {DialogConfirmValidationComponent} from "../dialog-confirm-validation/dialog-confirm-validation.component";
import {EntreeSortieValidation} from "../../model/epona-ui/EntreeSortieValidation";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {DatePipe} from "@angular/common";
import {Format} from "../../commons/constants/Format";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {CommandeService} from "../../services/epona/commande.service";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {UserService} from "../../services/user.service";
import {DialogDataServiceFait} from "../../model/epona-ui/DialogDataServiceFait";
import {DialogServiceFaitComponent} from "../dialog-service-fait/dialog-service-fait.component";
import {
  DialogDataGestionDocuments,
  DialogGestionDocumentsComponent
} from "../../documents/dialog-gestion-documents/dialog-gestion-documents.component";
import {Observable} from "rxjs";
import {DocumentJoint} from "../../model/epona-api/DocumentJoint";
import {DateHelper} from "../../helpers/date-helper";
import {Decimal2Pipe} from "../../commons/pipes/Decimal2Pipe";

@Component({
  selector: 'epona-entree-sortie-entete',
  templateUrl: './entree-sortie-entete.component.html',
  styleUrls: ['./entree-sortie-entete.component.css']
})
export class EntreeSortieEnteteComponent implements OnInit, OnChanges {
  @Input() readonly params: EntreeSortieParametrage;
  @Input() lieuCourant: Lieu;
  @Input() entete: EntreeSortieEntete;
  @Input() entreeSortieLiee: EntreeSortieEntete;
  @Input() modificationLigneEnCours: boolean;
  @Input() modeConsultation: boolean = false;

  @Output() readonly enteteSubmitted = new EventEmitter<EntreeSortieEntete>();
  @Output() readonly validationSubmitted = new EventEmitter<EntreeSortieValidation>();
  @Output() readonly suppressionSubmitted = new EventEmitter<boolean>();
  @Output() readonly serviceFaitUpdated = new EventEmitter<boolean>();
  @Output() readonly listeDocumentsUpdated = new EventEmitter<boolean>();

  enteteForm: UntypedFormGroup;
  droitConsultationCommande: boolean;
  enteteAffichee: boolean = true;

  afficherBoutonDocuments: boolean = false;
  documentsModifiables: boolean = false;
  afficherBoutonServiceFait: boolean = false;
  serviceFaitModifiable: boolean = false;

  // Permet d'accèder au FormGroup du DOM et de récupérer notamment la propriété submitted
  // Utile pour contrôler s'il y a eu des modifications lors d'un changement de page
  @ViewChild(FormGroupDirective)
  form: FormGroupDirective;

  constructor(private fb: UntypedFormBuilder,
              private messageTool: MessageTool,
              private dialog: MatDialog,
              private dateFormat: DatePipe,
              private decimal2: Decimal2Pipe,
              private entreeSortieService: EntreeSortieService,
              private commandeService: CommandeService,
              private userService: UserService) {

    this.enteteForm = fb.group({
      idEntreeSortieEntete:   fb.control(''),
      typeMouvement:          fb.control(null, Validators.required),
      lieuDestination:        fb.control(null),
      lieuEvenement:          fb.control(''),
      dateEvenement:          fb.control(null),
      referenceEvenement:     fb.control(''),
      codeBordereauLivraison: fb.control(''),
      dateBordereauLivraison: fb.control(null),
      temperatureConforme:    fb.control(null),
      commentaire:            fb.control(''),
    });
  }

  ngOnInit() {
    this.droitConsultationCommande = this.commandeService.droitConsultation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entete']) {
      this.initForm();

      this.afficherBoutonDocuments   = this.entete && this.entete.commande && this.entete.commande.externe === true;
      this.documentsModifiables      = this.entete && !this.entete.dateEnvoiSf && this.possedeDroit(CodeDroit.LIVRAISONS_SAISIE);
      this.afficherBoutonServiceFait = this.entete && this.entete.serviceFaitExiste;
      this.serviceFaitModifiable     = this.entete && !this.entete.dateEnvoiSf && this.possedeDroit(CodeDroit.LIVRAISONS_PREPARATION_SF);
    }
  }

  initForm() {
    if (this.params.commonTypeMouvementList && this.params.commonTypeMouvementList.length === 1) {
      const typeMouvement = new TypeMouvement();
      typeMouvement.codeTypeMouvement = this.params.commonTypeMouvementList[0];
      this.enteteForm.get('typeMouvement').setValue(typeMouvement);
    }

    if (this.entete) {
      // Mise à jour des champs avec les informations récupérées
      this.enteteForm.get('idEntreeSortieEntete').setValue(this.entete.idEntreeSortieEntete);
      this.enteteForm.get('typeMouvement').setValue(this.entete.typeMouvement);
      this.enteteForm.get('lieuDestination').setValue(this.entete.lieuDestination);
      this.enteteForm.get('lieuEvenement').setValue(this.entete.lieuEvenement);
      this.enteteForm.get('dateEvenement').setValue(DateHelper.timestampToJsDate(this.entete.dateEvenement));
      this.enteteForm.get('referenceEvenement').setValue(this.entete.referenceEvenement);
      this.enteteForm.get('codeBordereauLivraison').setValue(this.entete.codeBordereauLivraison);
      this.enteteForm.get('dateBordereauLivraison').setValue(DateHelper.timestampToJsDate(this.entete.dateBordereauLivraison));
      this.enteteForm.get('temperatureConforme').setValue(this.entete.temperatureConforme);
      this.enteteForm.get('commentaire').setValue(this.entete.commentaire);

      this.lieuCourant = this.entete.lieu;
    }

    if (this.params.editLieuDestinationRequired) {
      this.enteteForm.get('lieuDestination').setValidators(Validators.required);
    }

    if (this.params.editLieuEvenementRequired) {
      this.enteteForm.get('lieuEvenement').setValidators(Validators.required);
    }

    if (this.params.editDateEvenementRequired) {
      this.enteteForm.get('dateEvenement').setValidators(Validators.required);
    }

    this.disableFields();
  }

  isCreation() {
    return this.entete === undefined;
  }

  isEditable() {
    return this.entete ? this.entete.extra.editable.status : true;
  }

  disableFields() {
    if (!this.entreeSortieService.droitSaisie(this.params)) {
      this.enteteForm.disable();

    // S'il s'agit d'une création alors la plupart des champs sont activés
    } else if (this.isCreation()) {
      this.enteteForm.enable();

      // Certains champs sont toujours désactivés
      this.enteteForm.get('idEntreeSortieEntete').disable();

    // Sinon s'il s'agit d'une entrée/sortie modifiable alors la plupart des champs sont désactivés
    } else if (this.isEditable()) {
      this.enteteForm.disable();

      // Activation des champs spécifiques aux BL
      if (this.params.commonFieldsBLDisplayed) {
        this.enteteForm.get('codeBordereauLivraison').enable();
        this.enteteForm.get('dateBordereauLivraison').enable();
        this.enteteForm.get('temperatureConforme').enable();
      }

      // Activation des champs spécifiques aux sorties événementielles
      if (!this.params.editChampsEvenementDisabled) {
        this.enteteForm.get('lieuEvenement').enable();
        this.enteteForm.get('dateEvenement').enable();
        this.enteteForm.get('referenceEvenement').enable();
      }

      this.enteteForm.get('commentaire').enable();

    // Sinon (entrée/sortie non modifiable) alors les champs sont tous désactivés
    } else {
      this.enteteForm.disable();
    }
  }

  isInvalidForm() {
    return this.enteteForm.invalid
      || !this.lieuCourant          // lieu courant non initialisé
      || !this.lieuCourant.idLieu   // aucun lieu courant sélectionné
      ;
  }

  isModificationLigneEnCours() {
    return this.modificationLigneEnCours;
  }

  libelleCommande(commande: CommandeEntete): string {
    if (commande) {
      let libelle = 'no. ';
      libelle += commande.numeroEj ? commande.numeroEj : commande.idCommandeEntete;
      libelle += ' (';
      libelle += commande.externe ? commande.fournisseur.nom : this.dateFormat.transform(commande.dateLivraisonSouhaitee, Format.FORMAT_DATE_TIME);
      libelle += ')';
      return libelle;
    }
    return null;
  }

  onLieuDestinationSubmitted(lieuDestination: Lieu) {
    if (lieuDestination && lieuDestination.idLieu) {
      this.enteteForm.get('lieuDestination').setValue(lieuDestination);
    } else {
      this.enteteForm.get('lieuDestination').setValue(null);
    }
    this.enteteForm.markAsDirty();
  }

  changerAffichage(): void {
    this.enteteAffichee = !this.enteteAffichee;
  }

  onSubmit() {
    if (!this.entreeSortieService.droitSaisie(this.params)) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else {
      const entete = new EntreeSortieEntete();

      entete.lieu = this.lieuCourant;
      entete.typeMouvement = this.enteteForm.get('typeMouvement').value;
      entete.lieuDestination = this.enteteForm.get('lieuDestination').value;
      entete.lieuEvenement = this.enteteForm.get('lieuEvenement').value;
      entete.dateEvenement = this.enteteForm.get('dateEvenement').value;
      entete.referenceEvenement = this.enteteForm.get('referenceEvenement').value;
      entete.codeBordereauLivraison = this.enteteForm.get('codeBordereauLivraison').value;
      entete.dateBordereauLivraison = this.enteteForm.get('dateBordereauLivraison').value;
      entete.fraisPort = this.entete?.fraisPort; // Saisi dans le composant entree-sortie-lignes
      entete.temperatureConforme = this.enteteForm.get('temperatureConforme').value;
      entete.commentaire = this.enteteForm.get('commentaire').value;

      this.enteteSubmitted.emit(entete);
    }
  }

  openDialogValidation(): void {
    // Si le formulaire d'entête contient des modifications non enregistrées
    if (this.enteteForm.dirty) {
      this.messageTool.sendWarning(this.params.editEnregistrAvtValidationMsg);

    } else if (!this.entreeSortieService.droitValidation(this.params)) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else if (this.entete.extra.validable.status) {
      let message = `<p>${this.params.editValidationConfirmationMsg}</p>`;

      if (this.form.dirty) {
        message += "<p class='alert alert-warning'>Attention&nbsp;: certaines modifications n'ont pas été enregistrées.</p>";
      }

      const fraisPortPrevisionnels = this.entete.commande && this.entete.commande.fraisPortPrevisionnels !== undefined ? this.entete.commande.fraisPortPrevisionnels : null;
      const fraisPort = this.entete.fraisPort;
      if (fraisPortPrevisionnels !== null && fraisPort > fraisPortPrevisionnels) {
        message += `<p class="alert alert-warning">Attention&nbsp;: les frais de port saisis dans ce BL (${this.format(fraisPort)}&nbsp;€) sont supérieurs aux frais de port prévisionnels saisis au moment de la commande (${this.format(fraisPortPrevisionnels)}&nbsp;€).</p>`;
      }

      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        body: message,
        entete: this.entete
      };
      dialogConfig.maxWidth = '400px';

      const dialogRef = this.dialog.open(DialogConfirmValidationComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        if (dialogConfig.data.validation) {
          let validation: EntreeSortieValidation = new EntreeSortieValidation();
          validation.validation = dialogConfig.data.validation;
          validation.validationComplete = dialogConfig.data.validationComplete;

          // Envoi au composant parent les informations de la validation
          this.validationSubmitted.emit(validation)
        }
      });

    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.validable.details);
    }
  }

  private format(montant: number): any {
    return this.decimal2.transform(montant);
  }

  openDialogSuppression(): void {
    if (!this.entreeSortieService.droitSaisie(this.params)) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else if (this.entete.extra.deletable.status) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation de suppression",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: this.params.editSuppressionConfirmationMsg
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut supprimer l'inventaire
          this.suppressionSubmitted.emit(true);
        }
      });
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.deletable.details);
    }
  }

  private possedeDroit(codeDroit: string): boolean {
    return this.userService.utilisateurCourant.possedeDroit(codeDroit);
  }

  /* *********************** */
  /* Gestion du service fait */
  /* *********************** */

  openDialogServiceFait(): void {
    if (!this.possedeDroit(CodeDroit.LIVRAISONS_ENVOI_SF)) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else if (this.afficherBoutonServiceFait) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = new DialogDataServiceFait();
      dialogConfig.data.livraison = this.entete;
      dialogConfig.minWidth = '1000px';
      dialogConfig.maxWidth = 'none';
      dialogConfig.position = {top: '20px'}; // Pour éviter que la fenêtre se repositionne verticalement lors du changement d'onglet

      const dialogRef = this.dialog.open(DialogServiceFaitComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.serviceFaitUpdated.emit(true);
        }
      });
    }
  }

  openDialogGestionDocuments(): void {
    if (!this.possedeDroit(CodeDroit.LIVRAISONS_CONSULTATION)) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else {
      let dialogConfig = new MatDialogConfig<DialogDataGestionDocuments>();
      dialogConfig.data = new DialogDataGestionDocuments();
      dialogConfig.data.loadListeDocumentsFn = this.loadListeDocumentsObs;
      dialogConfig.data.loadDocumentFn = this.loadDocumentObs;
      dialogConfig.data.downloadFileFn = this.downloadFileObs;
      dialogConfig.data.deleteDocumentFn = this.deleteDocumentObs;
      dialogConfig.data.postDocumentFn = this.postDocumentObs;
      dialogConfig.data.putDocumentFn = this.putDocumentObs;
      dialogConfig.data.titre = `Gestion des documents du BL ${this.entete.idEntreeSortieEntete}`;
      dialogConfig.data.droitSaisie = this.possedeDroit(CodeDroit.LIVRAISONS_SAISIE);
      dialogConfig.data.modifiable = !this.entete.dateEnvoiSf;

      const dialogRef = this.dialog.open(DialogGestionDocumentsComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.listeDocumentsUpdated.emit(true);
        }
      });
    }
  }

  loadListeDocumentsObs = (): Observable<DocumentJoint[]> => {
    const fields = 'idDocument,libelle,nomFichier,contentType';
    return this.entreeSortieService.getListeDocuments(this.entete.idEntreeSortieEntete, fields);
  }

  loadDocumentObs = (idDocument: number): Observable<DocumentJoint> => {
    const fields = 'idDocument,libelle';
    return this.entreeSortieService.getDocument(this.entete.idEntreeSortieEntete, idDocument, fields)
  }

  downloadFileObs = (idDocument: number): Observable<any> => {
    return this.entreeSortieService.getFichier(this.entete.idEntreeSortieEntete, idDocument);
  }

  postDocumentObs = (document: DocumentJoint, file: File): Observable<any> => {
    return this.entreeSortieService.postDocument(this.entete.idEntreeSortieEntete, document, file);
  }

  putDocumentObs = (document: DocumentJoint, file: File): Observable<any> => {
    return this.entreeSortieService.putDocument(this.entete.idEntreeSortieEntete, document, file);
  }

  deleteDocumentObs = (idDocument: number): Observable<any> => {
    return this.entreeSortieService.deleleDocument(this.entete.idEntreeSortieEntete, idDocument);
  }
}

