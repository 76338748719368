import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective, Validators,
} from '@angular/forms';
import {MessageTool} from '../../commons/MessageTool';
import {UserService} from '../../services/user.service';
import {CodeDroit} from '../../commons/constants/CodeDroit';
import {Fdnc} from '../../model/epona-api/fdnc';
import {FdncService} from '../../services/epona/fdnc.service';
import {DesignationArticlePipe} from '../../commons/pipes/designation-article.pipe';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
  DialogDataGestionDocuments,
  DialogGestionDocumentsComponent
} from '../../documents/dialog-gestion-documents/dialog-gestion-documents.component';
import {Observable} from 'rxjs';
import {DocumentJoint} from '../../model/epona-api/DocumentJoint';
import {TypeNonConformite} from "../../model/bna-api/type-non-conformite";
import {TraitementNonConformite} from "../../model/bna-api/traitement-non-conformite";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {TempsTraitementFdnc} from "../../model/bna-api/temps-traitement-fdnc";
import {Article} from "../../model/epona-api/Article";
import {FdncBna} from "../../model/bna-api/fdnc-bna";
import {EtapeFdnc} from "../../model/bna-api/etape-fdnc";
import {StatutFdnc} from "../../model/bna-api/statut-fdnc";
import {Const} from '../../commons/constants/Const';
import {ContexteFdnc} from '../../model/bna-api/contexte-fdnc';
import {CustomValidators} from "../../commons/CustomValidators";

@Component({
  selector: 'epona-fdnc',
  templateUrl: './fdnc.component.html',
  styleUrls: ['./fdnc.component.css']
})
export class FdncComponent implements OnInit, OnChanges {

  @Input() fdnc: Fdnc | null | undefined;
  @Input() fdncBna: FdncBna | null | undefined;

  @Output() readonly fdncSubmitted = new EventEmitter<Fdnc>();
  @Output() readonly emissionSubmitted = new EventEmitter<Fdnc>();
  @Output() readonly suppressionSubmitted = new EventEmitter<Fdnc>();
  @Output() readonly listeDocumentsUpdated = new EventEmitter<boolean>();

  form!: FormGroup;
  droitSaisie: boolean = false;
  droitEmission: boolean = false;

  // Permet d'accéder au FormGroup du DOM et de récupérer notamment la propriété submitted
  // Utile pour contrôler s'il y a eu des modifications lors d'un changement de page
  @ViewChild(FormGroupDirective)
  formGroupDirective!: FormGroupDirective;

  fdncSubmittable: boolean = false;
  afficherBoutonDocuments: boolean = false;
  documentsModifiables: boolean = false;


  constructor(private fb: FormBuilder,
              private fdncService: FdncService,
              private userService: UserService,
              private dialog: MatDialog,
              private messageTool: MessageTool,
              private designationArticlePipe: DesignationArticlePipe) {
    this.initForm();
  }

  ngOnInit(): void {
    this.droitSaisie   = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_SAISIE);
    this.droitEmission = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_EMISSION);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fdnc']) {
      this.fdncSubmittable         = this.fdnc && this.droitSaisie && !this.fdnc.emise;
      this.afficherBoutonDocuments = this.fdnc !== null;
      this.documentsModifiables    = this.fdncSubmittable;

      this.setFormValues();
    }

    if (changes['fdncBna']) {
      this.setFormValuesBna();
    }
  }

  initForm() {
    this.form = this.fb.group({
      codeBna:                       this.fb.control<number|null>({ value: null, disabled: true }),
      article:                       this.fb.control<string|null>({ value: null, disabled: true }),
      contexte:                      this.fb.control<string|null>({ value: null, disabled: true }),

      dateConstat:                   this.fb.control<Date|null>({ value: null, disabled: false }, Validators.required),
      numeroLotProduit:              this.fb.control<string|null>({ value: null, disabled: false }),
      listeTypesNonConformite:       this.fb.control<TypeNonConformite[]|null>({ value: null, disabled: false }, [CustomValidators.nbMin(1), CustomValidators.nbMax(3)]),
      observationsNonConformite:     this.fb.control<string|null>({ value: null, disabled: false }, Validators.required),
      listeTraitementsNonConformite: this.fb.control<TraitementNonConformite[]|null>({ value: null, disabled: false }, [CustomValidators.nbMin(1), CustomValidators.nbMax(2)]),
      observationsTraitement:        this.fb.control<string|null>({ value: null, disabled: false }, Validators.required),
      tempsTraitement:               this.fb.control<TempsTraitementFdnc|null>({ value: null, disabled: false }, Validators.required),

      statut:                        this.fb.control<string|null>({ value: StatutFdnc.STATUT_EPONA.libelle, disabled: true }),
      etapeEnCours:                  this.fb.control<string|null>({ value: EtapeFdnc.ETAPE_EPONA.libelle, disabled: true }),
      gravite:                       this.fb.control<string|null>({ value: null, disabled: true }),
    });

    this.form.get('listeTraitementsNonConformite').valueChanges.subscribe(value => {
      this.updateTempsTraitementFormControl(value);
    });
  }

  buildContexteFdnc(idContexte: number): ContexteFdnc {
    let contexteFdnc = new ContexteFdnc();
    contexteFdnc.idContexteFdnc = idContexte;
    return contexteFdnc;
  }

  buildTempsTraitement(idTempsTraitement: number): TempsTraitementFdnc {
    let tempsTraitement: TempsTraitementFdnc = null;
    if (this.fdnc.idTempsTraitement) {
      tempsTraitement = new TempsTraitementFdnc(idTempsTraitement);
    }
    return tempsTraitement;
  }

  buildListeTypesNonConformite(listeIdTypeNonConformite: number[]): TypeNonConformite[] {
    if (listeIdTypeNonConformite !== null) {
      return listeIdTypeNonConformite.map(t => {
        let typeNonConformite = new TypeNonConformite();
        typeNonConformite.code = t;
        return typeNonConformite;
      });
    }
    return null;
  }

  buildListeTraitementsNonConformite(listeIdTraitementNonConformite: number[]): TraitementNonConformite[] {
    if (listeIdTraitementNonConformite !== null) {
      return listeIdTraitementNonConformite.map(t => {
        let traitementNonConformite = new TraitementNonConformite();
        traitementNonConformite.code = t;
        return traitementNonConformite;
      });
    }
    return null;
  }


  setFormValues() {
    if (this.fdnc) {
      if (!this.form.get('codeBna').value) {
        this.form.get('codeBna').setValue(this.fdnc.idFdnc);
      }
      this.form.get('contexte').setValue(this.buildContexteFdnc(this.fdnc.idContexte));
      this.form.get('article').setValue(this.designationArticlePipe.transform(this.buildArticle()));
      this.form.get('dateConstat').setValue(this.fdnc.dateConstat);
      this.form.get('numeroLotProduit').setValue(this.fdnc.numeroLotProduit);
      this.form.get('listeTypesNonConformite').setValue(this.buildListeTypesNonConformite(this.fdnc.listeTypesNonConformite));
      this.form.get('observationsNonConformite').setValue(this.fdnc.observationsNonConformite);
      this.form.get('listeTraitementsNonConformite').setValue(this.buildListeTraitementsNonConformite(this.fdnc.listeTraitementsNonConformite));
      this.form.get('observationsTraitement').setValue(this.fdnc.observationsTraitement);
      this.form.get('tempsTraitement').setValue(this.buildTempsTraitement(this.fdnc.idTempsTraitement));

      this.updateControls();
    }
  }

  setFormValuesBna() {
    if (this.fdncBna) {
      this.form.get('codeBna').setValue(this.fdncBna.code);
      this.form.get('contexte').setValue(this.fdncBna.contexte);
      this.form.get('statut').setValue(this.fdncBna.statut.libelle);
      this.form.get('etapeEnCours').setValue(this.fdncBna.etapeEnCours.libelle);
      this.form.get('gravite').setValue(this.fdncBna.gravite === 0 ? 'Mineure' : 'Majeure');
    }
  }

  private buildArticle(): Article {
    if (this.fdnc.entreeSortieLigne) {
      return this.fdnc.entreeSortieLigne.article;
    }
    if (this.fdnc.commandeLigne) {
      return this.fdnc.commandeLigne.article;
    }
    return null;
  }

  private updateControls(): void {
    if (!this.fdncSubmittable) {
      this.form.get('dateConstat').disable();
      this.form.get('numeroLotProduit').disable();
      this.form.get('listeTypesNonConformite').disable();
      this.form.get('observationsNonConformite').disable();
      this.form.get('listeTraitementsNonConformite').disable();
      this.form.get('observationsTraitement').disable();
      this.form.get('tempsTraitement').disable();
    }
  }

  updateTempsTraitementFormControl(listeTraitements: TraitementNonConformite[]|null) {
    if (this.fdncSubmittable) {
      if (listeTraitements && listeTraitements.some(traitement => traitement.tempsTraitement)) {
        this.form.get('tempsTraitement').enable();
      } else {
        this.form.get('tempsTraitement').setValue(null);
        this.form.get('tempsTraitement').disable();
      }
    } else {
      this.form.get('tempsTraitement').disable();
    }
  }

  onSubmit(): void {
    const fdnc = new Fdnc();
    fdnc.dateConstat                   = this.form.get('dateConstat').value;
    fdnc.numeroLotProduit              = this.form.get('numeroLotProduit').value;
    fdnc.listeTypesNonConformite       = (this.form.get('listeTypesNonConformite').value as TypeNonConformite[]).map(t => t.code);
    fdnc.observationsNonConformite     = this.form.get('observationsNonConformite').value;
    fdnc.listeTraitementsNonConformite = (this.form.get('listeTraitementsNonConformite').value as TraitementNonConformite[]).map(t => t.code);
    fdnc.observationsTraitement        = this.form.get('observationsTraitement').value;
    fdnc.idTempsTraitement             = (this.form.get('tempsTraitement').value as TempsTraitementFdnc)?.idTempsTraitementFdnc;
    this.fdncSubmitted.emit(fdnc);
  }

  openDialogEmission() {
    if (this.form.dirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées dans cette FDNC avant de l\'émettre';
      this.messageTool.sendWarning(message);

    } else {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Émission de la FDNC",
        yesLabel: "Confirmer l'émission",
        noLabel: "Annuler",
        body: 'Confirmez-vous l\'émission de cette FDNC vers la BNA&nbsp;?'
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut émettre la FDNC
          this.emissionSubmitted.emit(this.fdnc);
        }
      });
    }
  }

  openDialogSuppression(): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Suppression de la FDNC",
      yesLabel: "Confirmer la suppression",
      noLabel: "Annuler",
      body: 'Confirmez-vous la suppression de cette FDNC&nbsp;?'
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Envoi au composant parent que l'on veut supprimer la FDNC
        this.suppressionSubmitted.emit(this.fdnc);
      }
    });
  }

  /* ********************* */
  /* Gestion des documents */
  /* ********************* */

  loadListeDocumentsObs = (): Observable<DocumentJoint[]> => {
    const fields = 'idDocument,libelle,nomFichier,contentType';
    return this.fdncService.getListeDocuments(this.fdnc.idFdnc, fields);
  }
  loadDocumentObs = (idDocument: number): Observable<DocumentJoint> => {
    const fields = 'idDocument,libelle';
    return this.fdncService.getDocument(this.fdnc.idFdnc, idDocument, fields)
  }
  downloadFileObs = (idDocument: number): Observable<any> => {
    return this.fdncService.getFichier(this.fdnc.idFdnc, idDocument);
  }
  deleteDocumentObs = (idDocument: number): Observable<any> => {
    return this.fdncService.deleleDocument(this.fdnc.idFdnc, idDocument);
  }
  postDocumentObs = (document: DocumentJoint, file: File): Observable<any> => {
    return this.fdncService.postDocument(this.fdnc.idFdnc, document, file);
  }
  putDocumentObs = (document: DocumentJoint, file: File): Observable<any> => {
    return this.fdncService.putDocument(this.fdnc.idFdnc, document, file);
  }

  openDialogGestionDocuments(): void {
    if (!this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_CONSULTATION)) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else {
      let dialogConfig = new MatDialogConfig<DialogDataGestionDocuments>();
      dialogConfig.data = new DialogDataGestionDocuments();
      dialogConfig.data.loadListeDocumentsFn = this.loadListeDocumentsObs;
      dialogConfig.data.loadDocumentFn = this.loadDocumentObs;
      dialogConfig.data.downloadFileFn = this.downloadFileObs;
      dialogConfig.data.deleteDocumentFn = this.deleteDocumentObs;
      dialogConfig.data.postDocumentFn = this.postDocumentObs;
      dialogConfig.data.putDocumentFn = this.putDocumentObs;
      dialogConfig.data.titre = `Gestion des documents de la FDNC ${this.fdnc.idFdnc}`;
      dialogConfig.data.droitSaisie = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_SAISIE);
      dialogConfig.data.modifiable = !this.fdnc.emise;
      dialogConfig.data.nbDocumentsMax = Const.NB_DOCUMENTS_MAX_FDNC;

      const dialogRef = this.dialog.open(DialogGestionDocumentsComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.listeDocumentsUpdated.emit(true);
        }
      });
    }
  }
}
