<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>{{ fieldName }}</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>


<mat-dialog-content>
  <div class="question">
    {{ article | codeArticle:utilisation }}
    -
    {{ article | designation:utilisation }}
  </div>
  <div class="question" *ngIf="dlc">{{ dlc }}</div>

  <mat-form-field>
    <mat-label>{{ fieldName }}</mat-label>

    <textarea matInput cdkTextareaAutosize
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="6"
              [formControl]="commentaireCtrl"
              maxlength="255">
    </textarea>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Annuler</button>
  <button mat-raised-button color="primary" (click)="save()">Valider</button>
</mat-dialog-actions>
