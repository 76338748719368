import {Fdnc} from "./fdnc";
import {DateHelper} from "../../helpers/date-helper";

export class FdncPUT {
  dateConstat: string;
  numeroLotProduit: string;
  observationsNonConformite: string;
  observationsTraitement: string;
  idTempsTraitement: number;

  listeTraitementsNonConformite: number[];
  listeTypesNonConformite: number[];

  constructor(fdnc: Fdnc) {
    this.dateConstat                   = DateHelper.jsDateToJsonDate(fdnc.dateConstat);
    this.numeroLotProduit              = fdnc.numeroLotProduit;
    this.listeTypesNonConformite       = fdnc.listeTypesNonConformite;
    this.observationsNonConformite     = fdnc.observationsNonConformite;
    this.listeTraitementsNonConformite = fdnc.listeTraitementsNonConformite;
    this.observationsTraitement        = fdnc.observationsTraitement;
    this.idTempsTraitement             = fdnc.idTempsTraitement;
  }
}
