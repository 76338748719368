import {Lieu} from "./Lieu";
import {ServiceEmetteurDTO} from "./ServiceEmetteurDTO";
import {DestinationDTO} from "./DestinationDTO";
import {ServiceGestionnaireDTO} from "./ServiceGestionnaireDTO";
import {TypeTvaDTO} from "./TypeTvaDTO";

export class LieuPUT {

  masque: boolean;
  priseEnCompteVentes: boolean;

  lieuLivraison: boolean
  nomLieuLivraison: string;
  serviceEmetteur: ServiceEmetteurDTO;
  destination: DestinationDTO;
  serviceGestionnaire: ServiceGestionnaireDTO;
  typeTva: TypeTvaDTO;

  constructor(lieu: Lieu) {
    this.masque              = lieu.masque;
    this.priseEnCompteVentes = lieu.priseEnCompteVentes;
    this.lieuLivraison       = lieu.lieuLivraison;
    this.nomLieuLivraison    = lieu.nomLieuLivraison;
    this.serviceEmetteur     = lieu.serviceEmetteur ? new ServiceEmetteurDTO(lieu.serviceEmetteur) : null;
    this.destination         = lieu.destination ? new DestinationDTO(lieu.destination) : null;
    this.serviceGestionnaire = lieu.serviceGestionnaire ? new ServiceGestionnaireDTO(lieu.serviceGestionnaire) : null;
    this.typeTva             = lieu.typeTva ? new TypeTvaDTO(lieu.typeTva) : null;
  }
}
