<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field>
      <mat-label>Fournisseur</mat-label>
      <epona-select-fournisseur formControlName="fournisseur"
                                defaultLabel="- Tous -"
                                epoHighlightIfNotEmpty></epona-select-fournisseur>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Lot-marché</mat-label>
      <epona-select-marche formControlName="marche"
                           defaultLabel="- Tous -"
                           epoHighlightIfNotEmpty></epona-select-marche>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Article</mat-label>
      <epona-autocomplete-article formControlName="article" epoHighlightIfNotEmpty></epona-autocomplete-article>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Gravité</mat-label>
      <select-boolean formControlName="gravite"
                      defaultLabel="- Toutes -"
                      trueLabel="Majeure"
                      falseLabel="Mineure"
                      epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Statut</mat-label>
      <epona-select-statut-fdnc formControlName="statuts"
                                multiple="true"
                                defaultLabel="- Tous -"
                                epoHighlightIfNotEmpty>
      </epona-select-statut-fdnc>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Étape en cours</mat-label>
      <epona-select-etape-fdnc formControlName="etapesEnCours"
                                multiple="true"
                                defaultLabel="- Toutes -"
                                epoHighlightIfNotEmpty>
      </epona-select-etape-fdnc>
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon>
        Rechercher
      </button>
    </div>
  </mat-card>
</form>
