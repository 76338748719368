import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {ArticleService} from "../../services/epona/article.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {MessageTool} from "../../commons/MessageTool";
import {Tools} from "../../commons/Tools";
import {ArticleSearch} from "../../model/epona-api/ArticleSearch";
import {ArticleFilter} from "../../model/epona-ui/article-filter";
import {ArticleQueryParams} from "../../model/epona-ui/ArticleQueryParams";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Lieu} from "../../model/epona-api/Lieu";
import {FilterTools} from "../../commons/FilterTools";
import {Article} from "../../model/epona-api/Article";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {
  DisplayedColumnsTools,
  TableColumn,
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {SelectionModel} from "@angular/cdk/collections";
import {DialogDataModificationMultipleArticles} from "../../model/epona-ui/DialogDataModificationMultipleArticles";
import {DialogModificationMultipleComponent} from "../dialog-modification-multiple/dialog-modification-multiple.component";
import {FormTools} from "../../commons/FormTools";
import {NumberComparison} from "../../model/epona-ui/NumberComparison";
import {FamilleMarchesPipe} from "../../commons/pipes/FamilleMarchesPipe";
import {SousGroupePipe} from "../../commons/pipes/SousGroupePipe";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
  selector: 'epona-liste-articles',
  templateUrl: './liste-articles.component.html',
  styleUrls: ['./liste-articles.component.css']
})
export class ListeArticlesComponent implements OnInit {

  private readonly nbMaxLoaded = 1000;

  private readonly path: string;
  utilisation: string;
  afficherAchat: boolean = true;
  afficherVente: boolean = true;

  private readonly FIELDS = 'idArticle,' +
    'codeArticleVente,' +
    'pcb,' +
    'articleAchat,' +
    'articleVente,' +
    'actifAchat,' +
    'actifVente,' +
    'designationAchat,' +
    'designationVente,' +
    'articleDlc,' +
    'delaiReceptionDlc,' +
    'origineAchat,' +
    'codeArticleAchat,' +
    'sousFamille.libelle,' +
    'sousFamille.famille.libelle,' +
    'sousFamille.famille.departement.libelle,' +

    'familleMarches.idFamilleMarches,' +
    'familleMarches.code,' +
    'familleMarches.libelle,' +
    'familleMarches.categorieMarches.code,' +
    'familleMarches.categorieMarches.libelle,' +
    'sousGroupe.idSousGroupeArticles,' +
    'sousGroupe.code,' +
    'sousGroupe.libelle,' +
    'sousGroupe.groupe.code,' +
    'sousGroupe.groupe.libelle,' +
    'commandableExterne,' +
    'stockGere,' +
    'stockGereOperationnellement,' +
    'commentaireParametrage,' +
    'listeInformationsMarche.codeMarcheOrion,' +
    'listeInformationsMarche.numeroSousLot,' +
    'listeInformationsMarche.typeSousLot,' +
    'listeInformationsMarche.reference,' +
    'listeInformationsMarche.commentaireMarche,' +
    'listeInformationsMarche.prixHt,' +
    'listeInformationsMarche.listeMercuriales.prixHt' +
    '';

  dataSource = new MatTableDataSource([]);
  selection = new SelectionModel<Article>(true, []);

  filter: ArticleFilter;
  lieuCourant: Lieu;

  COLUMNS: {[key: string]: TableColumn} = {};
  COLUMNS_STORE_CODE = null;

  displayedColumns: string[] = [];

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  constructor(private route: ActivatedRoute,
              private articleService: ArticleService,
              private messageTool: MessageTool,
              public dialog: MatDialog,
              private router: Router) {

    this.path = this.route.snapshot.url[0].path;

    if (this.route.snapshot.data.utilisation) {
      this.utilisation = this.route.snapshot.data.utilisation;
      this.afficherAchat = !this.utilisation || this.utilisation === UtilisationArticle.ACHAT;
      this.afficherVente = !this.utilisation || this.utilisation === UtilisationArticle.VENTE;

      this.initInformationsColonnes();
    }
  }

  private initInformationsColonnes() {
    switch (this.utilisation) {
      case UtilisationArticle.ACHAT:
        this.COLUMNS = {
          checkBox:                    new TableColumn({label: 'Sélection',    hiddable: false, export: false}),
          familleMarches:              new TableColumn({label: 'Catégorie/famille de marchés', default: false}),
          sousGroupe:                  new TableColumn({label: 'Groupe/sous-groupe'}),
          codeArticle:                 new TableColumn({label: 'Code'}),
          designation:                 new TableColumn({label: 'Désignation' ,  hiddable: false}),
          actifAchat:                  new TableColumn({label: 'Actif'}),
          origineAchat:                new TableColumn({label: 'Origine',       default: false}),
          commandableExterne:          new TableColumn({label: 'Commandable',   tooltip: 'Peut être commandé à un fournisseur'}),
          stockGereComptablement:      new TableColumn({label: 'Stock compta.', tooltip: 'Stock géré comptablement'}),
          stockGereOperationnellement: new TableColumn({label: 'Stock opé.',    tooltip: 'Stock géré opérationnellement'}),
          lotMarche:                   new TableColumn({label: 'Lot-marché',    default: false}),
          typeSousLot:                 new TableColumn({label: 'Type',          tooltip: 'Type de lot-marché', default: false}),
          referenceFournisseur:        new TableColumn({label: 'Référence',     tooltip: 'Référence-fournisseur', default: false}),
          prixHt:                      new TableColumn({label: 'Prix HT',       default: false}),
          actions:                     new TableColumn({label: 'Actions',       hiddable: false, export: false})
        };
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.ARTICLES_ACHAT;
        break;

      case UtilisationArticle.VENTE:
        this.COLUMNS = {
          checkBox:          new TableColumn({label: 'Sélection',    hiddable: false, export: false}),
          sousFamille:       new TableColumn({label: 'Département/famille/sous-famille'}),
          codeArticle:       new TableColumn({label: 'Code', exportFormat: 'integer'}),
          designation:       new TableColumn({label: 'Désignation' , hiddable: false}),
          actifVente:        new TableColumn({label: 'Actif'}),
          colisage:          new TableColumn({label: 'Colisage',     tooltip: 'Colisage interne', exportFormat: 'decimal'}),
          articleDlc:        new TableColumn({label: 'Avec DLC'}),
          delaiReceptionDlc: new TableColumn({label: 'Durée conso.', tooltip: 'Durée de consommation après réception', exportFormat: 'integer'}),
          actions:           new TableColumn({label: 'Actions',      hiddable: false, export: false})
        };
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.ARTICLES_VENTE;
        break;

      default:
        this.COLUMNS = {
          checkBox:                    new TableColumn({label: 'Sélection',     hiddable: false, export: false}),
          sousFamille:                 new TableColumn({label: 'Département/famille/sous-famille'}),
          codeArticle:                 new TableColumn({label: 'Code', exportFormat: 'date'}),
          designation:                 new TableColumn({label: 'Désignation' ,  hiddable: false}),

          actifAchat:                  new TableColumn({label: 'Actif achat'}),
          familleMarches:              new TableColumn({label: 'Catégorie/famille de marchés'}),
          sousGroupe:                  new TableColumn({label: 'Groupe/sous-groupe'}),
          origineAchat:                new TableColumn({label: 'Origine',       default: false}),
          commandableExterne:          new TableColumn({label: 'Commandable',   tooltip: 'Peut être commandé à un fournisseur'}),
          stockGereComptablement:      new TableColumn({label: 'Stock compta.', tooltip: 'Stock géré comptablement'}),
          stockGereOperationnellement: new TableColumn({label: 'Stock opé.',    tooltip: 'Stock géré opérationnellement'}),
          lotMarche:                   new TableColumn({label: 'Lot-marché',    default: false}),
          typeSousLot:                 new TableColumn({label: 'Type',          tooltip: 'Type de lot-marché', default: false}),
          referenceFournisseur:        new TableColumn({label: 'Référence',     tooltip: 'Référence-fournisseur', default: false}),
          prixHt:                      new TableColumn({label: 'Prix HT',       default: false, exportFormat: 'decimal'}),

          actifVente:                  new TableColumn({label: 'Actif vente'}),
          colisage:                    new TableColumn({label: 'Colisage'}),
          articleDlc:                  new TableColumn({label: 'Avec DLC'}),
          delaiReceptionDlc:           new TableColumn({label: 'Durée conso.',  tooltip: 'Durée de consommation après réception'}),
          actions:                     new TableColumn({label: 'Actions',       hiddable: false, export: false})
        };
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.ARTICLES;
    }
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.filter = new ArticleFilter();
        if (this.afficherAchat) {
          this.filter.commandableExterne = -1;  // uniquement les articles dont le statut commandable n'est pas défini
        }
        if (this.afficherVente) {
          this.filter.actifVente = true;        // uniquement les articles actifs
        }
      }
      this.rechercher();
    });

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = ListeArticlesComponent.sortingDataAccessor;
  }

  private initFiltersFromURL(params: ParamMap) {
    this.filter = new ArticleFilter();
    this.filter.groupe                      = FilterTools.buildGroupeArticlesFromURL(params);
    this.filter.sousGroupe                  = FilterTools.buildSousGroupeArticlesFromURL(params);
    this.filter.departement                 = FilterTools.buildDepartementFromURL(params);
    this.filter.famille                     = FilterTools.buildFamilleFromURL(params);
    this.filter.sousFamille                 = FilterTools.buildSousFamilleFromURL(params);
    this.filter.codeArticle                 = params.get('codeArticle');
    this.filter.designation                 = params.get('designation');
    this.filter.actifVente                  = params.get('actifVente') ? Tools.stringToBoolean(params.get('actifVente')) : null;
    this.filter.colisage                    = params.get('colisage') ? +params.get('colisage') : null;
    this.filter.articleDlc                  = params.get('articleDlc') ? Tools.stringToBoolean(params.get('articleDlc')) : null;
    this.filter.delaiReceptionDlc           = params.get('delaiReceptionDlc') ? +params.get('delaiReceptionDlc') : null;
    this.filter.commandableExterne          = params.get('commandableExterne') ? +params.get('commandableExterne') : null;
    this.filter.stockGere                   = params.get('stockGere') ? Tools.stringToBoolean(params.get('stockGere')) : null;
    this.filter.stockGereOperationnellement = params.get('stockGereOperationnellement') ? Tools.stringToBoolean(params.get('stockGereOperationnellement')) : null;
    this.filter.marche                      = FilterTools.buildMarcheFromURL(params);
    this.filter.comparaisonPrixHt           = params.get('prixHt') ? new NumberComparison(params.get('operateurPrixHt'), +params.get('prixHt')) : null;
    this.filter.origineAchat                = params.get('origineAchat');
  }

  private rechercher(idArticle?: number) {
    this.dataSource.data = [];
    this.messageTool.clearAllMessages();

    const search = ArticleSearch.fabric(this.filter);
    search.idCrous = +localStorage.getItem('idCrous');
    search.inclureInformationsMarche = this.utilisation === UtilisationArticle.ACHAT;
    search.utilisation = this.utilisation;
    search.max = this.nbMaxLoaded;
    search.fields = this.FIELDS;

    this.articleService.getListeArticles(search).subscribe(data => {
      this.dataSource.data = data;
      this.setDisplayedColumns();
      this.selection.clear();

      if (idArticle) {
        const article = this.dataSource.data.find(art => art.idArticle === idArticle);
        if (article) {
          FormTools.markAsSuccess(article);
        }
      }

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  onFilterSubmitted(filter : ArticleFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate([this.path], {queryParams: ArticleQueryParams.fabric(this.filter)});
  }

  openDialogAjoutParametrage(): void {
    this.messageTool.clearAllMessages();

    const search = new ArticleSearch();
    search.idCrous = +localStorage.getItem('idCrous');
    search.utilisation = UtilisationArticle.VENTE;
    search.fields = 'codeArticleVente,idArticle';

    this.articleService.getListeArticles(search).subscribe((listeArticles: Article[]) => {
      let dialogConfig = new MatDialogConfig<DialogDataModificationMultipleArticles>();
      dialogConfig.data = new DialogDataModificationMultipleArticles();
      dialogConfig.data.listeArticles = [];
      dialogConfig.data.listeArticlesExistants = listeArticles;
      dialogConfig.data.utilisation = UtilisationArticle.VENTE;

      const dialogRef = this.dialog.open(DialogModificationMultipleComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(idArticle => {
        if (idArticle) {
          this.rechercher(idArticle);
        }
      });

    }, err => {
      this.messageTool.sendErrorMessage('Erreur lors de la récupération des articles existants');
      console.error(err);
    });
  }

  openDialogModificationUnique(article: Article): void {
    this.openDialogModification([article]);
  }

  openDialogModificationMultiple(): void {
    this.openDialogModification(this.selection.selected);
  }

  private openDialogModification(listeArticles: Article[]): void {
    this.messageTool.clearAllMessages();

    let dialogConfig = new MatDialogConfig<DialogDataModificationMultipleArticles>();
    dialogConfig.data = new DialogDataModificationMultipleArticles();
    dialogConfig.data.listeArticles = listeArticles;
    dialogConfig.data.utilisation = this.utilisation;
    dialogConfig.minWidth = '560px';

    const dialogRef = this.dialog.open(DialogModificationMultipleComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.dataSource._updateChangeSubscription();
        FormTools.markAsSuccess(listeArticles);
      }
    });
  }

  /* ******************** */
  /* Sélection des lignes */
  /* ******************** */

  // Si le nombre d'éléments sélectionnés correspond au nombre total de lignes
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // Sélectionne toutes les lignes si elles ne sont pas sélectionnées sinon déselectionne toutes les lignes
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  private static sortingDataAccessor(data: Article, sortHeaderId: string): string|number {
    switch(sortHeaderId) {
      case 'familleMarches':              return FamilleMarchesPipe.buildLibelle(data.familleMarches);
      case 'sousGroupe':                  return SousGroupePipe.buildLibelle(data.sousGroupe);
      case 'codeArticle':                 return data.articleAchat ? data.codeArticleAchat : data.codeArticleVente;
      case 'designation':                 return data.articleAchat ? data.designationAchat : data.designationVente;
      case 'actifAchat':                  return SortHelper.booleanToString(data.actifAchat);
      case 'origineAchat':                return data.origineAchat;
      case 'commandableExterne':          return SortHelper.booleanToString(data.commandableExterne);
      case 'stockGereComptablement':      return SortHelper.booleanToString(data.stockGere);
      case 'stockGereOperationnellement': return SortHelper.booleanToString(data.stockGereOperationnellement);
      case 'lotMarche':                   return data.listeInformationsMarche.map<string>(info => info.codeMarcheOrion + '.' + info.numeroSousLot).join(',');
      case 'typeSousLot':                 return data.listeInformationsMarche.map<string>(info => info.typeSousLot).join(',');
      case 'referenceFournisseur':        return data.listeInformationsMarche.map<string>(info => info.reference).join(',');
      case 'prixHt':                      return data.listeInformationsMarche.map<number>(info => info.prixHt)[0];
      case 'actifVente':                  return SortHelper.booleanToString(data.actifVente);
      case 'colisage':                    return data.pcb;
      case 'articleDlc':                  return SortHelper.booleanToString(data.articleDlc);
      case 'delaiReceptionDlc':           return data.delaiReceptionDlc;
    }
  }
}
