import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogDataArticleAutreLot} from "../../model/epona-ui/DialogDataArticleAutreLot";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {CommandeService} from "../../services/epona/commande.service";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {CommandeSearch} from "../../model/epona-api/CommandeSearch";
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CommandeLigne} from "../../model/epona-api/CommandeLigne";
import {Article} from "../../model/epona-api/Article";

@Component({
  selector: 'app-dialog-article-autre-lot',
  templateUrl: './dialog-article-autre-lot.component.html',
  styleUrls: ['./dialog-article-autre-lot.component.css']
})
export class DialogArticleAutreLotComponent implements OnInit {

  public formGroup: FormGroup;
  public listeCommandes: CommandeEntete[] = [];

  public readonly CODE_NOUVELLE_COMMANDE = 'new';
  public readonly CODE_IGNORER = 'no_action';

  constructor(public dialogRef: MatDialogRef<DialogArticleAutreLotComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataArticleAutreLot,
              private messageTool: MessageTool,
              private commandeService: CommandeService,
              private fb: FormBuilder) {
    this.formGroup = fb.group({
      action: fb.control<string>(this.CODE_IGNORER, Validators.required)
    });
  }

  ngOnInit(): void {
    this.loadListeCommandes();
  }

  private loadListeCommandes(): void {
    const search = new CommandeSearch();
    search.idLieu = this.data.commande.lieuDemandeur.idLieu;
    search.codesEtat = [CodeEtatCommande.BROUILLON];
    search.idSousLotZg = this.data.articleMarche.sousLotZg.idSousLotZg;
    search.fields = 'idCommandeEntete';

    this.commandeService.getListeEntetes(search).subscribe(data => {
      this.listeCommandes = data;
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    const action = this.formGroup.get('action').value;

    switch (action) {
      case this.CODE_IGNORER:
        this.close();
        break;
      case this.CODE_NOUVELLE_COMMANDE:
        this.ajouterSurNouvelleCommande();
        break;
      default:
        this.ajouterSurCommandeExistante(+action);
    }
  }

  private ajouterSurNouvelleCommande(): void {
    const entete = new CommandeEntete();
    entete.lieuDemandeur = this.data.commande.lieuDemandeur;
    entete.externe = true;
    entete.dateLivraisonSouhaitee = this.data.commande.dateLivraisonSouhaitee;

    this.commandeService.postEntete(entete).subscribe(data => {
      const message = `L'article ${this.data.articleMarche.designationAchat} a été ajouté à la nouvelle commande no. ${data.idCommandeEntete}`
      this.ajouterSurCommandeExistante(data.idCommandeEntete, message);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private ajouterSurCommandeExistante(idCommandeEntete: number, message: string = null): void {
    if (message === null) {
      message = `L'article ${this.data.articleMarche.designationAchat} a été ajouté à la commande no. ${idCommandeEntete}`;
    }

    const ligne = new CommandeLigne();
    ligne.article = new Article();
    ligne.article.idArticle = this.data.articleMarche.idArticle;
    ligne.article.sousLotZg = this.data.articleMarche.sousLotZg

    this.commandeService.postLigne(idCommandeEntete, ligne).subscribe(() => {
      this.messageTool.sendSuccess(message, ClearMessages.TRUE);
      this.close();

    }, err => {
      this.messageTool.sendError(err);
    })
  }
}
