import {DTOWithLieu} from "../epona-ui/DTOWithLieu";

export class InventaireSearch extends DTOWithLieu {
  idInventaire: number;
  utilisateur: string;
  cloture: boolean;
  libelle: string;
  annee: number;
  mois: number;
  fields: string;
}
