export class FdncBnaSearch {
  idEtablissementBns!: number;
  codeOrigine!: string;
  idBatimentBns!: number;
  idLieuBns!: number;
  numeroFdnc!: number;
  numeroEjCommande!: string;
  numeroBlFournisseur!: string;
  codeArticle!: string;
  gravite!: number;
  idStatut!: number;
  idsEtapeEnCours!: number[];

  fields: string;
}
