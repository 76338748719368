<mat-select [formControl]="formCtrl" (selectionChange)="selectionChanged()">
  <mat-option [value]="DEFAULT">{{ defaultLabel }}</mat-option>

  <mat-option *ngFor="let item of listePresents" [value]="item.codeMarcheOrion">{{ item.codeMarcheOrion }}</mat-option>

  <mat-optgroup label="Marchés à venir" *ngIf="afficherFuturs && listeFuturs.length > 0">
    <mat-option *ngFor="let item of listeFuturs" [value]="item.codeMarcheOrion">{{ item.codeMarcheOrion }}</mat-option>
  </mat-optgroup>

  <mat-optgroup label="Marchés terminés" *ngIf="afficherPasses && listePasses.length > 0">
    <mat-option *ngFor="let item of listePasses" [value]="item.codeMarcheOrion">{{ item.codeMarcheOrion }}</mat-option>
  </mat-optgroup>
</mat-select>
