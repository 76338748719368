import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';
import {DateFormatPipe} from "./pipes/DateFormatPipe";
import {ILigneArticleDlc} from "../model/epona-api/ILigneArticleDlc";
import {Information} from "../model/epona-api/Information";
import {DesignationArticlePipe} from "./pipes/designation-article.pipe";

export enum ClearMessages {
  TRUE,
  FALSE
}

@Injectable()
export class MessageTool {
  public static ACCES_REFUSE = "Accès refusé : vous ne disposez pas des droits suffisants";

  constructor(private toastr: ToastrService,
              private dateFormat: DateFormatPipe,
              private designationArticlePipe: DesignationArticlePipe) {}

  sendError(err: HttpErrorResponse): void {
    let logError: boolean = true;

    if (this.areStandardErrors(err)) {
      for (let error of err.error) {
        let description = error.error_description;
        if (error.error_code === 'TOO_MANY_RESULTS') {
          logError = false;
          description += ' Merci d\'affiner votre recherche.';
          this.sendWarning(description);
        } else {
          this.errorToast(description);
        }
      }
    } else if (this.areBlobErrors(err)) {
      (err.error as Blob).text().then(res => {
        for (let error of JSON.parse(res)) {
          this.errorToast(error.error_description);
        }
      });

    } else {
      this.errorToast("Une erreur s'est produite. Veuillez réessayer ultérieurement");
    }

    if (logError) {
      console.log(err);
    }
  }

  areStandardErrors(err: HttpErrorResponse): boolean {
    return err.status !== 0 && JSON.stringify(err.error).search('error_description') !== -1;
  }
  areBlobErrors(err: HttpErrorResponse): boolean {
    return err.status !== 0 && err.error.constructor === Blob;
  }

  sendSuccess(message: string, clear: ClearMessages = ClearMessages.FALSE) {
    if (clear === ClearMessages.TRUE) {
      this.clearAllMessages();
    }

    this.toastr.success(message);
  }

  sendInfo(message: string, clear: ClearMessages = ClearMessages.FALSE) {
    if (clear === ClearMessages.TRUE) {
      this.clearAllMessages();
    }

    this.toastr.info(message);
  }

  sendWarning(message: string, clear: ClearMessages = ClearMessages.FALSE) {
    if (clear === ClearMessages.TRUE) {
      this.clearAllMessages();
    }

    this.toastr.warning(message, '', {
      disableTimeOut: true,
      closeButton: true
    });
  }

  sendErrorMessage(message: string) {
    this.errorToast(message);
  }

  sendErrorMessages(details: Information[], nbMax: number = null) {
    const limit = nbMax ? nbMax : 3;
    for (const detail of details.slice(0, limit)) {
      this.errorToast(detail.description);
    }
  }

  clearAllMessages() {
    this.toastr.clear();
  }

  private errorToast(message: string) {
    this.toastr.error(message, '', {
      disableTimeOut: true,
      closeButton: true
    });
  }

  lignePrefix(ligne: ILigneArticleDlc): string {
    const designation = this.designationArticlePipe.transform(ligne.article);
    let message = `La ligne de l'article ${designation}`;
    if (ligne.article.articleDlc) {
      message += ' pour la DLC ';
      if (ligne.dlc) {
        message += this.dateFormat.transform(ligne.dlc);
      } else {
        message += 'non renseignée';
      }
    }
    return message;
  }
}
