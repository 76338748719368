import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {DialogDataLigneLotEntreeSortie} from "../../model/epona-ui/dialog-data-ligne-lot-entree-sortie";
import {EntreeSortieLigneLot} from "../../model/epona-api/entree-sortie-ligne-lot";
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup, ValidationErrors,
  Validators
} from "@angular/forms";
import {TableColumn} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {MatTableDataSource} from "@angular/material/table";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {CustomValidators} from "../../commons/CustomValidators";
import {FormTools} from "../../commons/FormTools";

@Component({
  selector: 'app-dialog-gestion-lots',
  templateUrl: './dialog-gestion-lots.component.html',
  styleUrls: ['./dialog-gestion-lots.component.css']
})
export class DialogGestionLotsComponent {

  static readonly COLUMNS: {[key: string]: TableColumn} = {
    numeroLot: new TableColumn({label: 'Numéro de lot'}),
    quantite:  new TableColumn({label: 'Quantité'}),
    actions:   new TableColumn({label: 'Actions'})
  };

  dataSource = new MatTableDataSource<EntreeSortieLigneLot>();
  columns = DialogGestionLotsComponent.COLUMNS;
  form: UntypedFormGroup = new FormGroup<any>({});
  listeLots: EntreeSortieLigneLot[];
  buttonsDisabled: boolean = false;

  rows: FormArray = this.fb.array([]);

  FIELDS_LOTS = 'idEntreeSortieLigneLot,numeroLot,quantite';

  modified: boolean = false;


  constructor(public dialog: MatDialogRef<DialogGestionLotsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataLigneLotEntreeSortie,
              private entreeSortieService: EntreeSortieService,
              private fb: UntypedFormBuilder,
              private messageTool: MessageTool) {
    this.initListeLots();
  }

  initListeLots() {
    this.entreeSortieService.getListeLotsFromLigne(this.data.entete.idEntreeSortieEntete,
      this.data.ligne.idEntreeSortieLigne, this.FIELDS_LOTS).subscribe(data => {
        this.listeLots = data;
        this.dataSource.data = this.listeLots;
        this.initForm();
    });
  }

  initForm() {
    if (this.listeLots) {
      for (const lot of this.listeLots) {
        const formGroup = this.initLotFormGroup(lot);
        this.rows.push(formGroup);
      }
    }
    this.form = this.fb.group({
      listeLots: this.rows,
      quantiteLigne: this.fb.control(this.data.ligne.quantite)
    });
    this.form.setValidators(DialogGestionLotsComponent.coherenceLots);

    if (!this.data.entete.extra.editable.status) {
      this.buttonsDisabled = true;
      this.form.disable();
    }
  }


  private static coherenceLots(formGroup: FormGroup): ValidationErrors[] {
    let errors: ValidationErrors[] = [];
    const fa = formGroup.get('listeLots') as FormArray;
    const fg = fa.controls.find(c => c.dirty) as FormGroup;
    if(fg) {
      if (fg.controls['numeroLot'].dirty) {
        const numeroLot:string = fg.controls['numeroLot'].value;
        const lotExistant = fa.controls.filter(f => !f.dirty).find((f:FormGroup) => f.controls['numeroLot'].value == numeroLot);
        if(lotExistant) {
          FormTools.addErrorToCtrl('lot-existant',fg.controls['numeroLot']);
          errors.push({'lot-existant': true});
        } else {
          FormTools.removeErrorFromCtrl('lot-existant', fg.controls['numeroLot']);
        }
      }

      if (fg.controls['quantite'].dirty) {
        const sommeQuantite = fa.controls.map((f:FormGroup) => f.controls['quantite'].value).reduce((acc,val) => acc + val, 0);
        if (formGroup.controls['quantiteLigne'].value < sommeQuantite) {
          FormTools.addErrorToCtrl('quantite-error',fg.controls['quantite']);
          errors.push({'quantite-error': true});
        } else {
          FormTools.removeErrorFromCtrl('quantite-error',fg.controls['quantite']);
        }
      }
    }
    if (errors) {
      return errors;
    }
    return null;
  }

  hasErrorDetails(index: number, controlName: string, errorCode: string): boolean {
    const ligneCtrl = (this.form.get('listeLots') as UntypedFormArray).controls[index];
    return ligneCtrl.get(controlName).hasError(errorCode);
  }

  annuler() {
    this.close();
  }

  close() {
    this.dialog.close(this.modified ? this.getQuantiteLots() : null);
  }

  removeLot(index: number) {
    const deletedLot = this.form.get("listeLots").value[index];

    if (!this.form.get('listeLots').valid || !deletedLot.idEntreeSortieLigneLot) {
      this.dataSource.data.splice(index, 1);
      this.getFormArrayListeLots().removeAt(index);
      this.dataSource._updateChangeSubscription();
    } else {
      this.entreeSortieService.deleteLot(this.data.entete.idEntreeSortieEntete, this.data.ligne.idEntreeSortieLigne, deletedLot).subscribe(() => {
        this.dataSource.data.splice(index, 1);
        this.getFormArrayListeLots().removeAt(index);
        this.dataSource._updateChangeSubscription();

        const message = `Le lot a été supprimé avec succès`;
        this.messageTool.sendSuccess(message, ClearMessages.TRUE);

        this.modified = true;

      }, error => {
        this.messageTool.sendError(error);
      });
    }

    this.dataSource._updateChangeSubscription();

  }

  initLotFormGroup(lot: EntreeSortieLigneLot) {
    return this.fb.group({
      idEntreeSortieLigneLot: this.fb.control(lot.idEntreeSortieLigneLot),
      numeroLot:              this.fb.control(lot.numeroLot, Validators.required),
      quantite:               this.fb.control(lot.quantite, [Validators.required, Validators.min(0), CustomValidators.notZero, CustomValidators.nbMaxDecimals(4)])
    });
  }

  public getFormArrayListeLots(): UntypedFormArray {
    return this.form.controls['listeLots'] as UntypedFormArray;
  }

  getQuantiteLots() {
    if (this.dataSource.data.length === 0) {
      return 0;
    }
    return this.dataSource.data.map(l => l.quantite).reduce((acc, curr) => acc + curr);
  }

  addNewLot() {
    const newLot = new EntreeSortieLigneLot();
    if (this.dataSource.data.length > 0) {
      newLot.quantite = this.data.ligne.quantite - this.getQuantiteLots();
    } else {
      newLot.quantite = this.data.ligne.quantite;
    }
    const newLotFormGroup = this.initLotFormGroup(newLot);
    newLotFormGroup.controls['numeroLot'].markAsTouched();
    newLotFormGroup.controls['quantite'].markAsTouched();

    this.dataSource.data.push(newLot);
    this.getFormArrayListeLots().push(newLotFormGroup);

    this.dataSource._updateChangeSubscription();
  }

  disableOtherControls(index: number) {
    const formArray = this.getFormArrayListeLots();
    formArray.controls.filter(c => c != formArray.controls[index]).forEach(c => c.disable());
    const formGroupLot = formArray.controls[index] as FormGroup;
    // pour s'assurer d'avoir toujours la bonne valeur dans l'objet passé à la requête
    this.dataSource.data[index].numeroLot = formGroupLot.controls['numeroLot'].value;
    this.dataSource.data[index].quantite = formGroupLot.controls['quantite'].value;
    this.dataSource._updateChangeSubscription();
  }

  updateLot(index: number) {
    const formCtrl = this.getFormArrayListeLots();
    if (formCtrl.dirty && formCtrl.valid) {
      const updatedLot = this.dataSource.data[index];
      if (!updatedLot.idEntreeSortieLigneLot) {
        this.entreeSortieService.postLot(this.data.entete.idEntreeSortieEntete, this.data.ligne.idEntreeSortieLigne, updatedLot).subscribe(data => {
          this.getFormArrayListeLots().controls[index].get("idEntreeSortieLigneLot").setValue(data.idEntreeSortieLigneLot);
          this.dataSource.data[index].idEntreeSortieLigneLot = data.idEntreeSortieLigneLot;
          this.dataSource.data[index].quantite = data.quantite;
          this.dataSource.data[index].numeroLot = data.numeroLot;
          const message = `Le lot a été créé avec succès`;
          this.messageTool.sendSuccess(message, ClearMessages.TRUE);
          formCtrl.markAsUntouched();
          formCtrl.markAsPristine();

          this.modified = true;

          this.getFormArrayListeLots().controls.filter(c => !c.dirty).forEach(f => f.enable());
        }, err => {
          this.messageTool.sendError(err);
        });
      } else {
        this.entreeSortieService.putLot(this.data.entete.idEntreeSortieEntete, this.data.ligne.idEntreeSortieLigne, updatedLot).subscribe(() => {
          this.dataSource.data[index].quantite = updatedLot.quantite;
          this.dataSource.data[index].numeroLot = updatedLot.numeroLot;
          const message = `Le lot a été mis à jour avec succès`;
          this.messageTool.sendSuccess(message, ClearMessages.TRUE);
          formCtrl.markAsUntouched();
          formCtrl.markAsPristine();

          this.modified = true;

          this.getFormArrayListeLots().controls.filter(c => !c.dirty).forEach(f => f.enable());
        }, err => {
          this.messageTool.sendError(err);
        });
      }

    }
  }
}
