import Dexie, { Table } from 'dexie';
import {InventaireLigne} from "../model/epona-api/InventaireLigne";
import {InventaireEntete} from "../model/epona-api/InventaireEntete";
import {InventaireUtilisateurDexie} from "../model/epona-api/inventaire-utilisateur-dexie";
import {LieuDexie} from "../model/epona-api/LieuDexie";
import {Article} from "../model/epona-api/Article";
export class Database  extends Dexie {
  articles: Table<Article, number>;
  inventaires: Table<InventaireEntete, number>;
  inventaireLignes: Table<InventaireLigne, number>;
  inventaireUtilisateur: Table<InventaireUtilisateurDexie, number>
  lieu: Table<LieuDexie, number>;

  constructor() {
    super('epona');
    this.version(1).stores({
      articles: 'idArticle',
      inventaires: 'idInventaireEntete',
      inventaireLignes: '++id, inventaire.idInventaireEntete, idInventaireLigne&, sync, article.idArticle',
      inventaireUtilisateur: 'eppn',
      lieu: 'idLieu'
    });
  }
}

export const db = new Database();
