import {EntreeSortieLigne} from "./EntreeSortieLigne";
import {EntreeSortieLigneDetailEcartDTO} from "./EntreeSortieLigneDetailEcartDTO";

export class EntreeSortieCorrectionPOST {
  quantite: number;
  listeDetailsEcart: EntreeSortieLigneDetailEcartDTO[];

  constructor(entreeSortieLigne: EntreeSortieLigne) {
    this.quantite          = entreeSortieLigne.quantite;
    this.listeDetailsEcart = EntreeSortieLigneDetailEcartDTO.buildList(entreeSortieLigne.listeDetailsEcart);
  }
}
