<mat-card class="card-width tdb-matcard">
  <mat-card-header class="card-title">
    <mat-card-title>Commandes en cours</mat-card-title>

    <a aria-label="Liste des commandes"
       title="Liste des commandes"
       class="envoi-link"
       routerLink="/liste-commandes">
      <mat-icon>open_in_new</mat-icon>
    </a>
  </mat-card-header>

<!--  COMMANDES EN COURS DE TRAITEMENT -->
  <table mat-table class="mat-elevation-z8 tdb-table" [dataSource]="dataSource"
         matSort matSortActive="batiment" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Nombres de commandes à traiter par lieu et par état">

    <ng-container matColumnDef="batiment">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bâtiment</th>
      <td mat-cell *matCellDef="let element">{{element.lieu?.nomRu}}</td>
    </ng-container>

    <ng-container matColumnDef="lieu">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Lieu</th>
      <td mat-cell *matCellDef="let element">{{element.lieu?.nomUd}}</td>
    </ng-container>

    <ng-container matColumnDef="nbBrouillon">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Brouillon</th>
      <td mat-cell class="nb" *matCellDef="let element">
        <div [hidden]="element.nbBrouillon != 0"> - </div>
        <a [routerLink]="['/liste-commandes']"
           [queryParams]="{idLieu: element.lieu.idLieu, codesEtat: CodeEtatCommande.BROUILLON}"
           [hidden]="element.nbBrouillon == 0">{{element.nbBrouillon}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nbEnPreparationEJ">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header matTooltip="Préparation de l'engagement juridique">Prépa. EJ</th>
      <td mat-cell class="nb" *matCellDef="let element">
        <div [hidden]="element.nbEnPreparationEJ != 0"> - </div>
        <a [routerLink]="['/liste-commandes']"
           [queryParams]="{idLieu: element.lieu.idLieu, codesEtat: CodeEtatCommande.PREPARATION_EJ}"
           [hidden]="element.nbEnPreparationEJ == 0">{{element.nbEnPreparationEJ}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nbEnAttenteRetourOrion">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header matTooltip="En attente du retour d'Orion">Attente Orion</th>
      <td mat-cell class="nb" *matCellDef="let element">
        <div [hidden]="element.nbEnAttenteRetourOrion != 0"> - </div>
        <a [routerLink]="['/liste-commandes']"
           [queryParams]="{idLieu: element.lieu.idLieu, codesEtat: CodeEtatCommande.EN_ATTENTE_RETOUR_ORION}"
           [hidden]="element.nbEnAttenteRetourOrion == 0">{{element.nbEnAttenteRetourOrion}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nbEnPreparationFournisseur">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header matTooltip="En préparation chez le fournisseur ou au magasin">Prépa. fourniss.</th>
      <td mat-cell class="nb" *matCellDef="let element">
        <div [hidden]="element.nbEnPreparationFournisseur != 0"> - </div>
        <a [routerLink]="['/liste-commandes']"
           [queryParams]="{idLieu: element.lieu.idLieu, codesEtat: CodeEtatCommande.EN_PREPARATION}"
           [hidden]="element.nbEnPreparationFournisseur == 0">{{element.nbEnPreparationFournisseur}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nbEnReception">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header matTooltip="En réception">En récep.</th>
      <td mat-cell class="nb" *matCellDef="let element">
        <div [hidden]="element.nbEnReception != 0"> - </div>
        <a [routerLink]="['/liste-commandes']"
           [queryParams]="{idLieu: element.lieu.idLieu, codesEtat: CodeEtatCommande.EN_RECEPTION}"
           [hidden]="element.nbEnReception == 0">{{element.nbEnReception}}</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator class="tdb-table" #paginator [pageSize]="3" hidePageSize></mat-paginator>
</mat-card>
