import {DTOWithLieu} from './DTOWithLieu';

export class FdncQueryParams extends DTOWithLieu {
  idFournisseur: number;
  codeMarcheOrion: string;
  idArticle: number;
  gravite: boolean;
  idsStatut: number[];
  idsEtapeEnCours: number[];
}
