<mat-select [formControl]="formCtrl"
            (selectionChange)="selectionChanged()"
            panelClass="select-panel"
            #select>
  <mat-select-trigger>
    {{ select.value !== DEFAULT ? getCurrentItem()?.nom : defaultLabel }} {{ getCurrentItem()?.actif === false ? '(inactif)' : '' }}
  </mat-select-trigger>

  <mat-option [value]="DEFAULT">{{ defaultLabel }}</mat-option>
  <mat-option *ngFor="let item of listeActifs" [value]="item.idBatiment" class="actif">
    {{ item.nom }}
  </mat-option>

  <mat-optgroup label="Bâtiments BNS inactifs" *ngIf="afficherInactifs && listeInactifs?.length > 0">
    <mat-option *ngFor="let item of listeInactifs" [value]="item.idBatiment" class="inactif">
      {{ item.nom }}
    </mat-option>
  </mat-optgroup>
</mat-select>
