import {EntreeSortieParametrage} from "./EntreeSortieParametrage";
import {EntreeSortieEntete} from "../epona-api/EntreeSortieEntete";
import {EntreeSortieLigne} from "../epona-api/EntreeSortieLigne";

export class DialogDataHistoriqueCorrectionsEntreeSortie {
  // Paramètres en entrée
  params: EntreeSortieParametrage;
  entete: EntreeSortieEntete;
  lignes: Array<EntreeSortieLigne>;

  // Paramètres de retour
}
