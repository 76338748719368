<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Ajouter un article</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <mat-form-field>
    <mat-label>Article</mat-label>

    <epona-autocomplete-article [formControl]="articleCtrl"
                                [stockGere]="true"
                                [listeIdArticleExclus]="listeIdArticleDejaPresents"
                                required></epona-autocomplete-article>
    <mat-error *ngIf="articleCtrl.hasError('required')">Article obligatoire</mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Annuler</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="disableBoutonAjouter()">Ajouter</button>
</mat-dialog-actions>
