import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { Arborescence } from '../model/Arborescence';
import {ArticleVEM} from "../model/vem-api/ArticleVEM";
import {Vente} from "../model/epona-api/Vente";
import {HttpService} from "./http.service";

@Injectable()
export class VemService {

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) { }

  // ==================================== VENTES ============================================
  /**
   * Méthode permettant de récupérer l'arborescence du Crous courant
   */
  getArborescence(idCrous: number = null): Observable<Arborescence> {
    if (idCrous === null){
      idCrous = +localStorage.getItem('idCrous');
    }
    return this.httpClient.get<Arborescence>(environment.apidanosUrl + '/' + idCrous + '/crous/' + idCrous + '?use=arborescence&lieuxMasques=0');
  }

  getVentes(venteFilter): Observable<Vente[]> {
    const idCrous = venteFilter.idCrous;

    // On affecte null à l'idCrous afin qu'il ne soit pas pris en compte comme paramètre dans l'url
    venteFilter.idCrous = null;

    const params = this.httpService.buildParams(venteFilter);

    return this.httpClient.get<Vente[]>(environment.apidanosUrl + '/' + idCrous + '/cumuls-ventes-caisse',
      {params: params});
  }

  //==================================== ARTICLES ============================================

  getListeArticles(fields: string): Observable<ArticleVEM[]> {
    const params = this.httpService.buildParams({
      fields: fields,
      estActif: 1,
      exclurePseudoArticles: 1
    });

    return this.httpClient.get<ArticleVEM[]>(environment.apidanosUrl + '/'
      + localStorage.getItem('idCrous')
      + '/articles-lite', {params});
  }
}
