import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogDataConfirmationEntreeSortie} from "../../model/epona-ui/DialogDataConfirmationEntreeSortie";
import {UntypedFormControl} from "@angular/forms";
import {CommandeService} from "../../services/epona/commande.service";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {MessageTool} from "../../commons/MessageTool";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";

@Component({
  selector: 'epona-dialog-confirm-validation',
  templateUrl: './dialog-confirm-validation.component.html',
  styleUrls: ['./dialog-confirm-validation.component.css']
})
export class DialogConfirmValidationComponent implements OnInit {
  validationCompleteCtrl: UntypedFormControl;

  listeBLNonValides: Array<EntreeSortieEntete>;
  hiddenDataTable: boolean = true;

  constructor(private dialog: MatDialogRef<DialogConfirmValidationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirmationEntreeSortie,
              private commandeService: CommandeService,
              private messageTool: MessageTool) {

    this.validationCompleteCtrl = new UntypedFormControl(false);

  }

  ngOnInit(): void {
    // La boite de dialogue a toujours la même taille. Cela évite de la définir dans le composant qui appelle la boite de dialogue.
    this.dialog.updateSize("500px");

    if (this.estBLSurCommande()) {
      this.getBLNonValides();
    }
  }

  getBLNonValides() {
    this.commandeService.getListeEntreeSortieFromCommande(this.data.entete.commande.idCommandeEntete).subscribe(data => {
      this.listeBLNonValides = new Array<EntreeSortieEntete>();

      for (let bl of data) {
        if (!bl.dateValidation && bl.idEntreeSortieEntete != this.data.entete.idEntreeSortieEntete) {
          this.listeBLNonValides.push(bl);
        }
      }

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  estBLSurCommande() {
    return this.data.entete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.APPROVISIONNEMENT
      && this.data.entete.commande;
  }

  change() {
    this.hiddenDataTable = !(this.validationCompleteCtrl.value && this.listeBLNonValides && this.listeBLNonValides.length > 0);
  }

  close() {
    this.dialog.close();
  }

  confirm() {
    this.data.validation = true;
    this.data.validationComplete = this.validationCompleteCtrl.value;
    this.close();
  }

}
