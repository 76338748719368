import {EntreeSortieLigne} from "../epona-api/EntreeSortieLigne";
import {EntreeSortieEntete} from "../epona-api/EntreeSortieEntete";
import {EntreeSortieParametrage} from "./EntreeSortieParametrage";
import {EntreeSortieLigneDetailEcart} from "../epona-api/EntreeSortieLigneDetailEcart";

export class DialogDataLigneEntreeSortie {
  // Paramètres en entrée
  action: Action;
  params: EntreeSortieParametrage;
  entete: EntreeSortieEntete;
  ligne: EntreeSortieLigne;
  listeDetailsEcart: EntreeSortieLigneDetailEcart[];
  lignesExistantes: Array<EntreeSortieLigne>;

  // Paramètres de retour
  lignesInserees: boolean;
  ligneModifiee: EntreeSortieLigne;
  listeDetailsEcartModifiee: EntreeSortieLigneDetailEcart[];
}

export enum Action {
  AJOUT,
  CORRECTION,
  DETAIL_ECART
}
