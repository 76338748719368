import {FournisseurFilter} from "./fournisseur-filter";

export class FournisseurQueryParams {

  static fabric(fournisseurFilter: FournisseurFilter) : FournisseurQueryParams {
    const fq = new FournisseurQueryParams();
    fq.nom = fournisseurFilter.nom;
    fq.siret = fournisseurFilter.siret;
    fq.codeMarcheOrion = fournisseurFilter.marche ? fournisseurFilter.marche.codeMarcheOrion : null;
    return fq;
  }

  nom: string;
  siret: string;
  codeMarcheOrion: string;
}
