import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {ServiceFaitEntete} from "../../model/epona-api/ServiceFaitEntete";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'epona-service-fait',
  templateUrl: './service-fait.component.html',
  styleUrls: ['./service-fait.component.css']
})
export class ServiceFaitComponent implements OnChanges {

  @Input() livraison: EntreeSortieEntete;
  @Input() serviceFait: ServiceFaitEntete;

  @Output() formEnteteIsDirty = new EventEmitter<boolean>();
  @Output() formLignesIsDirty = new EventEmitter<boolean>();

  modifiable: boolean = false;
  envoyable: boolean = false;

  constructor(private userService: UserService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['serviceFait']) {
      // Le SF est modifiable s'il n'a pas déjà été envoyé
      //  et que l'utilisateur a le droit de modifier les SF
      this.modifiable = this.serviceFait && !this.serviceFait.numeroSf
        && this.userService.utilisateurCourant.possedeDroit(CodeDroit.LIVRAISONS_PREPARATION_SF);

      // Le SF est envoyable s'il n'a pas déjà été envoyé
      //  et que l'utilisateur a le droit d'envoyer les SF
      this.envoyable = this.serviceFait && !this.serviceFait.numeroSf
        && this.userService.utilisateurCourant.possedeDroit(CodeDroit.LIVRAISONS_ENVOI_SF);
    }
  }

  onFormEnteteIsDirty(dirty: boolean): void {
    this.formEnteteIsDirty.emit(dirty);
  }

  onFormLignesIsDirty(dirty: boolean): void {
    this.formLignesIsDirty.emit(dirty);
  }
}
