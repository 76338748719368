<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Ajout d'un paramétrage</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="annuler()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Article</mat-label>
      <epona-autocomplete-article formControlName="article" [stockGere]="true"></epona-autocomplete-article>
      <mat-error *ngIf="form.get('article').hasError('required')">Article obligatoire</mat-error>
    </mat-form-field>


    <mat-form-field>
      <mat-label>Stock minimum</mat-label>
      <input matInput type="number" formControlName="stockMini">
      <span matSuffix>{{ form.get('article').value?.uniteExploitation?.abreviation }}</span>
      <mat-error *ngIf="form.get('stockMini').hasError('min')">Valeur minimum attendue : 1</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="annuler()">Annuler</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="save()" [disabled]="disableBoutonAjouter()">Ajouter</button>
</mat-dialog-actions>
