import {DTOWithLieu} from "./DTOWithLieu";

export class CommandeQueryParams extends DTOWithLieu {
  numeroCommande: string;
  dateLivraison: string;
  codesEtat: number[];
  externe: boolean;
  idFournisseur: number;
  codeMarcheOrion: string;
}
