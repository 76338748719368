import {LieuDTO} from "./LieuDTO";
import {PanierEntete} from "./PanierEntete";
import {Fournisseur} from "./Fournisseur";

export class PanierEntetePOST {
  fournisseur: Fournisseur;
  lieuDemandeur: LieuDTO;
  commentaire: string

  constructor(panierEntete: PanierEntete) {
    this.fournisseur            = panierEntete.fournisseur;
    this.lieuDemandeur          = panierEntete.lieuDemandeur ? new LieuDTO(panierEntete.lieuDemandeur) : null;
    this.commentaire            = panierEntete.commentaire;
  }
}
