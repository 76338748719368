import {EntreeSortieEntetePUT} from "./EntreeSortieEntetePUT";
import {LieuDTO} from "./LieuDTO";
import {TypeMouvementDTO} from "./TypeMouvementDTO";
import {CommandeEnteteDTO} from "./CommandeEnteteDTO";
import {EntreeSortieEntete} from "./EntreeSortieEntete";

export class EntreeSortieEntetePOST extends EntreeSortieEntetePUT {
  lieu: LieuDTO;
  typeMouvement: TypeMouvementDTO;
  commande: CommandeEnteteDTO;
  idLogiciel: number;

  constructor(entreeSortieEntete: EntreeSortieEntete) {
    super(entreeSortieEntete);

    this.lieu          = entreeSortieEntete.lieu ? new LieuDTO(entreeSortieEntete.lieu) : null;
    this.typeMouvement = entreeSortieEntete.typeMouvement ? new TypeMouvementDTO(entreeSortieEntete.typeMouvement) : null;
    this.commande      = entreeSortieEntete.commande ? new CommandeEnteteDTO(entreeSortieEntete.commande) : null;
    this.idLogiciel    = entreeSortieEntete.idLogiciel;
  }
}
