import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {ArticleService} from "./services/epona/article.service";
import {OnlineService} from "./services/online.service";
import {InventaireService} from "./services/epona/inventaire.service";
import {UpdateService} from "./services/epona/update.service";
const eponaProperties = require('../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit{
  version: string = eponaProperties.version;
  isLoading: boolean = true;

  /**
   * Constructeur par défaut de la classe AppComponent
   * @param cdRef paramètre qui sert à controler l'affichage du loader
   */
  constructor(private cdRef: ChangeDetectorRef,
              private inventaireService: InventaireService,
              private articleService: ArticleService,
              private onlineService: OnlineService,
              private sw: UpdateService){
    onlineService.isOnline.subscribe(r => this.synch(r));
    this.sw.checkForUpdates();
  }

  synch(isConnect: boolean) {
    if (isConnect) {
      this.inventaireService.sync();
    }
  }

  /**
   * Après le chargement de la vue
   */
  ngAfterViewInit(): void {
    this.isLoading = false;
    // Nécessaire pour ne prendre en compte les changements du booleen loading qu'à la fin du chargement de la page :
    this.cdRef.detectChanges();
  }
}
