import {Component, Input, OnInit} from '@angular/core';
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {CommandeService} from "../../services/epona/commande.service";
import {CommandeChangementEtat} from "../../model/epona-api/CommandeChangementEtat";
import {TypeMetaData} from "../../commons/enum/TypeMetaData";
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";

@Component({
  selector: 'epona-commande-changements-etat',
  templateUrl: './commande-changements-etat.component.html',
  styleUrls: ['./commande-changements-etat.component.css']
})
export class CommandeChangementsEtatComponent implements OnInit {

  @Input() commande: CommandeEntete;

  listeChangementsEtat: Array<CommandeChangementEtat>;

  constructor(private commandeService: CommandeService) { }

  ngOnInit(): void {
    if (this.commande) {
      const fields = 'etat.codeEtatCommande,date,utilisateur,commentaire';
      this.commandeService.getListeChangementsEtatFromCommande(this.commande.idCommandeEntete, fields).subscribe(data => {
        this.listeChangementsEtat = data;
      });
    }
  }

  type(changementEtat: CommandeChangementEtat): TypeMetaData {
    switch (changementEtat.etat.codeEtatCommande) {
      case CodeEtatCommande.BROUILLON:               return TypeMetaData.CREATION;
      case CodeEtatCommande.PREPARATION_EJ:          return TypeMetaData.PREPARATION_EJ;
      case CodeEtatCommande.EN_ATTENTE_RETOUR_ORION: return TypeMetaData.ATTENTE_RETOUR_ORION;
      case CodeEtatCommande.REFUSEE_ORION:           return TypeMetaData.REFUS_ORION;
      case CodeEtatCommande.EN_PREPARATION:          return this.commande.externe ? TypeMetaData.EN_PREPARATION_FOURNISSEUR : TypeMetaData.EN_PREPARATION_MAGASIN;
      case CodeEtatCommande.REFUSEE_MAGASIN:         return TypeMetaData.REFUS_MAGASIN;
      case CodeEtatCommande.EN_ACHEMINEMENT:         return TypeMetaData.EN_ACHEMINEMENT;
      case CodeEtatCommande.EN_RECEPTION:            return TypeMetaData.EN_RECEPTION;
      case CodeEtatCommande.RECEPTIONNEE:            return TypeMetaData.RECEPTIONNEE;
    }
  }
}
