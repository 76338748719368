enum TypeFinalisation {
  PREPARATION = "préparation",
  RECEPTION = "réception"
}

export class CommandeFinalisation {
  public static readonly TYPE_FINALISATION = TypeFinalisation;

  typeFinalisation: TypeFinalisation;
  commentaire: string;
}
