<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field >
      <mat-label>Identifiant interne</mat-label>

      <input type="number" matInput formControlName="numeroPanier" epoHighlightIfNotEmpty>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Fournisseur</mat-label>

      <epona-select-fournisseur formControlName="fournisseur"
                                defaultLabel="- Tous -"
                                [hasPunchout]="true"
                                epoHighlightIfNotEmpty>
      </epona-select-fournisseur>
    </mat-form-field>

    <mat-form-field >
      <mat-label>Eppn utilisateur</mat-label>

      <input type="text" matInput formControlName="utilisateur" epoHighlightIfNotEmpty>
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon>
        Rechercher
      </button>
    </div>
  </mat-card>
</form>
