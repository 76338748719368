export class StringHelper {
  static removeDiacritics(str: string) : string {
    /**
     * normalize("NFD") : décompose les caractères avec accents. Ex : é devient e'
     * replace(/[\u0300-\u036f]/g, "") : remplace les caractères avec accents par une chaîne vide
     * Exemple d'utilisation : rené ==> rene' ==> rene
     */
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") ;
  }

  static normalize(str: string): string {
    return this.removeDiacritics(str).toLowerCase();
  }
}
