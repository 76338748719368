import {Component, Input, TemplateRef} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'aide',
  templateUrl: './aide.component.html',
  styleUrls: ['./aide.component.css']
})
export class AideComponent {

  @Input() icon: string = 'help_outline';
  @Input() maxWidth: string;

  private readonly DEFAULT_MAX_WIDTH = '500px';

  private dialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog) {
  }

  open(template: TemplateRef<any>): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = this.maxWidth ? this.maxWidth : this.DEFAULT_MAX_WIDTH;

    this.dialogRef = this.dialog.open(template, dialogConfig);
  }

  close(): void {
    this.dialogRef.close();
  }
}
