import {Article} from "./Article";
import {Lieu} from "./Lieu";
import {InventaireLigne} from "./InventaireLigne";

export class ValorisationStock {
  dlc: Date;
  dateValorisation: Date;
  pmpHt: number;
  pmpTtc: number;

  dateCreation: Date;
  utilisateurCreation: string;

  lieu: Lieu;
  article: Article;
  inventaireLigne: InventaireLigne;

  static sort(a: ValorisationStock, b: ValorisationStock): number {
    return a.dateValorisation > b.dateValorisation ? -1 : 1;
  }
}
