import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {TypeMouvement} from "../../model/TypeMouvement";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {EtatCommande} from "../../model/EtatCommande";
import {TypeEcart} from "../../model/w3-api/TypeEcart";

@Injectable({
  providedIn: 'root'
})
export class ReferentielService {

  constructor(private httpClient: HttpClient) { }


  getTypesMouvement(): Observable<TypeMouvement[]> {
    return this.httpClient.get<TypeMouvement[]>(environment.eponaApiUrl + '/referentiel/types-mouvement', {
      params: new HttpParams().set('fields', 'codeTypeMouvement,libelle')
    });
  }


  /**
   * Méthode permettant de récupérer les états de commande
   */
  getEtatsCommande(): Observable<EtatCommande[]> {
    return this.httpClient.get<EtatCommande[]>(environment.eponaApiUrl + '/referentiel/etats-commande');
  }

  /**
   * Méthode permettant de récupérer les types d'écart de livraison
   */
  getListeTypesEcartLivraison(): Observable<TypeEcart[]> {
    return this.httpClient.get<TypeEcart[]>(environment.eponaApiUrl + '/referentiel/types-ecart-livraison');
  }
}
