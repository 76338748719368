<form [formGroup]="lieuForm" class="in-filter">
  <mat-form-field>
    <mat-label>{{ugLabel}}</mat-label>

    <mat-select formControlName="idUgLieu"
                (selectionChange)="selectUG()"
                panelClass="select-panel select-lieu-filter-panel">
      <mat-option [value]="DEFAULT">- {{ugDefault}} -</mat-option>
      <mat-option *ngFor="let ug of listeUG" [value]="ug.idUg">{{ug.nom}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ruLabel}}</mat-label>

    <mat-select formControlName="idRuLieu"
                (selectionChange)="selectRU()"
                panelClass="select-panel select-lieu-filter-panel">
      <mat-option [value]="DEFAULT">- {{ruDefault}} -</mat-option>
      <mat-option *ngFor="let ru of listeRU" [value]="ru.idRu">{{ru.nom}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{udLabel}}</mat-label>

    <mat-select formControlName="idUdLieu"
                (selectionChange)="selectUD()"
                panelClass="select-panel select-lieu-filter-panel">
      <mat-option [value]="DEFAULT">- {{udDefault}} -</mat-option>
      <mat-option *ngFor="let ud of listeUD" [value]="ud.idUd">{{ud.nom}}</mat-option>
    </mat-select>
  </mat-form-field>
</form>
