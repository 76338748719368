/* *********************************************************************** */
/* /!\               Fichier issu de angular-app-commons               /!\ */
/* /!\ Merci d'avertir le Cnous si une modification doit être apportée /!\ */
/* *********************************************************************** */

import { Injectable } from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {CanDeactivateComponent} from "./can-deactivate.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard  {

  constructor(public dialog: MatDialog) {}

  canDeactivate(component: CanDeactivateComponent): boolean | Observable<boolean> {
    if(!component.canDeactivate()) {
      const subject = new Subject<boolean>();

      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation",
        yesLabel: "Oui",
        noLabel: "Non",
        body: "<p>Confirmez-vous vouloir quitter la page&nbsp;?</p>Les modifications que vous avez apportées ne seront pas enregistrées.",
        validationWithObservable: true
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);
      dialogRef.componentInstance.subject = subject;

      return subject.asObservable();
    }

    return true;
  }
}
