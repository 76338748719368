<mat-select [formControl]="formCtrl"
            (selectionChange)="selectionChanged()"
            panelClass="select-panel"
            #selectSousGroupe>
  <mat-select-trigger>
    {{ selectSousGroupe.value !== DEFAULT ? getCurrentItem()?.libelle : defaultLabel }}
  </mat-select-trigger>

  <mat-option [value]="DEFAULT">{{ defaultLabel }}</mat-option>
  <mat-optgroup *ngFor="let group of groups" label="{{ group.code }} - {{ group.libelle }}">
    <mat-option *ngFor="let item of group.listeSousGroupe" [value]="item.idSousGroupeArticles">{{ group.code }}{{ item.code }} - {{ item.libelle }}</mat-option>
  </mat-optgroup>
</mat-select>
