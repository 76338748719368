import {Lieu} from '../epona-api/Lieu';
import {TypeMouvement} from "../TypeMouvement";

export class EntreeSortieFilter {
  lieu: Lieu;

  idEntreeSortie: number;
  typeMouvement: TypeMouvement;
  valide: boolean;
  codesStatut: string[];
  codeBL: string;
  dateMin: Date;
  lieuEvenement: string;
  dateEvenement: Date;
  referenceEvenement: string;
  utilisateur: string;
}
