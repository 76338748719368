<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" cdkDragHandle>
    <span *ngIf="estCreation()">Ajout d'une ligne</span>
    <span *ngIf="estCorrection()">Correction d'une ligne</span>
    <span *ngIf="estDetailEcart()">Détails d'un écart</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="annuler()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <p class="alert alert-warning" *ngIf="estCorrection()">
    <mat-icon>warning</mat-icon>
    Attention&nbsp;: vous êtes sur le point de corriger une ligne déjà validée.
  </p>

  <form [formGroup]="form">
    <!-- En création un formulaire vertical est affiché -->
    <div class="ligne-ajoutee" *ngIf="estCreation()">
      <mat-radio-group *ngIf="estApprovisionnement()" formControlName="typeArticle" class="radio-group" (change)="changeStateFieldArticle()">
        <mat-radio-button [value]="typeAjoutArticles.ARTICLES_NON_RECEPTIONNES">Ajouter les articles non réceptionnés</mat-radio-button>
        <mat-radio-button [value]="typeAjoutArticles.ARTICLE">Ajouter l'article suivant :</mat-radio-button>
      </mat-radio-group>

      <mat-form-field *ngIf="estBLSurCommandeExterne()">
        <mat-label *ngIf="estBLSurCommandeExterne()">Article de la commande no. {{ data.entete.commande.numeroEj }} ({{ data.entete.commande.fournisseur.nom }})</mat-label>
        <epona-autocomplete-article-marche formControlName="article"
                                           [sousLotZg]="data.entete.commande.sousLotZg"
                                           [listeIdArticleInclus]="listeIdArticleCommande"
                                           [listeIdArticleExclus]="listeIdArticleSansDlcDejaPresents"
                                           required
                                           (click)="enableFieldArticle()"
                                           [focus]="focusFieldArticle"></epona-autocomplete-article-marche>
      </mat-form-field>

      <mat-form-field *ngIf="!estBLSurCommandeExterne()">
        <mat-label>Article</mat-label>
        <epona-autocomplete-article formControlName="article"
                                    [stockGere]="data.params.editArticlesStockGereOnly ? true : undefined"
                                    [listeIdArticleExclus]="listeIdArticleSansDlcDejaPresents"
                                    [enStockUniquement]="data.params.editArticlesEnStockOnly"
                                    [lieu]="data.entete.lieu"
                                    required
                                    (click)="enableFieldArticle()"
                                    [focus]="focusFieldArticle"></epona-autocomplete-article>

        <mat-error *ngIf="form.get('article').hasError('required')">Article obligatoire</mat-error>
        <mat-error *ngIf="form.get('article').hasError('article-dlc')">Article périssable (avec DLC) uniquement</mat-error>
      </mat-form-field>

      <mat-form-field [class]="'hidden-' + !articleDlc" [hidden]="estApprovisionnement()">
        <mat-label>DLC</mat-label>

        <input matInput
               [matDatepicker]="picker"
               formControlName="dlc"
               [required]="dlcRequired"
               (click)="picker.open()"
               (focus)="picker.open()"
               autocomplete="off">
        <mat-datepicker-toggle [hidden]="true" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        <mat-error *ngIf="form.get('dlc').hasError('required')">DLC obligatoire</mat-error>
      </mat-form-field>

    </div>

    <!-- En correction ou saisie de détail la ligne modifiée est présentée dans un tableau horizontal -->
    <div class="ligne-modifiee" *ngIf="!estCreation()">
      <table class="mat-mdc-table mat-elevation-z8" aria-label="Rappel de la ligne en cours de modification">
        <thead>
          <tr class="mat-mdc-header-row">
            <th class="mat-mdc-header-cell title-container" scope="col">Article</th>
            <th class="mat-mdc-header-cell title-container" scope="col">DLC</th>
            <th class="mat-mdc-header-cell title-container quantite" scope="col" *ngIf="data.params.commonFieldsBLDisplayed">Quantité attendue</th>
            <th class="mat-mdc-header-cell title-container quantite" scope="col">{{ data.params.editQuantiteLabel }}{{ quantiteRequired ? '&nbsp;*' : '' }}</th>
            <th class="mat-mdc-header-cell title-container quantite" scope="col" *ngIf="data.params.commonFieldsBLDisplayed">Écart</th>
          </tr>
        </thead>

        <tbody>
          <tr class="mat-mdc-row">
            <td class="mat-mdc-cell">{{ data.ligne.article | designation }}</td>
            <td class="mat-mdc-cell">{{ data.ligne.dlc | dateFormat }}</td>
            <td class="mat-mdc-cell quantite" *ngIf="data.params.commonFieldsBLDisplayed">{{ data.ligne.quantiteAttendue }}</td>
            <td class="mat-mdc-cell quantite" *ngIf="!estCorrection()">{{ data.ligne.quantite }}</td>
            <td class="mat-mdc-cell quantite" *ngIf="estCorrection()">
              <mat-form-field appearance="fill" subscriptSizing="dynamic">
                <input matInput type="number" formControlName="quantite" [required]="quantiteRequired" [min]="0" (change)="updateEcart()" />

                <mat-error *ngIf="form.get('quantite').hasError('required')"    >Qtté. obligatoire</mat-error>
                <mat-error *ngIf="form.get('quantite').hasError('min')"         >Qtté. négative</mat-error>
                <mat-error *ngIf="form.get('quantite').hasError('zeroInterdit')">Qtté. nulle</mat-error>
                <mat-error *ngIf="form.get('quantite').hasError('modified')"    >Qtté. non modifiée</mat-error>
              </mat-form-field>
            </td>
            <td class="mat-mdc-cell quantite" *ngIf="data.params.commonFieldsBLDisplayed">
              {{ ecartCourant | signed }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>









    <div class="details-ecart" *ngIf="!estCreation() && data.params.commonFieldsBLDisplayed">
      <table mat-table
             class="mat-mdc-table"
             [dataSource]="dataSource"
             multiTemplateDataRows="true"
             formArrayName="details"
             aria-label="Détail de l'écart constaté sur une ligne">

        <ng-container matColumnDef="type">
          <th class="mat-mdc-header-cell title-container"  *matHeaderCellDef scope="col">Type d'écart&nbsp;*</th>

          <td mat-cell *matCellDef="let index = dataIndex" [formGroupName]="index">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
              <epona-select-type-detail-ecart formControlName="type">
              </epona-select-type-detail-ecart>

              <mat-error *ngIf="hasErrorDetails(index, 'type', 'required')">Type d'écart obligatoire</mat-error>
            </mat-form-field>
          </td>

          <td mat-footer-cell *matFooterCellDef>Total détaillé&nbsp;: </td>
        </ng-container>

        <ng-container matColumnDef="quantite">
          <th class="mat-mdc-header-cell title-container quantite" *matHeaderCellDef scope="col">Quantité&nbsp;*</th>

          <td mat-cell class="quantite" *matCellDef="let index = dataIndex" [formGroupName]="index">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
              <input matInput
                     type="number"
                     formControlName="quantite"
                     (change)="updateTotalDetaille()">

              <mat-error *ngIf="hasErrorDetails(index, 'quantite', 'required')">Qtté. obligatoire</mat-error>
              <mat-error *ngIf="hasErrorDetails(index, 'quantite', 'not_zero')">Qtté. 0 interdite</mat-error>
            </mat-form-field>
          </td>

          <td mat-footer-cell *matFooterCellDef [ngClass]="{'total-ok': totalDetaille === ecartCourant, 'total-ko': totalDetaille !== ecartCourant}">
            {{ totalDetaille | signed }}
          </td>
        </ng-container>

        <ng-container matColumnDef="commentaire">
          <th class="mat-mdc-header-cell title-container" *matHeaderCellDef scope="col">Commentaire</th>
          <td mat-cell *matCellDef="let index = dataIndex" [formGroupName]="index">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
              <input matInput
                     type="text"
                     maxlength="255"
                     formControlName="commentaire">
            </mat-form-field>
          </td>

          <td mat-footer-cell *matFooterCellDef>
            <mat-error *ngIf="getFormArrayDetails().hasError('total_detaille_ok')">Doit être égal à l'écart ({{ ecartCourant | signed }})</mat-error>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="mat-mdc-header-cell title-container" *matHeaderCellDef scope="col">Actions</th>
          <td mat-cell *matCellDef="let index = dataIndex" [formGroupName]="index">
            <button type="button"
                    mat-button
                    (click)="removeDetail(index)"
                    [disabled]="buttonsDisabled"
                    matTooltip="Supprimer cette ligne">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </td>

          <td mat-footer-cell *matFooterCellDef>
            <button type="button"
                    mat-button
                    (click)="addNewDetail()"
                    [disabled]="buttonsDisabled"
                    matTooltip="Ajouter une ligne">
              <fa-icon icon="plus"></fa-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['type', 'quantite', 'commentaire', 'actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['type', 'quantite', 'commentaire', 'actions'];"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef colspan="4">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>


        <tr mat-footer-row *matFooterRowDef="['type', 'quantite', 'commentaire', 'actions'];"></tr>
      </table>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="annuler()">Annuler</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">
    {{estCreation() ? 'Ajouter' : estCorrection() ? 'Corriger' : 'Enregistrer'}}
  </button>
</mat-dialog-actions>
