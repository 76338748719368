<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Visualisation du document: {{ filename }}</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
    <div *ngIf="isLoading" >
        <mat-progress-spinner diameter="25" mode="indeterminate" value="50"></mat-progress-spinner>
    </div>

    <mat-error *ngIf="message" class="alert alert-secondary" role="alert">
        {{ message }}
    </mat-error>
    <div *ngIf="isPdfFile; else imageBloc">
        <pdf-viewer [src]="preview"
                    [render-text]="true"
                    [original-size]="true"
                    style="width: 1000px; height: 550px;"></pdf-viewer>
    </div>
    <ng-template #imageBloc>
        <div>
            <img [src]="previewImg" class="preview" >
        </div>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button        color="accent"  (click)="close()">Annuler</button>
    <button mat-raised-button color="primary" (click)="upload()">Télécharger</button>
</mat-dialog-actions>
