import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {MessageTool} from "../../commons/MessageTool";
import {HttpService} from "../http.service";
import {LieuSearch} from "../../model/epona-api/LieuSearch";
import {from, Observable} from "rxjs";
import {Lieu} from "../../model/epona-api/Lieu";
import {LieuPOST} from "../../model/epona-api/LieuPOST";
import {LieuPUT} from "../../model/epona-api/LieuPUT";
import {tap} from "rxjs/operators";
import {db} from "../database";
import {LieuDexie} from "../../model/epona-api/LieuDexie";
import {OnlineService} from "../online.service";
import {liveQuery} from "dexie";

@Injectable()
export class LieuService {

  private env = environment;
  private readonly baseUrl: string;

  private isOnline = true;

  constructor(private httpClient: HttpClient,
              private messageTool: MessageTool,
              private httpService: HttpService,
              private onlineService: OnlineService) {
    onlineService.isOnline.subscribe(r => this.isOnline=r);
    this.baseUrl = this.env.eponaApiUrl + '/lieux';
  }

  getListeLieux(search: LieuSearch): Observable<any[]> {
    if (this.isOnline) {
      const params = this.httpService.buildParams(search);
      return this.httpClient.get<Lieu[]>(this.baseUrl, {params}).pipe(tap(data => {
        db.lieu.clear();
        data.forEach(res => {
          db.lieu.add(new LieuDexie(res))
        })
      }));
    } else {
      return from(liveQuery(() => db.lieu.toArray()));
    }
  }

  getLieu(idLieu: number, fields: string): Observable<any> {
    if (this.isOnline) {
      const params = this.httpService.buildParams({
        fields: fields
      });

      return this.httpClient.get<Lieu>(this.baseUrl + '/' + idLieu, {params});
    } else {
      return from(liveQuery(() => db.lieu.get(idLieu)));
    }
  }

  postLieu(lieu: Lieu): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl , new LieuPOST(lieu));
  }

  putLieu(lieu: Lieu): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + lieu.idLieu, new LieuPUT(lieu));
  }
}
