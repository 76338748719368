import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FournisseurService} from "../../services/epona/fournisseur.service";
import {Fournisseur} from "../../model/epona-api/Fournisseur";

@Component({
  selector: 'app-fournisseur',
  templateUrl: './fournisseur.component.html',
  styleUrls: ['./fournisseur.component.css']
})
export class FournisseurComponent implements OnInit {

  constructor(private route:ActivatedRoute,
              private fournisseurService: FournisseurService) { }

  fournisseur: Fournisseur;

  ngOnInit(): void {

    const id = this.route.snapshot.params.id;
    this.fournisseurService.getFournisseur(id, null).subscribe( fournisseur => {
        this.fournisseur = fournisseur;
      }
    );

  }

}
