<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Article d'un autre lot-marché</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <p>
    L'article <strong>{{ data.articleMarche.designationAchat }}</strong> ne peut être ajouté à cette commande car il appartient au lot-marché
    <strong>{{ data.articleMarche.sousLotZg.codeMarcheOrion }}.{{ data.articleMarche.sousLotZg.numeroSousLot }} ({{ data.articleMarche.sousLotZg.typeSousLot }})</strong>
  </p>

  <p>Quelle action souhaitez-vous effectuer&nbsp;?</p>

  <form [formGroup]="formGroup">
    <mat-radio-group formControlName="action" class="radio-group">
      <mat-radio-button [value]="commande.idCommandeEntete" *ngFor="let commande of listeCommandes">Ajouter cet article à la commande no. {{ commande.idCommandeEntete }}</mat-radio-button>
      <mat-radio-button [value]="CODE_NOUVELLE_COMMANDE" >Ajouter cet article à une nouvelle commande</mat-radio-button>
      <mat-radio-button [value]="CODE_IGNORER" >Ignorer cet article</mat-radio-button>
    </mat-radio-group>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Annuler</button>
  <button mat-raised-button color="primary" (click)="save()">Valider</button>
</mat-dialog-actions>
