import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CommandeFilter} from "../../model/epona-ui/CommandeFilter";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
  selector: 'epona-filtre-commandes',
  templateUrl: './filtre-commandes.component.html',
  styleUrls: ['./filtre-commandes.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class FiltreCommandesComponent implements OnInit, OnChanges {

  @Input() filter: CommandeFilter;
  @Output() readonly filterSubmitted = new EventEmitter<CommandeFilter>();

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {

    this.form = this.fb.group({
      numeroCommande: fb.control(null),
      dateLivraison: fb.control(null),
      etatsCommande: fb.control(null),
      externe: fb.control(null),
      fournisseur: fb.control(null),
      marche: fb.control(null)
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }
  }

  private initFieldsFilter() {
    this.form.get('numeroCommande').setValue(this.filter.numeroCommande);
    this.form.get('dateLivraison').setValue(this.filter.dateLivraison);
    this.form.get('etatsCommande').setValue(this.filter.etats);
    this.form.get('externe').setValue(this.filter.externe);
    this.form.get('fournisseur').setValue(this.filter.fournisseur);
    this.form.get('marche').setValue(this.filter.marche);
  }

  onSubmit() {
    const newFilter = new CommandeFilter();

    newFilter.numeroCommande = this.form.get('numeroCommande').value;
    newFilter.dateLivraison  = this.form.get('dateLivraison').value;
    newFilter.etats          = this.form.get('etatsCommande').value;
    newFilter.externe        = this.form.get('externe').value;
    newFilter.fournisseur    = this.form.get('fournisseur').value;
    newFilter.marche         = this.form.get('marche').value;

    this.filterSubmitted.emit(newFilter);
  }
}
