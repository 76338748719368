import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FiltreFdnc} from '../../model/epona-ui/filtre-fdnc';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {Fournisseur} from "../../model/epona-api/Fournisseur";
import {Marche} from "../../model/epona-api/Marche";
import {Article} from "../../model/epona-api/Article";
import {StatutFdnc} from "../../model/bna-api/statut-fdnc";
import {EtapeFdnc} from "../../model/bna-api/etape-fdnc";

@Component({
  selector: 'epona-filtre-fdnc',
  templateUrl: './filtre-fdnc.component.html',
  styleUrls: ['./filtre-fdnc.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class FiltreFdncComponent implements OnChanges {

  @Input() filtre: FiltreFdnc = new FiltreFdnc();
  @Output() readonly filtreSubmitted = new EventEmitter<FiltreFdnc>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      fournisseur:   this.fb.control<Fournisseur|null>(null),
      marche:        this.fb.control<Marche|null>(null),
      article:       this.fb.control<Article|null>(null),
      gravite:       this.fb.control<boolean|null>(null),
      statuts:       this.fb.control<StatutFdnc[]>(null),
      etapesEnCours: this.fb.control<EtapeFdnc[]>(null)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filtre'] && this.filtre !== undefined) {
      this.fillForm();
    }
  }

  private fillForm() {
    this.form.patchValue(this.filtre);
  }

  onSubmit() {
    this.filtreSubmitted.emit(this.form.value);
  }
}
