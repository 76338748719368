import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ParametrageLieuArticle} from '../../model/epona-api/ParametrageLieuArticle';
import {ParametrageLieuArticlePUT} from '../../model/epona-api/parametrageLieuArticlePUT';
import {ParametrageLieuArticlePOST} from '../../model/epona-api/parametrageLieuArticlePOST';
import { Injectable } from "@angular/core";
import {HttpService} from "../http.service";

@Injectable()
export class ParametrageLieuArticleService {

  private env = environment;
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) {
    this.baseUrl = this.env.eponaApiUrl + '/lieux-articles';
  }

  /**
   * endpoint permettant de rechercher les articles sur un lieu
   */
  getListeParametragesLieuArticle(idLieu: number, fields: string): Observable<ParametrageLieuArticle[]>{
    const params = this.httpService.buildParams({
      idLieu: idLieu.toString(),
      fields: fields
    });

    return this.httpClient.get<ParametrageLieuArticle[]>(this.baseUrl, {params});
  }

  /**
   * endpoint permettant de créer le paramétrage d'un article sur un lieu
   */
  postParametrageLieuArticle(parametrageLieuArticle: ParametrageLieuArticle): Observable<ParametrageLieuArticle>{
    const parametrageDTO = new ParametrageLieuArticlePOST(parametrageLieuArticle);
    return this.httpClient.post<ParametrageLieuArticle>(this.baseUrl, parametrageDTO);
  }

  /**
   * endpoint permettant de modifier le paramètrage d'un article sur un lieu
   */
  putParametrageLieuArticle(parametrageLieuArticle: ParametrageLieuArticle): Observable<any>{
    const parametrageDTO = new ParametrageLieuArticlePUT(parametrageLieuArticle);
    return this.httpClient.put<any>(this.baseUrl + '/'+ parametrageLieuArticle.idParametrageLieuArticle, parametrageDTO);
  }

  /**
   * endpoint permettant de supprimer le paramètrage d'un article sur un lieu
   */
  deleteParametrageLieuArticle(idParametrageLieuArticle: number): Observable<any>{
    return this.httpClient.delete(this.baseUrl + '/'+ idParametrageLieuArticle);
  }
}
