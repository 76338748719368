import {Article} from './Article';
import {InventaireEntete} from "./InventaireEntete";
import {DbSyncable} from "./db-syncable";

export class InventaireLigne extends DbSyncable{
  idInventaireLigne: number;
  inventaire: InventaireEntete;
  article: Article;
  dlc: Date;
  quantiteStockTheorique: number;
  pmpHtTheorique: number;
  pmpTtcTheorique: number;
  quantiteStockConstatee: number;
  pmpHtConstate: number;
  pmpTtcConstate: number;
  commentaire: string;
}
