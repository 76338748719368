<form [formGroup]="formColumns" autocomplete="off" *ngIf="availableColumnsKeys.length > 0">
  <mat-select multiple formControlName="cols" (selectionChange)="storeChange($event)" panelClass="select-panel">
    <mat-select-trigger>Colonnes affichées</mat-select-trigger>

    <mat-option *ngFor="let col of availableColumnsKeys"
                [value]="col"
                [matTooltip]="availableColumns[col].tooltip"
                [disabled]="availableColumns[col].hiddable === false">
      {{ availableColumns[col].label }}
    </mat-option>
  </mat-select>
</form>
