import {TypeBoutonCommande} from "../../commons/enum/TypeBoutonCommande";
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export class Bouton {
  typeBouton: TypeBoutonCommande;
  icone: IconProp;
  action: string;
  parametresAction: any[];
  isDisabled: string;
  parametresIsDisabled: any[];

  constructor(typeBouton: TypeBoutonCommande, icone: IconProp, action: any, isDisabled?: any) {
    this.typeBouton = typeBouton;

    this.icone = icone;

    this.action = typeof action === "string" ? action : action[0];
    this.parametresAction = typeof action === "string" ? null : action[1];

    if (isDisabled !== undefined) {
      this.isDisabled = typeof isDisabled === "string" ? isDisabled : isDisabled[0];
      this.parametresIsDisabled = typeof isDisabled === "string" ? null : isDisabled[1];
    }
  }

  libelleTruncate(limit: number) {
    const libelle = this.typeBouton.toString();
    if (limit) {
      if (libelle.length > limit) {
        return libelle.substr(0, limit).split(" ")[0].concat("…");
      } else {
        return libelle;
      }
    } else {
      return libelle;
    }
  }

  toolTip(limit?: number) {
    const libelle = this.typeBouton.toString();
    if (limit) {
      return libelle.length > limit ? libelle : "";
    } else {
      return "";
    }
  }
}
