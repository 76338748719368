import {Pipe, PipeTransform} from '@angular/core';
import {ReferentielOrion} from "../../model/epona-api/ReferentielOrion";

@Pipe({
  name: 'referentielOrion'
})
export class ReferentielOrionPipe implements PipeTransform {
  transform(referentiel: ReferentielOrion): string {
    if (referentiel) {
      return referentiel.libelle + ' (' + referentiel.code + ')';
    } else {
      return '';
    }
  }
}
