<form class="form" (ngSubmit)="onSubmit()" [formGroup]="form">
  <epona-tableau-commande-livraison [commande]="fdnc?.commandeEntete" [livraison]="fdnc?.entreeSortieEntete"></epona-tableau-commande-livraison>
  <div class="form-fields">
    <div class="conteneur-colonne">
      <div class="colonne">
        <mat-form-field>
          <mat-label>Code</mat-label>
          <input matInput formControlName="codeBna">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Contexte</mat-label>
          <epona-select-contexte-fdnc formControlName="contexte"
                                      displayType="select"
                                      required></epona-select-contexte-fdnc>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Date constat</mat-label>
          <input matInput [matDatepicker]="picker" class="mat-datepicker-input" formControlName="dateConstat" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="form.get('dateConstat')!.hasError('required')">Date constat obligatoire</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="fdnc?.entreeSortieLigne || fdnc?.commandeLigne">
          <mat-label>Article</mat-label>
          <textarea matInput cdkTextareaAutosize formControlName="article"></textarea>
        </mat-form-field>

        <mat-form-field *ngIf="fdnc?.entreeSortieLigne || fdnc?.commandeLigne">
          <mat-label>Numéro lot-produit</mat-label>
          <input matInput formControlName="numeroLotProduit">
        </mat-form-field>
      </div>

      <div class="colonne">
        <div>
          <mat-form-field>
            <mat-label>Statut</mat-label>
            <input matInput formControlName="statut" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Étape en cours</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="etapeEnCours"></textarea>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Gravité</mat-label>
            <input matInput formControlName="gravite" />
          </mat-form-field>
        </div>

        <mat-tab-group>
          <mat-tab label="Non-conformités">
            <mat-form-field>
              <mat-label>Types de non-conformité</mat-label>
              <epona-select-type-non-conformite formControlName="listeTypesNonConformite"
                                                [afficherInactifs]="true"
                                                [idContexte]="fdnc?.idContexte"
                                                required></epona-select-type-non-conformite>
              <mat-error *ngIf="form.get('listeTypesNonConformite')!.hasError('nbMin')">Au minimum 1 élément doit être sélectionné</mat-error>
              <mat-error *ngIf="form.get('listeTypesNonConformite')!.hasError('nbMax')">Au maximum 3 éléments doivent être sélectionnés</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Observations sur la non-conformité</mat-label>
              <textarea matInput cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="3"
                        formControlName="observationsNonConformite"
                        maxlength="5000" required></textarea>
              <mat-error *ngIf="form.get('observationsNonConformite')!.hasError('required')">Observations obligatoires</mat-error>
            </mat-form-field>
          </mat-tab>

          <mat-tab label="Traitements">
            <mat-form-field>
              <mat-label>Traitements</mat-label>
              <epona-select-traitement-non-conformite
                formControlName="listeTraitementsNonConformite"
                [afficherInactifs]="true"
                required
                (valueInitialized)="updateTempsTraitementFormControl($event)"></epona-select-traitement-non-conformite>
              <mat-error *ngIf="form.get('listeTraitementsNonConformite')!.hasError('nbMin')">Au minimum 1 élément doit être sélectionné</mat-error>
              <mat-error *ngIf="form.get('listeTraitementsNonConformite')!.hasError('nbMax')">Au maximum 2 éléments doivent être sélectionnés</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Temps de traitement</mat-label>
              <epona-select-temps-traitement-fdnc formControlName="tempsTraitement" required></epona-select-temps-traitement-fdnc>
              <mat-error *ngIf="form.get('tempsTraitement')!.hasError('required')">Temps de traitements obligatoire</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Observations sur les traitements</mat-label>
              <textarea matInput cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="3"
                        formControlName="observationsTraitement"
                        maxlength="5000" required></textarea>
              <mat-error *ngIf="form.get('observationsTraitement')!.hasError('required')">Observations obligatoires</mat-error>
            </mat-form-field>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <div class="form-actions">
    <button type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!fdncSubmittable || form.invalid || !form.dirty">
      <fa-icon icon="save"></fa-icon> Enregistrer
    </button>

    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogGestionDocuments()"
            [matTooltip]="documentsModifiables ? 'Gérer les documents joints…' : 'Voir les documents joints…'"
            *ngIf="afficherBoutonDocuments">
      <fa-icon icon="folder-open"></fa-icon> {{ documentsModifiables ? 'Gérer documents…' : 'Voir documents…' }}
    </button>

    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogEmission()"
            [disabled]="fdnc && fdnc.emise"
            *ngIf="fdnc && droitEmission">
      <fa-icon icon="paper-plane"></fa-icon> Émettre…
    </button>

    <button type="button"
            mat-raised-button color="primary"
            [matMenuTriggerFor]="menu">
      <mat-icon>more_horiz</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button type="button"
              mat-menu-item
              (click)="openDialogSuppression()"
              [disabled]="fdnc && fdnc.emise"
              *ngIf="fdnc && droitSaisie">
        <fa-icon icon="trash"></fa-icon> Supprimer…
      </button>
    </mat-menu>
  </div>
</form>
