<button class="handle"
        (click)="changerAffichage()"
        matTooltip="{{ enteteAffichee ? 'Masquer' : 'Afficher' }} l'entête"
        *ngIf="entete">
  <fa-icon icon="chevron-left" *ngIf="enteteAffichee"></fa-icon>
  <fa-icon icon="chevron-right" *ngIf="!enteteAffichee"></fa-icon>
</button>

<mat-card [ngClass]="{'masquee': !enteteAffichee}">
  <mat-card-header>
    <mat-card-title>Informations</mat-card-title>
  </mat-card-header>

  <form class="form" (ngSubmit)="onSubmit()" [formGroup]="panierEnteteForm">
    <div class="mat-form-field-row" *ngIf="!estCreation()">
      <mat-form-field>
        <mat-label>Identifiant interne</mat-label>

        <input matInput
               formControlName="idPanierEntete"
        />
      </mat-form-field>
    </div>

    <mat-form-field *ngIf="estCreation()">
      <mat-label>Fournisseur</mat-label>

      <epona-select-fournisseur formControlName="fournisseur"
                                defaultLabel="- Aucun -"
                                [hasPunchout]="true"
                                [required]="true">
      </epona-select-fournisseur>

      <mat-error *ngIf="panierEnteteForm.get('fournisseur').hasError('required')">Information obligatoire</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!estCreation()">
      <mat-label>Fournisseur</mat-label>
      <input matInput type="text" [value]="entete.fournisseur?.nom" disabled />
    </mat-form-field>

    <mat-form-field *ngIf="!estCreation()">
      <mat-label>Commentaire</mat-label>

      <textarea matInput cdkTextareaAutosize
                formControlName="commentaire"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                maxlength="500">
      </textarea>
    </mat-form-field>

    <epona-datatable-link-commandes label="Commandes générées"
                                    [listeCommandes]="listeCommandes" ></epona-datatable-link-commandes>

    <div class="form-actions">
      <button type="button"
              mat-raised-button color="primary"
              (click)="onSubmit()"
              [disabled]="isInvalidForm()"
              *ngIf="droitSaisiePaniers()" >
        <fa-icon icon="save"></fa-icon> Enregistrer
      </button>

      <button type="button"
              mat-raised-button color="primary"
              *ngIf="!estCreation() && droitSaisieCommandes()"
              (click)="openDialogGenerationCommandes()"
              matTooltip="Générer les commandes correspondant à ce panier">
        <fa-icon icon="forward"></fa-icon> Générer…
      </button>

      <button type="button"
              mat-raised-button color="primary"
              [matMenuTriggerFor]="menu"
              *ngIf="!estCreation() && droitSaisiePaniers()">
        <mat-icon>more_horiz</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button type="button"
                mat-menu-item
                (click)="openDialogSuppression()">
          <fa-icon icon="trash"></fa-icon> Supprimer
        </button>
      </mat-menu>
    </div>
  </form>

  <div style="height: 10px"></div>

  <mat-expansion-panel #metaDataPanel *ngIf="!estCreation()" >
    <mat-expansion-panel-header>
      <mat-panel-description>
        {{ metaDataPanel.expanded ? 'Masquer' : 'Afficher' }}
        les lots-marché de ce fournisseur
      </mat-panel-description>
    </mat-expansion-panel-header>

    <epona-liste-lot-marche [fournisseur]="entete.fournisseur" [small]="true" *ngIf="entete && entete.fournisseur"></epona-liste-lot-marche>
  </mat-expansion-panel>

  <mat-divider></mat-divider>

  <mat-expansion-panel #metaDataPanel *ngIf="!estCreation()" >
    <mat-expansion-panel-header>
      <mat-panel-description>
        {{ metaDataPanel.expanded ? 'Masquer' : 'Afficher' }}
        les informations supplémentaires
      </mat-panel-description>
    </mat-expansion-panel-header>

    <epona-meta-data type="creation"
                     [date]="entete.dateCreation"
                     [utilisateur]="entete.utilisateurCreation"
                     *ngIf="entete?.dateCreation">
    </epona-meta-data>

    <epona-meta-data type="modification"
                     [date]="entete.dateModification"
                     [utilisateur]="entete.utilisateurModification"
                     *ngIf="entete?.dateModification">
    </epona-meta-data>

  </mat-expansion-panel>

</mat-card>
