import {AfterViewChecked, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Action, DialogDataLigneEntreeSortie} from "../../model/epona-ui/DialogDataLigneEntreeSortie";
import {ArticleVEM} from "../../model/vem-api/ArticleVEM";
import {EntreeSortieLigne} from "../../model/epona-api/EntreeSortieLigne";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {MessageTool} from "../../commons/MessageTool";
import {Tools} from "../../commons/Tools";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";
import {MatTableDataSource} from "@angular/material/table";
import {EntreeSortieLigneDetailEcart} from "../../model/epona-api/EntreeSortieLigneDetailEcart";
import {CustomValidators} from "../../commons/CustomValidators";
import {CommandeService} from "../../services/epona/commande.service";
import {TypeAjoutArticles} from "../../commons/enum/TypeAjoutArticles";
import {HttpStatus} from "../../commons/constants/HttpStatus";
import {CommandeLigneSearch} from "../../model/epona-api/CommandeLigneSearch";

@Component({
  selector: 'app-dialog-ajout-ligne',
  templateUrl: './dialog-ajout-ligne.component.html',
  styleUrls: ['./dialog-ajout-ligne.component.css']
})
export class DialogAjoutLigneComponent implements AfterViewChecked {

  form: UntypedFormGroup;
  dlcRequired = false;
  articleDlc = false;
  quantiteRequired = false;
  quantiteZeroInterdite: boolean;
  buttonsDisabled: boolean;
  quantiteInitiale: number;
  ecartCourant: number;
  totalDetaille: number = 0;
  dataSource = new MatTableDataSource<EntreeSortieLigneDetailEcart>([]);
  typeAjoutArticles = TypeAjoutArticles;
  listeIdArticleCommande: number[];
  listeIdArticleSansDlcDejaPresents: number[];
  focusFieldArticle: boolean = false;

  constructor(public dialog: MatDialogRef<DialogAjoutLigneComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataLigneEntreeSortie,
              private entreeSortieService: EntreeSortieService,
              private commandeService: CommandeService,
              private fb: UntypedFormBuilder,
              private messageTool: MessageTool,
              private cdRef: ChangeDetectorRef) {

    this.initListeIdArticleCommande();
    if (data.lignesExistantes) {
      this.listeIdArticleSansDlcDejaPresents = data.lignesExistantes.filter(ligne => ligne.article.articleDlc !== true).map<number>(ligne => ligne.article.idArticle);
    }

    // Une quantité peut toujours être corrigée à 0, dans les autres cas cela dépend du type entrée/sortie
    this.quantiteZeroInterdite = this.estCorrection() ? false : this.data.params.editQuantiteZeroInterdite;

    // Le signe de la quantité reçue de l'API varie selon le type d'entrée/sortie
    if (this.data.ligne) {
      this.quantiteInitiale = this.calculValeurAPI(this.data.ligne.quantite);
      this.ecartCourant = this.data.ligne.ecart;
    }

    this.initForm();
  }



  ngAfterViewChecked() {
    // on force la détection de changement pour résoudre l'erreur "Expression has changed after it was checked" lors de la pirse du focus lors de la sélection de l'option "Ajouter l'article suivant"
    this.cdRef.detectChanges();
  }

  private initListeIdArticleCommande() {
    if (this.estBLSurCommandeExterne()) {
      const search = new CommandeLigneSearch();
      search.fields = 'article.idArticle';

      this.commandeService.getListeLignes(this.data.entete.commande.idCommandeEntete, search).subscribe(data => {
        this.listeIdArticleCommande = [];
        for (let ligne of data) {
          this.listeIdArticleCommande.push(ligne.article.idArticle);
        }
      });
    }
  }

  private initForm() {
    this.form = this.fb.group({
      typeArticle:      this.fb.control(this.estApprovisionnement() ? this.typeAjoutArticles.ARTICLES_NON_RECEPTIONNES : this.typeAjoutArticles.ARTICLE),
      article:          this.fb.control({value: null, disabled: this.estApprovisionnement()}, Validators.required),
      dlc:              this.fb.control({value: null, disabled: true}),
      quantite:         this.fb.control({value: null, disabled: this.estApprovisionnement()},
        [Validators.min(0), CustomValidators.zeroInterdit(this.quantiteZeroInterdite)]),
      details:          this.fb.array([])
    });

    this.setFormValidators();

    this.setFormValues();

    this.setFormDisabled();

    if (!this.estBLSurCommandeExterne()) {
      this.form.get('article').valueChanges.subscribe(articleVem => this.onChangeArticle(articleVem));
    }
  }

  private setFormValidators() {
    // En cas de mouvement de type DLC, l'article doit être périssable et la DLC est obligatoire
    if (this.data.entete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.PERIMES) {
      this.form.get('article').setValidators(DialogAjoutLigneComponent.estArticleDlc);
      this.form.get('dlc').setValidators(Validators.required);
      this.dlcRequired = true;
    }

    // En cas de correction
    if (this.estCorrection()) {
      const validators: ValidatorFn[] = [
       Validators.required,   // la quantité est obligatoire
       Validators.min(0), // la quantité ne peut être négative
        CustomValidators.zeroInterdit(this.quantiteZeroInterdite) // la quantité 0 peut être autorisée
      ];

      // S'il s'agit d'une correction d'une entrée/sortie autre qu'un BL alors la quantité devra être modifiée
      if (!this.estApprovisionnement()) {
        validators.push(DialogAjoutLigneComponent.modified(this.quantiteInitiale));
      }

      this.form.get('quantite').setValidators(validators);
      this.quantiteRequired = true;
    }
  }

  private setFormValues() {
    // Si une ligne est précisée (correction ou détails) alors le formulaire est prérempli
    if (this.data.ligne) {
      this.form.get('article').setValue(this.data.ligne.article);
      this.form.get('dlc').setValue(this.data.ligne.dlc);
      this.form.get('quantite').setValue(this.quantiteInitiale);

      this.initFormArrayDetails();
    }
  }

  private setFormDisabled() {
    // Par défaut les boutons du tableau des détails est désactivé
    this.buttonsDisabled = true;

    // En création le tableau des détails est désactivé si l'entête n'est pas modifiable
    if (this.estCreation()) {
      this.form.get('details').disable();

    // En correction tous les champs sont désactivés sauf la quantité et éventuellement le tableau des détails
    } else if (this.estCorrection()) {
      this.form.disable();
      this.form.get('quantite').enable();
      if (this.estApprovisionnement()) {
        this.form.get('details').enable();
        this.buttonsDisabled = false;
      }

    // En détail d'écart tous les champs sont désactivés sauf le tableau des détails si l'entête est modifiable
    } else if (this.estDetailEcart()) {
      this.form.disable();

      if (this.data.entete.extra.editable.status) {
        this.form.get('details').enable();
        this.buttonsDisabled = false;
      }
    }
  }

  estBLSurCommandeExterne(): boolean {
    return this.data.entete.commande && this.data.entete.commande.externe === true;
  }

  annuler() {
    this.dialog.close();
  }

  save() {
    if (this.estCreation()) {
      this.saveCreation();

    } else if (this.estCorrection()) {
      this.saveCorrection();

    } else if (this.estDetailEcart()) {
      this.saveDetails();
    }
  }

  private initLigneFromForm(): EntreeSortieLigne {
    let ligne = new EntreeSortieLigne();
    ligne.article  = this.form.get('article').value;
    ligne.dlc      = this.form.get('dlc').value;
    ligne.quantite = this.calculValeurAPI(this.form.get('quantite').value);
    ligne.listeDetailsEcart = this.getFormArrayDetails().value;

    if (!this.estBLSurCommandeExterne()) {
      ligne.article.idCrous = this.data.entete.lieu.idCrous;
    }

    return ligne;
  }

  onChangeArticle(article:ArticleVEM) {
    const dlcControl = this.form.get('dlc');
    this.articleDlc = article && article.articleDlc;
    if (article && article.articleDlc) {
      dlcControl.enable();
    } else {
      dlcControl.setValue(null);
      dlcControl.disable();
    }
  }

  private static estArticleDlc(formControl: UntypedFormControl) {
    if (formControl.value && !formControl.value.articleDlc) {
      return {'article-dlc': true};
    } else {
      return null;
    }
  }

  private static modified(quantiteInitiale: number) {
    return (formControl: UntypedFormControl) => {
      if (formControl.value === quantiteInitiale) {
        return {'modified': true};
      } else {
        return null;
      }
    };
  }

  calculValeurAPI(value: number): number {
    return Tools.signerValeur(value, this.data.params.editQuantitesPositives);
  }

  updateEcart() {
    if (this.estApprovisionnement() && this.estCorrection() && this.form.get('quantite').valid) {
      const quantite = this.form.get('quantite').value;
      this.ecartCourant = isNotNullOrUndefined(quantite) ? quantite - this.data.ligne.quantiteAttendue : null;
      this.getFormArrayDetails().setValidators(DialogAjoutLigneComponent.totalDetailleOK(this.ecartCourant));
      this.getFormArrayDetails().updateValueAndValidity();
    }
  }

  estCreation(): boolean {
    return this.data.action === Action.AJOUT;
  }
  estCorrection(): boolean {
    return this.data.action === Action.CORRECTION;
  }
  estDetailEcart(): boolean {
    return this.data.action === Action.DETAIL_ECART;
  }
  estApprovisionnement(): boolean {
    return this.data.entete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.APPROVISIONNEMENT;
  }

  changeStateFieldArticle() {
    if (this.form.get('typeArticle').value === this.typeAjoutArticles.ARTICLE) {
      this.form.get('article').enable();
      this.form.get('dlc').enable();
      this.form.get('quantite').enable();
      this.focusFieldArticle = true;

    } else {
      this.form.get('article').disable();
      this.form.get('dlc').disable();
      this.form.get('quantite').disable();
      this.focusFieldArticle = false;
    }
  }

  enableFieldArticle() {
    if(this.form.get('article').disabled) {
      this.form.get('article').enable();
      this.form.get('typeArticle').setValue(TypeAjoutArticles.ARTICLE);
      this.focusFieldArticle = true;
    }
  }

  /* ********************** */
  /* Gestion de la création */
  /* ********************** */

  private saveCreation() {
    if (this.form.get('typeArticle').value === this.typeAjoutArticles.ARTICLES_NON_RECEPTIONNES) {
      this.entreeSortieService.postArticlesNonReceptionnes(this.data.entete).subscribe(reponse => {
        if (reponse.status === HttpStatus.NO_CONTENT) {
          this.messageTool.sendInfo('Aucun article non récéptionné à ajouter');

        } else if (this.data.entete.commande) {
          this.messageTool.sendSuccess(`Les articles non réceptionnés de la commande no.
                  ${this.data.entete.commande.idCommandeEntete} ont été ajoutés au bordereau de livraion no.
                  ${this.data.entete.idEntreeSortieEntete}`);
          this.data.lignesInserees = true;

        } else {
          this.messageTool.sendSuccess(`Les articles non réceptionnés du bordereau de transfert no.
                  ${this.data.entete.entreeSortieEnteteOrigine.idEntreeSortieEntete} ont été ajoutés au bordereau de livraion no.
                  ${this.data.entete.idEntreeSortieEntete}`);
          this.data.lignesInserees = true;
        }

        this.dialog.close();

      }, err => {
        this.messageTool.sendError(err);
      });

    } else {
      const ligne: EntreeSortieLigne = this.initLigneFromForm();

      // Vérification que la ligne n'est pas déjà présente
      if (this.data.lignesExistantes.find( l => this.lignesSimilaires(l, ligne))) {
        this.messageTool.sendErrorMessage(`${this.messageTool.lignePrefix(ligne)} est déjà présente`);
        return;
      }

      // Envoi de la nouvelle ligne à l'API
      this.entreeSortieService.postLigne(this.data.entete, ligne).subscribe(() => {
        this.messageTool.sendSuccess(`${this.messageTool.lignePrefix(ligne)} a été créée avec succès`);
        this.data.ligneModifiee = ligne;
        this.dialog.close();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private lignesSimilaires(ligne1: EntreeSortieLigne, ligne2: EntreeSortieLigne): boolean {
    if (this.estBLSurCommandeExterne()) {
      return ligne1.article.idArticle === ligne2.article.idArticle;

    } else if (ligne1.article.codeArticleVente) {
      return ligne1.article.codeArticleVente === ligne2.article.codeArticleVente
        && Tools.datesAreEquals(ligne1.dlc, ligne2.dlc);

    } else if (ligne1.article.codeArticleAchat) {
      return ligne1.article.codeArticleAchat === ligne2.article.codeArticleAchat
        && Tools.datesAreEquals(ligne1.dlc, ligne2.dlc);
    }
  }

  /* ************************ */
  /* Gestion de la correction */
  /* ************************ */

  private static totalDetailleOK(ecartLigne: number) {
    return (formArray: UntypedFormArray) => {
      let totalDetaille = DialogAjoutLigneComponent.calculTotalDetaille(formArray);
      if (totalDetaille !== ecartLigne) {
        return {'total_detaille_ok': true};
      } else {
        return null;
      }
    };
  }

  private saveCorrection() {
    const ligne: EntreeSortieLigne = this.initLigneFromForm();

    // Envoi de la correction à l'API
    this.entreeSortieService.postCorrectionLigne(this.data.entete, this.data.ligne, ligne).subscribe(() => {
      this.messageTool.sendSuccess(`${this.messageTool.lignePrefix(ligne)} a été corrigée avec succès`);
      this.data.ligneModifiee = ligne;
      this.dialog.close();

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  /* ****************************** */
  /* Gestion des détails des écarts */
  /* ****************************** */

  private initFormArrayDetails() {
    if (this.estApprovisionnement()) {
      const formArray = this.getFormArrayDetails();

      if (this.estCorrection()) {
        formArray.setValidators(DialogAjoutLigneComponent.totalDetailleOK(this.ecartCourant));
      }

      if (this.data.listeDetailsEcart) {
        this.dataSource.data = this.data.listeDetailsEcart;

        for (const detail of this.dataSource.data) {
          formArray.push(this.initDetailFormGroup(detail));
        }
      }

      this.updateTotalDetaille();
    }
  }

  initDetailFormGroup(detailEcart: EntreeSortieLigneDetailEcart): UntypedFormGroup {
    return this.fb.group({
      type: this.fb.control(detailEcart.type, Validators.required),
      quantite: this.fb.control(detailEcart.quantite, [Validators.required, DialogAjoutLigneComponent.notZero]),
      commentaire: this.fb.control(detailEcart.commentaire),
    });
  }

  // TODO voir pour utiliser celui de CustomValidators (en ajoutant une valeur par défaut)
  private static notZero(formControl: UntypedFormControl) {
    if (formControl.value === 0) {
      return {'not_zero': true};
    } else {
      return null;
    }
  }

  public getFormArrayDetails(): UntypedFormArray {
    return this.form.controls['details'] as UntypedFormArray;
  }

  public addNewDetail() {
    const newDetail = new EntreeSortieLigneDetailEcart();
    const newDetailFormGroup = this.initDetailFormGroup(newDetail);
    newDetailFormGroup.controls['type'].markAsTouched();
    newDetailFormGroup.controls['quantite'].markAsTouched();

    this.dataSource.data.push(newDetail);
    this.getFormArrayDetails().push(newDetailFormGroup);

    this.dataSource._updateChangeSubscription();
  }

  public removeDetail(index: number) {
    this.dataSource.data.splice(index, 1);
    this.getFormArrayDetails().removeAt(index);
    this.updateTotalDetaille();
    this.dataSource._updateChangeSubscription();
  }

  public updateTotalDetaille() {
    this.totalDetaille = DialogAjoutLigneComponent.calculTotalDetaille(this.getFormArrayDetails());
  }

  private static calculTotalDetaille(formArray: UntypedFormArray): number {
    let total = 0;
    const formArrayControls = formArray.controls;
    for (let controlKey in formArrayControls) {
      if (formArrayControls.hasOwnProperty(controlKey)) {
        const quantiteCtrl = (formArrayControls[controlKey] as UntypedFormGroup).get('quantite');
        if (quantiteCtrl.valid) {
          total += quantiteCtrl.value;
        }
      }
    }
    return +total.toFixed(4);
  }

  // Test si un control du FormArray des détails a une erreur
  hasErrorDetails(index: number, controlName: string, errorCode: string): boolean {
    const ligneCtrl = (this.form.get('details') as UntypedFormArray).controls[index];
    return ligneCtrl.get(controlName).hasError(errorCode);
  }

  private saveDetails() {
    // Envoi des détails à l'API
    this.entreeSortieService.postDetailsEcartLigne(this.data.entete, this.data.ligne, this.form.value.details).subscribe(() => {
      this.messageTool.sendSuccess(`Le détail a été enregistré avec succès`);
      this.data.ligneModifiee = this.data.ligne;
      this.dialog.close();

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  protected readonly console = console;
}
