import {EntreeSortieLignePUT} from "./EntreeSortieLignePUT";
import {ArticleDTO} from "./ArticleDTO";
import {EntreeSortieLigne} from "./EntreeSortieLigne";
import {EntreeSortieEntete} from "./EntreeSortieEntete";

export class EntreeSortieLignePOST extends EntreeSortieLignePUT {
  article: ArticleDTO;
  dlc: Date;

  constructor(entreeSortieLigne: EntreeSortieLigne) {
    super(entreeSortieLigne);

    this.article          = new ArticleDTO(entreeSortieLigne.article);
    this.dlc              = entreeSortieLigne.dlc;
  }
}
