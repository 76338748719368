import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private map = new Map<string, any>();

  constructor() { }

  set(key: string, value) {
    this.map.set(key, value);
  }

  get(key: string):any {
    return this.map.get(key);
  }

  has(key: string):boolean {
    return this.map.has(key);
  }

  delete(key: string) {
    this.map.delete(key);
  }

  clear() {
    this.map.clear();
  }
}
