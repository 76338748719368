import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {StockCompact} from "../../model/epona-api/StockCompact";
import {MatSort} from "@angular/material/sort";
import {Lieu} from "../../model/epona-api/Lieu";
import {FiltreStocksComponent} from "../filtre-stocks/filtre-stocks.component";
import {StockCompactFilter} from "../../model/epona-ui/StockCompactFilter";
import {MessageTool} from "../../commons/MessageTool";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Tools} from "../../commons/Tools";
import {StockCompactSearch} from "../../model/epona-api/StockCompactSearch";
import {StockCompactQueryParams} from "../../model/epona-ui/StockCompactQueryParams";
import {StockService} from "../../services/epona/stock.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogDataStocks} from "../../model/epona-ui/DialogDataStocks";
import {DialogHistoriqueMouvementsStocksComponent} from "../dialog-historique-mouvements-stocks/dialog-historique-mouvements-stocks.component";
import {DialogDetailDlcComponent} from "../dialog-detail-dlc/dialog-detail-dlc.component";
import {FilterTools} from "../../commons/FilterTools";
import {
  DisplayedColumnsTools,
  TableColumn,
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {ArticleTools} from "../../commons/ArticleTools";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
  selector: 'app-liste-stocks',
  templateUrl: './liste-stocks.component.html',
  styleUrls: ['./liste-stocks.component.css']
})
export class ListeStocksComponent implements OnInit {

  dataSource = new MatTableDataSource<StockCompact>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    batiment:          new TableColumn({label: 'Bâtiment'}),
    lieu:              new TableColumn({label: 'Lieu stockage'}),
    groupe:            new TableColumn({label: 'Groupe',              default: false}),
    sousGroupe:        new TableColumn({label: 'Sous-groupe',         default: false}),
    codeArticleAchat:  new TableColumn({label: 'Code achat'}),
    codeArticleVente:  new TableColumn({label: 'Code vente',          default: false,  exportFormat: 'integer'}),
    designation:       new TableColumn({label: 'Désignation',         hiddable: false}),
    quantite:          new TableColumn({label: 'Quantité',            hiddable: false, exportFormat: 'decimal'}),
    uniteExploitation: new TableColumn({label: 'Unité',               default: false}),
    pmpHt:             new TableColumn({label: 'PMP HT',              default: false,  exportFormat: 'decimal'}),
    pmpTtc:            new TableColumn({label: 'PMP TTC',             default: false,  exportFormat: 'decimal'}),
    valeurHt:          new TableColumn({label: 'Valeur HT',           default: false,  exportFormat: 'decimal'}),
    valeurTtc:         new TableColumn({label: 'Valeur TTC',          default: false,  exportFormat: 'decimal'}),
    stockMini:         new TableColumn({label: 'Stock mini.',         default: false,  exportFormat: 'decimal'}),
    actions:           new TableColumn({label: 'Actions',             hiddable: false, export: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.STOCKS;

  displayedColumns: string[] = [];

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild(FiltreStocksComponent)
  filterComponent: FiltreStocksComponent;

  lieuCourant: Lieu;
  filter: StockCompactFilter;

  UA = UtilisationArticle;

  constructor(private stockService: StockService,
              private messageTool: MessageTool,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
    // // override the route reuse strategy
    // this.router.routeReuseStrategy.shouldReuseRoute = function() {
    //   return false;
    // };
  }

  ngOnInit(): void {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMAp pour que le ngOnInit soit rappelé si une recherche est lancé depuis l'état des stocks
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.initDefaultFilters();
      }

      // Lancement de la recherche
      this.rechercheStocksCompacts();
    });

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre de stock compact
    this.filter = new StockCompactFilter();
    this.filter.groupe                 = FilterTools.buildGroupeArticlesFromURL(params);
    this.filter.sousGroupe             = FilterTools.buildSousGroupeArticlesFromURL(params);
    this.filter.article                = FilterTools.buildArticleFromURL(params);
    this.filter.masquerQuantitesNulles = params.get('masquerQuantitesNulles') ? Tools.stringToBoolean(params.get('masquerQuantitesNulles')) : true;
    this.filter.stockFaible            = params.get('stockFaible') ? Tools.stringToBoolean(params.get('stockFaible')) : null;
    this.filter.nombreJoursAvantDlc    = params.get('nombreJoursAvantDlc') ? +params.get('nombreJoursAvantDlc') : null;
  }

  // Initialisation des filtres à partir des valeurs par défaut
  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre de stock compact
    this.filter = new StockCompactFilter();
    this.filter.masquerQuantitesNulles = true;
  }

  private rechercheStocksCompacts() {
    this.dataSource.data = [];

    const search = this.buildSearchFromFilters();
    search.fields = 'lieu,' +
      'article.idArticle,' +
      'article.articleAchat,' +
      'article.codeArticleAchat,' +
      'article.designationAchat,' +
      'article.sousGroupe.libelle,' +
      'article.sousGroupe.groupe.libelle,' +
      'article.uniteExploitation.abreviation,' +
      'article.articleVente,' +
      'article.codeArticleVente,' +
      'article.designationVente,' +
      'article.articleDlc,' +
      'quantite,' +
      'pmpHt,' +
      'pmpTtc,' +
      'stockMini,' +
      'dlcMin';

    this.stockService.getListeStocksCompacts(search).subscribe(data => {
      this.dataSource.data = data;
      this.setDisplayedColumns();
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private buildSearchFromFilters(): StockCompactSearch {
    const search = new StockCompactSearch();

    search.setIdsLieu(this.lieuCourant);

    search.codeGroupe             = this.filter.groupe      ? this.filter.groupe.code                     : null;
    search.idSousGroupe           = this.filter.sousGroupe  ? this.filter.sousGroupe.idSousGroupeArticles : null;
    search.idArticle              = this.filter.article     ? this.filter.article.idArticle               : null;
    search.masquerQuantitesNulles = this.filter.masquerQuantitesNulles;
    search.stockFaible            = this.filter.stockFaible;
    search.nombreJoursAvantDlc    = this.filter.nombreJoursAvantDlc;

    return search;
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche de commande pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: StockCompactFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['etat-stocks'], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): StockCompactQueryParams {
    const params = new StockCompactQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.codeGroupe             = this.filter.groupe      ? this.filter.groupe.code                     : null;
    params.idSousGroupe           = this.filter.sousGroupe  ? this.filter.sousGroupe.idSousGroupeArticles : null;
    params.idArticle              = this.filter.article     ? this.filter.article.idArticle               : null;
    params.masquerQuantitesNulles = this.filter.masquerQuantitesNulles;
    params.stockFaible            = this.filter.stockFaible;
    params.nombreJoursAvantDlc    = this.filter.nombreJoursAvantDlc;

    return params;
  }

  /* ************************* */
  /* Historique des mouvements */
  /* ************************* */

  openDialogHistoriqueMouvements(stockCompact: StockCompact) {

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = new DialogDataStocks();
    dialogConfig.data.stockCompact = stockCompact;
    dialogConfig.position = {top: '20px'}; // Pour éviter que la fenêtre se repositionne verticalement lors du changement d'onglet

    this.dialog.open(DialogHistoriqueMouvementsStocksComponent, dialogConfig);
  }

  /* ************** */
  /* Détail par DLC */
  /* ************** */

  openDialogDetailDlc(stockCompact: StockCompact) {

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = new DialogDataStocks();
    dialogConfig.data.stockCompact = stockCompact;

    this.dialog.open(DialogDetailDlcComponent, dialogConfig);
  }

  /* ************** */
  /*       Tri      */
  /* ************** */

  sortingDataAccessor(data: StockCompact, sortHeaderId: string): string|number {
    switch (sortHeaderId) {
      case 'lieu'             : return SortHelper.stringToString(data.lieu?.nomUd);
      case 'batiment'         : return SortHelper.stringToString(data.lieu?.nomRu);
      case 'groupe'           : return SortHelper.stringToString(data.article?.sousGroupe.groupe.libelle);
      case 'sousGroupe'       : return SortHelper.stringToString(data.article?.sousGroupe.libelle);
      case 'codeArticleAchat' : return ArticleTools.getProperty(ArticleTools.PROP_CODE, data.article, UtilisationArticle.ACHAT, true);
      case 'codeArticleVente' : return ArticleTools.getProperty(ArticleTools.PROP_CODE, data.article, UtilisationArticle.VENTE, true);
      case 'designation'      : return SortHelper.stringToString(ArticleTools.getProperty(ArticleTools.PROP_DESIGNATION, data.article, UtilisationArticle.ACHAT, false));
      case 'quantite'         : return data.quantite;
      case 'uniteExploitation': return data.article.uniteExploitation?.abreviation;
      case 'valeurHt'         : return data.pmpHt * data.quantite;
      case 'valeurTtc'        : return data.pmpTtc * data.quantite;
      case 'stockMini'        : return data.stockMini;
      default                 : return data[sortHeaderId];
    }
  }
}
