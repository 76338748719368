import {EchangeFdnc} from "./echange-fdnc";

export class EchangeFdncPOST {
  commentaire: string;
  resultat: number;

  constructor(echange: EchangeFdnc) {
    this.commentaire = echange.commentaire;
    this.resultat    = echange.resultat;
  }
}
