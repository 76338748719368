import {Lieu} from './Lieu';
import {Extra} from './Extra';
import {EtatCommande} from "../EtatCommande";
import {Fournisseur} from "./Fournisseur";
import {SousLotZg} from "./SousLotZg";

export class CommandeEntete {
  idCommandeEntete: number;

  lieuDemandeur: Lieu;
  etatCommande: EtatCommande;
  fournisseur: Fournisseur;
  sousLotZg: SousLotZg;

  externe: boolean;
  dateCommande: Date;
  dateLivraisonSouhaitee: Date;
  codeUf: string;
  nombreClientsEstimatif: number;
  fraisPortPrevisionnels: number;
  commentaire: string;
  codeClientDemandeur: string;
  envoiEdi: boolean;
  montantHt: number;
  montantTtc: number;
  numeroEj: string;
  idPanierEntete: number;

  nbLignesCommandableNon: number;
  nbLignesCommandableNonDefini: number;

  dateCreation: Date;
  utilisateurCreation: string;
  dateModification: Date;
  utilisateurModification: string;

  extra: Extra;
}
