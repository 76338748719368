import {HttpClient} from '@angular/common/http';
import {HttpService} from '../http.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {AlerteArticleSearch} from "../../model/epona-api/AlerteArticleSearch";
import {EntreeSortieSearch} from '../../model/epona-api/EntreeSortieSearch';
import {CommandeSearch} from '../../model/epona-api/CommandeSearch';
import {InventaireSearch} from '../../model/epona-api/InventaireSearch';
import {TdbInventairesParLieu} from '../../model/epona-api/TdbInventairesParLieu';
import {TdbNbArticlesAlerteParLieu} from '../../model/epona-api/TdbNbArticlesAlerteParLieu';
import {TdbNbCommandesParLieuEtat} from '../../model/epona-api/TdbNbCommandesParLieuEtat';
import {TdbNbEntreesSortiesParLieuType} from '../../model/epona-api/TdbNbEntreesSortiesParLieuType';

@Injectable()
export class TableauBordService {

  private env = environment;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) {
  }

  getCommandesParEtatLieu(commandeSearch: CommandeSearch): Observable<Array<TdbNbCommandesParLieuEtat>>  {
    const params = this.httpService.buildParams(commandeSearch);
    return this.httpClient.get<TdbNbCommandesParLieuEtat[]>(this.env.eponaApiUrl + '/tableau-bord/commandes', {params});
  }

  getEntreesSorties(entreeSortiesSearch: EntreeSortieSearch): Observable<Array<TdbNbEntreesSortiesParLieuType>> {
    const params = this.httpService.buildParams(entreeSortiesSearch);
    return this.httpClient.get<TdbNbEntreesSortiesParLieuType[]>(this.env.eponaApiUrl + '/tableau-bord/entrees-sorties', {params});
  }

  getStockAlerte(alerteArticleSearch : AlerteArticleSearch): Observable<Array<TdbNbArticlesAlerteParLieu>> {
    const params = this.httpService.buildParams(alerteArticleSearch);
    return this.httpClient.get<TdbNbArticlesAlerteParLieu[]>(this.env.eponaApiUrl + '/tableau-bord/alertes-articles', {params});
  }

  getInventaire(inventaireSearch: InventaireSearch): Observable<Array<TdbInventairesParLieu>> {
    const params = this.httpService.buildParams(inventaireSearch);
    return this.httpClient.get<TdbInventairesParLieu[]>(this.env.eponaApiUrl + '/tableau-bord/inventaires', {params});
  }
}
