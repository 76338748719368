import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {TotauxTva} from "../../model/epona-api/TotauxTva";
import {Totaux} from "../../model/epona-api/Totaux";
import {CommandeService} from "../../services/epona/commande.service";
import {MessageTool} from "../../commons/MessageTool";

@Component({
  selector: 'epona-commande-totaux',
  templateUrl: './commande-totaux.component.html',
  styleUrls: ['./commande-totaux.component.css']
})
export class CommandeTotauxComponent implements OnChanges {

  @Input() entete: CommandeEntete;
  @Input() forceUpdate: number;

  listeTotauxTva: Array<TotauxTva>;
  totaux: Totaux;

  constructor(private commandeService: CommandeService,
              private messageTool: MessageTool) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entete'] || changes['forceUpdate']) {
      this.getListeTotauxTva();
      this.getTotaux();
    }
  }

  private getListeTotauxTva() {
    if (this.entete) {
      this.commandeService.getTotauxTva(this.entete.idCommandeEntete, null).subscribe(data => {
        this.listeTotauxTva = data.length > 0 ? data : null;
      }, err => {
        this.listeTotauxTva = null;
        this.messageTool.sendError(err);
      });
    }
  }

  private getTotaux() {
    if (this.entete) {
      this.commandeService.getTotaux(this.entete.idCommandeEntete, null).subscribe(data => {
        this.totaux = data;
      }, err => {
        this.totaux = null;
        this.messageTool.sendError(err);
      });
    }
  }
}
