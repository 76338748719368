export enum TypeBoutonCommande {
  ENREGISTRER                   = 'Enregistrer',

  ENVOYER_MAGASIN               = 'Envoyer au magasin…',
  FINALISER_PREPARATION         = 'Finaliser la préparation…',
  REFUSER_PAR_MAGASIN           = 'Refuser…',
  CREER_BL                      = 'Créer un BL…',
  FINALISER_RECEPTION           = 'Finaliser la réception…',
  FINALISER_COMMANDE            = 'Finaliser la commande…',
  ANNULER_FINALISATION_COMMANDE = 'Annuler la finalisation de la commande…',
  SUPPRIMER                     = 'Supprimer…',
  DUPLIQUER                     = 'Dupliquer…'
}
