<table mat-table [dataSource]="dataSource"
       matSort matSortActive="date" matSortDirection="desc" matSortDisableClear="true"
       cnousTableExporter [columns]="columns"
       class="mat-elevation-z8"
       aria-label="Liste d'échanges liés à une fiches de non-conformité">

  <ng-container matColumnDef="date">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['date'].tooltip">{{ columns['date'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.dateEnregistrement | dateTimeFormat }}</td>
  </ng-container>

  <ng-container matColumnDef="auteur">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['auteur'].tooltip">{{ columns['auteur'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.nomAuteur }}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['type'].tooltip">{{ columns['type'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.type === 1 ? 'Réponse' : 'Vérification' }}</td>
  </ng-container>

  <ng-container matColumnDef="message">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['message'].tooltip">{{ columns['message'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <dl *ngIf="element.type === 1">
        <dt>Détail de l'action</dt>
        <dl>{{ element.detailAction }}</dl>
        <dt>Analyse des causes</dt>
        <dl>{{ element.analyseCauses }}</dl>
        <dt>Action à mettre en œuvre</dt>
        <dl>{{ element.actionAMettreEnOeuvre }}</dl>
        <dt *ngIf="element.commentaire">Observations et commentaires</dt>
        <dl *ngIf="element.commentaire">{{ element.commentaire }}</dl>
      </dl>
      <dl *ngIf="element.type === 2">
        <dt>Résultat</dt>
        <dl>{{ resultatToString(element.resultat) }}</dl>
        <dt>Observations et commentaires</dt>
        <dl>{{ element.commentaire }}</dl>
      </dl>
    </td>
  </ng-container>

  <ng-container matColumnDef="detailAction">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['detailAction'].tooltip">{{ columns['detailAction'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.detailAction }}</td>
  </ng-container>

  <ng-container matColumnDef="analyseCauses">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['analyseCauses'].tooltip">{{ columns['analyseCauses'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.analyseCauses }}</td>
  </ng-container>

  <ng-container matColumnDef="actionAMettreEnOeuvre">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['actionAMettreEnOeuvre'].tooltip">{{ columns['actionAMettreEnOeuvre'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.actionAMettreEnOeuvre }}</td>
  </ng-container>

  <ng-container matColumnDef="listeTypesAction">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['listeTypesAction'].tooltip">{{ columns['listeTypesAction'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <ul *ngIf="element.listeTypesAction?.length > 0">
        <li *ngFor="let typeAction of element.listeTypesAction">{{ typeAction.libelle }}</li>
      </ul>
    </td>
  </ng-container>

  <ng-container matColumnDef="incidenceQualiteProduit">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['incidenceQualiteProduit'].tooltip">{{ columns['incidenceQualiteProduit'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <epona-boolean [value]="!element.incidenceQualiteProduit" trueLabel="Non" falseLabel="Oui" *ngIf="element.type === 1"></epona-boolean>
    </td>
  </ng-container>

  <ng-container matColumnDef="commentaire">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['commentaire'].tooltip">{{ columns['commentaire'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.commentaire }}</td>
  </ng-container>

  <ng-container matColumnDef="resultat">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['resultat'].tooltip">{{ columns['resultat'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ resultatToString(element.resultat) }}</td>
  </ng-container>

  <ng-container matColumnDef="valide">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['valide'].tooltip">{{ columns['valide'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <epona-boolean [value]="element.valide"></epona-boolean>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="colonnesAffichees"></tr>
  <tr mat-row *matRowDef="let row; columns: colonnesAffichees;"></tr>


  <!-- Ligne de pied de tableau en cas de liste vide -->
  <ng-container matColumnDef="noRecord">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="colonnesAffichees.length">Liste vide</td>
  </ng-container>

  <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
</table>
