import {ErrorStateMatcher} from "@angular/material/core";
import {FormControl, FormGroupDirective, NgForm} from "@angular/forms";

// source : https://stackoverflow.com/questions/54349574/angular-material-mat-error-shows-only-when-from-control-was-touched
export class InstantErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null,
               form: FormGroupDirective | NgForm
                 | null): boolean {
    return control && control.invalid;
  }
}
