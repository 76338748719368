<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field>
      <mat-label>Groupe</mat-label>
      <epona-select-groupe-articles formControlName="groupe"
                                    defaultLabel="- Tous -"
                                    [showUndefinedOption]="true"
                                    undefinedLabel="- Non défini -"
                                    epoHighlightIfNotEmpty>
      </epona-select-groupe-articles>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sous-groupe</mat-label>
      <epona-select-sous-groupe-articles formControlName="sousGroupe"
                                         defaultLabel="- Tous -"
                                         [groupe]="form.get('groupe').value"
                                         epoHighlightIfNotEmpty>
      </epona-select-sous-groupe-articles>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Article</mat-label>
      <epona-autocomplete-article formControlName="article"
                                  [stockGere]="true"
                                  epoHighlightIfNotEmpty></epona-autocomplete-article>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Masquer quantités nulles</mat-label>
      <select-boolean formControlName="masquerQuantitesNulles"
                      defaultLabel=""
                      epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Stock faible</mat-label>
      <select-boolean formControlName="stockFaible"
                      defaultLabel="- Tous -"
                      epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nb. jours avant DLC</mat-label>
      <input type="number" matInput formControlName="nombreJoursAvantDlc" epoHighlightIfNotEmpty />
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon> Rechercher
      </button>
    </div>
  </mat-card>
</form>
