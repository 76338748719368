import {ArticlePUT} from "./ArticlePUT";
import {Article} from "./Article";

export class ArticlePOST extends ArticlePUT {

  idCrous : number;
  codeArticleVente : number;

  constructor(article: Article, utilisation: string) {
    super(article, utilisation);
    this.idCrous = article.idCrous;
    this.codeArticleVente = article.codeArticleVente;
  }
}
