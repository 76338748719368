<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span *ngIf="droitModification && estAjout">Ajout d'un article depuis VEM</span>
    <span *ngIf="droitModification && multiple">Modification de plusieurs articles</span>
    <span *ngIf="droitModification && !estAjout && !multiple">Modification de l'article {{ articleEpona | codeArticle:data.utilisation:true }}</span>
    <span *ngIf="!droitModification">Consultation d'un article</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="annuler()" [disabled]="sauvegardeEnCours">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content class="modification-multiple">
  <div class="alert alert-info" role="alert" *ngIf="multiple">
    Cocher les cases des propriétés à modifier
    pour les <strong>{{ data.listeArticles.length }}</strong> articles sélectionnés
  </div>

  <form [formGroup]="form">
    <mat-form-field *ngIf="!multiple && (afficherAchat || !estAjout)">
      <mat-label>Article</mat-label>

      <textarea matInput cdkTextareaAutosize
                [value]="articleEpona | designation:data.utilisation"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                disabled>
      </textarea>

      <aide matSuffix icon="more" maxWidth="1000px" matTooltip="Afficher les informations marché"
                  *ngIf="data.utilisation === UtilisationArticle.ACHAT && articleEpona && articleEpona.listeInformationsMarche">
        <table role="table" class="mat-mdc-table cdk-table" aria-label="Informations marché de l'article">
          <thead role="rowgroup">
            <tr role="row" class="mat-mdc-row cdk-row">
              <th role="columnheader" class="mat-mdc-cell cdk-cell" scope="col">Marché</th>
              <th role="columnheader" class="mat-mdc-cell cdk-cell" scope="col">Type</th>
              <th role="columnheader" class="mat-mdc-cell cdk-cell" scope="col">Référence</th>
              <th role="columnheader" class="mat-mdc-cell cdk-cell" scope="col">Prix</th>
              <th role="columnheader" class="mat-mdc-cell cdk-cell" scope="col">Description</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row" class="mat-mdc-row cdk-row" *ngFor="let informationsMarche of articleEpona.listeInformationsMarche">
              <td role="cell" class="mat-mdc-cell cdk-cell">{{ informationsMarche.codeMarcheOrion }}</td>
              <td role="cell" class="mat-mdc-cell cdk-cell">{{ informationsMarche.typeSousLot }}</td>
              <td role="cell" class="mat-mdc-cell cdk-cell">{{ informationsMarche.reference }}</td>
              <td role="cell" class="mat-mdc-cell cdk-cell">{{ (informationsMarche.listeMercuriales ? informationsMarche.listeMercuriales[0].prixHt : informationsMarche.prixHt) | currency:'EUR':'symbol':'1.2-4' }}</td>
              <td role="cell" class="mat-mdc-cell cdk-cell">{{ informationsMarche.commentaireMarche }}</td>
            </tr>
          </tbody>
        </table>
      </aide>
    </mat-form-field>

    <mat-form-field *ngIf="afficherVente && estAjout">
      <mat-label>Article VEM</mat-label>

      <epona-autocomplete-article-vem formControlName="articleVem"
                                      [listeCodesArticleExclus]="listeCodesArticleExistants"
                                      required>
      </epona-autocomplete-article-vem>

      <mat-error *ngIf="form.get('articleVem').hasError('required')">Article obligatoire</mat-error>
    </mat-form-field>

    <fieldset *ngIf="afficherAchat && !multiple">
      <legend>Paramètres nationaux</legend>

      <div class="mat-form-field-row">
        <mat-form-field>
          <mat-label>Famille de marchés</mat-label>
          <span matPrefix *ngIf="multiple">
            <mat-checkbox formControlName="familleMarchesCheck" matTooltip="Modification de la propriété « Famille de marchés »" (click)="$event.stopPropagation()"></mat-checkbox>
          </span>
          <epona-select-famille-marches formControlName="familleMarches" [exclureAlimentaire]="articlesPunchOutUniquement"></epona-select-famille-marches>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Sous-groupe</mat-label>
          <span matPrefix *ngIf="multiple">
            <mat-checkbox formControlName="sousGroupeCheck" matTooltip="Modification de la propriété « Sous-groupe »" (click)="$event.stopPropagation()"></mat-checkbox>
          </span>
          <epona-select-sous-groupe formControlName="sousGroupe" [exclureAlimentaire]="articlesPunchOutUniquement"></epona-select-sous-groupe>
        </mat-form-field>
      </div>
    </fieldset>

    <fieldset *ngIf="afficherAchat">
      <legend>Paramètres locaux</legend>

      <div class="mat-form-field-row">
        <mat-form-field>
          <mat-label>Commandable au fournisseur</mat-label>
          <span matPrefix *ngIf="multiple">
            <mat-checkbox formControlName="commandableExterneCheck" matTooltip="Modification de la propriété « Commandable »" (click)="$event.stopPropagation()"></mat-checkbox>
          </span>

          <select-boolean formControlName="commandableExterne" defaultLabel="Non défini" *ngIf="!multiple"></select-boolean>

          <mat-select formControlName="commandableExterne" *ngIf="multiple">
            <mat-option [value]="DEFAULT"></mat-option>
            <mat-option [value]="-1">Non défini</mat-option>
            <mat-option [value]="0">Non</mat-option>
            <mat-option [value]="1">Oui</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!multiple">
          <mat-label>Commentaire</mat-label>

          <textarea matInput cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="6"
                    formControlName="commentaireParametrage"
                    maxlength="255">
                </textarea>
        </mat-form-field>
      </div>

      <div class="mat-form-field-row">
        <mat-form-field>
          <mat-label>Stock géré comptablement</mat-label>
          <span matPrefix *ngIf="multiple">
            <mat-checkbox formControlName="stockGereCheck" matTooltip="Modification de la propriété « Stock géré comptablement »" (click)="$event.stopPropagation()"></mat-checkbox>
          </span>
          <select-boolean formControlName="stockGere" required></select-boolean>
          <mat-error *ngIf="form.get('stockGere').hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Stock géré opérationnellement</mat-label>
          <span matPrefix *ngIf="multiple">
            <mat-checkbox formControlName="stockGereOperationnellementCheck" matTooltip="Modification de la propriété « Stock géré opérationnellement »" (click)="$event.stopPropagation()"></mat-checkbox>
          </span>
          <select-boolean formControlName="stockGereOperationnellement" required></select-boolean>
          <mat-error *ngIf="form.get('stockGereOperationnellement').hasError('required')">Champ obligatoire</mat-error>
        </mat-form-field>
      </div>
    </fieldset>

    <div class="mat-form-field-row" *ngIf="afficherVente">
      <mat-form-field>
        <mat-label>Actif</mat-label>
        <span matPrefix *ngIf="multiple">
          <mat-checkbox formControlName="actifVenteCheck" matTooltip="Modification de la propriété « Actif »" (click)="$event.stopPropagation()"></mat-checkbox>
        </span>
        <select-boolean formControlName="actifVente" required></select-boolean>
        <mat-error *ngIf="form.get('actifVente').hasError('required')">Champ obligatoire</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Colisage interne</mat-label>
        <span matPrefix *ngIf="multiple">
          <mat-checkbox formControlName="colisageCheck" matTooltip="Modification de la propriété « Colisage interne »"></mat-checkbox>
        </span>
        <input type="number" matInput formControlName="colisage" min="1" [max]="MAX_COLISAGE" />
        <mat-error *ngIf="form.get('colisage').hasError('min')">Valeur minimum : 1</mat-error>
        <mat-error *ngIf="form.get('colisage').hasError('max')">Valeur maximum : {{ MAX_COLISAGE }}</mat-error>
      </mat-form-field>
    </div>

    <div class="mat-form-field-row" *ngIf="afficherVente">
      <mat-form-field>
        <mat-label>Avec DLC</mat-label>
        <span matPrefix *ngIf="multiple">
          <mat-checkbox formControlName="articleDlcCheck" matTooltip="Modification de la propriété « Avec DLC »" (click)="$event.stopPropagation()"></mat-checkbox>
        </span>
        <select-boolean formControlName="articleDlc" required></select-boolean>
        <mat-error *ngIf="form.get('articleDlc').hasError('required')">Champ obligatoire</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Durée de consommation</mat-label>
        <span matPrefix *ngIf="multiple">
          <mat-checkbox formControlName="delaiReceptionDlcCheck" matTooltip="Modification de la propriété « Durée de consommation »"></mat-checkbox>
        </span>
        <input type="number" matInput formControlName="delaiReceptionDlc" min="1" [max]="MAX_DELAI_RECEPTION_DLC" />
        <mat-error *ngIf="form.get('delaiReceptionDlc').hasError('min')">Valeur minimum : 1</mat-error>
        <mat-error *ngIf="form.get('delaiReceptionDlc').hasError('max')">Valeur maximum : {{ MAX_DELAI_RECEPTION_DLC }}</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="annuler()" [disabled]="sauvegardeEnCours">Annuler</button>
  <button mat-raised-button color="primary" (click)="sauvegarder()" [disabled]="!droitModification || sauvegardeEnCours || !form.valid || estAjout && !articleVem">Sauvegarder</button>
  <mat-progress-bar [value]="nbArticlesTraites / nbArticlesATraiter * 100" *ngIf="sauvegardeEnCours"></mat-progress-bar>
</mat-dialog-actions>

