<mat-card>
  <mat-card-header>
    <mat-card-title>Liste des lots</mat-card-title>
  </mat-card-header>

  <mat-divider></mat-divider>

  <div class="table-actions">
    <button type="button"
            mat-raised-button color="primary"
            (click)="exporter.exportTable({nomFichier: 'liste-lots.xlsx'})"
            [disabled]="dataSource.data.length === 0">
      <fa-icon icon="file-excel"></fa-icon> Exporter
    </button>

    <form-displayed-columns [availableColumns]="columns"
                            [storeCode]="columnsStoreCode"
                            [(displayedColumns)]="colonnesAffichees"></form-displayed-columns>
  </div>


  <table mat-table [dataSource]="dataSource"
         cnousTableExporter #exporter="cnousTableExporter" [columns]="columns"
         matSort matSortActive="nom" matSortDirection="asc" matSortDisableClear="true"
         class="mat-elevation-z8"
         aria-label="Liste de lots">

    <ng-container matColumnDef="batiment">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['batiment'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.entete.lieu.nomRu}}</td>
    </ng-container>

    <ng-container matColumnDef="lieu">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['lieu'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.entete.lieu.nomUd}}</td>
    </ng-container>

    <ng-container matColumnDef="fournisseur">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['fournisseur'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.entete.commande.fournisseur?.nom}}</td>
    </ng-container>

    <ng-container matColumnDef="numeroBL">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['numeroBL'].label }}</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['/bordereau-livraison', element.entreeSortieLigne.entete.idEntreeSortieEntete]" matTooltip="Consulter le bordereau de livraison">
          {{ element.entreeSortieLigne.entete | bordereauLivraison }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="dateBL">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['dateBL'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.entete.dateBordereauLivraison | dateFormat}}</td>
    </ng-container>

    <ng-container matColumnDef="referenceFournisseur">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['referenceFournisseur'].tooltip">{{ columns['referenceFournisseur'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.referenceFournisseur}}</td>
    </ng-container>

    <ng-container matColumnDef="codeAchat">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['codeAchat'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.article.codeArticleAchat}}</td>
    </ng-container>

    <ng-container matColumnDef="codeVente">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['codeVente'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.article.codeArticleVente}}</td>
    </ng-container>

    <ng-container matColumnDef="designation">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['designation'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.article | designation}}</td>
    </ng-container>

    <ng-container matColumnDef="dlc">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['dlc'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.entreeSortieLigne.dlc | dateFormat}}</td>
    </ng-container>

    <ng-container matColumnDef="numeroLot">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['numeroLot'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.numeroLot}}</td>
    </ng-container>

    <ng-container matColumnDef="quantite">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['quantite'].label }}</th>
      <td mat-cell *matCellDef="let element">{{element.quantite}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="colonnesAffichees"></tr>
    <tr mat-row *matRowDef="let row; columns: colonnesAffichees;"></tr>


    <!-- Ligne de pied de tableau en cas de liste vide -->
    <ng-container matColumnDef="noRecord">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="colonnesAffichees.length">Liste vide</td>
    </ng-container>

    <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
  </table>
</mat-card>
