  <div class="crous-recherche-liste" [ngClass]="{'no-marge': small}">
    <mat-card>
      <mat-card-header *ngIf="!small">
        <mat-card-title>Liste des lots-marchés actuels</mat-card-title>
      </mat-card-header>

      <mat-divider *ngIf="!small"></mat-divider>

      <table mat-table [dataSource]="dataSource"
             class="mat-elevation-z8"
             matSort matSortActive="codeMarcheOrion" matSortDirection="asc" matSortDisableClear="true"
             aria-label="Liste des lots-marchés d'un fournisseur">

        <ng-container matColumnDef="codeMarcheOrion">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Code</th>
          <td mat-cell *matCellDef="let element">{{element.codeMarcheOrion}}</td>
        </ng-container>

        <ng-container matColumnDef="numeroSousLot">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Lot</th>
          <td mat-cell *matCellDef="let element">{{element.numeroSousLot}}</td>
        </ng-container>

        <ng-container matColumnDef="libelle">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Libellé</th>
          <td mat-cell *matCellDef="let element">{{element.libelle }}</td>
        </ng-container>

        <ng-container matColumnDef="typeSousLot">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">{{element.typeSousLot }}</td>
        </ng-container>

        <ng-container matColumnDef="zoneGeographique">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Zone géographique</th>
          <td mat-cell *matCellDef="let element">{{ element.zoneGeographique ? element.zoneGeographique.libelle : 'Toutes' }}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>
    </mat-card>
  </div>

