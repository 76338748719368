import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self, SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {OrionService} from "../../../services/epona/orion.service";
import {CacheService} from "../../../services/cache.service";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {FamilleMarches} from "../../../model/epona-api/FamilleMarches";
import {TypeReferentielOrion} from "../../constants/TypeReferentielOrion";
import {ReferentielOrion} from "../../../model/epona-api/ReferentielOrion";
import {CategorieMarches} from "../../../model/epona-api/CategorieMarches";

@Component({
  selector: 'epona-select-famille-marches',
  templateUrl: './select-famille-marches.component.html',
  styleUrls: ['./select-famille-marches.component.css'],
  providers: [{provide: MatFormFieldControl, useExisting: SelectFamilleMarchesComponent}]
})
export class SelectFamilleMarchesComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<FamilleMarches> {

  @Input() defaultLabel: string = '- Aucune -';
  @Input() exclureAlimentaire: boolean = false;
  @Output() readonly valueChanged = new EventEmitter<FamilleMarches>();

  public readonly DEFAULT = Const.DEFAULT;
  private readonly CACHE_KEY: string = 'orion-familles-marches';
  private readonly CODE_CATEGORIE_DENREE_ALIMENTAIRE = '10';
  private static loadInProgress: boolean = false;
  private static listLoaded = new Subject<ReferentielOrion[]>();

  formCtrl: UntypedFormControl;
  liste: FamilleMarches[];
  groups: CategorieMarches[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private orionService: OrionService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: FamilleMarches): void {
    this.formCtrl.setValue(obj ? obj.idFamilleMarches : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
    this.valueChanged.emit(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): FamilleMarches {
    return this.getCurrentItem();
  }
  set value(value: FamilleMarches) {
    this.formCtrl.setValue(value ? value.idFamilleMarches : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): FamilleMarches {
    return this.liste ? this.liste.find(item => item.idFamilleMarches === this.formCtrl.value) : null;
  }

  private loadListe(): void {
    // Si la liste a déjà été chargée
    if (this.cacheService.has(this.CACHE_KEY)) {
      this.setAndSortListeFromCache();

      // Sinon, si le chargement est en cours, attente de la fin du chargement
    } else if (SelectFamilleMarchesComponent.loadInProgress) {
      SelectFamilleMarchesComponent.listLoaded.subscribe(list => {
        this.setAndSortListeFromCache();
        SelectFamilleMarchesComponent.listLoaded.unsubscribe();
      });

      // Sinon récupération de la liste via l'API
    } else {
      SelectFamilleMarchesComponent.loadInProgress = true;
      this.orionService.getListeReferentielOrion(TypeReferentielOrion.FAMILLE_MARCHES, null).subscribe(data => {
        this.cacheService.set(this.CACHE_KEY, data);
        SelectFamilleMarchesComponent.loadInProgress = false;
        SelectFamilleMarchesComponent.listLoaded.next(data);
        this.setAndSortListeFromCache();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = this.cacheService.get(this.CACHE_KEY);
    this.buildGroups();
    this.sort();
  }

  private buildGroups(): void {
    this.groups = [];
    for (const item of this.liste) {
      // Exclusion éventuelle de la catégorie "denrée alimentaire"
      if (!this.exclureAlimentaire || item.categorieMarches.code !== this.CODE_CATEGORIE_DENREE_ALIMENTAIRE) {
        let group: CategorieMarches = this.groups.find(g => g.code === item.categorieMarches.code);
        if (!group) {
          group = item.categorieMarches;
          group.listeFamillesMarches = [];
          this.groups.push(group);
        }
        group.listeFamillesMarches.push(item);
      }
    }
  }

  private sort(): void {
    this.groups.sort((groupeA, groupeB) => {
      return groupeA.libelle.localeCompare(groupeB.libelle);
    });

    for (const group of this.groups) {
      group.listeFamillesMarches.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    }
  }
}
