import {Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {Const} from '../../constants/Const';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NgControl, UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {MessageTool} from '../../MessageTool';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatFormFieldControl} from '@angular/material/form-field';
import {TypeNonConformite} from '../../../model/bna-api/type-non-conformite';
import {BnaFdncService} from "../../../services/bna/bna-fdnc.service";
import {TypeNonConformiteSearch} from "../../../model/bna-api/type-non-conformite-search";
import {ContexteFdnc} from "../../../model/bna-api/contexte-fdnc";
import {CacheService} from "../../../services/cache.service";

@Component({
  selector: 'epona-select-type-non-conformite',
  templateUrl: './select-type-non-conformite.component.html',
  styleUrls: ['./select-type-non-conformite.component.css'],
  providers: [{provide: MatFormFieldControl, useExisting: SelectTypeNonConformiteComponent}]
})
export class SelectTypeNonConformiteComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<TypeNonConformite[]> {

  @Input() defaultLabel: string = '- Aucun -';
  @Input() displayType: 'select'|'checkbox' = 'select';
  @Input() afficherInactifs: boolean = false;

  public readonly DEFAULT = Const.DEFAULT;
  private readonly CACHE_KEY: string = 'types-non-conformite';

  formCtrl: UntypedFormControl;
  formGroup: FormGroup;
  formArray: FormArray<FormControl<boolean>>;
  liste: TypeNonConformite[] = [];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private bnaFdncService: BnaFdncService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl();
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });

    this.formArray = new FormArray<FormControl<boolean>>([]);
    this.formGroup = new FormGroup({
      'types': this.formArray
    });
    this.formGroup.valueChanges.subscribe(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    // this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: TypeNonConformite[]): void {
    this.formCtrl.setValue(this.typeNonConformiteToNumber(obj));
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): TypeNonConformite[] {
    return this.getCurrentItem();
  }
  set value(value: TypeNonConformite[]) {
    this.formCtrl.setValue(this.typeNonConformiteToNumber(value));
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  private _idContexte: number = null;
  @Input()
  get idContexte(): number {
    return this._idContexte;
  }
  set idContexte(value: number) {
    this._idContexte = value;
    this.loadListe();
  }

  getCurrentItem(): TypeNonConformite[] {
    if (this.displayType === 'select') {
      return this.liste ? this.liste.filter(item => (this.formCtrl.value as number[]).includes(item.code)) : [];
    } else {
      return this.formArray.controls
        .map((formCtrl:FormControl<boolean>, index: number) => formCtrl.value ? this.liste[index] : null)
        .filter(type => type !== null);
    }
  }

  private loadListe() {
    if (!this._idContexte) {
      // Si la liste a déjà été chargée
      if (this.cacheService.has(this.CACHE_KEY)) {
        this.setAndSortListeFromCache();

        // Sinon récupération de la liste via l'API
      } else {
        const search = new TypeNonConformiteSearch();
        search.fields = 'code,libelle,description,actif,ordreAffichage';

        this.bnaFdncService.getListeTypesNonConformite(search).subscribe(data => {
          this.cacheService.set(this.CACHE_KEY, data);
          this.setAndSortListeFromCache();

        }, err => {
          this.messageTool.sendError(err);
        });
      }
    } else {
      const search = new TypeNonConformiteSearch();
      search.idContexteFdnc = this._idContexte;
      search.fields = 'code,libelle,description,actif,ordreAffichage';

      this.bnaFdncService.getListeTypesNonConformite(search).subscribe(data => {
        this.liste = data.filter(t => t.actif || this.afficherInactifs);
        this.tri(this.liste);
        this.initFormArray();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = (this.cacheService.get(this.CACHE_KEY) as TypeNonConformite[]).filter(t => t.actif || this.afficherInactifs);
    this.tri(this.liste);
    this.initFormArray();
  }

  tri(liste: Array<TypeNonConformite>) {
    liste.sort(TypeNonConformite.sort);
  }

  private initFormArray() {
    if (this.displayType === 'checkbox') {
      const checked = this.liste.length === 1;
      this.formArray.clear();
      for (const type of this.liste) {
        this.formArray.push(new FormControl<boolean>({value: checked, disabled: !type.actif}));
      }
      if (checked) {
        this.selectionChanged();
      }
    }
  }

  typeNonConformiteToNumber(obj: TypeNonConformite[]): number[] {
    if (obj) {
      return (obj as TypeNonConformite[]).map(type => type.code);
    } else {
      return [];
    }
  }
}
