import {InventaireEntete} from "../epona-api/InventaireEntete";
import {InventaireLigne} from "../epona-api/InventaireLigne";
import {Article} from "../epona-api/Article";

export class DialogDataAjoutArticleInventaire {
  // Paramètres en entrée
  entete: InventaireEntete
  lignes: Array<InventaireLigne>

  // Paramètres de retour
  article: Article;
}
