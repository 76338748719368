import {DTOWithLieu} from "../epona-ui/DTOWithLieu";

export class EntreeSortieLigneLotSearch extends DTOWithLieu {
  idFournisseur: number;
  codeMarche: string;
  numeroLot: string[];
  idArticle: number;

  fields: string;
}
