<epona-select-lieu [lieu]="lieuCourant"
                   [livraisonUniquement]="true"
                   lieuLabel="Lieu de livraison"
                   [disabled]="!!entete"
                   (lieuSubmitted)="onLieuSubmitted($event)"></epona-select-lieu>

<h1 *ngIf="!entete">Création d'une commande</h1>
<h1 *ngIf="entete && droitModification">Modification de la commande</h1>
<h1 *ngIf="entete && !droitModification">Consultation de la commande</h1>

<div class="fiche">
  <epona-commande-entete [entete]="entete"
                         [lignes]="lignes"
                         [lieuCourant]="lieuCourant"
                         [isInvalidFormLignes]="isInvalidFormLignes"
                         [modificationLigneEnCours]="modificationLigneEnCours"
                         [listeBordereauxLivraisonLies]="listeBordereauxLivraisonLies"
                         (enteteSubmitted)="doActionEntete($event)"
                         (envoiSubmitted)="doEnvoi($event)"
                         (suppressionSubmitted)="doSuppression($event)"
                         (refusSubmitted)="doRefus($event)"
                         (blSubmitted)="doGenerationBL($event)"
                         (courrielChanged)="onCourrielChanged()"
                         (finalisationCommandeSubmitted)="doFinalisationCommande($event)"
                         (annulationFinalisationCommandeSubmitted)="doAnnulationFinalisationCommande($event)"
                         (envoiEjSubmitted)="doHandleEnvoiEngagementJuridique()"
                         (finalisationPreparationReceptionSubmitted)="doFinalisationPreparationReception($event)"
                         (duplicationSubmitted)="doDuplication($event)"
                         class="entete">
  </epona-commande-entete>

  <!-- Le composant des lignes n'est affiché que si l'on est en modification donc s'il y a un idCommande -->
  <div class="lignes">
    <mat-card *ngIf="idCommande">
      <mat-tab-group [mat-stretch-tabs]="false" (selectedIndexChange)="indexChange($event)">
        <mat-tab label="Lignes">
          <epona-commande-lignes [entete]="entete"
                                 [lignes]="lignes"
                                 (listeArticlesInserted)="doHandleListeArticlesInserted($event)"
                                 (ligneUpdated)="doHandleLigneUpdated($event)"
                                 (lignesDeleted)="doHandleLignesDeleted($event)"
                                 (isInvalidFormLignes)="getIsInvalidFormLignes($event)"
                                 (debutModification)="doDebutModificationLigne()"
                                 (finModification)="doFinModificationLigne()">
          </epona-commande-lignes>
        </mat-tab>

        <mat-tab label="FDNC" *ngIf="estExterne()" [disabled]="!estEnvoyee()">
          <div *ngIf="displayFdnc">
            <epona-fdnc-commande-livraison [commande]="entete"></epona-fdnc-commande-livraison>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
