import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Format} from '../constants/Format';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value):any {
    return super.transform(value, Format.FORMAT_TIME);
  }
}
