<p class="franco-port" *ngIf="entete.sousLotZg?.francoPort">
  Des frais de port seront facturés si le total HT de la commande est inférieur à <strong>{{ entete.sousLotZg.francoPort | decimal2 }}&nbsp;€</strong>.
</p>

<mat-card class="ht-tva" *ngIf="totaux && listeTotauxTva">
  <table aria-label="Total HT de la commande et totaux par taux de TVA">
    <tbody>
      <tr>
        <th scope="row">Total commande HT</th>
        <td class="montant">{{ totaux.totalHt | decimal2 }} €</td>
      </tr>
      <tr *ngFor="let tva of listeTotauxTva">
        <th class="tauxTva" scope="row">{{ tva.tauxTva |decimal1 }} %</th>
        <td class="montant">{{ tva.sousTotalTva | decimal2 }} €</td>
      </tr>
    </tbody>
  </table>
</mat-card>

<mat-card class="ttc" *ngIf="totaux && totaux.totalTtc > 0">
  <table aria-label="Total TTC de la commande">
    <tbody>
      <tr>
        <th scope="row">Total commande TTC</th>
        <td class="montant">{{ totaux.totalTtc | decimal2 }} €</td>
      </tr>
    </tbody>
  </table>
</mat-card>
