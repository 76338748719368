<div class="liste-sous-lots">
  <div *ngFor="let entry of mapTotauxSetParSousLot | keyvalue" class="sous-lot">
    <div class="libelle-sous-lot">{{ entry.value.totaux.sousLot | lotMarcheOrionType }}</div>

    <mat-card class="ht-tva" *ngIf="entry.value.totaux && entry.value.listeTotauxTva">
      <table aria-label="Total HT de la commande et totaux par taux de TVA">
        <tbody>
        <tr>
          <th scope="row">Total HT</th>
          <td class="montant">{{ entry.value.totaux.totalHt | decimal2 }} €</td>
        </tr>
        <tr *ngFor="let tva of entry.value.listeTotauxTva">
          <th class="tauxTva" scope="row">{{ tva.tauxTva |decimal1 }} %</th>
          <td class="montant">{{ tva.sousTotalTva | decimal2 }} €</td>
        </tr>
        </tbody>
      </table>
    </mat-card>

    <mat-card class="ttc" *ngIf="entry.value.totaux && entry.value.totaux.totalTtc > 0">
      <table aria-label="Total TTC de la commande">
        <tbody>
        <tr>
          <th scope="row">Total TTC</th>
          <td class="montant">{{ entry.value.totaux.totalTtc | decimal2 }} €</td>
        </tr>
        </tbody>
      </table>
    </mat-card>
  </div>
</div>
