import {EntreeSortieLigne} from "./EntreeSortieLigne";
import {EntreeSortieParametrage} from "../epona-ui/EntreeSortieParametrage";
import {EntreeSortieEntete} from "./EntreeSortieEntete";

export class EntreeSortieLignePUT {
  quantite: number;
  prixHt: number;
  prixTtc: number;

  constructor(entreeSortieLigne: EntreeSortieLigne) {
    const parametrage = EntreeSortieParametrage.getParametrageFromType(entreeSortieLigne.entete.typeMouvement);

    this.quantite = entreeSortieLigne.quantite;
    if (parametrage.editPrixSaisis) {
      this.prixHt   = entreeSortieLigne.prixHt;
      this.prixTtc  = entreeSortieLigne.prixTtc;
    }
  }
}
