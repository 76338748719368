import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DialogDataCreationFdnc} from "./dialog-data-creation-fdnc";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {ContexteFdnc} from "../../model/bna-api/contexte-fdnc";
import {TypeNonConformite} from "../../model/bna-api/type-non-conformite";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {CommandeService} from "../../services/epona/commande.service";
import {CommandeLigne} from "../../model/epona-api/CommandeLigne";
import {Fdnc} from "../../model/epona-api/fdnc";
import {FdncService} from "../../services/epona/fdnc.service";
import {Router} from "@angular/router";
import {Article} from "../../model/epona-api/Article";
import {EntreeSortieLigne} from "../../model/epona-api/EntreeSortieLigne";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {CustomValidators} from "../../commons/CustomValidators";
import {CommandeLigneSearch} from "../../model/epona-api/CommandeLigneSearch";
import {TraitementNonConformite} from "../../model/bna-api/traitement-non-conformite";
import {TempsTraitementFdnc} from "../../model/bna-api/temps-traitement-fdnc";
import {forkJoin, Observable} from "rxjs";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {SortHelper} from '../../helpers/sort-helper';
import {DateFormatPipe} from '../../commons/pipes/DateFormatPipe';

@Component({
  selector: 'app-dialog-creation-fdnc',
  templateUrl: './dialog-creation-fdnc.component.html',
  styleUrls: ['./dialog-creation-fdnc.component.css']
})
export class DialogCreationFdncComponent implements OnInit {

  commande: CommandeEntete|null = null;
  livraison: EntreeSortieEntete|null = null;
  articleSelectionne: Article|null = null;

  constatForm: FormGroup;
  articleForm: FormGroup;
  typesForm: FormGroup;
  traitementsForm: FormGroup;

  // Pour éviter les chargements inutiles de composants
  afficherArticleForm: boolean = false;
  afficherTypesForm: boolean = false;
  afficherTraitementsForm: boolean = false;

  listeLignesArticles: LigneArticleDTO[] = [];

  contexteSelectionne: ContexteFdnc|null = null;

  constructor(private dialogRef: MatDialogRef<DialogCreationFdncComponent, boolean>,
              private fdncService: FdncService,
              private dateFormatPipe: DateFormatPipe,
              private commandeService: CommandeService,
              private entreeSortieService: EntreeSortieService,
              private messageTool: MessageTool,
              private dialog: MatDialog,
              private router: Router,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataCreationFdnc) {
    this.commande = data.commande !== null ? data.commande : data.livraison.commande;
    this.livraison = data.livraison;
    this.articleSelectionne = this.data.ligne?.article;
  }

  ngOnInit(): void {
    // Formulaire de choix du contexte
    this.constatForm = this.fb.group({
      'dateConstat': this.fb.control<Date|null>(new Date(), Validators.required),
      'contexte':    this.fb.control<ContexteFdnc|null>(null, Validators.required)
    });
    this.constatForm.get('contexte').valueChanges.subscribe(value => {
      this.contexteSelectionne = value;
      if (!this.contexteSelectionne.presenceArticle) {
        this.articleForm.reset();
      }
      this.typesForm.reset();
    });

    // Formulaire de choix de l'article
    this.articleForm = this.fb.group({
      'ligneArticle': this.fb.control<LigneArticleDTO|null>(null, Validators.required),
      'numeroLot':    this.fb.control<string|null>(null)
    });
    this.articleForm.get('ligneArticle').valueChanges.subscribe({
      next: (ligne: LigneArticleDTO) => {
        this.articleSelectionne = ligne?.article;
      }
    });

    // Formulaire de choix des types de non-conformité
    this.typesForm = this.fb.group({
      'types':        this.fb.control<TypeNonConformite[]|null>(null, [CustomValidators.nbMin(1), CustomValidators.nbMax(3)]),
      'observations': this.fb.control<string|null>(null, Validators.required)
    });

    // Formulaire de choix des traitements
    this.traitementsForm = this.fb.group({
      'traitements':  this.fb.control<TraitementNonConformite[]|null>(null, [CustomValidators.nbMin(1), CustomValidators.nbMax(2)]),
      'temps': this.fb.control<TempsTraitementFdnc|null>({value: null, disabled: true}, Validators.required),
      'observations': this.fb.control<string|null>(null, Validators.required)
    });

    this.traitementsForm.get('traitements').valueChanges.subscribe(value => {
      if (value && value.some(traitement => traitement.tempsTraitement)) {
        this.traitementsForm.get('temps').enable();
      } else {
        this.traitementsForm.get('temps').setValue(null);
        this.traitementsForm.get('temps').disable();
      }
    });
  }

  openDialogFermeture(): void {
    if (this.constatForm.dirty) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation de fermeture",
        yesLabel: "Confirmer la fermeture",
        noLabel: "Annuler",
        body: 'Confirmez-vous la fermeture du formulaire de création de FDNC&nbsp;?'
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  // private readonly STEP_INDEX_AVEC_ARTICLE_CONTEXTE    = 0;
  private readonly STEP_INDEX_AVEC_ARTICLE_ARTICLE     = 1;
  private readonly STEP_INDEX_AVEC_ARTICLE_TYPES       = 2;
  private readonly STEP_INDEX_AVEC_ARTICLE_TRAITEMENTS = 3;

  // private readonly STEP_INDEX_SANS_ARTICLE_CONTEXTE    = 0;
  private readonly STEP_INDEX_SANS_ARTICLE_TYPES       = 1;
  private readonly STEP_INDEX_SANS_ARTICLE_TRAITEMENTS = 2;

  changementEtape(event: StepperSelectionEvent): void {
    const stepAvecArticle = this.contexteSelectionne && this.contexteSelectionne.presenceArticle;

    const versAvant = event.previouslySelectedIndex < event.selectedIndex;

    if (stepAvecArticle && event.selectedIndex === this.STEP_INDEX_AVEC_ARTICLE_ARTICLE) {
      this.afficherArticleForm = true;
      if (versAvant) {
        this.loadListeLignesArticles();
        if (this.articleSelectionne && this.data.ligne && this.articleSelectionne === this.data.ligne.article) {
          this.articleForm.get('ligneArticle').setValue(this.data.ligne);
        }
      }
    } else if (stepAvecArticle && event.selectedIndex === this.STEP_INDEX_AVEC_ARTICLE_TYPES
      || !stepAvecArticle && event.selectedIndex === this.STEP_INDEX_SANS_ARTICLE_TYPES) {
      this.afficherTypesForm = true;
      if (!this.contexteSelectionne.presenceLivraison && versAvant && !this.typesForm.get('observations').value) {
        this.typesForm.get("observations").setValue(  'Livraison attendue le ' + this.dateFormatPipe.transform(this.commande.dateLivraisonSouhaitee));
      }
    } else if (stepAvecArticle && event.selectedIndex === this.STEP_INDEX_AVEC_ARTICLE_TRAITEMENTS
      || !stepAvecArticle && event.selectedIndex === this.STEP_INDEX_SANS_ARTICLE_TRAITEMENTS) {
      this.afficherTraitementsForm = true;
    }
  }

  private loadListeLignesArticles(): void {
    this.listeLignesArticles = [];

    let chargementLignesCommande: boolean;
    let chargementLignesBL: boolean;

    if (this.livraison === null) {
      chargementLignesCommande = true;
      chargementLignesBL = false;
    } else {
      chargementLignesCommande = this.contexteSelectionne.articleCommandePossible;
      chargementLignesBL = true;
    }

    const listeObservables: Observable<any>[] = [];

    if (chargementLignesCommande) {
      const search = new CommandeLigneSearch();
      search.fields = 'idCommandeLigne,article.codeArticleAchat,article.designationAchat,reference';
      listeObservables.push(this.commandeService.getListeLignes(this.commande.idCommandeEntete, search));
    }

    if (chargementLignesBL) {
      const fields = 'idEntreeSortieLigne,article.codeArticleAchat,article.designationAchat,referenceFournisseur,dlc';
      listeObservables.push(this.entreeSortieService.getListeLignes(this.livraison.idEntreeSortieEntete, fields));
    }

    if (listeObservables.length > 0) {
      forkJoin(listeObservables).subscribe({
        next: data => {
          const listeLignesCommande: CommandeLigne[] = chargementLignesCommande ? data[0] : null;
          const listeLignesBL: EntreeSortieLigne[] = chargementLignesCommande ? data[1] : data[0];

          if (listeLignesCommande) {
            listeLignesCommande.forEach(ligneCommande => {
              const ligneArticle = LigneArticleDTO.buildFromCommandeLigne(ligneCommande);
              this.listeLignesArticles.push(ligneArticle);
            });
          }

          if (listeLignesBL) {
            listeLignesBL.forEach(ligneBL => {
              const ligneArticle = LigneArticleDTO.buildFromEntreeSortieLigne(ligneBL);

              if (ligneArticle.dlcDluo) {
                this.listeLignesArticles.push(ligneArticle);
              } else {
                const ligneArticleExistante = this.listeLignesArticles.find(l => l.article.codeArticleAchat === ligneArticle.article.codeArticleAchat && !ligneArticle.dlcDluo);
                if (ligneArticleExistante === undefined) {
                  this.listeLignesArticles.push(ligneArticle);
                } else {
                  ligneArticleExistante.idEntreeSortieLigne = ligneArticle.idEntreeSortieLigne;
                }
              }
            });
          }
          this.listeLignesArticles.sort(SortHelper.compareArticleAchat);
        },
        error: err => {
          this.messageTool.sendError(err);
        }
      });
    }
  }

  private entreeSortieLigneSelectionnee(): EntreeSortieLigne|null {
    const ligneArticle: LigneArticleDTO = this.articleForm.get('ligneArticle').value;
    if (ligneArticle && ligneArticle.idEntreeSortieLigne) {
      const ligne = new EntreeSortieLigne();
      ligne.idEntreeSortieLigne = ligneArticle.idEntreeSortieLigne;
      return ligne;
    }
    return null;
  }

  private commandeLigneSelectionnee(): CommandeLigne|null {
    const ligneArticle: LigneArticleDTO = this.articleForm.get('ligneArticle').value;
    if (ligneArticle && ligneArticle.idCommandeLigne && !ligneArticle.idEntreeSortieLigne) {
      const ligne = new CommandeLigne();
      ligne.idCommandeLigne = ligneArticle.idCommandeLigne;
      return ligne;
    }
    return null;
  }

  creerFdnc() {
    const fdnc = new Fdnc();
    fdnc.commandeEntete     = this.commande;
    fdnc.entreeSortieEntete = this.livraison;

    fdnc.dateConstat = this.constatForm.get('dateConstat').value;
    fdnc.idContexte    = this.contexteSelectionne.idContexteFdnc;

    fdnc.listeTypesNonConformite   = (this.typesForm.get('types').value as TypeNonConformite[]).map(t => t.code);
    fdnc.observationsNonConformite = this.typesForm.get('observations').value;

    fdnc.listeTraitementsNonConformite = (this.traitementsForm.get('traitements').value as TraitementNonConformite[]).map(t => t.code);
    fdnc.idTempsTraitement             = (this.traitementsForm.get('temps').value as TempsTraitementFdnc)?.idTempsTraitementFdnc;
    fdnc.observationsTraitement        = this.traitementsForm.get('observations').value;

    fdnc.commandeLigne     = this.commandeLigneSelectionnee();
    fdnc.entreeSortieLigne = this.entreeSortieLigneSelectionnee();
    fdnc.numeroLotProduit  = this.articleForm.get('numeroLot').value;

    this.fdncService.postFdnc(fdnc).subscribe({
      next: data => {
        this.dialogRef.close();
        this.messageTool.sendSuccess("La FDNC a été créée avec succès", ClearMessages.TRUE);
        this.router.navigate(['fdnc', data.idFdnc]);
      },
      error: err => {
        this.messageTool.sendError(err)
      }
    });
  }
}

export class LigneArticleDTO {

  idCommandeLigne?: number;
  idEntreeSortieLigne?: number;
  article: Article;
  reference: string;
  dlcDluo?: Date;

  static buildFromCommandeLigne(ligne: CommandeLigne): LigneArticleDTO {
    const ligneArticleDto = new LigneArticleDTO();
    ligneArticleDto.idCommandeLigne = ligne.idCommandeLigne;
    ligneArticleDto.article         = ligne.article;
    ligneArticleDto.reference       = ligne.reference;
    ligneArticleDto.dlcDluo         = null;
    return ligneArticleDto;
  }

  static buildFromEntreeSortieLigne(ligne: EntreeSortieLigne): LigneArticleDTO {
    const ligneArticleDto = new LigneArticleDTO();
    ligneArticleDto.idEntreeSortieLigne = ligne.idEntreeSortieLigne;
    ligneArticleDto.article             = ligne.article;
    ligneArticleDto.reference           = ligne.referenceFournisseur;
    ligneArticleDto.dlcDluo             = ligne.dlc;
    return ligneArticleDto;
  }
}
