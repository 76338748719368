import {CommandeEntete} from "./CommandeEntete";

export class CommandeEntetePATCH {
  codeClientDemandeur: string;
  commentaire: string;

  constructor(commandeEntete: CommandeEntete) {
    this.codeClientDemandeur    = commandeEntete.codeClientDemandeur;
    this.commentaire            = commandeEntete.commentaire;
  }
}
