<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field *ngIf="afficherAchat">
      <mat-label>Prix HT</mat-label>
      <epona-number-comparison formControlName="comparaisonPrixHt" epoHighlightIfNotEmpty></epona-number-comparison>
      <aide matSuffix>
        Les articles retournés auront tous au moins un prix correspondant à la condition saisie
        (rappel : un article peut être dans plusieurs marchés et donc avoir plusieurs prix).
      </aide>
    </mat-form-field>

    <mat-form-field *ngIf="afficherAchat">
      <mat-label>Marché</mat-label>
      <epona-select-marche formControlName="marche"
                           defaultLabel="- Tous -"
                           epoHighlightIfNotEmpty></epona-select-marche>
      <aide matSuffix>
        Les articles retournés feront tous partis du marché sélectionné
        (rappel : un article peut être dans plusieurs marchés).
      </aide>
    </mat-form-field>

    <mat-form-field *ngIf="afficherAchat">
      <mat-label>Commandable au fournisseur</mat-label>
      <mat-select formControlName="commandableExterne" epoHighlightIfNotEmpty>
        <mat-option [value]="DEFAULT">- Tous -</mat-option>
        <mat-option [value]="-1">Non défini</mat-option>
        <mat-option [value]="0">Non</mat-option>
        <mat-option [value]="1">Oui</mat-option>
      </mat-select>
      <aide matSuffix>
        Un article est commandable au fournisseur si son paramétrage budgétaire a été validé.
      </aide>
    </mat-form-field>

    <mat-form-field *ngIf="afficherAchat">
      <mat-label>Stock géré comptablement</mat-label>
      <select-boolean formControlName="stockGere"
                            defaultLabel="- Tous -"
                            epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field *ngIf="afficherAchat">
      <mat-label>Stock géré opérationnellement</mat-label>
      <select-boolean formControlName="stockGereOperationnellement"
                      defaultLabel="- Tous -"
                      epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field *ngIf="afficherAchat">
      <mat-label>Origine</mat-label>
      <mat-select formControlName="origineAchat" epoHighlightIfNotEmpty>
        <mat-option [value]="DEFAULT">- Tous -</mat-option>
        <mat-option [value]="CodeOrigineAchat.BNA">BNA</mat-option>
        <mat-option [value]="CodeOrigineAchat.PUNCHOUT">Paniers</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="afficherAchat">
      <mat-label>Groupe</mat-label>
      <epona-select-groupe-articles formControlName="groupe"
                                    defaultLabel="- Tous -"
                                    [showUndefinedOption]="afficherAchat"
                                    undefinedLabel="- Non défini -"
                                    epoHighlightIfNotEmpty>
      </epona-select-groupe-articles>
    </mat-form-field>

    <mat-form-field *ngIf="afficherAchat">
      <mat-label>Sous-groupe</mat-label>
      <epona-select-sous-groupe-articles formControlName="sousGroupe"
                                         defaultLabel="- Tous -"
                                         [groupe]="form.get('groupe').value"
                                         epoHighlightIfNotEmpty>
      </epona-select-sous-groupe-articles>
    </mat-form-field>

    <mat-form-field *ngIf="afficherVente">
      <mat-label>Département</mat-label>
      <epona-select-departement formControlName="departement"
                                defaultLabel="- Tous -"
                                epoHighlightIfNotEmpty>
      </epona-select-departement>
    </mat-form-field>

    <mat-form-field *ngIf="afficherVente">
      <mat-label>Famille</mat-label>
      <epona-select-famille formControlName="famille"
                            defaultLabel="- Toutes -"
                            [departement]="form.get('departement').value"
                            epoHighlightIfNotEmpty>
      </epona-select-famille>
    </mat-form-field>

    <mat-form-field *ngIf="afficherVente">
      <mat-label>Sous-famille</mat-label>
      <epona-select-sous-famille formControlName="sousFamille"
                                 defaultLabel="- Toutes -"
                                 [famille]="form.get('famille').value"
                                 epoHighlightIfNotEmpty>
      </epona-select-sous-famille>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Code-article</mat-label>
      <input matInput formControlName="codeArticle" epoHighlightIfNotEmpty>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Désignation (contient)</mat-label>
      <input matInput formControlName="designation" epoHighlightIfNotEmpty>
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon>
        Rechercher
      </button>
    </div>

    <mat-expansion-panel #otherFiltersPanel *ngIf="afficherVente">
      <mat-expansion-panel-header>
        <mat-panel-description>
          {{ otherFiltersPanel.expanded ? 'Masquer' : 'Afficher' }}
          les autres filtres
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field>
        <mat-label>Actif</mat-label>
        <select-boolean formControlName="actifVente"
                              defaultLabel="- Tous -"
                              epoHighlightIfNotEmpty>
        </select-boolean>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Colisage</mat-label>
        <input matInput type="number" formControlName="colisage" epoHighlightIfNotEmpty>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Article avec DLC</mat-label>
        <select-boolean formControlName="articleDlc"
                              defaultLabel="- Tous -"
                              epoHighlightIfNotEmpty>
        </select-boolean>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Durée consommation</mat-label>
        <input matInput type="number" formControlName="delaiReceptionDlc" epoHighlightIfNotEmpty>
      </mat-form-field>
    </mat-expansion-panel>
  </mat-card>
</form>
