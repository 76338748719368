<mat-card>
  <mat-card-header>
    <mat-card-title>Paramétrage local</mat-card-title>
  </mat-card-header>

  <div class="table-actions">
    <button type="button"
            mat-raised-button color="primary"
            (click)="ajoutParametrage()"
            [disabled]="!possedeDroitParametrageLocal">
      <fa-icon icon="plus"></fa-icon> Ajouter…
    </button>
  </div>

  <table mat-table [dataSource]="dataSource"
         class="mat-elevation-z8"
         matSort matSortActive="batiment" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Liste des paramétrages locaux d'un fournisseur">

    <ng-container matColumnDef="batiment">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bâtiment</th>
      <td mat-cell *matCellDef="let element">{{element.lieu.nomRu}}</td>
    </ng-container>

    <ng-container matColumnDef="lieu">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Lieu livraison</th>
      <td mat-cell *matCellDef="let element">{{element.lieu.nomUd}}</td>
    </ng-container>

    <ng-container matColumnDef="codeClient">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header matTooltip="Code-client du lieu Crous chez le fournisseur">Code-client</th>
      <td mat-cell *matCellDef="let element">{{element.codeClient}}</td>
    </ng-container>

    <ng-container matColumnDef="mail">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header matTooltip="Email du fournisseur pour ce lieu">Email</th>
      <td mat-cell *matCellDef="let element">{{element.mail}}</td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Actions</th>
      <td mat-cell *matCellDef="let element">
        <button type="button"
                mat-button
                (click)="modifieParametrage(element)"
                matTooltip="Modifier le parametrage"
                [disabled]="!possedeDroitParametrageLocal">
          <fa-icon icon="edit" style="color: #007bff;"></fa-icon>
        </button>

        <button type="button"
                mat-button
                (click)="supprimeParametrage(element)"
                [disabled]="!possedeDroitParametrageLocal"
                matTooltip="Supprimer le parametrage">
          <fa-icon icon="trash" style="color: #007bff;"></fa-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    <!-- Ligne de pied de tableau en cas de liste vide -->
    <ng-container matColumnDef="noRecord">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
    </ng-container>

    <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
  </table>
</mat-card>
