import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {ArticleVEM} from "../../../model/vem-api/ArticleVEM";
import {Observable, Subject} from "rxjs";
import {VemService} from "../../../services/vem.service";
import {CacheService} from "../../../services/cache.service";
import {MessageTool} from "../../MessageTool";
import {map, startWith} from "rxjs/operators";
import {Tools} from "../../Tools";
import {MatAutocompleteActivatedEvent} from "@angular/material/autocomplete";
import {MatFormFieldControl} from "@angular/material/form-field";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {FocusMonitor} from "@angular/cdk/a11y";

@Component({
  selector: 'epona-autocomplete-article-vem',
  templateUrl: './autocomplete-article-vem.component.html',
  styleUrls: ['./autocomplete-article-vem.component.css'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompleteArticleVemComponent
    }
  ]
})
export class AutocompleteArticleVemComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<ArticleVEM> {
  @ViewChild('article') articleElement: ElementRef;

  private CACHE_ARTICLES_KEY: string = 'articles';

  articleCtrl: UntypedFormControl;

  listeArticles: Array<ArticleVEM> = [];
  filteredArticles: Observable<ArticleVEM[]>;

  // Valeurs :
  //    - undefined : pas de liste préchargée
  //    - null : liste préchargée mais pas encore le résultat (dans le cas où la liste est chargée via une requête)
  @Input() listeArticlesPreChargee: Array<ArticleVEM>;

  @Input() listeCodesArticleExclus: Array<number>;

  // MatFormFieldControl
  stateChanges = new Subject<void>();
  focused: boolean = false;
  // Ajout d'une classe qui permettra de faciliter l'application des styles
  controlType: string = 'article-autocomplete';
  propagateChange = (_: any) => { };
  onTouched = () => {};

  static nextId = 0;
  @HostBinding()
  id = `article-autocomplete-${AutocompleteArticleVemComponent.nextId++}`;

  @Input()
  set focus(value: boolean) {
    if (this.articleElement) {
      if (value ) {
        this.articleElement.nativeElement.focus();
      } else {
        this.articleElement.nativeElement.blur();
      }
    }
  }

  @Input()
  get value(): ArticleVEM {
    return this.articleCtrl.value;
  }
  set value(value: ArticleVEM) {
    this.articleCtrl.setValue(value);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  // Affichage du label en position flottant lorsque le champ a le focus
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;


  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.articleCtrl.disable() : this.articleCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  get empty(): boolean {
    return !this.articleCtrl.value;
  }

  // Affichage du champ en erreur et permet de gérer le mat-error
  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  constructor(private vemService: VemService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    // Affichage du champ avec un soulignement si le champ a le focus
    // origin values : mouse, keyboard, null, ...
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.articleCtrl = new UntypedFormControl(null);
  }

  ngOnInit() {
    this.getListeArticles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listeArticlesPreChargee'] && !changes['listeArticlesPreChargee'].firstChange) {
      this.getListeArticles();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  getListeArticles() {
    if (this.listeArticlesPreChargee === null) {
      return;
    }

    if (this.listeArticlesPreChargee) {
        this.setAndSortListeArticlesFromCache(this.listeArticlesPreChargee);

    // Si la liste d'articles a déjà été chargée
    } else if (this.cacheService.has(this.CACHE_ARTICLES_KEY)) {
      this.setAndSortListeArticlesFromCache();

    // Sinon récupération de la liste via l'API-VEM
    } else {
      const fields = 'codeArticle,designation,articleDlc,listeCodesEAN.codeEAN';
      this.vemService.getListeArticles(fields).subscribe(data => {
        this.cacheService.set(this.CACHE_ARTICLES_KEY, data);
        this.setAndSortListeArticlesFromCache();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeArticlesFromCache(listeArticlesForcee?: Array<ArticleVEM>) {
    if (listeArticlesForcee) {
      this.listeArticles = listeArticlesForcee;
    } else {
      this.listeArticles = this.cacheService.get(this.CACHE_ARTICLES_KEY);
    }

    if (this.listeCodesArticleExclus && this.listeCodesArticleExclus.length > 0) {
      // Conservation uniquement des articles non exclus
      this.listeArticles = this.listeArticles.filter((article: ArticleVEM) =>
        !this.listeCodesArticleExclus.find(codeArticle => codeArticle === article.codeArticle)
      );
    }

    this.tri(this.listeArticles);

    // Autocomplétion
    this.filteredArticles = this.articleCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => !value || typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterArticle(name) : this.listeArticles.slice())
      );
  }

  private filterArticle(name: string): ArticleVEM[] {
    // Le filtre ne tient pas compte des accents
    const filterValue = Tools.removeDiacritics(name.toLowerCase());

    let listeArticlesFilter: Array<ArticleVEM> = this.listeArticles.filter(article => {
      return Tools.removeDiacritics(article.designation.toLowerCase()).indexOf(filterValue) >= 0
          || article.codeArticle.toString().indexOf(filterValue) === 0
          || article.listeCodesEAN
            && article.listeCodesEAN.find(code => code.codeEAN.indexOf(filterValue) === 0);
    });

    this.tri(listeArticlesFilter);

    return listeArticlesFilter;
  }

  //tri d'un tableau ArticleVEM par désignation de l'article
  tri(listeArticles: Array<ArticleVEM>) {
    listeArticles.sort((a, b) => {
      if(a.designation === undefined) {
        a.designation = '';
        return 1;
      }

      return a.designation.localeCompare(b.designation);
    });
  }

  //ControlValueAccessor
  registerOnChange(fn: any): void {
    this.propagateChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.articleCtrl.setValue(obj);
  }

  optionSelected() {
    this.propagateChange(this.articleCtrl.value);
  }

  optionActivated(activatedEvent: MatAutocompleteActivatedEvent) {
    // Procédure qui est appélee lorsqu'une option est sélectionnée par le clavier
    if (activatedEvent && activatedEvent.option) {
      this.articleCtrl.setValue(activatedEvent.option.value);
      this.propagateChange(this.articleCtrl.value);
    }
  }

  // Touche relâchée : l'article vaut null car seul la sélection d'un article doit retourner un article
  // Envoi que si ce n'est pas un objet car l'événement touche relâchée est aussi appelé lors de la sélection d'une option par le clavier
  keyup() {
    if (!(this.articleCtrl.value instanceof Object)) {
      this.propagateChange(null);
    }
  }

  displayArticle(article?: ArticleVEM): string | undefined {
    if (article) {
      return article.designation ? article.designation : ''+article.codeArticle;
    } else {
      return undefined;
    }
  }


  //MatFormFieldControl
  onContainerClick(event: MouseEvent): void {
  }

  // Pour la propriété aria-describedby
  setDescribedByIds(ids: string[]): void {
  }

}
