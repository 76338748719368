import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpService} from "../http.service";
import {Observable} from "rxjs";
import {Etablissement} from "../../model/bns-api/etablissement";
import {environment} from "../../../environments/environment";
import {EtablissementSearch} from "../../model/bns-api/etablissement-search";

@Injectable({
  providedIn: 'root'
})
export class EtablissementService {

  constructor(private httpClient: HttpClient, private httpService: HttpService) {
  }

  getEtablissementsLite(): Observable<Etablissement[]> {
    const search = new EtablissementSearch();
    search.interne = true;
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<Etablissement[]>(environment.bnsApiUrl + '/etablissements-lite', {params});
  }
}
