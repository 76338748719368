<div class="table-actions">
  <button type="button"
          mat-raised-button color="primary"
          (click)="exporter.exportTable({nomFichier: 'suivi-envois.xlsx'})"
          [disabled]="dataSource.data.length === 0">
    <fa-icon icon="file-excel"></fa-icon> Exporter
  </button>
</div>

<table mat-table cnousTableExporter #exporter="cnousTableExporter" [columns]="COLUMNS" [dataSource]="dataSource"
       class="mat-elevation-z8 table-adaptive"
       aria-label="Suivi des envois">

  <ng-container matColumnDef="typeOperations">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['typeOperations'].tooltip">{{ COLUMNS['typeOperations'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.typeOperations?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['date'].tooltip">{{ COLUMNS['date'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ (element.dateEnvoi ? element.dateEnvoi : element.dateErreurEnvoi) | dateTimeFormat }}</td>
  </ng-container>

  <ng-container matColumnDef="piece">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['piece'].tooltip">{{ COLUMNS['piece'].label }}</th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>

  <ng-container matColumnDef="numeroEj">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['numeroEj'].tooltip">{{ COLUMNS['numeroEj'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.engagementJuridique.numeroEj }}</td>
  </ng-container>

  <ng-container matColumnDef="numeroLigne">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['numeroLigne'].tooltip">{{ COLUMNS['numeroLigne'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.engagementJuridiqueLigne?.numeroLigne }}</td>
  </ng-container>

  <ng-container matColumnDef="origine">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['origine'].tooltip">{{ COLUMNS['origine'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="['/bordereau-livraison', element.idEntreeSortieEntete]" (click)="close()" *ngIf="element.idEntreeSortieEntete">BL {{ element.idEntreeSortieEntete }}</a>
      <a [routerLink]="['/commande', commande.idCommandeEntete]" (click)="close()" *ngIf="!element.idEntreeSortieEntete">Commande {{ commande | commande }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="montant">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['montant'].tooltip">{{ COLUMNS['montant'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.montantHt | decimal2 }}</td>
  </ng-container>

  <ng-container matColumnDef="agent">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['agent'].tooltip">{{ COLUMNS['agent'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ (element.utilisateurEnvoi ? element.utilisateurEnvoi : element.utilisateurErreurEnvoi) }}</td>
  </ng-container>

  <ng-container matColumnDef="erreur">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['erreur'].tooltip">{{ COLUMNS['erreur'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <div class="erreur" *ngIf="element.messageErreurEnvoi">
        {{ element.messageErreurEnvoi }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="debug">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['debug'].label }}</th>
    <td mat-cell *matCellDef="let element;let i = dataIndex">
      <button type="button"
              mat-button
              matTooltip="Télécharger le fichier de débug"
              (click)="downloadFile(element)"><mat-icon>download</mat-icon></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  <!-- Ligne de pied de tableau en cas de liste vide -->
  <ng-container matColumnDef="noRecord">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
  </ng-container>

  <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
</table>
