import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LieuFilter} from "../../model/epona-ui/lieu-filter";
import {Lieu} from "../../model/epona-api/Lieu";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {MatSort} from "@angular/material/sort";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {LieuService} from "../../services/epona/lieu.service";
import {MessageTool} from "../../commons/MessageTool";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Tools} from "../../commons/Tools";
import {LieuSearch} from "../../model/epona-api/LieuSearch";
import {FormTools} from "../../commons/FormTools";
import {LieuQueryParams} from "../../model/epona-ui/LieuQueryParams";
import {DialogDataModificationLieu} from "../../model/epona-ui/DialogDataModificationLieu";
import {DialogAjoutModificationLieuComponent} from "../dialog-ajout-modification-lieu/dialog-ajout-modification-lieu.component";
import {LocalStorageHelper} from "../../helpers/local-storage-helper";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
  selector: 'epona-liste-lieux',
  templateUrl: './liste-lieux.component.html',
  styleUrls: ['./liste-lieux.component.css']
})
export class ListeLieuxComponent implements OnInit {

  private readonly FIELDS = 'idLieu,' +
    'idBatimentBns,' +
    'idLieuBns,' +
    'idCrous,' +
    'idUg,' + // nécessaire pour la colonne codeVem
    'idRu,' +
    'idUd,' +
    'nomRu,' +
    'nomUd,' +
    'adresse1,' +
    'adresse2,' +
    'codePostal,' +
    'ville,' +
    'masque,' +
    'priseEnCompteVentes,' +
    'lieuLivraison,' +
    'nomLieuLivraison,' +
    'serviceEmetteur,' +
    'destination,' +
    'serviceGestionnaire,' +
    'typeTva' +
    '';

  dataSource = new MatTableDataSource<Lieu>([]);
  selection = new SelectionModel<Lieu>(true, []);

  filter: LieuFilter;

  readonly COLUMNS: {[key: string]: TableColumn} = {
    checkBox:            new TableColumn({label: 'Sélection', hiddable: false, export: false}),
    idLieu:              new TableColumn({label: 'Id.',                  default: false, tooltip: 'Identifiant interne', exportFormat: 'integer'}),
    batiment:            new TableColumn({label: 'Bâtiment',  hiddable: false}),
    lieu:                new TableColumn({label: 'Lieu',      hiddable: false}),
    masque:              new TableColumn({label: 'Masqué'}),
    priseEnCompteVentes: new TableColumn({label: 'Ventes',               default: false, tooltip: 'Prise en compte des ventes'}),
    lieuLivraison:       new TableColumn({label: 'Livraisons',           default: false, tooltip: 'Lieu de livraison'}),
    nomLieuLivraison:    new TableColumn({label: 'Nom lieu livraison',   default: false}),
    serviceEmetteur:     new TableColumn({label: 'Service émetteur',     default: false, tooltip: 'Service émetteur par défaut'}),
    destination:         new TableColumn({label: 'Destination',          default: false, tooltip: 'Destination par défaut'}),
    serviceGestionnaire: new TableColumn({label: 'Service gestionnaire', default: false, tooltip: 'Service gestionnaire par défaut'}),
    typeTva:             new TableColumn({label: 'Type de TVA',          default: false, tooltip: 'Type de TVA par défaut'}),
    actions:             new TableColumn({label: 'Actions',   hiddable: false, export: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.LIEUX;

  displayedColumns: string[] = [];

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  constructor(private route: ActivatedRoute,
              private lieuService: LieuService,
              private messageTool: MessageTool,
              public dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.filter = new LieuFilter();
        this.filter.masque = false;
      }
      this.rechercher();
    });

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = ListeLieuxComponent.sortingDataAccessor;
  }

  private initFiltersFromURL(params: ParamMap) {
    this.filter = new LieuFilter();
    this.filter.masque              = params.get('affiche') ? !Tools.stringToBoolean(params.get('affiche')) : null;
    this.filter.priseEnCompteVentes = params.get('priseEnCompteVentes') ? Tools.stringToBoolean(params.get('priseEnCompteVentes')) : null;
    this.filter.lieuLivraison       = params.get('lieuLivraison') ? Tools.stringToBoolean(params.get('lieuLivraison')) : null;
    this.filter.parametrePourOrion  = params.get('parametrePourOrion') ? Tools.stringToBoolean(params.get('parametrePourOrion')) : null;
  }

  private rechercher(idLieu?: number) {
    this.dataSource.data = [];
    this.selection.clear();

    const search = LieuSearch.fabric(this.filter);
    search.idCrous = +localStorage.getItem('idCrous');
    search.lieuxAutorisesUniquement = true;
    search.fields = this.FIELDS;

    this.lieuService.getListeLieux(search).subscribe(data => {
      this.dataSource.data = data;
      this.setDisplayedColumns();
      this.selection.clear();

      if (idLieu) {
        const lieu = this.dataSource.data.find(l => l.idLieu === idLieu);
        if (lieu) {
          FormTools.markAsSuccess(lieu);
        }
      }

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  onFilterSubmitted(filter : LieuFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['liste-lieux'], {queryParams: LieuQueryParams.fabric(this.filter)});
  }

  openDialogAjout(): void {
    this.selection.clear();

    const search = new LieuSearch();
    search.idCrous = LocalStorageHelper.getIdEtablissement();
    search.fields = 'idLieu,idCrous,idBatimentBns,idLieuBns,idRu,idUd';

    this.lieuService.getListeLieux(search).subscribe(data => {
      let dialogConfig = new MatDialogConfig<DialogDataModificationLieu>();
      dialogConfig.data = new DialogDataModificationLieu();
      dialogConfig.data.listeLieuxExistants = data;
      dialogConfig.minWidth = '620px';

      const dialogRef = this.dialog.open(DialogAjoutModificationLieuComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(idLieu => {
        if (idLieu) {
          this.rechercher(idLieu);
        }
      });
    }, err => {
      this.messageTool.sendErrorMessage('Erreur lors de la récupération des lieux existants');
      this.messageTool.sendError(err);
    });
  }

  openDialogModificationUnique(lieu: Lieu): void {
    this.selection.clear();
    this.selection.select(lieu);
    this.openDialogModification([lieu]);
  }

  openDialogModificationMultiple(): void {
    this.openDialogModification(this.selection.selected);
  }

  private openDialogModification(listeLieux: Lieu[]): void {
    let dialogConfig = new MatDialogConfig<DialogDataModificationLieu>();
    dialogConfig.data = new DialogDataModificationLieu();
    dialogConfig.data.listeLieux = listeLieux;
    dialogConfig.minWidth = '620px';

    const dialogRef = this.dialog.open(DialogAjoutModificationLieuComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.dataSource._updateChangeSubscription();
        FormTools.markAsSuccess(listeLieux);
      }
    });
  }

  /* ******************** */
  /* Sélection des lignes */
  /* ******************** */

  // Si le nombre d'éléments sélectionnés correspond au nombre total de lignes
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // Sélectionne toutes les lignes si elles ne sont pas sélectionnées sinon déselectionne toutes les lignes
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  private static sortingDataAccessor(data: Lieu, sortHeaderId: string): string|number {
    switch(sortHeaderId) {
      case 'idLieu':              return data.idLieu;
      case 'batiment':            return SortHelper.stringToString(data.nomRu);
      case 'lieu':                return SortHelper.stringToString(data.nomUd);
      case 'masque':              return SortHelper.booleanToString(data.masque);
      case 'priseEnCompteVentes': return SortHelper.booleanToString(data.priseEnCompteVentes);
      case 'lieuLivraison':       return SortHelper.booleanToString(data.lieuLivraison);
      case 'serviceEmetteur':
      case 'destination':
      case 'serviceGestionnaire':
      case 'typeTva':
        return data[sortHeaderId] ? data[sortHeaderId].libelle : '';
    }
  }
}
