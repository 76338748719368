import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BnaFdncService} from "../../services/bna/bna-fdnc.service";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {DialogDataAjoutEchange} from "./dialog-data-ajout-echange";
import {EchangeFdnc} from "../../model/bna-api/echange-fdnc";
import {BnsService} from "../../services/bns/bns.service";

@Component({
  selector: 'app-dialog-ajout-echange',
  templateUrl: './dialog-ajout-echange.component.html',
  styleUrls: ['./dialog-ajout-echange.component.css']
})
export class DialogAjoutEchangeComponent implements OnInit {

  form: FormGroup;
  listeResultats: ResultatFdnc[] = [];

  constructor(private dialogRef: MatDialogRef<DialogAjoutEchangeComponent, EchangeFdnc|null>,
              private bnaFdncService: BnaFdncService,
              private bnsService: BnsService,
              private fb: FormBuilder,
              private messageTool: MessageTool,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataAjoutEchange) {
    this.buildListeResultats();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'resultat':    this.fb.control<ResultatFdnc|null>(null, Validators.required),
      'commentaire': this.fb.control<string|null>(null, [Validators.required, Validators.maxLength(5000)]),
    });
  }

  ajouterEchange(): void {
    const echange: EchangeFdnc = new EchangeFdnc();
    echange.resultat = this.form.get('resultat').value?.code;
    echange.commentaire = this.form.get('commentaire').value;

    this.bnaFdncService.postEchangeDeclarant(this.data.fdncBna.idFdnc, echange).subscribe({
      next: echangeDeclarant => {
        this.dialogRef.close(echangeDeclarant);
        this.messageTool.sendSuccess("L'échange a été ajouté avec succès", ClearMessages.TRUE);
      },
      error: err => {
        console.error(err);
        this.messageTool.sendErrorMessage('Erreur technique lors de l\'envoi de l\'échange dans la BNA');
      }
    });
  }

  buildListeResultats() {
    this.listeResultats = [];
    for (let i = 1 ; i <= 5; i++) {
      this.listeResultats.push(new ResultatFdnc(i, this.bnaFdncService.resultatToString(i)));
    }
  }
}

export class ResultatFdnc {
  code: number;
  libelle: string;

  constructor(code: number, libelle: string) {
    this.code = code;
    this.libelle = libelle;
  }
}
