import {Component, Inject, OnInit} from '@angular/core';
import {ServiceFaitEntete} from "../../model/epona-api/ServiceFaitEntete";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {DialogDataServiceFait} from "../../model/epona-ui/DialogDataServiceFait";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {DialogErrorsData} from "../../model/epona-ui/DialogErrorsData";
import {DialogErrorsComponent} from "../../commons/dialog-errors/dialog-errors.component";

@Component({
  selector: 'epona-dialog-service-fait',
  templateUrl: './dialog-service-fait.component.html',
  styleUrls: ['./dialog-service-fait.component.css']
})
export class DialogServiceFaitComponent implements OnInit {

  serviceFait: ServiceFaitEntete;
  serviceFaitEnvoye: boolean = false;

  private formEnteteDirty: boolean = false;
  private formLignesDirty: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogServiceFaitComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataServiceFait,
              public dialog: MatDialog,
              private messageTool: MessageTool,
              private entreeSortieService: EntreeSortieService) { }

  ngOnInit(): void {
    this.loadSF();
  }

  private loadSF(): void {
    if (this.data.livraison) {
      const fields = '';
      this.entreeSortieService.getServiceFaitEntete(this.data.livraison.idEntreeSortieEntete, null).subscribe(data => {
        this.serviceFait = data;
        this.serviceFaitEnvoye = !!data.numeroSf;
      }, error => {
        this.messageTool.sendError(error);
      });
    }
  }

  close() {
    if (this.formEnteteDirty || this.formLignesDirty) {
      let message = "<p>Certaines modifications n'ont pas été enregistrées, confirmez-vous la fermeture de cette fenêtre ?</p>";

      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation de fermeture",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: message
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  openDialogEnvoiSF() {
    // Si le formulaire d'entête contient des modifications non enregistrées
    if (this.formEnteteDirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées dans l\'entête de ce service fait avant de l\'envoyer vers Orion';
      this.messageTool.sendWarning(message);

    // Si le formulaire des lignes contient des modifications non enregistrées
    } else if (this.formLignesDirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées sur les lignes de ce service fait avant de l\'envoyer vers Orion';
      this.messageTool.sendWarning(message);

    // Sinon ouverture de la fenêtre de confirmation
    } else {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation d'envoi vers Orion",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: this.buildMessageConfirmation()
      };

      const dialogRefConf = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRefConf.afterClosed().subscribe(result => {
        if (result) {
          this.entreeSortieService.postEnvoiServiceFait(this.data.livraison.idEntreeSortieEntete).subscribe((warnings) => {
            if (warnings) {
              let dialogConfigErr = new MatDialogConfig<DialogErrorsData>();
              dialogConfigErr.data = new DialogErrorsData();
              dialogConfigErr.data.title = 'Erreurs non bloquantes retournées par Orion';
              dialogConfigErr.data.textClass = 'alert-warning';
              dialogConfigErr.data.text = '<p>La constatation de service fait a été envoyée vers Orion avec succès.</p>' +
                '<p>Cependant des erreurs ont été retournés par Orion au moment de la mise à jour de l\'engagement juridique, ' +
                'ce qui a pu empêcher la prise en compte de tout ou partie des frais de port :</p>';
              dialogConfigErr.data.errors = (warnings as any[]).map(error => {
                error.error_description = error.error_description.replace('Erreur fonctionnelle retournée par Orion : ', '');
                return error;
              });
              dialogConfigErr.maxWidth = '500px';
              this.dialog.open(DialogErrorsComponent, dialogConfigErr);

            } else {
              this.messageTool.sendSuccess(`La constatation de service fait a été envoyée vers Orion avec succès`, ClearMessages.TRUE);
            }
            this.dialogRef.close(true);

          }, err => {
            // Les erreurs Orion sont affichées différemment
            if (this.messageTool.areStandardErrors(err)
              && err.error.find(error => error.error_code === 'ORION_ERREUR_FONCTIONNELLE')) {

              console.error(err);

              let dialogConfigErr = new MatDialogConfig<DialogErrorsData>();
              dialogConfigErr.data = new DialogErrorsData();
              dialogConfigErr.data.title = 'Erreurs retournées par Orion';
              dialogConfigErr.data.text = 'Erreurs retournées par Orion au moment de l\'envoi de la constatation de service fait :';
              dialogConfigErr.data.errors = (err.error as any[]).map(error => {
                error.error_description = error.error_description.replace('Erreur fonctionnelle retournée par Orion : ', '');
                return error;
              });
              this.dialog.open(DialogErrorsComponent, dialogConfigErr);

            } else {
              this.messageTool.sendError(err);
            }
          });
        }
      });
    }
  }

  private buildMessageConfirmation(): string {
    let message = '<p class="question">Confirmez-vous l\'envoi de cette constation de service fait vers Orion&nbsp;?</p>';
    if (this.data.livraison.nbDocuments === 0) {
      message += '<p class="alert alert-warning">Aucun document n\'a été joint au bordereau de livraison ' +
        'donc cette constatation de service fait sera envoyée <strong>sans pièce jointe</strong> autre que la commande d\'origine.</p>';
    }
    return message;
  }

  openDialogSuppressionSF() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de suppression",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: '<p class="question">Confirmez-vous que cette constation de service fait a déjà été saisie dans Orion&nbsp;?</p>' +
        '<p>Le service fait d\'Épona sera supprimé et ne pourra plus être envoyé vers Orion.</p>'
    };

    const dialogRefConf = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRefConf.afterClosed().subscribe(result => {
      if (result) {
        this.entreeSortieService.postSuppressionServiceFait(this.data.livraison.idEntreeSortieEntete).subscribe(() => {
          this.messageTool.sendSuccess(`La constatation de service fait a été supprimée`, ClearMessages.TRUE);
          this.dialogRef.close(true);

        }, err => {
          this.messageTool.sendError(err);
        });
      }
    });
  }

  onFormEnteteIsDirty(dirty: boolean): void {
    this.formEnteteDirty = dirty;
  }
  onFormLignesIsDirty(dirty: boolean): void {
    this.formLignesDirty = dirty;
  }


}
