import {Component, OnDestroy, ViewChild} from '@angular/core';
import {Lieu} from "../../model/epona-api/Lieu";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {FormGroupDirective} from "@angular/forms";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {CanDeactivateComponent} from "../../intercepteurs/can-deactivate/can-deactivate.component";
import {EntreeSortieLigne} from "../../model/epona-api/EntreeSortieLigne";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {EntreeSortieEnteteComponent} from "../entree-sortie-entete/entree-sortie-entete.component";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {Tools} from "../../commons/Tools";
import {EntreeSortieSearch} from "../../model/epona-api/EntreeSortieSearch";
import {Observable, Subscription} from "rxjs";
import {EntreeSortieValidation} from "../../model/epona-ui/EntreeSortieValidation";
import {Fields} from "../../commons/constants/Fields";
import {FormTools} from "../../commons/FormTools";
import {Const} from "../../commons/constants/Const";

@Component({
  selector: 'app-entree-sortie',
  templateUrl: './entree-sortie.component.html',
  styleUrls: ['./entree-sortie.component.css']
})
export class EntreeSortieComponent extends CanDeactivateComponent implements OnDestroy {
  // Paramétrages spécifiques à la catégorie d'entrée/sortie
  params = new EntreeSortieParametrage({});
  idEntete: number;
  lieuCourant: Lieu;
  entete: EntreeSortieEntete;
  lignes: Array<EntreeSortieLigne>;
  entreeSortieLiee: EntreeSortieEntete;
  modificationLigneEnCours: boolean = false;

  droitModification: boolean;

  private readonly ONLY_META_DATA = true;

  formGroupDirective: FormGroupDirective;

  navigationSubscription: Subscription;

  // Permet d'accèder aux données du composant enfant afin de contrôler s'il y a eu des modifications si changement de page
  // ViewChild est une réquête dynamique : il sera toujours à jour du template !
  @ViewChild(EntreeSortieEnteteComponent)
  set element(element: EntreeSortieEnteteComponent) {
    this.formGroupDirective = element.form;
  }

  constructor(private entreeSortieService: EntreeSortieService,
              private messageTool: MessageTool,
              private router: Router,
              private route: ActivatedRoute) {
    super();

    if (this.route.snapshot.data.params) {
      this.params = this.route.snapshot.data.params;
    }

    // Pour recharger une page déjà chargée. Nécessaire pour la redirection vers un BL non validé depuis la popup de consulation d'un Bl non validé
    // https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });

    this.droitModification = this.entreeSortieService.droitModification(this.params);
  }

  init() {
    // Récupération du paramètre idEntete dans l'url.
    // Pas besoin d'utiliser l'observable paramMap car on n'a pas besoin que le ngOnInit soit rappelé
    this.idEntete = parseInt(this.route.snapshot.paramMap.get('id'));

    if (this.idEntete) {
      this.loadEntreeSortie();
    } else {
      this.lieuCourant = new Lieu();
      this.lieuCourant.initWithLocalStorage();
    }
  }

  ngOnDestroy() {
    // Pour éviter de continuer à exécuter la méthode init() sur chaque événement navigationEnd.
    // Si on ne le fait pas, la redirection vers n'importe quelle route appel la méthode init() !!!
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  loadEntreeSortie(onlyMetaData: boolean = false, ligneToHighlight: EntreeSortieLigne = null) {
    this.getEntete(onlyMetaData);
    this.getLignes(ligneToHighlight);
    if (!onlyMetaData) {
      this.getEntreeSortieLiee();
    }
  }

  getEntete(onlyMetaData: boolean = false) {
    this.entreeSortieService.getEntete(this.idEntete, Fields.ENTREE_SORTIE_ENTETE).subscribe(data => {
      // Si le type de mouvement de l'entête chargé ne correspond pas à un type de mouvement géré
      //  alors l'utilisateur est redirigé vers la liste
      if (!this.params.commonTypeMouvementList.includes(data.typeMouvement.codeTypeMouvement)) {
        this.messageTool.sendErrorMessage("Entrées/sortie inconnue");
        this.router.navigate([this.params.commonListPath]);
      }

      if (onlyMetaData) {
        this.entete.dateModification = data.dateModification;
        this.entete.utilisateurModification = data.utilisateurModification;
        this.entete.dateValidation = data.dateValidation;
        this.entete.utilisateurValidation = data.utilisateurValidation;
        this.entete.extra = data.extra;

      } else {
        this.entete = data;
        this.lieuCourant = data.lieu;
      }

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  getLignes(ligneToHighlight: EntreeSortieLigne = null) {
    this.entreeSortieService.getListeLignes(this.idEntete, Fields.ENTREE_SORTIE_LIGNES).subscribe(data => {
      this.lignes = data
      this.highLightLigne(ligneToHighlight);
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private lignesSimilaires(ligne1: EntreeSortieLigne, ligne2: EntreeSortieLigne): boolean {
    return ligne1.article.idArticle === ligne2.article.idArticle
      && Tools.datesAreEquals(ligne1.dlc, ligne2.dlc);
  }

  getEntreeSortieLiee() {
    // TODO : prise en compte des droits de consultation selon le type d'E/S liées
    if (this.params.commonFieldsTRFDisplayed || this.params.commonFieldsSORDisplayed) {
      this.getListeEntreesSortiesLiees().subscribe(data => {
        this.entreeSortieLiee = data.length > 0 ? data[0] : null;

      }, err => {
        this.entreeSortieLiee = null;
        this.messageTool.sendError(err);
      });
    }
  }

  private getListeEntreesSortiesLiees(): Observable<EntreeSortieEntete[]> {
    const search = new EntreeSortieSearch();
    search.idCrous = +localStorage.getItem('idCrous');
    search.idEntreeSortieOrigine = this.idEntete;
    search.fields = Fields.ENTREE_SORTIE_LIEE;

    return this.entreeSortieService.getListeEntetes(search);
  }

  // Récupération du composant enfant (entete) de l'entête à créer ou à modifier
  doHandleEntete(entete: EntreeSortieEntete) {
    if (this.idEntete) {
      this.doModificationEntete(entete);
    } else {
      this.doCreationEntete(entete);
    }
  }

  private doCreationEntete(entete: EntreeSortieEntete) {
    entete.idLogiciel = Const.ID_LOGICIEL_EPONA;

    this.entreeSortieService.postEntete(entete).subscribe(data => {
      this.messageTool.sendSuccess(this.params.editCreationSuccessMsg, ClearMessages.TRUE);
      this.resetFormStates();

      this.router.navigate([this.params.commonEditPath, data.idEntreeSortieEntete]);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private doModificationEntete(entete: EntreeSortieEntete) {
    entete.idEntreeSortieEntete = this.idEntete;

    this.entreeSortieService.putEntete(entete).subscribe(() => {
      this.getEntete();

      this.messageTool.sendSuccess(this.params.editModificationSuccessMsg, ClearMessages.TRUE);
      this.resetFormStates();

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  // Récupération du composant enfant (entête) si on doit faire la validation de l'inventaire
  doValidation(validation: EntreeSortieValidation) {
    if (validation.validation) {
      this.postValidation(validation.validationComplete);
    }
  }

  private postValidation(validationComplete: boolean) {
    this.entreeSortieService.postValidation(this.idEntete, validationComplete).subscribe(() => {
      this.getEntete();
      this.getEntreeSortieLiee();

      this.messageTool.sendSuccess(this.params.editValidationSuccessMsg, ClearMessages.TRUE);
      this.resetFormStates();

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  // Récupération du composant enfant (entête) si on doit faire la suppression de l'entrée-sortie
  doSuppression(doSuppression: boolean) {
    if (doSuppression) {
      this.entreeSortieService.delete(this.idEntete).subscribe(() => {
        this.messageTool.sendSuccess(this.params.editSuppressionSuccessMsg, ClearMessages.TRUE);
        this.resetFormStates();

        // Redirection vers la liste des entrées-sorties
        this.router.navigate([this.params.commonListPath]);

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  doServiceFaitUpdated(doUpdated: boolean) {
    if (doUpdated) {
      this.getEntete();
    }
  }

  doListeDocumentsUpdated(doUpdated: boolean) {
    if (doUpdated) {
      this.getEntete();
    }
  }

  // Récupération du composant enfant (lignes) qu'une ligne a été créée
  doHandleLigneInserted(ligne: EntreeSortieLigne) {
    this.loadEntreeSortie(this.ONLY_META_DATA, ligne);
  }

  // Récupération du composant enfant (ligne) que des lignes ont été ajoutées
  doHandleLignesInserted(lignesInserted: boolean) {
    this.loadEntreeSortie(this.ONLY_META_DATA);
  }

  // Récupération du composant enfant (lignes) qu'une ligne a été modifiée
  doHandleLigneUpdated(ligneToHighlight: EntreeSortieLigne) {
    this.getEntete(this.ONLY_META_DATA);
    this.highLightLigne(ligneToHighlight);
  }

  highLightLigne(ligneToHighlight: EntreeSortieLigne) {
    if (ligneToHighlight) {
      this.lignes.forEach(ligne => {
        if (this.lignesSimilaires(ligne, ligneToHighlight)) {
          FormTools.markAsSuccess(ligne);
        }
      });
    }
  }

  // Récupération du composant enfant (lignes) que des lignes ont été supprimées
  doHandleLignesDeleted(listeLignes: Array<EntreeSortieLigne>) {
    this.loadEntreeSortie();
  }

  doDebutModificationLigne() {
    this.modificationLigneEnCours = true;
  }
  doFinModificationLigne() {
    this.modificationLigneEnCours = false;
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  /* **************** */
  /* Gestion des FDNC */
  /* **************** */

  private readonly TAB_INDEX_FDNC = 1;
  displayFdnc = false;
  indexChange(index: number) {
    if (index === this.TAB_INDEX_FDNC) {
      this.displayFdnc = true;  // Pour que les FDNC ne soit chargées qu'à la sélection de l'onglet
    }
  }
}
