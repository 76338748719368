<mat-card [ngClass]="{'mat-card-router-link': !linkFromDialog}">
  <mat-card-header>
    <mat-card-title>{{label}}</mat-card-title>
  </mat-card-header>

  <table mat-table [dataSource]="dataSource"
         class="mat-elevation-z8"
         aria-label="Liste des bordereaux de livraison d'une commande">

    <ng-container matColumnDef="text">
      <td mat-cell *matCellDef="let data">
        No. {{data.idEntreeSortieEntete}}
        / {{data.codeBordereauLivraison}}
        / {{data.dateBordereauLivraison | dateFormat}}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let data" class="">
        <a *ngIf="!linkFromDialog"
           [routerLink]="['/bordereau-livraison', data.idEntreeSortieEntete]"
           matTooltip="Consulter le bordereau de livraison no. {{data.idEntreeSortieEntete}}">
          <mat-icon class="mat-icon-router-link">open_in_browser</mat-icon>
        </a>

        <button *ngIf="linkFromDialog" type="button"
                mat-button
                matTooltip="Consulter le bordereau de livraison no. {{data.idEntreeSortieEntete}}"
                (click)="openDialog(data)"><mat-icon>open_in_browser</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['text', 'action'];"></tr>
  </table>
</mat-card>
