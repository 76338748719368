import {AfterViewChecked, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {PanierEntete} from "../../model/epona-api/PanierEntete";
import {Lieu} from "../../model/epona-api/Lieu";
import {PanierService} from "../../services/epona/panier.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {CanDeactivateComponent} from "../../intercepteurs/can-deactivate/can-deactivate.component";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {Subscription} from "rxjs";
import {FormGroupDirective} from "@angular/forms";
import {PanierEnteteComponent} from "../panier-entete/panier-entete.component";
import {PanierLigne} from "../../model/epona-api/panier-ligne";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogDataCommandes} from "../../model/epona-ui/DialogDataCommandes";
import {DialogCommandesGenereesComponent} from "../dialog-commandes-generees/dialog-commandes-generees.component";

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.css']
})
export class PanierComponent extends CanDeactivateComponent implements AfterViewChecked {

  idPanier: number;
  lieuCourant: Lieu;
  entete: PanierEntete;
  droitModification: boolean;
  navigationSubscription: Subscription;
  formGroupDirective: FormGroupDirective;
  lignes: Array<PanierLigne>;
  modificationLigneEnCours: boolean = false;

  private readonly FIELDS_ENTETE = '' +
    'idPanierEntete,' +
    'lieuDemandeur.idLieu,' +
    'fournisseur.idFournisseur,' +
    'fournisseur.nom,' +
    'dateCreation,utilisateurCreation,' +
    'dateModification,utilisateurModification,' +
    'nbLignes,' +
    'commentaire,' +
    'extra.deletable,' +
    'extra.commandesGenerables' +
    '';

  private readonly FIEDLS_LIGNE = 'idPanierLigne,' +
    'numeroLigne,' +
    'codeArticle,' +
    'prixUnitaire,' +
    'quantiteParPrixUnitaire,' +
    'description,' +
    'descriptionCourte,' +
    'devise,' +
    'conditionnement,' +
    'quantite,' +
    'valeurHt,' +
    'dateMajPrix,' +
    'erreur,' +
    'delaisLivraisonEnJours,' +
    'classification,' +
    'tauxTaxe,' +
    'sousLotZg.codeMarcheOrion,' +
    'sousLotZg.numeroSousLot,' +
    'sousLotZg.typeSousLot,' +
    'article.articleAchat,' +
    'article.actifAchat,' +
    'article.codeArticleAchat,' +
    'article.designationAchat,' +
    'article.origineAchat,' +
    'article.codeEtatValidation,' +
    'article.commandableExterne,' +
    'article.commentaireParametrage,' +
    'article.uniteExploitation,' +
    'prixHtBna,' +
    'colisageBna,' +
    'estPrixRecent,' +
    'ageMaxPrix' +
    '';

  // Permet d'accèder aux données du composant enfant afin de contrôler s'il y a eu des modifications si changement de page
  // ViewChild est une réquête dynamique : il sera toujours à jour du template !
  @ViewChild(PanierEnteteComponent)
  set element(element: PanierEnteteComponent) {
    this.formGroupDirective = element.form;
  }

  constructor(private panierService: PanierService,
              private messageTool: MessageTool,
              private router: Router,
              private route: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              public dialog: MatDialog) {
    super();

    // Pour recharger une page déjà chargée. Nécessaire pour la redirection vers un BL non validé depuis la popup de consulation d'un Bl non validé
    // https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });

    this.droitModification = this.panierService.droitModification();
  }

  // Récupération du composant enfant (lignes) que des lignes ont été supprimées
  doHandleLignesDeleted(lignes: Array<PanierLigne>) {
    this.load();
  }




  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  getEntete() {
    this.panierService.getEntete(this.idPanier, this.FIELDS_ENTETE).subscribe(data => {
      this.entete = data;
      this.lieuCourant = data.lieuDemandeur;

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  load() {
    this.getEntete();
    this.getLignes();
  }

  getLignes() {
    this.panierService.getListeLignes(this.idPanier, this.FIEDLS_LIGNE).subscribe(data => {
      this.lignes = data;
      this.lignes.sort((a, b) => {
        return a.descriptionCourte.localeCompare(b.descriptionCourte);
      });

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private init() {
    this.idPanier = parseInt(this.route.snapshot.paramMap.get('id'));

    if (this.idPanier) {
      this.load();
    } else {
      this.lieuCourant = new Lieu();
      this.lieuCourant.initWithLocalStorage();
    }
  }

  ngOnDestroy() {
    // Pour éviter de continuer à exécuter la méthode init() sur chaque événement navigationEnd.
    // Si on ne le fait pas, la redirection vers n'importe quelle route appel la méthode init() !!!
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  // Pour résoudre l'erreur Expression has changed after it was checked. Previous value: 'undefined'. Current value: 'true'
  // lors de la désacativation des lignes lors de l'envoi d'un panier
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  doSuppression(doSuppression: boolean) {
    if (doSuppression) {
      this.panierService.deletePanier(this.idPanier).subscribe(() => {
        this.messageTool.sendSuccess("Le panier a été supprimé avec succès", ClearMessages.TRUE);
        this.resetFormStates();

        // Redirection vers la liste des paniers
        this.redirectionListePanier();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  doGenerationCommandes(doGeneration: boolean) {
    if (doGeneration) {
      const fields = 'idCommandeEntete,sousLotZg.codeMarcheOrion,sousLotZg.numeroSousLot,sousLotZg.typeSousLot';

      this.panierService.postGenerationCommandes(this.entete, fields).subscribe(data => {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.data = new DialogDataCommandes();
        dialogConfig.data.listeCommandes = data;
        this.dialog.open(DialogCommandesGenereesComponent, dialogConfig);

      }, err => {
        this.messageTool.sendError(err);
      })
    }
  }

  private redirectionListePanier() {
    this.router.navigate(['liste-paniers'], {
      queryParams: {
        idLieu: this.lieuCourant.idLieu
      }
    });
  }

  doActionEntete(entete: PanierEntete) {
    if (!this.idPanier) {
      this.doCreationEntete(entete);
    } else {
      this.doModificationEntete(entete);
    }
  }

  private doCreationEntete(entete: PanierEntete) {
    this.panierService.postEntete(entete).subscribe(data => {
      this.messageTool.sendSuccess("Le panier a été enregistré avec succès", ClearMessages.TRUE);
      this.resetFormStates();

      this.router.navigate(['panier', data.idPanierEntete]);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private doModificationEntete(entete: PanierEntete) {
    entete.idPanierEntete = this.idPanier;
    this.panierService.putEntete(entete).subscribe(data => {
      this.messageTool.sendSuccess("Le panier a été modifié avec succès", ClearMessages.TRUE);
      this.resetFormStates();

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private handleModification() {
    this.getEntete();
    this.messageTool.sendSuccess("Le panier a été modifié avec succès", ClearMessages.TRUE);
    this.resetFormStates();
  }

  doDebutModificationLigne() {
    this.modificationLigneEnCours = true;
  }
  doFinModificationLigne() {
    this.modificationLigneEnCours = false;
  }



}
