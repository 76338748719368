<epona-select-lieu [lieu]="lieuCourant" lieuLabel="Lieu de livraison"
                   (lieuSubmitted)="onLieuSubmitted($event)"
                   (lieuCorrected)="onLieuCorrected($event)"></epona-select-lieu>

<h1>Recherche des lots</h1>

<div class="crous-recherche">
  <epona-filtre-lots class="crous-recherche-filtre"
                      [filter]="filter" (filterSubmitted)="onFilterSubmitted($event)"></epona-filtre-lots>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-content>
        <div class="alert alert-warning" *ngIf="afficherAvertissement">
          La recherche de lot nécessite de renseigner un fournisseur, un marché ou un article ainsi qu'au moins un numéro de lot.
        </div>
      </mat-card-content>
    </mat-card>

    <epona-tableau-lots [colonnesAffichees]="colonneAffichees" [listeLots]="listeLots" *ngIf="!afficherAvertissement"></epona-tableau-lots>
  </div>
</div>
