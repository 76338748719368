import {Component, OnInit, ViewChild} from '@angular/core';
import {InventaireFilter} from '../../model/epona-ui/InventaireFilter';
import {InventaireSearch} from '../../model/epona-api/InventaireSearch';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MessageTool} from '../../commons/MessageTool';
import {InventaireService} from '../../services/epona/inventaire.service';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Lieu} from "../../model/epona-api/Lieu";
import {Tools} from "../../commons/Tools";
import {InventaireQueryParams} from "../../model/epona-ui/InventaireQueryParams";
import {InventairesFilterComponent} from "../inventaires-filter/inventaires-filter.component";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {OnlineService} from "../../services/online.service";
import {InventaireEntete} from "../../model/epona-api/InventaireEntete";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
  selector: 'app-liste-inventaires',
  templateUrl: './liste-inventaires.component.html',
  styleUrls: ['./liste-inventaires.component.css']
})
export class ListeInventairesComponent implements OnInit {
  dataSource = new MatTableDataSource<InventaireEntete>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    idInventaireEntete: new TableColumn({label: 'No.',          hiddable: false, exportFormat: 'integer'}),
    batiment:           new TableColumn({label: 'Bâtiment'}),
    lieu:               new TableColumn({label: 'Lieu'}),
    libelle:            new TableColumn({label: 'Libellé',      hiddable: false}),
    dateCloture:        new TableColumn({label: 'Date clôture', exportFormat: 'datetime'}),
    utilisateurCloture: new TableColumn({label: 'Clôturé par'}),
    actions:            new TableColumn({label: 'Actions',      export: false, hiddable: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.INVENTAIRES;

  displayedColumns: string[] = [];

  @ViewChild(InventairesFilterComponent)
  filterComponent: InventairesFilterComponent;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  lieuCourant: Lieu;
  filter: InventaireFilter;

  constructor(private inventaireService: InventaireService,
              private messageTool: MessageTool,
              private route: ActivatedRoute,
              private router: Router,
              public onlineService: OnlineService) {
  }

  ngOnInit() {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMAp pour que le ngOnInit soit rappelé si une recherche est lancé depuis la liste des ivnentaires
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.initDefaultFilters();
      }

      // Lancement de la recherche
      this.rechercher();
    });

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  // Initialisation des filtres à partie des paramètres de l'URL
  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre d'inventaires
    this.filter = new InventaireFilter();
    this.filter.annee = params.get('annee') ? +params.get('annee') : null;
    this.filter.mois = params.get('mois') ? +params.get('mois') : null;
    this.filter.cloture = params.get('cloture') ? Tools.stringToBoolean(params.get('cloture')) : null;
    this.filter.utilisateur = params.get('utilisateur');
    this.filter.libelle = params.get('libelle');
    this.filter.idInventaire = params.get('idInventaire') ? +params.get('idInventaire') : null;
  }

  // Initialisation des filtres à partir des valeurs par défaut
  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre d'inventaires
    this.filter = new InventaireFilter();
    this.filter.cloture = false;
  }

  rechercher() {
    this.dataSource.data = [];

    const search = this.buildSearchFromFilters();
    search.fields = 'idInventaireEntete,lieu.nomRu,lieu.nomUd,libelle,dateCloture,utilisateurCloture';

    this.inventaireService.getListeInventaires(search).subscribe(data => {
      this.dataSource.data = data;
      this.setDisplayedColumns();
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  private buildSearchFromFilters(): InventaireSearch {
    const search = new InventaireSearch();

    search.setIdsLieu(this.lieuCourant);

    search.idInventaire = this.filter.idInventaire;
    search.libelle = this.filter.libelle;
    search.utilisateur = this.filter.utilisateur;
    search.cloture = this.filter.cloture;
    search.annee = this.filter.annee;
    search.mois = this.filter.mois;

    return search;
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche d'inventaire pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: InventaireFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
      this.router.navigate(['liste-inventaires'], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): InventaireQueryParams {
    const params = new InventaireQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.idInventaire = this.filter.idInventaire;
    params.libelle      = this.filter.libelle;
    params.utilisateur  = this.filter.utilisateur;
    params.cloture      = this.filter.cloture;
    params.annee        = this.filter.annee;
    params.mois         = this.filter.mois;

    return params;
  }

  sortingDataAccessor(data: InventaireEntete, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'idInventaireEntete': return data.idInventaireEntete;
      case 'batiment'          : return SortHelper.stringToString(data.lieu?.nomRu);
      case 'lieu'              : return SortHelper.stringToString(data.lieu?.nomUd);
      case 'libelle'           : return SortHelper.stringToString(data.libelle);
      case 'dateCloture'       : return SortHelper.dateToNumber(data.dateCloture);
      case 'utilisateurCloture': return SortHelper.stringToString(data.utilisateurCloture);
      default                  : return data[sortHeaderId];
    }
  }
}
