import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ArticleService} from "../../services/epona/article.service";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {DialogDataModificationMultipleArticles} from "../../model/epona-ui/DialogDataModificationMultipleArticles";
import {concat, Observable, of} from "rxjs";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {catchError, finalize, tap, toArray} from "rxjs/operators";
import {ArticleVEM} from "../../model/vem-api/ArticleVEM";
import {Article} from "../../model/epona-api/Article";
import {TypeReferentielOrion} from "../../commons/constants/TypeReferentielOrion";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {UserService} from "../../services/user.service";
import {Const} from "../../commons/constants/Const";
import {ToastrService} from "ngx-toastr";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@Component({
  selector: 'app-dialog-modification-multiple',
  templateUrl: './dialog-modification-multiple.component.html',
  styleUrls: ['./dialog-modification-multiple.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class DialogModificationMultipleComponent {

  public readonly MAX_COLISAGE = 1000;
  public readonly MAX_DELAI_RECEPTION_DLC = 1000;
  public readonly DEFAULT = Const.DEFAULT;
  public readonly NON_DEFINI = -1;

  droitModification: boolean;
  articleEpona: Article;
  articleVem: ArticleVEM;
  form : UntypedFormGroup;
  estAjout: boolean = false;
  multiple: boolean = true;
  sauvegardeEnCours: boolean = false;
  readonly nbArticlesATraiter: number;
  nbArticlesTraites: number = 0;
  afficherAchat: boolean = true;
  afficherVente: boolean = true;
  articlesPunchOutUniquement: boolean = false;
  listeCodesArticleExistants: number[];

  TypeReferentielOrion = TypeReferentielOrion;
  UtilisationArticle = UtilisationArticle;

  private fields = ['familleMarches', 'sousGroupe', 'commandableExterne', 'commentaireParametrage', 'stockGere', 'stockGereOperationnellement', 'actifVente', 'colisage', 'articleDlc', 'delaiReceptionDlc'];

  constructor(private dialogRef: MatDialogRef<DialogModificationMultipleComponent>,
              private fb: UntypedFormBuilder,
              private articleService: ArticleService,
              private userService: UserService,
              private messageTool: MessageTool,
              private toastrService: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataModificationMultipleArticles) {

    this.dialogRef.disableClose = true;

    this.droitModification = this.userService.utilisateurCourant.possedeDroit(CodeDroit.GESTION_ARTICLES);

    this.estAjout = !this.data.listeArticles || this.data.listeArticles.length === 0;
    this.multiple = this.data.listeArticles && this.data.listeArticles.length > 1;

    this.nbArticlesATraiter = this.estAjout ? 1 : this.data.listeArticles.length;
    this.nbArticlesTraites = 0;

    this.afficherAchat = !this.data.utilisation || this.data.utilisation === UtilisationArticle.ACHAT;
    this.afficherVente = !this.data.utilisation || this.data.utilisation === UtilisationArticle.VENTE;

    this.articlesPunchOutUniquement = this.data.listeArticles && this.data.listeArticles.filter(article => article.origineAchat === 'PO').length === this.data.listeArticles.length;

    this.articleEpona = null;
    this.articleVem = null;

    this.form = fb.group({
      familleMarches:              fb.control({value: null, disabled: this.multiple}),
      sousGroupe:                  fb.control({value: null, disabled: this.multiple}),
      // si multiple commandableExterne = DEFAULT, NON_DEFINI, 0 et 1
      // sinon commandableExterne = null, false et true
      commandableExterne:          fb.control({value: this.multiple ? this.DEFAULT : this.NON_DEFINI, disabled: this.multiple}),
      commentaireParametrage:      fb.control({value: null, disabled: this.multiple}),
      stockGere:                   fb.control({value: null, disabled: this.multiple}, Validators.required),
      stockGereOperationnellement: fb.control({value: null, disabled: this.multiple}, Validators.required),

      articleVem:                  fb.control(null, this.afficherVente && this.estAjout ? Validators.required : null),
      actifVente:                  fb.control({value: null, disabled: this.multiple}, this.afficherVente ? Validators.required : null),
      colisage:                    fb.control({value: null, disabled: this.multiple}, [Validators.min(1), Validators.max(this.MAX_COLISAGE)]),
      articleDlc:                  fb.control({value: null, disabled: this.multiple}, this.afficherVente ? Validators.required : null),
      delaiReceptionDlc:           fb.control({value: null, disabled: this.multiple}, [Validators.min(1), Validators.max(this.MAX_DELAI_RECEPTION_DLC)]),
    });

    // Contrôles indiquant si le champ associé doit être pris en compte lors de l'enregistrement
    //  masqués et toujours vrais s'il ne s'agit pas d'une modification multiple
    //  affichés et décochés par défaut s'il s'agit d'une modification multiple
    for (const name of this.fields) {
      if (name !== 'articleVem') {
        this.form.addControl(name + 'Check', fb.control(!this.multiple));
      }
    }

    this.initValues();
    this.initFormChanges();
  }

  private initValues(): void {
    if (this.estAjout) {
      this.form.get('actifVente').setValue(true);
      this.form.get('articleDlc').setValue(false);

      // Champs cachés
      this.form.get('commandableExterne').setValue(false);
      this.form.get('stockGere').setValue(true);
      this.form.get('stockGereOperationnellement').setValue(true);

      if (this.data.listeArticlesExistants) {
        this.listeCodesArticleExistants = new Array<number>();
        for (const article of this.data.listeArticlesExistants) {
          this.listeCodesArticleExistants.push(article.codeArticleVente);
        }
      }

    } else {
      const first = this.data.listeArticles[0];

      if (!this.multiple) {
        if (this.afficherVente && this.estAjout) {
          this.articleVem = new ArticleVEM();
          this.articleVem.codeArticle = first.codeArticleVente;
          this.articleVem.designation = first.designationVente;
          this.form.get('articleVem').setValue(this.articleVem);
        } else {
          this.articleEpona = first;
        }
      }

      for (const name of this.fields) {
        if (name === 'familleMarches') {
          if (first.familleMarches && this.data.listeArticles.filter(article => {
            return !article.familleMarches && first.familleMarches
              || article.familleMarches && !first.familleMarches
              || article.familleMarches.idFamilleMarches !== first.familleMarches.idFamilleMarches
          }).length === 0) {
            this.form.get(name).setValue(first.familleMarches);
          }

        } else if (name === 'sousGroupe') {
          if (first.sousGroupe && this.data.listeArticles.filter(article => {
            return !article.sousGroupe && first.sousGroupe
              || article.sousGroupe && !first.sousGroupe
              || article.sousGroupe.idSousGroupeArticles !== first.sousGroupe.idSousGroupeArticles
          }).length === 0) {
            this.form.get(name).setValue(first.sousGroupe);
          }

        } else if (name === 'commandableExterne') {
          if (this.data.listeArticles.filter(article => !DialogModificationMultipleComponent.identique(article[name], first[name])).length === 0) {
            this.form.get(name).setValue(this.commandableExterneBooleanToCtrl(first.commandableExterne));
          }

        } else if (name === 'stockGereOperationnellement') {
          if (this.data.listeArticles.filter(article => !DialogModificationMultipleComponent.identique(article[name], first[name])).length === 0) {
            this.form.get(name).setValue(first.stockGereOperationnellement);
          }
          this.updateStateOfStockGereOperationnellement();

        } else if (name === 'colisage') {
          if (this.data.listeArticles.filter(article => article.pcb !== first.pcb).length === 0) {
            this.form.get(name).setValue(first.pcb);
          }

        } else {
          if (this.data.listeArticles.filter(article => !DialogModificationMultipleComponent.identique(article[name], first[name])).length === 0) {
            this.form.get(name).setValue(first[name]);
          }
        }
      }
    }
  }

  private static identique(v1, v2): boolean {
    if (v1 === undefined || v1 === null) {
      return v2 === undefined || v2 === null;
    }
    if (typeof v1 === 'object' && typeof v2 === 'object') {
      if ('numero' in v1 && 'numero' in v2) {
        return v1.numero === v2.numero;
      } else if ('code' in v1 && 'code' in v2) {
        return v1.code === v2.code;
      }
    }
    return v1 === v2;
  }

  private initFormChanges(): void {
    // Si l'utilisateur n'a pas le droit de modification alors tous les champs sont désactivés
    if (!this.droitModification) {
      this.form.disable();
      return;
    }

    if (this.estAjout) {
      this.form.get('articleVem').valueChanges.subscribe(articleVem => {
        this.articleVem = articleVem;
      });
    }

    if (this.multiple) {
      for (const name of this.fields) {
        this.form.get(name + 'Check').valueChanges.subscribe(checked => {
          if (checked) {
            if (name === 'actifVente' || name === 'articleDlc') {
              this.form.get(name).setValidators(Validators.required);
            }
            this.form.get(name).enable();
          } else {
            if (name === 'actifVente' || name === 'articleDlc') {
              this.form.get(name).clearValidators();
            }
            this.form.get(name).disable();
          }
        });
      }
    }

    this.form.get('stockGere').valueChanges.subscribe(() => {
      this.updateStateOfStockGereOperationnellement();
    });

    // Si seulement des articles PunchOut sont modifiés
    if (this.articlesPunchOutUniquement) {
      // Si une famille de marchés est déjà renseignée, elle ne peut être modifiée
      if (this.multiple || this.form.get('familleMarches').value) {
        this.form.get('familleMarchesCheck').disable();
        this.form.get('familleMarches').disable()
      }
      // Si un sous-groupe est déjà renseigné, ile ne peut être modifiée
      if (this.multiple || this.form.get('sousGroupe').value) {
        this.form.get('sousGroupeCheck').disable();
        this.form.get('sousGroupe').disable()
      }

    // Sinon (au moins un article BNA) les champs Famille de marchés et Sous-groupe ne sont pas modifiables
    } else if (this.afficherAchat) {
      this.form.get('familleMarchesCheck').disable();
      this.form.get('sousGroupeCheck').disable();
      this.form.get('familleMarches').disable();
      this.form.get('sousGroupe').disable();
    }
  }

  annuler() {
    if (!this.sauvegardeEnCours) {
      this.dialogRef.close(null);
    }
  }

  sauvegarder() {
    this.marquerDebutSauvegarde();

    if (this.estAjout) {
      const articleExistant = this.data.listeArticlesExistants.find(a => a.codeArticleVente === this.articleVem.codeArticle);

      if (articleExistant) {
        this.data.listeArticles = [articleExistant];
        this.messageTool.sendErrorMessage(`Cet article existe déjà dans Épona (identifiant interne : ${articleExistant.idArticle})`);
        this.marquerFinSauvegarde();
        return;
      } else {
        const article = new Article();
        article.codeArticleVente = this.articleVem.codeArticle;
        article.idCrous = +localStorage.getItem('idCrous');
        this.data.listeArticles = [article];
      }
    }

    const listeObservables = new Array<Observable<any>>();

    for (const article of this.data.listeArticles) {
      for (const name of this.fields) {
        if (this.form.get(name + 'Check').value) {
          if (name === 'colisage') {
            article.pcb = this.form.get('colisage').value;
          } else if (name === 'commandableExterne') {
            article[name] = this.commandableExterneCtrlToBoolean(this.form.get(name).value, article[name]);
          } else {
            article[name] = this.form.get(name).value;
          }
        }
      }

      if (this.estAjout) {
        listeObservables.push(this.articleService.postArticle(article, UtilisationArticle.VENTE));
      } else {
        let utilisation: string = null;
        if (!this.afficherAchat || !this.afficherVente) {
          utilisation = this.afficherAchat ? UtilisationArticle.ACHAT : UtilisationArticle.VENTE;
        }
        listeObservables.push(this.articleService.putArticle(article, utilisation)
          .pipe(
            tap(() => this.nbArticlesTraites++),
            catchError(err => { // L'erreur sera catchée et retournée afin de pouvoir être incluse dans le traitement du résultat global
              this.nbArticlesTraites++;
              return of({article: article, error: err});
            })
          )
        );
      }
    }

    if (this.estAjout) {
      listeObservables[0].pipe(
        finalize(() => {
          this.marquerFinSauvegarde();
        })
      ).subscribe(res => {
        this.messageTool.sendSuccess("L'article a été ajouté avec succès", ClearMessages.TRUE);
        this.dialogRef.close(res.idArticle);
      }, err => {
        this.messageTool.sendError(err);
      });

    } else {
      concat(...listeObservables).pipe(
        finalize(() => {
          this.marquerFinSauvegarde();
        })
      ).pipe(
        toArray() // afin de n'avoir qu'un seul next en résultat du subscribe
      ).subscribe({
        next: res => {
          this.handleModificationResult(res);
        },
        error: err => {
          this.messageTool.sendError(err);
        }
      });
    }
  }

  private marquerDebutSauvegarde(): void {
    this.sauvegardeEnCours = true;
    // this.form.disable(); // TODO : Le formulaire ne peut être désactivé car sinon en cas de modification multiple le compte comptable enregistré est null
  }

  private marquerFinSauvegarde(): void {
    this.sauvegardeEnCours = false;
    // this.form.enable();
  }

  private handleModificationResult(resultList: any[]): void {
    const nbSucces = resultList.filter(res => res === null).length;
    const nbEchecs = resultList.length - nbSucces;

    // Aucune erreur
    if (nbEchecs === 0) {
      this.messageTool.sendSuccess(this.multiple ? `Les ${this.nbArticlesATraiter} articles ont été enregistrés avec succès` : "L'article a été enregistré avec succès");
      this.dialogRef.close(true);

    // Au moins une erreur
    } else {
      // Affichage et log des erreurs
      resultList.filter(res => res !== null).forEach(res => {
        for (let error of res.error.error) {
          let description = error.error_description;
          // Utilisation du toastrService pour pouvoir forcer tapToDismiss à false
          this.toastrService.error(`[Article ${res.article.codeArticleAchat}] ${description}`, '', {
            disableTimeOut: true,
            closeButton: true,
            tapToDismiss: false
          });
        }
        console.error(res);
      });

      // Affichage du bilan de la sauvegarde
      if (nbSucces === 0) {
        this.messageTool.sendErrorMessage(this.multiple ? `L'enregistrement des ${this.nbArticlesATraiter} articles a échoué` : "L'enregistrement de l'article a échoué");
      } else {
        this.messageTool.sendWarning(`L'enregistrement a échoué pour ${nbEchecs} des ${this.nbArticlesATraiter} articles`);
      }
    }
  }

  private commandableExterneCtrlToBoolean(ctrlValue, initialValue): boolean {
    if (this.multiple) {
      if (ctrlValue === this.NON_DEFINI) {
        return null;
      } else if (ctrlValue === 0) {
        return false;
      } else if (ctrlValue === 1) {
        return true;
      } else {
        return initialValue;
      }
    } else {
      return ctrlValue;
    }
  }

  private commandableExterneBooleanToCtrl(articleValue): boolean|number {
    if (this.multiple) {
      if (articleValue === false) {
        return 0;
      } else if (articleValue === true) {
        return 1;
      } else {
        return this.NON_DEFINI;
      }
    } else {
      return articleValue;
    }
  }

  updateStateOfStockGereOperationnellement() {
    const stockGereComptablement = this.form.get('stockGere').value;

    const opeCheckCtrl = this.form.get('stockGereOperationnellementCheck');
    const opeCtrl      = this.form.get('stockGereOperationnellement');

    // En cas de stock géré comptablement, le stock est obligatoirement géré opérationnellement
    if (stockGereComptablement === true) {
      opeCheckCtrl.setValue(true);
      opeCheckCtrl.disable();
      opeCtrl.setValue(true);
      opeCtrl.disable();

    // En cas de stock non géré comptablement le stock peut être géré ou non opérationnellement
    } else if (stockGereComptablement === false) {
      opeCheckCtrl.enable();
      if (!this.multiple) {
        opeCtrl.enable();
      }

    // Si aucune valeur sélectionnée, la gestion opérationnelle du stock ne peut être paramétré
    } else {
      opeCheckCtrl.setValue(false);
      opeCheckCtrl.disable();
      opeCtrl.setValue(null);
      opeCtrl.disable();
    }

    this.form.updateValueAndValidity();
  }
}
