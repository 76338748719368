import {Fournisseur} from '../epona-api/Fournisseur';
import {Marche} from '../epona-api/Marche';
import {Article} from '../epona-api/Article';
import {StatutFdnc} from '../bna-api/statut-fdnc';
import {EtapeFdnc} from '../bna-api/etape-fdnc';

export class FiltreFdnc {
  fournisseur: Fournisseur | null = null;
  marche: Marche | null = null;
  article: Article | null = null;
  gravite: boolean = null;
  statuts: StatutFdnc[] = [];
  etapesEnCours: EtapeFdnc[] = [];
}


