<form class="form" (ngSubmit)="onSubmit()" [formGroup]="form" style="margin-top: 1em;">
  <div class="mat-form-field-row">
    <mat-form-field>
      <mat-label>Lot-marché</mat-label>
      <input matInput value="{{ entete?.codeMarche }}" disabled="disabled" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date service fait</mat-label>
      <input matInput
             [matDatepicker]="picker"
             formControlName="dateServiceFait">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="mat-form-field-row">
    <mat-form-field>
      <mat-label>Service constatation</mat-label>
      <epona-select-referentiel-orion formControlName="serviceConstatation"
                                      [type]="TypeReferentielOrion.SERVICE_EMETTEUR"></epona-select-referentiel-orion>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Libellé</mat-label>
      <textarea matInput cdkTextareaAutosize
                formControlName="libelle"
                required="required"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                maxlength="50">
      </textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Observation</mat-label>
      <textarea matInput cdkTextareaAutosize
                formControlName="observation"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                maxlength="512">
      </textarea>
    </mat-form-field>
  </div>

  <div class="mat-form-field-row">
    <mat-form-field>
      <mat-label>Exercice budgétaire</mat-label>
      <input matInput value="{{ entete?.exerciceBudgetaire }}" disabled="disabled" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Statut</mat-label>
      <input matInput value="{{ entete?.statut.libelle }}" disabled="disabled" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Agent constatation</mat-label>
      <input matInput value="{{ entete?.codeAgentConstatation }}" disabled="disabled" />
    </mat-form-field>
  </div>

  <div class="form-actions">
    <button type="submit"
            mat-raised-button color="primary"
            [disabled]="form.invalid" *ngIf="modifiable">
      <fa-icon icon="save"></fa-icon> Enregistrer
    </button>
  </div>
</form>
