import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-revision-tarifaire',
  templateUrl: './dialog-revision-tarifaire.component.html',
  styleUrls: ['./dialog-revision-tarifaire.component.css']
})
export class DialogRevisionTarifaireComponent {

  constructor(public dialogRef: MatDialogRef<DialogRevisionTarifaireComponent>) {
  }

  close() {
    this.dialogRef.close();
  }

}
