<mat-card class="card-width tdb-matcard">
  <mat-card-header class="card-title">
    <mat-card-title>Bordereaux de livraison à traiter</mat-card-title>

    <a aria-label="Liste des bordereaux de livraison"
       title="Liste des bordereaux de livraison"
       class="envoi-link"
       routerLink="/liste-bordereaux-livraison">
      <mat-icon>open_in_new</mat-icon>
    </a>
  </mat-card-header>

  <table mat-table class="mat-elevation-z8 tdb-table" [dataSource]="dataSource"
         matSort matSortActive="batiment" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Nombres de bordereaux de livraison à traiter par lieu">

    <ng-container matColumnDef="batiment">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bâtiment</th>
      <td mat-cell *matCellDef="let element">{{element.lieu?.nomRu}}</td>
    </ng-container>

    <ng-container matColumnDef="lieu">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Lieu</th>
      <td mat-cell *matCellDef="let element">{{element.lieu?.nomUd}}</td>
    </ng-container>

    <ng-container matColumnDef="nombreNonValides">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Nombre de bordereaux de livraison non validés" mat-sort-header>Non validés</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['/liste-bordereaux-livraison']" [queryParams]="{idLieu: element.lieu.idLieu, codesStatut: ['NV']}">{{element.detail[0].nombreNonValide}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nombreValidesSansSf">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Nombre de bordereaux de livraison validés sans SF" mat-sort-header>Attente SF</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['/liste-bordereaux-livraison']" [queryParams]="{idLieu: element.lieu.idLieu, codesStatut: ['VSSF']}">{{element.detail[0].nombreValideSansSf}}</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator class="tdb-table" #paginator [pageSize]="3" hidePageSize></mat-paginator>
  <!--  decommente si besoin [pageSizeOptions]="[3, 5, 10]">-->
</mat-card>
