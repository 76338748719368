import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'signed'
})
export class SignedNumberPipe implements PipeTransform {
  transform(value: number) {
    return value > 0 ? '+' + value : value;
  }
}
