<mat-card class="login-card">
  <mat-card-content>
      <mat-tab-group [selectedIndex]="selectedIndex" [mat-stretch-tabs]="false">
        <mat-tab label="EPPN">
          <form [formGroup]="formEppn" (ngSubmit)="onSubmitEppn()" class="tab-eppn">
            <mat-form-field>
              <mat-label>EPPN</mat-label>
              <input matInput formControlName="eppn" required />
              <mat-error *ngIf="formEppn.get('eppn').hasError('required')">Veuillez renseigner votre EPPN</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Mot de passe</mat-label>
              <input type="password" matInput formControlName="pwd" />
            </mat-form-field>

            <button mat-raised-button color="primary" [disabled]="formEppn.invalid">Se connecter</button>
          </form>
        </mat-tab>

        <mat-tab label="Json">
          <form [formGroup]="formJson" (ngSubmit)="onSubmitJson()" class="tab-json">
            <mat-form-field>
              <mat-label>Json utilisateur</mat-label>
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="4"
                        cdkAutosizeMaxRows="10"
                        formControlName="token"
                        required></textarea>
              <mat-hint>Ex.&nbsp;: {{ '{"env":"DEV", "appId":"martin@cnous.fr", "etab":1,"roles":"1,2", "bat:"4,5"}' }}</mat-hint>
              <mat-error *ngIf="formJson.get('token').hasError('required')">Veuillez renseigner le Json</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Mot de passe</mat-label>
              <input type="password" matInput formControlName="pwd" />
            </mat-form-field>

            <button mat-raised-button color="primary" [disabled]="formJson.invalid">Se connecter</button>
          </form>
        </mat-tab>

        <mat-tab label="Utilisateurs-tests">
          <table class="utilisateurs-tests" aria-label="Liste des utilisateurs de tests de l'application">
            <thead>
            <th>EPPN</th>
            <th>Fonction</th>
            <th>Action</th>
            </thead>
            <tbody>
            <tr *ngFor="let utilisateur of listeUtilisateursTests">
              <td>{{ utilisateur.eppn }}</td>
              <td>{{ utilisateur.fonction }}</td>
              <td><button mat-button
                          (click)="onSubmitUtilisateurTest(utilisateur)"
                          matTooltip="Se connecter en tant que {{ utilisateur.eppn }}">Se connecter</button></td>
            </tr>
            </tbody>
          </table>
        </mat-tab>

      </mat-tab-group>
  </mat-card-content>
</mat-card>
