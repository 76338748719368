import { Pipe, PipeTransform } from '@angular/core';
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";

@Pipe({
  name: 'commande'
})
export class CommandePipe implements PipeTransform {

  transform(commande: CommandeEntete): string {
    return CommandePipe.buildNumero(commande);
  }

  static buildNumero(commande: CommandeEntete): string {
    if (commande) {
      if (commande.numeroEj) {
        return commande.numeroEj
      } else {
        return '' + commande.idCommandeEntete;
      }
    } else {
      return '';
    }
  }
}
