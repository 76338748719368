import {EtatCommande} from "../EtatCommande";
import {Fournisseur} from "../epona-api/Fournisseur";
import {Marche} from "../epona-api/Marche";

export class CommandeFilter {
  numeroCommande: string;
  dateLivraison: Date;
  etats: EtatCommande[];
  externe: boolean;
  fournisseur: Fournisseur;
  marche: Marche;
}
