import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {UserService} from '../services/user.service';
import {Utilisateur} from '../model/Utilisateur';
import {DialogConfirmComponent} from "../commons/dialog-confirm/dialog-confirm.component";
import {environment} from "../../environments/environment";
import {CodeDroit} from "../commons/constants/CodeDroit";
import {TopbarMenu} from "../commons/enum/TopbarMenu";
import {TopbarSubmenu} from "../commons/enum/TopbarSubmenu";
import {OnlineService} from "../services/online.service";

@Component({
  selector: 'epona-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit, OnDestroy {

  private static activatedMenu: TopbarMenu;
  private static activatedSubmenu: TopbarSubmenu;

  readonly MENUS = TopbarMenu;
  readonly SUBMENUS = TopbarSubmenu;
  readonly CD = CodeDroit;

  user: Utilisateur;
  userEventsSubscription: Subscription;
  environment: any;
  nomCrous: string;


  constructor(private router: Router,
              private fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private userService: UserService,
              public onlineService: OnlineService) {
    this.environment = environment;
    this.nomCrous = localStorage.getItem('nomCrous');
  }

  ngOnInit() {
    this.userEventsSubscription = this.userService.userEvents.subscribe(user => {
      this.user = user ? user : null;
    });
  }

  ngOnDestroy(): void {
    if (this.userEventsSubscription) {
      this.userEventsSubscription.unsubscribe();
    }
  }

  static setActivatedMenu(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    this.activatedMenu = activatedRouteSnapshot.data.menu !== undefined ? activatedRouteSnapshot.data.menu : null;
    this.activatedSubmenu = activatedRouteSnapshot.data.submenu !== undefined ? activatedRouteSnapshot.data.submenu : null;
  }

  isActivatedM(menu: TopbarMenu) {
      return menu === TopbarComponent.activatedMenu;
  }

  isActivatedSM(menu: TopbarSubmenu) {
    return menu === TopbarComponent.activatedSubmenu;
  }

  possedeUnDroit(codesDroit: string | string[]): boolean {
    if (typeof codesDroit === 'string') {
      return this.user.possedeDroit(codesDroit);
    } else {
      for (const code of codesDroit) {
        if (this.user.possedeDroit(code)) {
          return true;
        }
      }
      return false;
    }
  }

  logout() {
    this.userService.logOut();
  }

  openDialogDeconnexion(): void {
    let dialogConfig = new MatDialogConfig();
    let body = '<p class="question">Êtes-vous sûr de vouloir vous déconnecter&nbsp;?</p>';
    body += '<p class="alert alert-warning">Toute opération en cours sera annulée.</p>';
    body += '<p class="alert alert-warning">Pour une déconnexion complète il est nécessaire de fermer toutes les fenêtres du navigateur.</p>'
    dialogConfig.data = {
      title: 'Confirmation de déconnexion',
      yesLabel: 'Confirmer',
      noLabel: 'Annuler',
      body: body
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.logout();
      }
    });
  }

  isDev(): boolean {
    return environment.env === 'DEV'
  }
}
