import {DTOWithLieu} from '../epona-ui/DTOWithLieu';

export class FdncEponaSearch extends DTOWithLieu {
  idFournisseur!: number ;
  codeMarcheOrion!: string;
  idArticle!: number;
  emise!: boolean;
  idCommandeEntete!: number;
  idEntreeSortieEntete!: number;

  fields: string;
}
