import {TypeEcart} from "../w3-api/TypeEcart";

export class TypeEcartDTO {
  idTypeEcartLivraison: number;
  code: string;

  constructor(typeEcart: TypeEcart) {
    this.idTypeEcartLivraison = typeEcart.idTypeEcartLivraison;
    this.code = typeEcart.code;
  }
}
