import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {EngagementJuridiqueEntete} from "../../model/epona-api/EngagementJuridiqueEntete";
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";
import {UserService} from "../../services/user.service";
import {CodeDroit} from "../../commons/constants/CodeDroit";

@Component({
  selector: 'epona-engagement-juridique',
  templateUrl: './engagement-juridique.component.html',
  styleUrls: ['./engagement-juridique.component.css']
})
export class EngagementJuridiqueComponent implements OnChanges {

  @Input() commande: CommandeEntete;
  @Input() engagementJuridique: EngagementJuridiqueEntete;

  @Output() formEnteteIsDirty = new EventEmitter<boolean>();
  @Output() formLignesIsDirty = new EventEmitter<boolean>();
  @Output() closeDialog = new EventEmitter<boolean>();

  modifiable: boolean = false;
  envoyable: boolean = false;

  constructor(private userService: UserService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['commande']) {
      // L'EJ est modifiable si la commande est à l'état "Préparation de l'EJ"
      //  et que l'utilisateur a le droit de modifier les EJ
      this.modifiable = this.commande.etatCommande.codeEtatCommande === CodeEtatCommande.PREPARATION_EJ
        && this.userService.utilisateurCourant.possedeDroit(CodeDroit.COMMANDES_PREPARATION_EJ);

      this.envoyable = this.commande.etatCommande.codeEtatCommande === CodeEtatCommande.PREPARATION_EJ
        && this.userService.utilisateurCourant.possedeDroit(CodeDroit.COMMANDES_ENVOI_EJ)
    }
  }

  onFormEnteteIsDirty(dirty: boolean): void {
    this.formEnteteIsDirty.emit(dirty);
  }

  onFormLignesIsDirty(dirty: boolean): void {
    this.formLignesIsDirty.emit(dirty);
  }

  onCloseDialog(): void {
    this.closeDialog.emit(true);
  }
}
