<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>Création d'une FDNC</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="openDialogFermeture()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <epona-tableau-commande-livraison [commande]="commande" [livraison]="livraison" [article]="articleSelectionne"></epona-tableau-commande-livraison>

  <mat-stepper [linear]="true" (selectionChange)="changementEtape($event)">
    <mat-step label="Constat" [completed]="constatForm.valid">
      <form [formGroup]="constatForm" class="conteneur-colonne">
        <div class="colonne">
          <mat-form-field>
            <mat-label>Contexte de la découverte de la non-conformité</mat-label>
            <epona-select-contexte-fdnc formControlName="contexte"
                                        displayType="radio"
                                        [presenceLivraison]="this.data.livraison !== null"
                                        [presenceArticle]="this.data.ligne !== null"
                                        required></epona-select-contexte-fdnc>
            <mat-error *ngIf="constatForm.get('contexte').hasError('required')">Contexte obligatoire</mat-error>
          </mat-form-field>
        </div>

        <div class="colonne">
          <mat-form-field>
            <mat-label>Date constat</mat-label>
            <input matInput [matDatepicker]="picker" class="mat-datepicker-input" formControlName="dateConstat" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="constatForm.get('dateConstat').hasError('required')">Date constat obligatoire</mat-error>
          </mat-form-field>

          <div class="step-buttons">
            <button type="submit" mat-button color="primary" matStepperNext [disabled]="!constatForm.valid">Suivant</button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step label="Article" [completed]="articleForm.valid" *ngIf="!!contexteSelectionne && contexteSelectionne.presenceArticle">
      <form [formGroup]="articleForm" class="conteneur-colonne" *ngIf="afficherArticleForm">
        <div class="colonne">
          <mat-radio-group formControlName="ligneArticle" required>
            <ul *ngIf="listeLignesArticles.length > 0">
              <li *ngFor="let item of listeLignesArticles">
                <mat-radio-button [value]="item"  [checked]="item.article?.codeArticleAchat === articleSelectionne?.codeArticleAchat">
                  {{ item.article.designationAchat }}
                </mat-radio-button>
              </li>
            </ul>
          </mat-radio-group>
        </div>

        <div class="colonne">
          <mat-form-field>
            <mat-label>Numéro lot-produit</mat-label>
            <input matInput formControlName="numeroLot" maxlength="255" />
          </mat-form-field>

          <div class="step-buttons">
            <button type="button" mat-button color="accent" matStepperPrevious>Retour</button>
            <button type="submit" mat-button color="primary" matStepperNext [disabled]="!articleForm.valid">Suivant</button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step label="Types de non-conformité" [completed]="typesForm.valid">
      <form [formGroup]="typesForm" class="conteneur-colonne" *ngIf="afficherTypesForm">
        <div class="colonne">
          <mat-form-field>
            <mat-label>Types de non-conformité</mat-label>
            <epona-select-type-non-conformite formControlName="types"
                                              displayType="checkbox"
                                              [afficherInactifs]="false"
                                              [idContexte]="contexteSelectionne.idContexteFdnc"
                                              required></epona-select-type-non-conformite>
            <mat-error *ngIf="typesForm.get('types').hasError('nbMin')">Au minimum 1 élément doit être sélectionné</mat-error>
            <mat-error *ngIf="typesForm.get('types').hasError('nbMax')">Au maximum 3 éléments doivent être sélectionnés</mat-error>
          </mat-form-field>
        </div>

        <div class="colonne">
          <mat-form-field>
            <mat-label>Observations sur la non-conformité</mat-label>
            <textarea matInput cdkTextareaAutosize
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="6"
                      formControlName="observations"
                      required
                      maxlength="5000"></textarea>
            <mat-error *ngIf="typesForm.get('observations').hasError('required')">Observations obligatoires</mat-error>
          </mat-form-field>

          <div class="step-buttons">
            <button type="button" mat-button color="accent"  matStepperPrevious>Retour</button>
            <button type="submit" mat-button color="primary" matStepperNext [disabled]="!typesForm.valid">Suivant</button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step label="Traitements" [completed]="traitementsForm.valid">
      <form [formGroup]="traitementsForm" (ngSubmit)="creerFdnc()" class="conteneur-colonne" *ngIf="afficherTraitementsForm">
        <div class="colonne">
          <mat-form-field>
            <mat-label>Traitements de non-conformité</mat-label>
            <epona-select-traitement-non-conformite formControlName="traitements"
                                                    displayType="checkbox"
                                                    [afficherInactifs]="false"
                                                    required></epona-select-traitement-non-conformite>
            <mat-error *ngIf="traitementsForm.get('traitements').hasError('nbMin')">Au minimum 1 élément doit être sélectionné</mat-error>
            <mat-error *ngIf="traitementsForm.get('traitements').hasError('nbMax')">Au maximum 2 éléments doivent être sélectionnés</mat-error>
          </mat-form-field>
        </div>

        <div class="colonne">
          <mat-form-field>
            <mat-label>Temps de traitement</mat-label>
            <epona-select-temps-traitement-fdnc formControlName="temps" required></epona-select-temps-traitement-fdnc>
            <mat-error *ngIf="traitementsForm.get('temps').hasError('required')">Temps de traitement obligatoire</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Observations sur les traitements</mat-label>
            <textarea matInput cdkTextareaAutosize
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="6"
                      formControlName="observations"
                      required
                      maxlength="5000"></textarea>
            <mat-error *ngIf="traitementsForm.get('observations').hasError('required')">Observations obligatoires</mat-error>
          </mat-form-field>

          <div class="step-buttons">
            <button type="button" mat-button        color="accent"  matStepperPrevious>Retour</button>
            <button type="submit" mat-raised-button color="primary" [disabled]="!traitementsForm.valid">Créer la FDNC</button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
