import {EntreeSortieLigneLot} from "./entree-sortie-ligne-lot";

export class EntreeSortieLigneLotPOSTPUT {
  numeroLot: string;
  quantite: number;

  constructor(entreeSortieLigneLot: EntreeSortieLigneLot) {
    this.numeroLot = entreeSortieLigneLot.numeroLot;
    this.quantite = entreeSortieLigneLot.quantite;
  }
}
