import {Lieu} from './Lieu';
import {Extra} from "./Extra";
import {Article} from "./Article";

export class ParametrageLieuArticle {
  idParametrageLieuArticle: number;
  lieu: Lieu;
  article: Article;
  stockMini: number;
  extra: Extra;
}
