import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {FournisseurFilter} from "../../model/epona-ui/fournisseur-filter";
import {FournisseurQueryParams} from "../../model/epona-ui/fournisseur-query-params";
import {MatTableDataSource} from "@angular/material/table";
import {Tools} from "../../commons/Tools";
import {FournisseurService} from "../../services/epona/fournisseur.service";
import {FournisseurSearch} from "../../model/epona-api/FournisseurSearch";
import {MatSort} from "@angular/material/sort";
import {MessageTool} from "../../commons/MessageTool";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {FilterTools} from "../../commons/FilterTools";
import {Fournisseur} from "../../model/epona-api/Fournisseur";

@Component({
  selector: 'app-liste-fournisseurs',
  templateUrl: './liste-fournisseurs.component.html',
  styleUrls: ['./liste-fournisseurs.component.css']
})
export class ListeFournisseursComponent implements OnInit {
  dataSource = new MatTableDataSource([]);

  filter: FournisseurFilter;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  readonly COLUMNS: {[key: string]: TableColumn} = {
    nom:        new TableColumn({label: 'Nom',      hiddable: false}),
    siret:      new TableColumn({label: 'Siret'}),
    mail:       new TableColumn({label: 'Email'}),
    codeClient: new TableColumn({label: 'Code-client', tooltip: 'Code-client du Crous chez le fournisseur'}),
    telephone:  new TableColumn({label: 'Téléphone',   default: false}),
    ville:      new TableColumn({label: 'Ville',       default: false}),
    actions:    new TableColumn({label: 'Actions',     export: false, hiddable: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.FOURNISSEURS;

  displayedColumns: string[] = [];

  constructor(private route: ActivatedRoute,
              private fournisseurService: FournisseurService,
              private messageTool: MessageTool,
              private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.filter = new FournisseurFilter();
      }
      this.rechercher();
    });

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  private initFiltersFromURL(params: ParamMap) {
    this.filter = new FournisseurFilter();
    this.filter.nom = params.get('nom');
    this.filter.siret = params.get('siret');
    this.filter.marche = FilterTools.buildMarcheFromURL(params);
  }

  rechercher() {
    this.dataSource.data = [];

    const search = FournisseurSearch.fabric(localStorage.getItem('idCrous'), this.filter,"idFournisseur,siret,nom,mail,telephone,adresseVille,codeClient");

    this.fournisseurService.getListeFournisseurs(search).subscribe(data => {
      this.dataSource.data = data;
      this.setDisplayedColumns();
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  onFilterSubmitted(filter: FournisseurFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['liste-fournisseurs'], {queryParams: FournisseurQueryParams.fabric(this.filter)});
  }

  sortingDataAccessor(data: Fournisseur, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'ville': return data.adresseVille;
      default     : return data[sortHeaderId];
    }
  }
}
