<epona-select-lieu [lieu]="lieuCourant"
                   [disabled]="!!inventaireEntete"
                   (lieuSubmitted)="doCheckInventaireEnCours($event)">
</epona-select-lieu>

<h1 *ngIf="!inventaireEntete">Création d'un inventaire</h1>
<h1 *ngIf="inventaireEntete">Modification de l'inventaire</h1>

<div class="fiche">
  <epona-inventaire-entete [inventaireEntete]="inventaireEntete"
                           [lieuCourant]="lieuCourant"
                           (enteteSubmitted)="doActionEntete($event)"
                           (clotureSubmitted)="doCloture($event)"
                           (suppressionSubmitted)="doSuppression($event)"
                           class="entete">
  </epona-inventaire-entete>

  <!-- Le composant des lignes n'est affiché que si on est en modification donc s'il y a un idInventaire -->
  <epona-inventaire-lignes [inventaireEntete]="inventaireEntete"
                           [inventaireLignes]="inventaireLignes"
                           [validationLignesErreur]="validationLignesErreur"
                           (articleInserted)="doHandleArticleInserted($event)"
                           (ligneInserted)="doHandleLigneInserted($event)"
                           (ligneUpdated)="doHandleLigneUpdated($event)"
                           *ngIf="idInventaire"
                           class="lignes">
  </epona-inventaire-lignes>
</div>
