import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

// Référence : https://stackoverflow.com/questions/41038970/how-to-determine-previous-page-url-in-angular#answer-48866813
@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router : Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl(){
    return this.previousUrl;
  }
}
