import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {TypeAjoutLigne} from "../../commons/enum/TypeAjoutLigne";
import {InventaireLigne} from "../../model/epona-api/InventaireLigne";
import {DialogDataAjoutDLCInventaire} from "../../model/epona-ui/DialogDataAjoutDLCInventaire";
import {InventaireService} from "../../services/epona/inventaire.service";
import {MessageTool} from "../../commons/MessageTool";
import {ArticleTools} from "../../commons/ArticleTools";

@Component({
  selector: 'app-dialog-ajout-dlc',
  templateUrl: './dialog-ajout-dlc.component.html',
  styleUrls: ['./dialog-ajout-dlc.component.css']
})
export class DialogAjoutDlcComponent implements OnInit {
  dlcCtrl: UntypedFormControl;
  typeAjoutLigneCtrl: UntypedFormControl;

  typeAjoutLigne = TypeAjoutLigne;

  @ViewChild('picker') picker;

  constructor(public dialog: MatDialogRef<DialogAjoutDlcComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataAjoutDLCInventaire,
              private fb: UntypedFormBuilder,
              private inventaireService: InventaireService,
              private messageTool: MessageTool) {

    this.dlcCtrl = new UntypedFormControl('', Validators.required);
    this.typeAjoutLigneCtrl = new UntypedFormControl(TypeAjoutLigne.SANS_DLC);
  }

  ngOnInit() {
      this.dlcCtrl.disable();
  }

  close() {
    this.dialog.close();
  }

  save() {
    const inventaireLigne = new InventaireLigne();
    inventaireLigne.dlc = this.dlcCtrl.value;
    inventaireLigne.article = this.data.article;

    this.inventaireService.postInventaireLignes(this.data.entete.idInventaireEntete, inventaireLigne).subscribe(data => {
      const designation = ArticleTools.getDesignation(inventaireLigne.article);
      this.messageTool.sendSuccess(`La ligne pour l'article ${ designation } a été ajoutée avec succès`);
      inventaireLigne.idInventaireLigne = data.idInventaireLigne;
      this.data.ligne = inventaireLigne;
      this.dialog.close();
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  updateStatusDlc() {
    if(this.typeAjoutLigneCtrl.value === TypeAjoutLigne.SANS_DLC) {
      this.dlcCtrl.disable();
    } else {
      this.dlcCtrl.enable();
      this.picker.open();
    }
  }

  enableFieldDlc() {
    this.dlcCtrl.enable();
    this.typeAjoutLigneCtrl.setValue(TypeAjoutLigne.AVEC_DLC);
    this.picker.open();
  }
}
