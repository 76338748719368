import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {Fournisseur} from "../../model/epona-api/Fournisseur";
import {MatTableDataSource} from "@angular/material/table";
import {EponaService} from "../../services/epona/epona.service";
import {SousLotZg} from "../../model/epona-api/SousLotZg";
import {SousLotZgSearch} from "../../model/epona-api/SousLotZgSearch";
import {MatSort} from "@angular/material/sort";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
  selector: 'epona-liste-lot-marche',
  templateUrl: './liste-lot-marche.component.html',
  styleUrls: ['./liste-lot-marche.component.css']
})
export class ListeLotMarcheComponent implements OnChanges {

  @Input() fournisseur : Fournisseur;
  @Input() small = false;

  dataSource = new MatTableDataSource<SousLotZg>([]);
  displayedColumns: string[];
  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  constructor(private eponaService: EponaService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.small)  {
      this.displayedColumns = ['codeMarcheOrion', 'numeroSousLot', 'typeSousLot'];
    } else {
      this.displayedColumns = ['codeMarcheOrion', 'numeroSousLot', 'libelle', 'typeSousLot', 'zoneGeographique'];
    }


    if (changes['fournisseur'] && this.fournisseur) {
      this.loadSousLog();
    }
  }

  private loadSousLog(){
    const search = new SousLotZgSearch();
    search.idFournisseur = this.fournisseur.idFournisseur;
    search.date = new Date();
    search.fields = 'idSousLotZg,codeMarcheOrion,numeroSousLot,libelle,typeSousLot,zoneGeographique';

    this.eponaService.getListeSousLotsZg(search).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    });
  }

  sortingDataAccessor(data: SousLotZg, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'codeMarcheOrion' : return data.codeMarcheOrion;
      case 'numeroSousLot'   : return data.numeroSousLot;
      case 'libelle'         : return SortHelper.stringToString(data.libelle);
      case 'typeSousLot'     : return data.typeSousLot;
      case 'zoneGeographique': return SortHelper.stringToString(data.zoneGeographique?.libelle);
      default                : return data[sortHeaderId];
    }
  }
}
