import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageTool} from "../../commons/MessageTool";
import {CommandeSearch} from "../../model/epona-api/CommandeSearch";
import {MatTableDataSource} from "@angular/material/table";
import {CommandeFilter} from "../../model/epona-ui/CommandeFilter";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {CommandeService} from "../../services/epona/commande.service";
import {MatSort} from "@angular/material/sort";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Tools} from "../../commons/Tools";
import {Lieu} from "../../model/epona-api/Lieu";
import {EtatCommande} from "../../model/EtatCommande";
import {FiltreCommandesComponent} from "../filtre-commandes/filtre-commandes.component";
import {CommandeQueryParams} from "../../model/epona-ui/CommandeQueryParams";
import {Format} from "../../commons/constants/Format";
import {DatePipe} from "@angular/common";
import {
  DisplayedColumnsTools,
  TableColumn,
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";
import {FilterTools} from "../../commons/FilterTools";
import {SortHelper} from "../../helpers/sort-helper";
import {LotMarcheOrionPipe} from "../../commons/pipes/LotMarcheOrionPipe";

@Component({
  selector: 'app-liste-commandes',
  templateUrl: './liste-commandes.component.html',
  styleUrls: ['./liste-commandes.component.css']
})
export class ListeCommandesComponent implements OnInit {
  dataSource = new MatTableDataSource<CommandeEntete>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    idCommandeEntete:       new TableColumn({label: 'Id.',            tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
    numeroEj:               new TableColumn({label: 'No. EJ',         tooltip: 'Numéro d\'engagement juridique'}),
    batiment:               new TableColumn({label: 'Bâtiment livraison'}),
    lieuDemandeur:          new TableColumn({label: 'Lieu livraison'}),
    externe:                new TableColumn({label: 'Type'}),
    etatCommande:           new TableColumn({label: 'État'}),
    commandableExterne:     new TableColumn({label: 'Commandable',    tooltip: 'Articles commandables', default: false}),
    dateCommande:           new TableColumn({label: 'Date commande',  tooltip: 'Date de la commande', exportFormat: 'date'}),
    dateLivraisonSouhaitee: new TableColumn({label: 'Date souhaitée', tooltip: 'Date de livraison souhaitée', default: false, exportFormat: 'date'}),
    fournisseur:            new TableColumn({label: 'Fournisseur'}),
    lotMarche:              new TableColumn({label: 'Lot-marché',     default: false}),
    commentaire:            new TableColumn({label: 'Commentaire',    default: false}),
    montantHt:              new TableColumn({label: 'Montant HT',     default: false, exportFormat: 'decimal'}),
    montantTtc:             new TableColumn({label: 'Montant TTC',    default: false, exportFormat: 'decimal'}),
    utilisateur:            new TableColumn({label: 'Utilisateur',    default: false}),
    pdf:                    new TableColumn({label: 'PDF',            default: false, export: false}),
    actions:                new TableColumn({label: 'Actions',        export: false, hiddable: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.COMMANDES;

  displayedColumns: string[] = [];

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild(FiltreCommandesComponent)
  filterComponent: FiltreCommandesComponent;

  lieuCourant: Lieu;
  filter: CommandeFilter;
  droitSaisie: boolean;
  droitModification: boolean;

  constructor(private commandeService: CommandeService,
              private messageTool: MessageTool,
              private route: ActivatedRoute,
              private router: Router,
              private dateFormat: DatePipe) {
  }

  ngOnInit() {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMAp pour que le ngOnInit soit rappelé si une recherche est lancé depuis la liste des commandes
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.initDefaultFilters();
      }

      // Lancement de la recherche
      this.rechercheCommandes();
    });

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = ListeCommandesComponent.sortingDataAccessor;

    this.droitSaisie = this.commandeService.droitSaisie();
    this.droitModification = this.commandeService.droitModification();
  }

  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre de commandes
    this.filter = new CommandeFilter();
    this.filter.etats          = ListeCommandesComponent.buildEtatsCommandeFromURL(params);
    this.filter.dateLivraison  = params.get('dateLivraison') ? new Date(params.get('dateLivraison')) : null;
    this.filter.numeroCommande = params.get('numeroCommande') ? params.get('numeroCommande') : null;
    this.filter.externe        = params.get('externe') ? Tools.stringToBoolean(params.get('externe')) : null;
    this.filter.fournisseur    = FilterTools.buildFournisseurFromURL(params);
    this.filter.marche         = FilterTools.buildMarcheFromURL(params);
  }

  private static buildEtatsCommandeFromURL(params: ParamMap): EtatCommande[] {
    if (params.get('codesEtat')) {
      return params.getAll('codesEtat').filter(codeEtat => !isNaN(+codeEtat)).map(codeEtat => new EtatCommande(+codeEtat));
    } else {
      return null;
    }
  }

  // Initialisation des filtres à partir des valeurs par défaut
  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre de commandes
    this.filter = new CommandeFilter();
    this.filter.etats = [
      new EtatCommande(CodeEtatCommande.BROUILLON),
      new EtatCommande(CodeEtatCommande.PREPARATION_EJ),
      new EtatCommande(CodeEtatCommande.EN_ATTENTE_RETOUR_ORION),
      new EtatCommande(CodeEtatCommande.EN_PREPARATION),
      new EtatCommande(CodeEtatCommande.EN_ACHEMINEMENT),
      new EtatCommande(CodeEtatCommande.EN_RECEPTION)
    ];
  }

  rechercheCommandes() {
    this.dataSource.data = [];

    const search = this.buildSearchFromFilters();
    search.fields = '' +
      'idCommandeEntete,' +
      'lieuDemandeur.nomRu,' +
      'lieuDemandeur.nomUd,' +
      'externe,' +
      'etatCommande.libelle,' +
      'dateCommande,' +
      'dateLivraisonSouhaitee,' +
      'commentaire,' +
      'sousLotZg.codeMarcheOrion,' +
      'sousLotZg.numeroSousLot,' +
      'fournisseur.nom,' +
      'numeroEj,' +
      'montantHt,' +
      'montantTtc,' +
      'utilisateurCreation,' +
      'utilisateurModification,' +
      'nbLignesCommandableNon,' +
      'nbLignesCommandableNonDefini';

    this.commandeService.getListeEntetes(search).subscribe(data => {
      this.dataSource.data = data;
      this.setDisplayedColumns();
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private buildSearchFromFilters(): CommandeSearch {
    const search = new CommandeSearch();

    search.setIdsLieu(this.lieuCourant);

    search.numeroCommande  = this.filter.numeroCommande;
    search.dateLivraison   = this.filter.dateLivraison;
    search.codesEtat       = this.filter.etats ? this.filter.etats.map(etat => etat.codeEtatCommande) : null;
    search.externe         = this.filter.externe;
    search.idFournisseur   = this.filter.fournisseur ? this.filter.fournisseur.idFournisseur: null;
    search.codeMarcheOrion = this.filter.marche ? this.filter.marche.codeMarcheOrion: null;

    return search;
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche de commande pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: CommandeFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['liste-commandes'], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): CommandeQueryParams {
    const params = new CommandeQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.numeroCommande  = this.filter.numeroCommande;
    params.dateLivraison   = this.dateFormat.transform(this.filter.dateLivraison, Format.FORMAT_DATE_URL_REST);
    params.codesEtat       = this.filter.etats ? this.filter.etats.map(etat => etat.codeEtatCommande) : null;
    params.externe         = this.filter.externe;
    params.idFournisseur   = this.filter.fournisseur ? this.filter.fournisseur.idFournisseur : null;
    params.codeMarcheOrion = this.filter.marche ? this.filter.marche.codeMarcheOrion: null;

    return params;
  }

  downloadPdf(entete: CommandeEntete): void {
    this.commandeService.downloadPdf(entete);
  }

  private static sortingDataAccessor(data: CommandeEntete, sortHeaderId: string): string|number {
    switch(sortHeaderId) {
      case 'batiment':               return SortHelper.stringToString(data.lieuDemandeur?.nomRu);
      case 'lieuDemandeur':          return SortHelper.stringToString(data.lieuDemandeur?.nomUd);
      case 'externe':                return SortHelper.booleanToString(data.externe);
      case 'etatCommande':           return SortHelper.stringToString(data.etatCommande.libelle);
      case 'commandableExterne':     return ListeCommandesComponent.commandableExterne(data);
      case 'dateCommande':           return SortHelper.dateToNumber(data.dateCommande);
      case 'dateLivraisonSouhaitee': return SortHelper.dateToNumber(data.dateLivraisonSouhaitee);
      case 'fournisseur':            return data.fournisseur ? SortHelper.stringToString(data.fournisseur.nom) : '';
      case 'lotMarche':              return LotMarcheOrionPipe.buildLibelle(data.sousLotZg);
      case 'commentaire':            return SortHelper.stringToString(data.commentaire);
      case 'utilisateur':            return data.utilisateurModification ? data.utilisateurModification : data.utilisateurCreation;
      case 'pdf':                    return SortHelper.booleanToString(data.externe);

      case 'idCommandeEntete':
      case 'numeroEj':
      case 'montantHt':
      case 'montantTtc':
      default: return data[sortHeaderId];
    }
  }

  private static commandableExterne(data: CommandeEntete): string {
    if (data.nbLignesCommandableNon > 0) return 'Non';
    if (data.nbLignesCommandableNon === 0 && data.nbLignesCommandableNonDefini > 0) return 'En attente';
    if (data.nbLignesCommandableNon === 0 && data.nbLignesCommandableNonDefini === 0) return 'Oui';
    return '';
  }
}
