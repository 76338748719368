export class BatimentBns {

  idBatiment!: number;
  nom!: string;
  actif!: boolean;

  static buildWithId(id: number): BatimentBns {
    const batiment = new BatimentBns();
    batiment.idBatiment = id;
    return batiment;
  }

  static sort(a: BatimentBns, b: BatimentBns): number {
    return a.nom.localeCompare(b.nom);
  }
}
