import {CommandeEntete} from "./CommandeEntete";
import {EntreeSortieEntete} from "./EntreeSortieEntete";
import {TypeNonConformite} from "../bna-api/type-non-conformite";
import {TraitementNonConformite} from "../bna-api/traitement-non-conformite";
import {EntreeSortieLigne} from "./EntreeSortieLigne";
import {ContexteFdnc} from "../bna-api/contexte-fdnc";
import {TempsTraitementFdnc} from "../bna-api/temps-traitement-fdnc";
import {CommandeLigne} from "./CommandeLigne";

export class Fdnc {
  idFdnc!: number;

  commandeEntete!: CommandeEntete;
  commandeLigne!: CommandeLigne;
  entreeSortieEntete!: EntreeSortieEntete;
  entreeSortieLigne!: EntreeSortieLigne;
  idContexte: number;
  emise!: boolean;
  dateEmission!: Date;
  utilisateurEmission!: string;
  dateConstat!: Date;
  numeroLotProduit: string;
  observationsNonConformite!: string;
  observationsTraitement!: string;
  idTempsTraitement!: number;
  listeTypesNonConformite!: number[];
  listeTraitementsNonConformite!: number[];
  idFdncBna!: number;
  utilisateurCreation!: string;
  dateCreation!: Date;
  utilisateurModification!: string;
  dateModification!: Date;
}
