
import {InventaireEntete} from "./InventaireEntete";

export class InventaireEntetePOST {

  idInventaireEntete: number

  constructor(inventaire: InventaireEntete) {
      this.idInventaireEntete = inventaire.idInventaireEntete;
  }

}
