import {Component, Input, OnInit} from '@angular/core';
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {CommandeService} from "../../services/epona/commande.service";
import {DocumentJoint} from "../../model/epona-api/DocumentJoint";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {environment} from "../../../environments/environment";
import {HttpService} from "../../services/http.service";

@Component({
  selector: 'epona-service-fait-pieces-jointes',
  templateUrl: './service-fait-pieces-jointes.component.html',
  styleUrls: ['./service-fait-pieces-jointes.component.css']
})
export class ServiceFaitPiecesJointesComponent implements OnInit {

  private env = environment;

  @Input() livraison: EntreeSortieEntete;
  @Input() modifiable: boolean;

  nomFichierCommande: string;
  listeDocuments: DocumentJoint[] = [];

  constructor(private commandeService: CommandeService,
              private entreeSortieService: EntreeSortieService,
              private httpService: HttpService) {
  }

  ngOnInit(): void {
    this.nomFichierCommande = this.commandeService.defaultFilenamePdf(this.livraison.commande);

    this.entreeSortieService.getListeDocuments(this.livraison.idEntreeSortieEntete, 'idDocument,libelle').subscribe({
      next: (data) => {
        this.listeDocuments = data;
      }
    });
  }

  downloadPdfCommande(entete: CommandeEntete): void {
    this.commandeService.downloadPdf(entete);
  }

  downloadDocument(document: DocumentJoint): void {
    const url = this.env.eponaApiUrl + '/entrees-sorties/' + this.livraison.idEntreeSortieEntete + '/documents/' + document.idDocument + '/fichier';
    this.httpService.downloadFile(url);
  }
}
