import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
  selector: 'epona-datatable-link-commandes',
  templateUrl: './datatable-link-commandes.component.html',
  styleUrls: ['./datatable-link-commandes.component.css']
})
export class DatatableLinkCommandesComponent implements OnInit, OnChanges{
  dataSource = new MatTableDataSource<CommandeEntete>([]);

  @Input() label: string;
  @Input() listeCommandes: Array<CommandeEntete>;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['listeCommandes']) {
      this.dataSource.data = this.listeCommandes.sort((a,b) => {
        return SortHelper.dateToNumber(b.dateCommande) - SortHelper.dateToNumber(a.dateCommande);
      });
    }
  }
}
