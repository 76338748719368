<epona-select-lieu [lieu]="lieuCourant"
                   [livraisonUniquement]="true"
                   lieuLabel="Lieu de livraison"
                   (lieuSubmitted)="onLieuSubmitted($event)"
                   (lieuCorrected)="onLieuCorrected($event)"></epona-select-lieu>

<h1>Liste des FDNC</h1>

<div class="crous-recherche">
  <epona-filtre-fdnc [filtre]="filtre"
                     class="crous-recherche-filtre"
                     (filtreSubmitted)="onFilterSubmitted($event)"></epona-filtre-fdnc>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Résultat</mat-card-title>
      </mat-card-header>

      <mat-divider></mat-divider>

      <div class="table-actions">
        <button type="button" mat-raised-button color="primary"
                (click)="cnousTableExporter.exportTable({nomFichier: 'liste-fdnc.xlsx'})"
                [disabled]="listeFDNC.length === 0">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                [storeCode]="COLUMNS_STORE_CODE"
                                [(displayedColumns)]="colonnesAffichees"></form-displayed-columns>

        <span class="nb-results">Nb. FDNC : {{ listeFDNC.length }}</span>
      </div>

      <epona-tableau-fdnc [listeFdnc]="listeFDNC"
                          [colonnesAffichees]="colonnesAffichees"></epona-tableau-fdnc>
    </mat-card>
  </div>
</div>
