import {CommandeLigne} from "./CommandeLigne";

export class CommandeLignePUT {
  quantite: number;
  commentaire: string;
  quantiteMagasin: number;
  commentaireMagasin: string;

  constructor(commandeLigne: CommandeLigne) {
    this.quantite           = commandeLigne.quantite;
    this.commentaire        = commandeLigne.commentaire;
    this.quantiteMagasin    = commandeLigne.quantiteMagasin;
    this.commentaireMagasin = commandeLigne.commentaireMagasin;
  }
}
