import {CommandeEntete} from "./CommandeEntete";
import {LieuDTO} from "./LieuDTO";
import {CommandeEntetePUT} from "./CommandeEntetePUT";

export class CommandeEntetePOST extends CommandeEntetePUT {
  externe: boolean;
  lieuDemandeur: LieuDTO;

  constructor(commandeEntete: CommandeEntete) {
    super(commandeEntete)
    this.externe                = commandeEntete.externe;
    this.lieuDemandeur          = commandeEntete.lieuDemandeur ? new LieuDTO(commandeEntete.lieuDemandeur) : null;
  }
}
