import {CommandeEntete} from "../epona-api/CommandeEntete";
import {CommandeLigne} from "../epona-api/CommandeLigne";
import {Article} from "../epona-api/Article";

export class DialogDataAjoutArticlesCommande {
  // Paramètres en entrée
  entete: CommandeEntete;
  lignes: Array<CommandeLigne>;
  utilisation: string;

  // Paramètres de retour
  listeArticles: Array<Article>;
}
