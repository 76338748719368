import {DTOWithLieu} from "../epona-ui/DTOWithLieu";

export class StockSearch extends DTOWithLieu {
  codeGroupe: string;
  idSousGroupe: number;
  idArticle: number;

  enStock: boolean;
  masquerQuantitesNulles: boolean;

  fields: string;
}
