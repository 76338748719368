import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {concat, interval} from "rxjs";
import {filter, first} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(appRef: ApplicationRef, public updates: SwUpdate) {
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    everySixHoursOnceAppIsStable$.subscribe( async () => {
      await updates.checkForUpdate().then( r =>  {
        console.log("promise checkForUpdate");
        this.checkForUpdates();
      }).catch(reason => {
        console.error('catch checkForUpdate', reason);
      });
    });
  }

  public checkForUpdates(): void {
    console.log('checking for updates');
    this.updates.versionUpdates
      .subscribe(evt => {
          console.log(evt.type)
        if (evt.type == 'VERSION_READY') {
          this.promptUser();
        }
      });
  }

  private promptUser(): void {
    console.log('updating to new version');
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
