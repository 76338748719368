<mat-icon (click)="open(template);$event.stopPropagation();">{{ icon }}</mat-icon>

<ng-template #template>
  <mat-dialog-content>
    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="close()">Fermer</button>
  </mat-dialog-actions>
</ng-template>
