import {ArticleFilter} from "./article-filter";
import {NumberComparisonOperator} from "../../commons/enum/NumberComparisonOperator";

export class ArticleQueryParams {

  idCrous: number;

  codeGroupe: string;
  idSousGroupe: number;
  codeDepartement: number;
  codeFamille: number;
  codeSousFamille: number;
  codeArticle: string;
  designation: string;
  actifVente: boolean;
  colisage: number;
  articleDlc: boolean;
  delaiReceptionDlc: number;
  commandableExterne: number;
  stockGere: boolean;
  stockGereOperationnellement: boolean;
  codeMarcheOrion: string;
  operateurPrixHt: NumberComparisonOperator;
  prixHt: number;
  origineAchat: string;

  static fabric(filter: ArticleFilter) : ArticleQueryParams {
    const params = new ArticleQueryParams();

    params.idCrous           = +localStorage.getItem('idCrous');

    params.codeGroupe                  = filter.groupe      ? filter.groupe.code                     : null;
    params.idSousGroupe                = filter.sousGroupe  ? filter.sousGroupe.idSousGroupeArticles : null;
    params.codeDepartement             = filter.departement ? filter.departement.codeDepartement     : null;
    params.codeFamille                 = filter.famille     ? filter.famille.codeFamille             : null;
    params.codeSousFamille             = filter.sousFamille ? filter.sousFamille.codeSousFamille     : null;
    params.codeArticle                 = filter.codeArticle;
    params.designation                 = filter.designation;
    params.actifVente                  = filter.actifVente;
    params.colisage                    = filter.colisage;
    params.articleDlc                  = filter.articleDlc;
    params.delaiReceptionDlc           = filter.delaiReceptionDlc;
    params.commandableExterne          = filter.commandableExterne;
    params.stockGere                   = filter.stockGere;
    params.stockGereOperationnellement = filter.stockGereOperationnellement;
    params.codeMarcheOrion             = filter.marche ? filter.marche.codeMarcheOrion : null;
    params.operateurPrixHt             = filter.comparaisonPrixHt ? filter.comparaisonPrixHt.operator : null;
    params.prixHt                      = filter.comparaisonPrixHt ? filter.comparaisonPrixHt.value : null;
    params.origineAchat                = filter.origineAchat;

    return params;
  }
}
