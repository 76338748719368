import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'epona-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.css']
})
export class BooleanComponent implements OnInit {

  static readonly DEFAULT_LABEL_TRUE  = 'Oui';
  static readonly DEFAULT_LABEL_FALSE = 'Non';
  static readonly DEFAULT_LABEL_NULL  = '';

  @Input() value: boolean;
  @Input() trueLabel: string  = BooleanComponent.DEFAULT_LABEL_TRUE;
  @Input() falseLabel: string = BooleanComponent.DEFAULT_LABEL_FALSE;
  @Input() nullLabel: string  = BooleanComponent.DEFAULT_LABEL_NULL;

  constructor() { }

  ngOnInit(): void {
  }
}
