<mat-select [formControl]="formCtrl"
            (selectionChange)="selectionChanged()"
            panelClass="select-panel"
            #selectSousGroupe>
  <mat-select-trigger>
    {{ selectSousGroupe.value !== DEFAULT ? getCurrentItem()?.libelle : defaultLabel }}
  </mat-select-trigger>

  <mat-option [value]="DEFAULT">{{ defaultLabel }}</mat-option>
  <mat-option *ngFor="let item of liste" [value]="item.idSousGroupeArticles">
    {{ item.groupe.code }}{{ item.code }} - {{ item.libelle }}
  </mat-option>
</mat-select>
