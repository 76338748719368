import {FdncPUT} from "./fdnc-put";
import {CommandeEnteteDTO} from "./CommandeEnteteDTO";
import {EntreeSortieEnteteDTO} from "./entree-sortie-entete-dto";
import {EntreeSortieLigneDTO} from "./entree-sortie-ligne-dto";
import {Fdnc} from "./fdnc";
import {ContexteFdncDto} from "./contexte-fdnc-dto";
import {CommandeLigneDTO} from "./commande-ligne-dto";
import {ContexteFdnc} from '../bna-api/contexte-fdnc';

export class FdncPOST extends FdncPUT {
  commandeEntete: CommandeEnteteDTO;
  commandeLigne: CommandeLigneDTO;
  entreeSortieEntete: EntreeSortieEnteteDTO;
  entreeSortieLigne: EntreeSortieLigneDTO;
  idContexte: number;

  constructor(fdnc: Fdnc) {
    super(fdnc);
    this.commandeEntete     = fdnc.commandeEntete     ? new CommandeEnteteDTO(fdnc.commandeEntete) : null;
    this.commandeLigne      = fdnc.commandeLigne      ? new CommandeLigneDTO(fdnc.commandeLigne) : null;
    this.entreeSortieEntete = fdnc.entreeSortieEntete ? new EntreeSortieEnteteDTO(fdnc.entreeSortieEntete) : null;
    this.entreeSortieLigne  = fdnc.entreeSortieLigne  ? new EntreeSortieLigneDTO(fdnc.entreeSortieLigne) : null;
    this.idContexte         = fdnc.idContexte;
  }
}
