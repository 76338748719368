import {EntreeSortieLigne} from "./EntreeSortieLigne";
import {CommandeLigne} from "./CommandeLigne";

export class CommandeLigneDTO {
  idCommandeLigne: number;

  constructor(commandeLigne: CommandeLigne) {
    this.idCommandeLigne = commandeLigne.idCommandeLigne;
  }
}
