export class AlerteArticleSearch {
  idLieu: number;
  idCrous: number;
  idBatimentBns: number;
  idLieuBns: number;
  idRu: number;
  idUd: number;
  nombreJours: number;

  fields: string;
}
