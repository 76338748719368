<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>Ajout d'un échange</span>
    <span class="space"></span>
    <button type="button" mat-icon-button [mat-dialog-close]="null">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Résultat</mat-label>
      <mat-select formControlName="resultat">
        <mat-option [value]="null">- Aucun -</mat-option>
        <mat-option [value]="resultat" *ngFor="let resultat of listeResultats">{{ resultat.libelle }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Commentaire</mat-label>
      <textarea matInput cdkTextareaAutosize
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="6"
                formControlName="commentaire"
                maxlength="5000">
      </textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  [mat-dialog-close]="null">Annuler</button>
  <button mat-raised-button color="primary" (click)="ajouterEchange()" [disabled]="!form.valid">Ajouter</button>
</mat-dialog-actions>
