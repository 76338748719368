import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TableColumn} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {EchangeFdnc} from "../../model/bna-api/echange-fdnc";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {SortHelper} from "../../helpers/sort-helper";
import {BnaFdncService} from "../../services/bna/bna-fdnc.service";

@Component({
  selector: 'epona-tableau-echanges-fdnc',
  templateUrl: './tableau-echanges-fdnc.component.html',
  styleUrls: ['./tableau-echanges-fdnc.component.css']
})
export class TableauEchangesFdncComponent implements OnInit, OnChanges {
  // Définition des colonnes
  static readonly COLUMNS: {[key: string]: TableColumn} = {
    date:                    new TableColumn({label: 'Date', exportFormat: 'date'}),
    auteur:                  new TableColumn({label: 'Auteur'}),
    type:                    new TableColumn({label: 'Type'}),
    message:                 new TableColumn({label: 'Message'}),
    detailAction:            new TableColumn({label: 'Détail action'}),
    analyseCauses:           new TableColumn({label: 'Analyse causes'}),
    incidenceQualiteProduit: new TableColumn({label: 'Incidence qualité', tooltip: 'Incidence sur la qualité du produit'}),
    listeTypesAction:        new TableColumn({label: 'Types d\'action'}),
    actionAMettreEnOeuvre:   new TableColumn({label: 'Action à mettre en œuvre'}),
    commentaire:             new TableColumn({label: 'Commentaire'}),
    resultat:                new TableColumn({label: 'Résultat'}),
    valide:                  new TableColumn({label: 'Validé'})
  };

  @Input() listeEchanges: Array<EchangeFdnc> = [];
  @Input() colonnesAffichees: string[] = Object.keys(TableauEchangesFdncComponent.COLUMNS);

  dataSource = new MatTableDataSource<EchangeFdnc>();
  columns = TableauEchangesFdncComponent.COLUMNS;

  @ViewChild(MatSort, {static: true})
  sort!: MatSort;

  constructor(private bnaFdncService: BnaFdncService) {
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = TableauEchangesFdncComponent.sortingDataAccessor;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listeEchanges']) {
      this.dataSource.data = this.listeEchanges;
    }
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  private static sortingDataAccessor(data: EchangeFdnc, sortHeaderId: string): string|number {
    switch (sortHeaderId) {
      case 'date':                    return SortHelper.dateToNumber(data.dateEnregistrement);
      default:                        return 0;
    }
  }

  resultatToString(resultat: number): string {
    return this.bnaFdncService.resultatToString(resultat);
  }
}
