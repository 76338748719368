<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field>
      <mat-label>Identifiant interne / numéro d'EJ</mat-label>

      <input type="text" matInput formControlName="numeroCommande" epoHighlightIfNotEmpty>
    </mat-form-field>

    <mat-form-field>

      <mat-label>Type</mat-label>

      <select-boolean formControlName="externe"
                            defaultLabel="- Tous -"
                            trueLabel="Externe"
                            falseLabel="Interne"
                            epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field>
      <mat-label>États</mat-label>

      <epona-select-etat-commande formControlName="etatsCommande"
                                  multiple="true"
                                  defaultLabel="- Tous -"
                                  epoHighlightIfNotEmpty>
      </epona-select-etat-commande>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Marché</mat-label>
      <epona-select-marche formControlName="marche"
                           defaultLabel="- Tous -"
                           epoHighlightIfNotEmpty>
      </epona-select-marche>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Fournisseur</mat-label>

      <epona-select-fournisseur formControlName="fournisseur"
                                defaultLabel="- Tous -"
                                epoHighlightIfNotEmpty>
      </epona-select-fournisseur>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date de livraison souhaitée</mat-label>

      <input matInput
             [matDatepicker]="picker"
             formControlName="dateLivraison"
             (click)="picker.open()"
             (focus)="picker.open()"
             autocomplete="off"
             epoHighlightIfNotEmpty>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon>
        Rechercher
      </button>
    </div>
  </mat-card>
</form>
