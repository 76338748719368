import {CommandeEntete} from "../epona-api/CommandeEntete";
import {CommandeLigne} from "../epona-api/CommandeLigne";

export class DialogDataCommentaireCommande {
  // Paramètre d'entrée
  entete: CommandeEntete;
  ligne: CommandeLigne;
  utilisation: string;
  field: string;

  // Paramètre de sortie
  ligneUpdated: CommandeLigne;
}
