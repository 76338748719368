import {RestaurantUniversitaire} from './RestaurantUniversitaire';
import {Droit} from './Droit';

export class Utilisateur{
  nom: string;
  eppn: string;
  listeDroits: Array<Droit>;
  listeRu: Array<RestaurantUniversitaire>;
  listeIdDroit: Array<number> = [];
  listeIdBatiment: Array<number> = [];
  dateExpiration: Date;

  possedeDroit(codeDroit: string): boolean {
    if (this.listeDroits) {
      let found = false;
      this.listeDroits.forEach(droit => {
        if (`${droit.idDroit}` === codeDroit) {
          found = true;
        }
      });
      return found;

    } else if (this.listeIdDroit) {
      return this.listeIdDroit.find(idDroit => ''+idDroit === codeDroit) !== undefined;

    } else {
      return false;
    }
  }
}
