<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field>
      <mat-label>Fournisseur</mat-label>

      <epona-select-fournisseur formControlName="fournisseur"
                                defaultLabel="- Tous -"
                                epoHighlightIfNotEmpty>
      </epona-select-fournisseur>
    </mat-form-field>

    <mat-form-field >
      <mat-label>Marché</mat-label>
      <epona-select-marche formControlName="marche"></epona-select-marche>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Article</mat-label>

      <epona-autocomplete-article formControlName="article"
                                  utilisation="achat">
      </epona-autocomplete-article>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Numéros des lots (1 par ligne)</mat-label>

      <textarea matInput
                cdkTextareaAutosize
                formControlName="numeroLot"
                epoHighlightIfNotEmpty></textarea>
      <mat-error *ngIf="form.get('numeroLot')!.hasError('required')">Champ obligatoire</mat-error>
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary">
        <fa-icon icon="search"></fa-icon>
        Rechercher
      </button>
    </div>
  </mat-card>
</form>
