import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TotauxTva} from "../../model/epona-api/TotauxTva";
import {Totaux} from "../../model/epona-api/Totaux";
import {MessageTool} from "../../commons/MessageTool";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";

@Component({
  selector: 'epona-entree-sortie-totaux',
  templateUrl: './entree-sortie-totaux.component.html',
  styleUrls: ['./entree-sortie-totaux.component.css']
})
export class EntreeSortieTotauxComponent implements OnChanges {

  @Input() entete: EntreeSortieEntete;
  @Input() forceUpdate: number;

  listeTotauxTva: Array<TotauxTva>;
  totaux: Totaux;

  constructor(private entreeSortieService: EntreeSortieService,
              private messageTool: MessageTool) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entete'] || changes['forceUpdate']) {
      this.getListeTotauxTva();
      this.getTotaux();
    }
  }

  private getListeTotauxTva() {
    if (this.entete) {
      this.entreeSortieService.getTotauxTva(this.entete.idEntreeSortieEntete, null).subscribe(data => {
        this.listeTotauxTva = data.length > 0 ? data : null;
      }, err => {
        this.listeTotauxTva = null;
        this.messageTool.sendError(err);
      });
    }
  }

  private getTotaux() {
    if (this.entete) {
      this.entreeSortieService.getTotaux(this.entete.idEntreeSortieEntete, null).subscribe(data => {
        this.totaux = data;
      }, err => {
        this.totaux = null;
        this.messageTool.sendError(err);
      });
    }
  }
}
