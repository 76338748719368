import {InventaireEntete} from "../epona-api/InventaireEntete";
import {InventaireLigne} from "../epona-api/InventaireLigne";
import {Article} from "../epona-api/Article";
import {Fournisseur} from "../epona-api/Fournisseur";
import {ParametrageLieuFournisseur} from "../epona-api/parametrage-lieu-fournisseur";
import {Lieu} from "../epona-api/Lieu";

export class DialogueDataParametrageLieuFournisseur {

  fournisseur: Fournisseur;
  lieu: Lieu;
  parametrageLieuFournisseur: ParametrageLieuFournisseur;


}
