import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup, ValidationErrors,
  Validators
} from "@angular/forms";
import {LotFilter} from "../../model/epona-ui/lot-filter";
import {MessageTool} from "../../commons/MessageTool";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
  selector: 'epona-filtre-lots',
  templateUrl: './filtre-lots.component.html',
  styleUrls: ['./filtre-lots.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class FiltreLotsComponent implements OnInit, OnChanges {
  form: UntypedFormGroup;

  @Input() filter: LotFilter;
  @Output() readonly filterSubmitted = new EventEmitter<LotFilter>();

  constructor(private fb: UntypedFormBuilder, private messageTool: MessageTool) {
    this.form = this.fb.group({
      fournisseur : this.fb.control(null),
      marche      : this.fb.control(null),
      article     : this.fb.control(null),
      numeroLot   : this.fb.control(null, Validators.required)
    });

    this.form.setValidators(FiltreLotsComponent.required);
  }

  onSubmit() {

    if (this.form.hasError('required')) {
      this.messageTool.sendErrorMessage('Merci de renseigner un fournisseur, un marché ou un article');
      return;
    }

    if (this.form.dirty) {
      const newFilter = new LotFilter();

      newFilter.article     = this.form.get('article').value;
      newFilter.marche      = this.form.get('marche').value;
      newFilter.numeroLot   = this.form.get('numeroLot').value ? this.form.get('numeroLot').value.split('\n').join(';') : null;
      newFilter.fournisseur = this.form.get('fournisseur').value;

      this.filterSubmitted.emit(newFilter);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }
  }

  private initFieldsFilter() {
    this.form.get('numeroLot').setValue(this.filter.numeroLot);
    this.form.get('marche').setValue(this.filter.marche);
    this.form.get('article').setValue(this.filter.article);
    this.form.get('fournisseur').setValue(this.filter.fournisseur);

    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  ngOnInit(): void {
  }

  private static required(formCtrl: AbstractControl): ValidationErrors {
    if (!formCtrl.get('fournisseur').value && !formCtrl.get('marche').value && !formCtrl.get('article').value) {
      return {'required': true};

    } else {
      return null;
    }
  }
}
