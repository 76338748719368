import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogConsultationBlComponent} from "../dialog-consultation-bl/dialog-consultation-bl.component";

@Component({
  selector: 'epona-datatable-link-bl',
  templateUrl: './datatable-link-bl.component.html',
  styleUrls: ['./datatable-link-bl.component.css']
})
export class DatatableLinkBlComponent implements OnInit, OnChanges {
  dataSource = new MatTableDataSource<EntreeSortieEntete>([]);

  @Input() label: string;
  @Input() listeBL : Array<EntreeSortieEntete>;
  @Input() linkFromDialog: boolean = false;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['listeBL']) {
      this.dataSource.data = this.listeBL;
    }
  }

  openDialog(bl: EntreeSortieEntete) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = bl;

    this.dialog.open(DialogConsultationBlComponent, dialogConfig);

  }

}
