<mat-select [formControl]="formCtrl"
            (selectionChange)="selectionChanged()"
            *ngIf="displayType === 'select'"
            #selectGroupe>
  <mat-select-trigger>
    {{ selectGroupe.value !== DEFAULT ? getCurrentItem()?.libelle : defaultLabel }}
  </mat-select-trigger>

  <mat-option [value]="DEFAULT">{{ defaultLabel }}</mat-option>
  <mat-option *ngFor="let item of liste"
              [value]="item.idContexteFdnc"
              [disabled]="contexteDisabled(item)">
    {{ item.libelle }}
  </mat-option>
</mat-select>

<mat-radio-group [formControl]="formCtrl" (change)="selectionChanged()" *ngIf="displayType === 'radio'">
  <ul>
    <li *ngFor="let item of liste">
      <mat-radio-button [value]="item.idContexteFdnc"
                        [disabled]="contexteDisabled(item)">
        {{ item.libelle }}
      </mat-radio-button>

      <aide *ngIf="item.exemples">
        <dl class="aide-contenu">
          <dt>{{ item.libelle }}</dt>
          <dd>{{ item.exemples }}</dd>
        </dl>
      </aide>
    </li>
  </ul>
</mat-radio-group>
