import {Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from "@angular/material/form-field";
import {ControlValueAccessor, NgControl, UntypedFormControl} from "@angular/forms";
import {TempsTraitementFdnc} from "../../../model/bna-api/temps-traitement-fdnc";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {CacheService} from "../../../services/cache.service";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {BnaFdncService} from "../../../services/bna/bna-fdnc.service";

@Component({
  selector: 'epona-select-temps-traitement-fdnc',
  templateUrl: './select-temps-traitement-fdnc.component.html',
  styleUrls: ['./select-temps-traitement-fdnc.component.css'],
  providers: [{provide: MatFormFieldControl, useExisting: SelectTempsTraitementFdncComponent}]
})
export class SelectTempsTraitementFdncComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<TempsTraitementFdnc|null> {

  @Input() defaultLabel: string = '- Aucun -';

  public readonly DEFAULT = Const.DEFAULT;
  private readonly CACHE_KEY: string = 'temps-traitement';

  formCtrl: UntypedFormControl;
  liste: TempsTraitementFdnc[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private bnaFdncService: BnaFdncService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: TempsTraitementFdnc): void {
    this.formCtrl.setValue(obj ? obj.idTempsTraitementFdnc : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): TempsTraitementFdnc {
    return this.getCurrentItem();
  }
  set value(value: TempsTraitementFdnc) {
    this.formCtrl.setValue(value ? value.idTempsTraitementFdnc : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): TempsTraitementFdnc {
    return this.liste && this.liste.find(item => item.idTempsTraitementFdnc === this.formCtrl.value)
  }

  private loadListe() {
    // Si la liste a déjà été chargée
    if (this.cacheService.has(this.CACHE_KEY)) {
      this.setAndSortListeFromCache();

    // Sinon récupération de la liste via l'API
    } else {
      this.bnaFdncService.getListeTempsTraitementFdnc().subscribe(data => {
        this.cacheService.set(this.CACHE_KEY, data);
        this.setAndSortListeFromCache();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = [];

    // Tri et stockage de la liste chargée
    this.tri(this.cacheService.get(this.CACHE_KEY));
    this.liste = this.liste.concat(this.cacheService.get(this.CACHE_KEY));
  }

  tri(liste: Array<TempsTraitementFdnc>) {
    liste.sort(TempsTraitementFdnc.sort);
  }
}
