<h1>Modification du fournisseur</h1>

<div class="fiche">

  <epona-fournisseur-parametrage-global [fournisseur]="fournisseur" class="entete" style="width: 40%"></epona-fournisseur-parametrage-global>

  <div style="display: block">
      <epona-fournisseur-parametrage-local [fournisseur]="fournisseur" style="width: 60%"></epona-fournisseur-parametrage-local>
      <epona-liste-lot-marche [fournisseur]="fournisseur"></epona-liste-lot-marche>
  </div>

</div>
