import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Lieu} from '../../model/epona-api/Lieu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {TdbInventairesParLieu} from '../../model/epona-api/TdbInventairesParLieu';
import {TableauBordService} from '../../services/epona/tableau-bord.service';
import {InventaireSearch} from '../../model/epona-api/InventaireSearch';
import {MatSort} from "@angular/material/sort";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
  selector: 'epona-card-inventaires',
  templateUrl: './card-inventaires.component.html',
  styleUrls: ['./card-inventaires.component.css']
})
export class CardInventairesComponent implements OnInit, AfterViewInit, OnChanges {
  displayedColumns: string[] = ['batiment', 'lieu', 'dateDernierInventaireValide', 'inventaireNonValide'];
  listeInventairesParLieu: Array<TdbInventairesParLieu>;
  dataSource = new MatTableDataSource<TdbInventairesParLieu>([]);
  @Input() lieuCourant: Lieu;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  test = [];
  constructor(private tdbService: TableauBordService) { }

  ngOnInit(): void {
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingdataAccessor;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['lieuCourant']){
      this.rempliListeInventaire();
    }
  }

  rempliListeInventaire() {
    const inventaireSearch = new InventaireSearch();
    this.dataSource.data = [];

    this.listeInventairesParLieu = new Array<TdbInventairesParLieu>();
    inventaireSearch.idCrous       = this.lieuCourant.idCrous;
    inventaireSearch.idBatimentBns = this.lieuCourant.idBatimentBns;
    inventaireSearch.idLieuBns     = this.lieuCourant.idLieuBns;
    inventaireSearch.idRu          = this.lieuCourant.idRu;
    inventaireSearch.idUd          = this.lieuCourant.idUd;

    this.tdbService.getInventaire(inventaireSearch).subscribe(data => {
      for (const dataUnit of data) {
        const nbInventairesParLieu = {} as TdbInventairesParLieu;
        nbInventairesParLieu.lieu = dataUnit.lieu;
        nbInventairesParLieu.dernierInventaireValide = dataUnit.dernierInventaireValide;
        nbInventairesParLieu.inventaireNonValide = dataUnit.inventaireNonValide;
        this.listeInventairesParLieu.push(nbInventairesParLieu);
      }
      this.dataSource.data = this.listeInventairesParLieu;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  sortingdataAccessor(data: TdbInventairesParLieu, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'batiment'                    : return SortHelper.stringToString(data.lieu?.nomRu)
      case 'lieu'                        : return SortHelper.stringToString(data.lieu?.nomUd);
      case 'dateDernierInventaireValide' : return data.dernierInventaireValide?.idInventaireEntete ?
                                                          SortHelper.dateToNumber(data.dernierInventaireValide?.dateValidation) : 'aucun';
      case 'inventaireNonValide'         : return SortHelper.stringToString(data.inventaireNonValide?.libelle);
      default                            : return data[sortHeaderId];
    }
  }

}
