import {TypeEcartDTO} from "./TypeEcartDTO";
import {EntreeSortieLigneDetailEcart} from "./EntreeSortieLigneDetailEcart";

export class EntreeSortieLigneDetailEcartDTO {
  type: TypeEcartDTO;
  quantite: number;
  commentaire: string;

  constructor(detailEcart: EntreeSortieLigneDetailEcart) {
    this.type = new TypeEcartDTO(detailEcart.type);
    this.quantite = detailEcart.quantite;
    this.commentaire = detailEcart.commentaire;
  }

  static buildList(listeDetailsEcart: EntreeSortieLigneDetailEcart[]) {
    const listeDetailsDTO: EntreeSortieLigneDetailEcartDTO[] = [];
    for (const detail of listeDetailsEcart) {
      listeDetailsDTO.push(new EntreeSortieLigneDetailEcartDTO(detail));
    }
    return listeDetailsDTO;
  }
}
