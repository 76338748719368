import {TypeTvaDTO} from "./TypeTvaDTO";
import {ServiceGestionnaireDTO} from "./ServiceGestionnaireDTO";
import {DestinationDTO} from "./DestinationDTO";
import {OperationDTO} from "./OperationDTO";
import {ElementAxeComplementaireDTO} from "./ElementAxeComplementaireDTO";
import {EvenementDTO} from "./EvenementDTO";
import {EngagementJuridiqueLigne} from "./EngagementJuridiqueLigne";
import {EngagementJuridiqueCumulDTO} from "./EngagementJuridiqueCumulDTO";

export class EngagementJuridiqueLigneDTO {
  cumul: EngagementJuridiqueCumulDTO;
  destination: DestinationDTO;
  operation: OperationDTO;
  serviceGestionnaire: ServiceGestionnaireDTO;
  elementAxeComplementaire: ElementAxeComplementaireDTO;
  evenement: EvenementDTO;
  typeTva: TypeTvaDTO;
  montantHt: number;

  constructor(ligne: EngagementJuridiqueLigne) {
    this.cumul                    = ligne.cumul ? new EngagementJuridiqueCumulDTO(ligne.cumul) : null;
    this.destination              = ligne.destination ? new DestinationDTO(ligne.destination) : null;
    this.operation                = ligne.operation ? new OperationDTO(ligne.operation) : null;
    this.serviceGestionnaire      = ligne.serviceGestionnaire ? new ServiceGestionnaireDTO(ligne.serviceGestionnaire) : null;
    this.elementAxeComplementaire = ligne.elementAxeComplementaire ? new ElementAxeComplementaireDTO(ligne.elementAxeComplementaire) : null;
    this.evenement                = ligne.evenement ? new EvenementDTO(ligne.evenement) : null;
    this.typeTva                  = ligne.typeTva ? new TypeTvaDTO(ligne.typeTva) : null;
    this.montantHt                = ligne.montantHt;
  }
}
