import {DTOWithLieu} from "./DTOWithLieu";

export class InventaireQueryParams extends DTOWithLieu {
  idInventaire: number;
  utilisateur: string;
  libelle: string;
  cloture: boolean;
  annee: number;
  mois: number;
}
