/* *********************************************************************** */
/* /!\               Fichier issu de angular-app-commons               /!\ */
/* /!\ Merci d'avertir le Cnous si une modification doit être apportée /!\ */
/* *********************************************************************** */

import { Injectable } from '@angular/core';
import {DatePipe} from "@angular/common";
import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from "@angular/common/http";
import {Format} from "../commons/constants/Format";
import {MessageTool} from "../commons/MessageTool";
import {forkJoin, Observable, of} from "rxjs";
import {HttpDataWithPagination} from "../model/http-data-with-pagination";
import {map, mergeAll} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient,
              private dateFormat: DatePipe,
              private messageService: MessageTool) { }

  buildParams(paramObject: {[key: string]: any}): HttpParams | undefined {
    let params;

    if (paramObject) {
      params = new HttpParams();

      // Pour chaque paramètre
      for (const param in paramObject) {
        // Si le paramètre a une valeur
        if (paramObject[param] !== null && paramObject[param] !== undefined) {
          // Si le paramètre est un objet
          if (typeof paramObject[param] === "object") {
            // Si le paramètre est une date
            if (paramObject[param] instanceof Date) {
              params = params.append(param, ''+this.dateFormat.transform(paramObject[param], Format.FORMAT_DATE_URL_REST))

            // Si le paramètre est un tableau
            } else if (paramObject[param] instanceof Array) {
              for (const i in paramObject[param]) {
                if (paramObject[param].hasOwnProperty(i)) {
                  params = params.append(param, '' + paramObject[param][i]);
                }
              }

            // Sinon, parcours de l'objet pour ajouter chacun des ses paramètres
            } else {
              for (const subParam in paramObject[param]) {
                if (paramObject[param].hasOwnProperty(subParam) && paramObject[param][subParam] !== null) {
                  params = params.append(subParam, '' + paramObject[param][subParam]);
                }
              }
            }
          } else {
            params = params.append(param, '' + paramObject[param]);
          }
        }
      }
    }

    return params;
  }

  downloadFile(url: string, defaultContentType: string|undefined = undefined, defaultFilename: string|undefined = undefined) {
    this.httpClient.get(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    }).subscribe({
      next: (response: HttpResponse<any>) => {
        const contentType = HttpService.getContentType(response, defaultContentType);
        const filename = HttpService.getFilename(response, defaultFilename);
        const blob = new Blob([response.body], contentType ? {type: contentType} : {});
        const downloadURL = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = ''+filename;
        link.click();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status === 404) {
          this.messageService.sendError(err);
        }
      }
    });
  }

  private static getFilename(response: HttpResponse<any>, defaultFilename: string|undefined): string|undefined {
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition) {
      return contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    } else {
      return defaultFilename;
    }
  }

  private static getContentType(response: HttpResponse<any>, defaultContentType: string|undefined): string|undefined {
    const contentType = response.headers.get('content-type');
    if (contentType) {
      return contentType.trim();
    } else {
      return defaultContentType;
    }
  }

  getListeComplete<T, S>(url: string, search: S): Observable<T[]> {
    // Récupération de la première page de résultats
    return this.getListeWithPagination(url, search, 1).pipe(
      // Traitement du résultat de la première page
      map<HttpDataWithPagination<T>, Observable<T[]>>(response => {
        const listeComplete = response.data;

        // Pas de pagination => envoi des résultats
        if (!response.pagination) {
          return of(listeComplete); // of() car un Observable est attendu
        }

        // Calcul du nombre total de pages
        const nbPages = Math.ceil(response.pagination.count / response.pagination.limit);

        // Pas d'autre page => envoi des résultats
        if (nbPages === 1) {
          return of(listeComplete); // of() car un Observable est attendu
        }

        // Liste des appels à effectuer pour récupérer les autres pages
        const listeObservables: Observable<HttpDataWithPagination<T>>[] = [];
        for (let pageNumber = 2; pageNumber <= nbPages; pageNumber++) {
          listeObservables.push(this.getListeWithPagination(url, search, pageNumber));
        }

        // Exécution en parallèle puis traitement unique des résultats
        return forkJoin(listeObservables).pipe(map(data => {
          // Ajout des résultats de chaque appel à la liste complète
          for (let resp of data) {
            listeComplete.push(... resp.data);
          }
          return listeComplete; // Retourne un Observable car dans un forkJoin
        }));
      }),
      mergeAll()); // Sinon un Observable<Observable<>> est retourné
  }

  getListeWithPagination<T, S>(url: string, search: S, pageNumber: number|undefined = undefined): Observable<HttpDataWithPagination<T>> {
    let params = this.buildParams(search);
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber);
    }

    return this.httpClient.get<T[]>(url, { observe: 'response', params: params }).pipe(
      map((response: HttpResponse<T[]>) => {
        const httpData = new HttpDataWithPagination<T>();
        httpData.data = response.body;
        if (response.headers.get('Pagination-Count') !== null) {
          httpData.pagination = {
            count: +response.headers.get('Pagination-Count')!,
            page:  +response.headers.get('Pagination-Page')!,
            limit: +response.headers.get('Pagination-Limit')!
          }
        }
        return httpData;
      })
    );
  }
}
