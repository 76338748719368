import {Component, Input} from '@angular/core';
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {Article} from '../../model/epona-api/Article';

@Component({
  selector: 'epona-tableau-commande-livraison',
  templateUrl: './tableau-commande-livraison.component.html',
  styleUrls: ['./tableau-commande-livraison.component.css']
})
export class TableauCommandeLivraisonComponent {
  @Input() commande: CommandeEntete|null = null;
  @Input() livraison: EntreeSortieEntete|null = null;
  @Input() article: Article|null = null;
}
