<mat-card>
  <mat-card-header>
    <mat-card-title>Informations</mat-card-title>
  </mat-card-header>
  <form class="form" (ngSubmit)="onSubmit()" [formGroup]="inventaireEnteteForm">
    <mat-form-field *ngIf="!isCreation()">
      <mat-label>Identifiant d'inventaire</mat-label>

      <input matInput formControlName="idEnteteInventaire">
    </mat-form-field>

    <mat-form-field *ngIf="!isCreation()">
      <mat-label>État</mat-label>

      <input matInput value="Clôturé" disabled="disabled" *ngIf="inventaireEntete.dateCloture" />
      <mat-icon matSuffix *ngIf="inventaireEntete.dateCloture" style="color: green">check</mat-icon>

      <input matInput value="Non clôturé" disabled="disabled" *ngIf="!inventaireEntete.dateCloture" />
      <mat-icon matSuffix *ngIf="!inventaireEntete.dateCloture" style="color: orange">warning</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Libellé</mat-label>

      <input matInput formControlName="libelle" maxlength="255" required>

      <mat-error *ngIf="libelleCtrl.hasError('required')">Libellé obligatoire</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Commentaire</mat-label>

      <textarea matInput cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                formControlName="commentaire"
                maxlength="500">
      </textarea>
    </mat-form-field>

    <div class="form-actions">
      <button type="submit"
              mat-raised-button color="primary"
              [disabled]="isInvalidForm() || !isEditable() || !(onlineService.isOnline | async)">
        <fa-icon icon="save"></fa-icon> Enregistrer
      </button>

      <button type="button"
              mat-raised-button color="primary"
              (click)="openDialogCloture()" [disabled]="!isEditable() || !(onlineService.isOnline | async)" *ngIf="!isCreation()">
        <fa-icon icon="lock"></fa-icon> Clôturer…
      </button>

      <button type="button"
              mat-raised-button color="primary"
              [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button type="button"
                mat-menu-item
                (click)="openDialogSuppression()"
                [disabled]="!isEditable() || !(onlineService.isOnline | async)"
                *ngIf="!isCreation()">
          <fa-icon icon="trash"></fa-icon> Supprimer…
        </button>
      </mat-menu>
    </div>
  </form>

  <mat-expansion-panel #metaDataPanel *ngIf="!isCreation()">
    <mat-expansion-panel-header>
      <mat-panel-description>
        {{ metaDataPanel.expanded ? 'Masquer' : 'Afficher' }}
        les informations supplémentaires
      </mat-panel-description>
    </mat-expansion-panel-header>

    <epona-meta-data type="creation"
                     [date]="inventaireEntete.dateCreation"
                     [utilisateur]="inventaireEntete.utilisateur"
                     *ngIf="inventaireEntete?.dateCreation">
    </epona-meta-data>

    <epona-meta-data type="modification"
                     [date]="inventaireEntete.dateModification"
                     [utilisateur]="inventaireEntete.utilisateurModification"
                     *ngIf="inventaireEntete?.dateModification">
    </epona-meta-data>

    <epona-meta-data type="cloture"
                     [date]="inventaireEntete.dateCloture"
                     [utilisateur]="inventaireEntete.utilisateurCloture"
                     *ngIf="inventaireEntete?.dateCloture">
    </epona-meta-data>
  </mat-expansion-panel>
</mat-card>
