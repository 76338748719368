import {TypeOperationsOrion} from "./TypeOperationsOrion";
import {EngagementJuridiqueEntete} from "./EngagementJuridiqueEntete";
import {EngagementJuridiqueLigne} from "./EngagementJuridiqueLigne";

export class OrionSuivi {
  idSuivi: number;
  typeOperations: TypeOperationsOrion;
  engagementJuridique: EngagementJuridiqueEntete;
  engagementJuridiqueLigne: EngagementJuridiqueLigne|null;
  idEntreeSortieEntete: number|null;
  montantHt: number;
  dateEnvoi: Date;
  utilisateurEnvoi: string;
  dateErreurEnvoi: Date;
  utilisateurErreurEnvoi: string;
  messageErreurEnvoi: string;

  static sort(a: OrionSuivi, b: OrionSuivi): number {
    const date1 = a.dateEnvoi ? a.dateEnvoi : a.dateErreurEnvoi;
    const date2 = b.dateEnvoi ? b.dateEnvoi : b.dateErreurEnvoi;
    return date1 === date2 ? 0 : (date1 < date2 ? -1 : 1);
  }

}
