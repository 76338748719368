<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>{{ data.titre }}</span>
    <span class="space"></span>
    <button type="button" mat-icon-button [mat-dialog-close]="listeDocumentsModifiee">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <epona-tableau-documents [loadListeDocumentsFn]="data.loadListeDocumentsFn"
                           [loadDocumentFn]="data.loadDocumentFn"
                           [downloadFileFn]="data.downloadFileFn"
                           [postDocumentFn]="data.postDocumentFn"
                           [putDocumentFn]="data.putDocumentFn"
                           [deleteDocumentFn]="data.deleteDocumentFn"
                           [droitSaisie]="data.droitSaisie"
                           [modifiable]="data.modifiable"
                           [nbDocumentsMax]="data.nbDocumentsMax"

                           (listeDocumentsModifiee)="onListeDocumentsModifiee()"></epona-tableau-documents>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="close()">Fermer</button>
</mat-dialog-actions>
