<mat-card class="ht-tva" *ngIf="totaux && listeTotauxTva">
  <table aria-label="Total HT du bordereau et totaux par taux de TVA">
    <tbody>
    <tr>
      <th scope="row">Total HT</th>
      <td class="montant">{{ totaux.totalHt | decimal2 }} €</td>
    </tr>
    <tr *ngFor="let tva of listeTotauxTva">
      <th class="tauxTva" scope="row">{{ tva.tauxTva |decimal1 }} %</th>
      <td class="montant">{{ tva.sousTotalTva | decimal2 }} €</td>
    </tr>
    </tbody>
  </table>
</mat-card>

<mat-card class="ttc" *ngIf="totaux && totaux.totalTtc > 0">
  <table aria-label="Total TTC du bordereau">
    <tbody>
    <tr>
      <th scope="row">Total TTC</th>
      <td class="montant">{{ totaux.totalTtc | decimal2 }} €</td>
    </tr>
    </tbody>
  </table>
</mat-card>
