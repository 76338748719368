import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Const} from "../../commons/constants/Const";
import {FormBuilder, FormGroup} from "@angular/forms";
import {StockCompactFilter} from "../../model/epona-ui/StockCompactFilter";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {Groupe} from "../../model/epona-api/Groupe";
import {SousGroupe} from "../../model/epona-api/SousGroupe";
import {Article} from "../../model/epona-api/Article";

@Component({
  selector: 'epona-filtre-stocks',
  templateUrl: './filtre-stocks.component.html',
  styleUrls: ['./filtre-stocks.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class FiltreStocksComponent implements OnChanges {
  readonly DEFAULT = Const.DEFAULT;

  @Input() filter: StockCompactFilter;
  @Output() readonly filterSubmitted = new EventEmitter<StockCompactFilter>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      groupe:                 this.fb.control<Groupe|null>(null),
      sousGroupe:             this.fb.control<SousGroupe|null>(null),
      article:                this.fb.control<Article|null>(null),
      masquerQuantitesNulles: this.fb.control<boolean|null>(null),
      stockFaible:            this.fb.control<boolean|null>(null),
      nombreJoursAvantDlc:    this.fb.control<number|null>(null)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }
  }

  private initFieldsFilter() {
    this.form.get('groupe').setValue(this.filter.groupe);
    this.form.get('sousGroupe').setValue(this.filter.sousGroupe);
    this.form.get('article').setValue(this.filter.article);
    this.form.get('masquerQuantitesNulles').setValue(this.filter.masquerQuantitesNulles);
    this.form.get('stockFaible').setValue(this.filter.stockFaible);
    this.form.get('nombreJoursAvantDlc').setValue(this.filter.nombreJoursAvantDlc);
  }

  onSubmit () {
    const filter = new StockCompactFilter();

    filter.groupe                 = this.form.get('groupe').value;
    filter.sousGroupe             = this.form.get('sousGroupe').value;
    filter.article                = this.form.get('article').value;
    filter.masquerQuantitesNulles = this.form.get('masquerQuantitesNulles').value;
    filter.stockFaible            = this.form.get('stockFaible').value;
    filter.nombreJoursAvantDlc    = this.nullIfEmptyFormControl('nombreJoursAvantDlc');

    this.filterSubmitted.emit(filter);
  }

  private nullIfEmptyFormControl(formControlName: any): any {
    return this.form.get(formControlName).value === '' ? null : this.form.get(formControlName).value;
  }
}
