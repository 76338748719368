export class SousLotZgSearch {
  idCrous: number;
  codeMarcheOrion: string;
  numeroLot: number;
  numeroSousLot: number;
  date: Date;
  idFournisseur;

  fields: string;
}
