<form class="form" (ngSubmit)="onSubmit()" [formGroup]="form" style="margin-top: 1em;">
  <div class="mat-form-field-row">
    <mat-form-field>
      <mat-label>Lot-marché</mat-label>
      <input matInput value="{{ entete?.codeMarche }}" disabled="disabled" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Fournisseur</mat-label>
      <epona-select-fournisseur formControlName="fournisseur"></epona-select-fournisseur>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date commande</mat-label>
      <input matInput
             [matDatepicker]="picker"
             formControlName="dateCommande">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="mat-form-field-row">
    <mat-form-field>
      <mat-label>Service émetteur</mat-label>
      <epona-select-referentiel-orion formControlName="serviceEmetteur"
                                      [type]="TypeReferentielOrion.SERVICE_EMETTEUR"
                                      required="true"></epona-select-referentiel-orion>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Objet</mat-label>
      <textarea matInput cdkTextareaAutosize
                formControlName="objet"
                required="required"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                maxlength="50">
      </textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Observation</mat-label>
      <textarea matInput cdkTextareaAutosize
                formControlName="observation"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                maxlength="512">
      </textarea>
    </mat-form-field>
  </div>

  <div class="mat-form-field-row">
    <mat-form-field>
      <mat-label>Exercice budgétaire</mat-label>
      <input matInput value="{{ entete?.exerciceBudgetaire }}" disabled="disabled" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Statut</mat-label>
      <input matInput value="{{ entete?.statut.libelle }}" disabled="disabled" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Agent émetteur</mat-label>
      <input matInput value="{{ entete?.codeAgentEmetteur }}" disabled="disabled" />
    </mat-form-field>
  </div>

  <div class="form-actions">
    <button type="submit"
            mat-raised-button color="primary"
            [disabled]="form.invalid" *ngIf="modifiable">
      <fa-icon icon="save"></fa-icon> Enregistrer
    </button>
  </div>
</form>
