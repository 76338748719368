<epona-lieu-filter [lieu]="lieuCourant"
                   (lieuSubmitted)="getLieuSubmitted($event)"></epona-lieu-filter>

<h1>Ventes en temps réel</h1>

<div class="crous-recherche">
  <div class="crous-recherche-filtre">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Recherche</mat-card-title>
        </mat-card-header>

        <mat-divider></mat-divider>

        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Journée</mat-label>
          <mat-select formControlName="journee">
            <mat-option [value]="today">Aujourd'hui</mat-option>
            <mat-option [value]="yesterday">Hier</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Heure de début</mat-label>
          <input matInput type="time" min="00:00" max="23:59" required
                 [value]="'00:00'" formControlName="heureDebut">
        </mat-form-field>

        <div class="filtre-actions">
          <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid">
            <fa-icon icon="search"></fa-icon> Rechercher
          </button>
        </div>
      </mat-card>
    </form>
  </div>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Ventes</mat-card-title>
      </mat-card-header>

      <mat-divider></mat-divider>

      <table mat-table [dataSource]="dataSource"
             class="mat-elevation-z8"
             matSort matSortActive="montantTTC" matSortDirection="desc" matSortDisableClear="true"
             aria-label="Liste de ventes par caisse">

        <ng-container matColumnDef="ru">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>RU</th>
          <td mat-cell *matCellDef="let element">{{element.caisse.ud.ru.nom}}</td>
        </ng-container>

        <ng-container matColumnDef="ud">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>UD</th>
          <td mat-cell *matCellDef="let element">{{element.caisse.ud.nom}}</td>
        </ng-container>

        <ng-container matColumnDef="caisse">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Caisse</th>
          <td mat-cell *matCellDef="let element">{{element.caisse.nom}}</td>
        </ng-container>

        <ng-container matColumnDef="montantTTC">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>CA TTC</th>
          <td mat-cell *matCellDef="let element">{{element.montantTTC | mycurrency}}</td>
        </ng-container>

        <ng-container matColumnDef="nombreTickets">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Nb. tickets vente</th>
          <td mat-cell *matCellDef="let element">{{element.nombreTickets}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>
    </mat-card>
  </div>
</div>
