import {BooleanComponent} from "../commons/outputs/boolean/boolean.component";
import {StringHelper} from "./string-helper";
import {ArticleDTO} from '../model/epona-api/ArticleDTO';
import {LigneArticleDTO} from '../fdnc/dialog-creation-fdnc/dialog-creation-fdnc.component';

export class SortHelper {
  static stringToString(str: string): string {
    if (str === undefined || str === null) {
      return '';
    }
    return StringHelper.removeDiacritics(str);
  }

  static booleanToString(bool: boolean): string {
    if (bool === true) {
      return BooleanComponent.DEFAULT_LABEL_TRUE;
    } else if (bool === false) {
      return BooleanComponent.DEFAULT_LABEL_FALSE;
    } else {
      return BooleanComponent.DEFAULT_LABEL_NULL;
    }
  }

  static dateToNumber(date: Date): number {
    return date ? new Date(date).getTime() : 0;
  }

  static compareArticleAchat( a: LigneArticleDTO, b: LigneArticleDTO ) {
    if ( a.article.designationAchat < b.article.designationAchat ){
      return -1;
    }
    if ( a.article.designationAchat > b.article.designationAchat ){
      return 1;
    }
    return 0;
  }
}
