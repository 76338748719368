import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {Fournisseur} from "../../model/epona-api/Fournisseur";
import {UserService} from "../../services/user.service";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {FournisseurService} from "../../services/epona/fournisseur.service";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";

@Component({
  selector: 'epona-fournisseur-parametrage-global',
  templateUrl: './fournisseur-parametrage-global.component.html',
  styleUrls: ['./fournisseur-parametrage-global.component.css']
})
export class FournisseurParametrageGlobalComponent implements OnInit, OnChanges {

  @Input() fournisseur: Fournisseur;

  fournisseurGlobalForm: UntypedFormGroup;

  @ViewChild(FormGroupDirective)
  form: FormGroupDirective;

  disabledForm = true;

  constructor(private fb: UntypedFormBuilder,
              private messageTool: MessageTool,
              private fournisseurService: FournisseurService,
              private userService: UserService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fournisseur']) {
      this.disabledForm = !this.userService.utilisateurCourant.possedeDroit(CodeDroit.GESTION_FOURNISSEURS_GLOBALE)
      this.initForm();
    }
  }

  initForm() {
    if (this.fournisseur) {
      this.fournisseurGlobalForm = this.fb.group({
        nom: [ { value:this.fournisseur.nom, disabled: true }],
        siret: [ { value:this.fournisseur.siret, disabled: true }],
        telephone: [ { value:this.fournisseur.telephone, disabled: this.disabledForm }, Validators.maxLength(20)],
        fax: [ { value:this.fournisseur.fax, disabled: this.disabledForm }, Validators.maxLength(20)],
        mail: [ { value:this.fournisseur.mail, disabled: this.disabledForm }, [Validators.maxLength(100), Validators.email]],
        adresseNumero: [ { value:this.fournisseur.adresseNumero, disabled: true }],
        adresseBTQ: [ { value:this.fournisseur.adresseBTQ, disabled: true }],
        adresseComplement: [ { value:this.fournisseur.adresseComplement, disabled: true }],
        adresseRue: [ { value:this.fournisseur.adresseRue, disabled: true }],
        adressePays: [ { value:this.fournisseur.adressePays, disabled: true }],
        adresseCodePostal: [ { value:this.fournisseur.adresseCodePostal, disabled: true }],
        adresseVille: [ { value:this.fournisseur.adresseVille, disabled: true }],
        codeTiersOrion: [ { value:this.fournisseur.codeTiersOrion, disabled: true }],
        codeClient: [ { value:this.fournisseur.codeClient, disabled: this.disabledForm }, Validators.maxLength(50)]
      })
    }
  }

  onSubmit() {
    const fournisseur = Fournisseur.fromRaw(this.fournisseurGlobalForm.getRawValue(), this.fournisseur.idFournisseur);
    this.fournisseurService.putFournisseur(fournisseur).subscribe(res => {
      this.messageTool.sendSuccess("Le fournisseur a été enregistré avec succès", ClearMessages.TRUE);
    }, err => {
      this.messageTool.sendError(err);
    });
  }

}
