<p class="alert alert-info" role="alert" *ngIf="modifiable && commande.fraisPortPrevisionnels > 0">
  Les lignes correspondant aux frais de port prévisionnels ({{ commande.fraisPortPrevisionnels | decimal2 }}&nbsp;€)
  seront ajoutées à l'engagement juridique au moment de l'envoi vers Orion.
</p>

<button type="button"
        mat-button
        class="lock"
        (click)="verrouille = !verrouille"
        matTooltip="{{ verrouille ? 'Modifier les valeurs' : 'Verrouiller les valeurs' }}"
        *ngIf="modifiable">
  <fa-icon icon="lock"   *ngIf="verrouille"></fa-icon>
  <fa-icon icon="unlock" *ngIf="!verrouille"></fa-icon>
</button>


<form [formGroup]="tableau.form" *ngFor="let tableau of tableaux">
  <div>
    <strong [ngClass]="{'total-ok': modifiable && tableau.cumul.totalHt === tableau.totalHt, 'total-ko': modifiable && tableau.cumul.totalHt !== tableau.totalHt}">Cumul no. {{ tableau.cumul.numeroCumul }}</strong> /
    <strong>Famille</strong>&nbsp;: {{ tableau.cumul.familleMarches.categorieMarches.code + tableau.cumul.familleMarches.code }} - {{ tableau.cumul.familleMarches.libelle }} /
    <strong>TVA</strong>&nbsp;: {{ tableau.cumul.tva.taux | decimal1 }} %
  </div>
  <div>
    <strong>Nature budgétaire</strong>&nbsp;: {{ tableau.cumul.natureBudgetaire.code }} - {{ tableau.cumul.natureBudgetaire.libelle }} /
    <strong>Compte comptable</strong>&nbsp;: {{ tableau.cumul.compteComptable.numero }} - {{ tableau.cumul.compteComptable.libelle }}
  </div>

  <table mat-table
         [dataSource]="tableau.dataSource"
         multiTemplateDataRows="true"
         formArrayName="lignes"
         class="mat-elevation-z8"
         aria-label="Liste des lignes du cumul de l'engagement juridique">

    <ng-container matColumnDef="destination">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Destination*</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <div *ngIf="verrouille">{{ data.destination?.libelle }}</div>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" *ngIf="!verrouille">
          <epona-select-referentiel-orion formControlName="destination"
                                          [type]="TypeReferentielOrion.DESTINATION"
                                          defaultLabel="- Aucune -"
                                          (valueChanged)="updateLigneT(tableau, index)"></epona-select-referentiel-orion>

          <mat-error *ngIf="hasErrorT(tableau.form, index, 'destination', 'required')"
                     matTooltip="Destination obligatoire"
                     matTooltipClass="error-mattooltip">Information obligatoire</mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="operation">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Opération</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <div *ngIf="verrouille">{{ data.operation?.libelle }}</div>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" *ngIf="!verrouille">
          <epona-select-referentiel-orion formControlName="operation"
                                          [type]="TypeReferentielOrion.OPERATION"
                                          defaultLabel="- Aucune -"
                                          (valueChanged)="updateLigneT(tableau, index)"></epona-select-referentiel-orion>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="serviceGestionnaire">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Service gestionnaire">Serv. gest.*</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <div *ngIf="verrouille">{{ data.serviceGestionnaire?.libelle }}</div>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" *ngIf="!verrouille">
          <epona-select-referentiel-orion formControlName="serviceGestionnaire"
                                          [type]="TypeReferentielOrion.SERVICE_GESTIONNAIRE"
                                          (valueChanged)="updateLigneT(tableau, index)"></epona-select-referentiel-orion>

          <mat-error *ngIf="hasErrorT(tableau.form, index, 'serviceGestionnaire', 'required')"
                     matTooltip="Service gestionnaire obligatoire"
                     matTooltipClass="error-mattooltip">Information obligatoire</mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="elementAxeComplementaire">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Élément d'axe complémentaire">Axe compl.</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <div *ngIf="verrouille">{{ data.elementAxeComplementaire?.libelle }}</div>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" *ngIf="!verrouille">
          <epona-select-referentiel-orion formControlName="elementAxeComplementaire"
                                          [type]="TypeReferentielOrion.ELEMENT_AXE_COMPLEMENTAIRE"
                                          (valueChanged)="updateLigneT(tableau, index)"></epona-select-referentiel-orion>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="evenement">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Événement</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <div *ngIf="verrouille">{{ data.evenement?.libelle }}</div>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" *ngIf="!verrouille">
          <epona-select-referentiel-orion formControlName="evenement"
                                          [type]="TypeReferentielOrion.EVENEMENT"
                                          (valueChanged)="updateLigneT(tableau, index)"></epona-select-referentiel-orion>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="typeTva">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Type de TVA">Type TVA*</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <div *ngIf="verrouille">{{ data.typeTva?.libelle }}</div>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" *ngIf="!verrouille">
          <epona-select-referentiel-orion formControlName="typeTva"
                                          [type]="TypeReferentielOrion.TYPE_TVA"
                                          (valueChanged)="updateLigneT(tableau, index)"></epona-select-referentiel-orion>

          <mat-error *ngIf="hasErrorT(tableau.form, index, 'typeTva', 'required')"
                     matTooltip="Type de TVA obligatoire"
                     matTooltipClass="error-mattooltip">Information obligatoire</mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="montantHt">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Total HT*</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" class="montant" [formGroupName]="index">
        <div *ngIf="verrouille">{{ data.montantHt | decimal2 }}</div>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" *ngIf="!verrouille">
          <input matInput type="number"
                 (blur)="updateLigneT(tableau, index)"
                 formControlName="montantHt"
                 step="0.01">

          <mat-error *ngIf="hasErrorT(tableau.form, index, 'montantHt', 'required')"
                     matTooltip="Montant HT obligatoire"
                     matTooltipClass="error-mattooltip">Information obligatoire</mat-error>
          <mat-error *ngIf="hasErrorT(tableau.form, index, 'montantHt', 'min')"
                     matTooltipClass="error-mattooltip">Montant négatif interdit</mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Actions</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex">
        <button type="button"
                mat-button
                (click)="supprimerLigne(tableau, index)"
                matTooltip="Supprimer la ligne"
                [disabled]="verrouille || tableau.lignes.length == 1">
          <fa-icon icon="trash"></fa-icon>
        </button>

        <button type="button"
                mat-button
                (click)="ajouterLigne(tableau, data)"
                matTooltip="Ajouter une ligne"
                [disabled]="verrouille">
          <fa-icon icon="plus"></fa-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    <!-- Ligne de pied de tableau pour le total -->
    <ng-container matColumnDef="boutonLignesCommande">
      <td mat-footer-cell *matFooterCellDef colspan="2">
        <a href="#"
           matTooltip="Afficher les lignes de la commande correspondant au cumul no. {{ tableau.cumul.numeroCumul }}"
           (click)="$event.preventDefault();openDialogLignesCumul(tableau)"
           *ngIf="!tableau.cumul.estCumulFraisPort">Lignes de la commande…</a>
        <span *ngIf="tableau.cumul.estCumulFraisPort">(frais de port)</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="totalLibelle">
      <td mat-footer-cell *matFooterCellDef colspan="4">
        Total
        <span *ngIf="modifiable">(attendu : {{ tableau.cumul.totalHt | decimal2 }}) :</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="totalMontantHt">
      <td mat-footer-cell *matFooterCellDef
          [ngClass]="{'total-ok': modifiable && tableau.cumul.totalHt === tableau.totalHt, 'total-ko': modifiable && tableau.cumul.totalHt !== tableau.totalHt}"
          class="montant">
        {{ tableau.totalHt | decimal2 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="resultat">
      <td mat-footer-cell *matFooterCellDef
          [ngClass]="{'total-ok': tableau.cumul.totalHt === tableau.totalHt, 'total-ko': tableau.cumul.totalHt !== tableau.totalHt}">
        <fa-icon icon="check-circle" *ngIf="tableau.cumul.totalHt === tableau.totalHt"></fa-icon>
        <fa-icon icon="times-circle" *ngIf="tableau.cumul.totalHt !== tableau.totalHt"></fa-icon>
      </td>
    </ng-container>

    <tr mat-footer-row *matFooterRowDef="displayedColumnsFooter"></tr>
  </table>
</form>
