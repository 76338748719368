import {Component, OnInit, ViewChild} from '@angular/core';
import {Lieu} from "../model/epona-api/Lieu";
import {MatTableDataSource} from "@angular/material/table";
import {DialogConfirmComponent} from "../commons/dialog-confirm/dialog-confirm.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {AjoutArticleModalComponent} from "./ajout-article-modal/ajout-article-modal.component";
import {MatSort} from "@angular/material/sort";
import {MessageTool} from "../commons/MessageTool";
import {ParametrageLieuArticle} from "../model/epona-api/ParametrageLieuArticle";
import {ParametrageLieuArticleService} from "../services/epona/parametrage-lieu-article.service";
import {ArticleTools} from "../commons/ArticleTools";
import {SortHelper} from "../helpers/sort-helper";

@Component({
  selector: 'epona-lieux-articles',
  templateUrl: './lieux-articles.component.html',
  styleUrls: ['./lieux-articles.component.css']
})
export class LieuxArticlesComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  lieuCourant: Lieu;

  dataSource = new MatTableDataSource<ParametrageLieuArticle>([]);
  // 'actions' dans displayedColumns permet d'afficher et d'utiliser le bouton (poubelle) supprimer
  displayedColumns = [
    'codeArticle',
    'designation',
    'stockMini',
    'uniteExploitation',
    'actions'
  ];

  // ========================================================================================= //
  // ==================================== DEBUT METHODES ===================================== //
  // ========================================================================================= //

  constructor(private parametrageLieuArticleService: ParametrageLieuArticleService,
              private dialog: MatDialog,
              private messageTool: MessageTool) {
  }

  ngOnInit() {
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();
    this.getListeParametrages();

    // Fonction de recherche
    this.dataSource.filterPredicate = (data: ParametrageLieuArticle, filter: string) => {
      const codeArticle = ArticleTools.getCodeArticle(data.article);
      const designation = ArticleTools.getDesignation(data.article);
      return codeArticle === filter
        || designation.trim().toLowerCase().indexOf(filter) > -1
        || ''+data.stockMini === filter
        ;
    };

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'codeArticle': return ArticleTools.getCodeArticle(item.article);
        case 'designation': return SortHelper.stringToString(ArticleTools.getDesignation(item.article));
        default: return item[property];
      }
    };

    this.dataSource.sort = this.sort;
  }

  getListeParametrages() {
    this.dataSource.data = [];
    if (this.lieuCourant.idLieu) {
      const fields = 'idParametrageLieuArticle,' +
        'article.articleAchat,' +
        'article.articleVente,' +
        'article.codeArticleAchat,' +
        'article.codeArticleVente,' +
        'article.designationAchat,' +
        'article.designationVente,' +
        'article.uniteExploitation.abreviation,' +
        'stockMini';

      this.parametrageLieuArticleService.getListeParametragesLieuArticle(this.lieuCourant.idLieu, fields)
        .subscribe(data => {
          this.dataSource.data = data;
        }, error => {
          this.messageTool.sendError(error);
        })
    }
  }

  /**
   * Appel le pop-up AjoutArticleModalComponent pour ajouter un article
   */
  openDialogAjoutParametrage(): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';

    const dialogRef = this.dialog.open(AjoutArticleModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.ajoutParametrage(result);
      }
    });
  }

  ajoutParametrage(parametrage: ParametrageLieuArticle) {
    parametrage.lieu = this.lieuCourant;
    parametrage.article.idCrous = this.lieuCourant.idCrous;

    this.parametrageLieuArticleService.postParametrageLieuArticle(parametrage).subscribe(result => {
      parametrage.idParametrageLieuArticle = result.idParametrageLieuArticle;
      this.dataSource.data = this.dataSource.data.concat([parametrage]);

      const designation = ArticleTools.getDesignation(parametrage.article);
      this.messageTool.sendSuccess(`Le paramétrage de l'article ${ designation } a été ajouté avec succès`);
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  updateStockMini(parametrageLieuArticle: ParametrageLieuArticle, event: FocusEvent) {
    this.updateValue(parametrageLieuArticle, 'stockMini', event);
  }

  // field = 'stockMini'
  updateValue(parametrageLieuArticle: ParametrageLieuArticle, field: string, event: FocusEvent) {
    let editValue = event.target['value'];

    // Si les valeurs saisie et initiale sont vides alors il n'y a rien à faire
    if (editValue === '' && (parametrageLieuArticle[field] === undefined || parametrageLieuArticle[field] === null)) {
      return;
    }

    // Si la valeur saisie est différente de la valeur d'origine
    if (editValue != parametrageLieuArticle[field]) {
      // Si la valeur saisie est vide alors null sera envoyé
      parametrageLieuArticle[field] = editValue === '' ? null : editValue;

      // Modification du paramétrage via l'API
      this.parametrageLieuArticleService.putParametrageLieuArticle(parametrageLieuArticle).subscribe(() => {
        const designation = ArticleTools.getDesignation(parametrageLieuArticle.article);
        this.messageTool.sendSuccess(`Le paramétrage de l'article ${ designation } a été mis à jour avec succès`);
      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  /**
   * Ouvre une fenêtre de dialog pour confirmer la suppression
   */
  openDialogDelete(idParametrageLieuArticle: number): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de suppression",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: "Êtes-vous sûr de vouloir supprimer le paramétrage de cet article ?"
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.supprimerParametrage(idParametrageLieuArticle);
      }
    });
  }

  /**
   * applique le filtre de la barre de recherche
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * supprime l'article de la table s'il est supprimable
   */
  supprimerParametrage(idParametrageLieuArticle: number) {
    this.parametrageLieuArticleService.deleteParametrageLieuArticle(idParametrageLieuArticle).subscribe(() => {
      // Mise à jour de la table
      const itemIndex = this.dataSource.data.findIndex(obj => obj.idParametrageLieuArticle === idParametrageLieuArticle);
      this.dataSource.data.splice(itemIndex, 1);
      this.dataSource._updateChangeSubscription();

      this.messageTool.sendSuccess(`Le paramétrage a été supprimé avec succès`);
    }, error => {
      this.messageTool.sendError(error);
    })
  }

  getLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;
    this.getListeParametrages();
  }
}
