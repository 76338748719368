import {Article} from "../epona-api/Article";
import {InventaireEntete} from "../epona-api/InventaireEntete";
import {InventaireLigne} from "../epona-api/InventaireLigne";

export class DialogDataAjoutDLCInventaire {
  // Paramètre en entrée
  article: Article;
  entete: InventaireEntete;

  // Paramètre de retour
  ligne: InventaireLigne;
}
