import {InventaireLigne} from "../epona-api/InventaireLigne";
import {InventaireEntete} from "../epona-api/InventaireEntete";

export class DialogDataCommentaireInventaire {
  // Paramètre d'entrée
  entete: InventaireEntete;
  ligne: InventaireLigne;

  // Paramètre de sortie
  ligneUpdated: InventaireLigne;
}
