import {Article} from "./Article";
import {ILigneArticleDlc} from "./ILigneArticleDlc";
import {EntreeSortieLigneDetailEcart} from "./EntreeSortieLigneDetailEcart";
import {EntreeSortieEntete} from "./EntreeSortieEntete";
import {ILigneModifiable} from "./ILigneModifiable";
import {ILigneAvecQuantiteReceptionnee} from "./ILigneAvecQuantiteReceptionnee";

export class EntreeSortieLigne implements ILigneArticleDlc, ILigneModifiable, ILigneAvecQuantiteReceptionnee {
  idEntreeSortieLigne: number;
  referenceFournisseur: string;
  dlc: Date;
  quantiteSortie: number;
  quantiteAttendue: number;
  quantite: number;
  ecart: number;
  ecartDetaille: number;
  horsMarche: boolean;
  prixHt: number;
  montantTotalHt: number;
  tauxTva: number;
  prixTtc: number;
  montantTotalTtc: number;

  entete: EntreeSortieEntete;
  article: Article;

  utilisateurCreation: string;

  nbLots: number;
  quantiteLots: number;

  listeDetailsEcart: EntreeSortieLigneDetailEcart[];

  selected: boolean;
  success: boolean = null;

  quantiteCommandee: number; // Pour l'affichage dans la visualisation d'un BL
  quantiteReceptionnee: number; // Pour l'affichage dans la visualisation d'un BL
  quantiteEnStock: number; // Pour l'affichage dans la visualisation d'une sortie
}
