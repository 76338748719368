import {ServiceEmetteurDTO} from "./ServiceEmetteurDTO";
import {ServiceFaitEntete} from "./ServiceFaitEntete";

export class ServiceFaitEntetePUT {
  serviceConstatation: ServiceEmetteurDTO;
  libelle: string;
  observation: string;

  constructor(serviceFaitEntete: ServiceFaitEntete) {
    this.serviceConstatation = serviceFaitEntete.serviceConstatation ? new ServiceEmetteurDTO(serviceFaitEntete.serviceConstatation) : null;
    this.libelle             = serviceFaitEntete.libelle;
    this.observation         = serviceFaitEntete.observation;
  }
}
