import {ILigneModifiable} from "../model/epona-api/ILigneModifiable";
import {HttpErrorResponse} from "@angular/common/http";
import {AbstractControl, ValidationErrors} from "@angular/forms";

export class FormTools {
  static unmark(ligne: ILigneModifiable | ILigneModifiable[]): void {
    if (Array.isArray(ligne)) {
      ligne.forEach(item => FormTools.unmark(item));
    } else {
      ligne.success = null;
    }
  }

  static markAsSuccess(ligne: ILigneModifiable | ILigneModifiable[], timeout = 3000): void {
    if (Array.isArray(ligne)) {
      ligne.forEach(item => FormTools.markAsSuccess(item, timeout));
    } else {
      ligne.success = true;
      setTimeout(function () {
        ligne.success = null;
      }, timeout);
    }
  }

  static markAsFailure(ligne: ILigneModifiable | ILigneModifiable[]): void {
    if (Array.isArray(ligne)) {
      ligne.forEach(item => FormTools.markAsFailure(item));
    } else {
      ligne.success = false;
    }
  }

  static ngErrorFromHttpError(err: HttpErrorResponse): ValidationErrors {
    if (err.status === 0) {
      return {'network': true};
    } else if (err.status >= 500) {
      return {'5xx': true};
    } else {
      return {'4xx': true};
    }
  }

  static addErrorToCtrl(errorCode: string, ctrl: AbstractControl): void {
    if (ctrl.errors === null) {
      ctrl.setErrors({});
    }
    ctrl.errors[errorCode] = true;
  }

  static removeErrorFromCtrl(errorCode: string, ctrl: AbstractControl): void {
    if (ctrl.errors === null) {
      return;
    }
    delete ctrl.errors[errorCode];
    if (Object.keys(ctrl.errors).length === 0) {
      ctrl.setErrors(null);
    }
  }
}
