import {Pipe, PipeTransform} from '@angular/core';
import {FamilleMarches} from "../../model/epona-api/FamilleMarches";

@Pipe({
  name: 'familleMarches'
})
export class FamilleMarchesPipe implements PipeTransform {
  transform(familleMarches: FamilleMarches): string {
    return FamilleMarchesPipe.buildLibelle(familleMarches);
  }

  static buildLibelle(familleMarches: FamilleMarches): string {
    if (familleMarches) {
      return familleMarches.categorieMarches.libelle
        + ' > ' + familleMarches.libelle;
    } else {
      return '';
    }
  }
}
