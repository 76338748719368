import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {TableauFdncComponent} from "../tableau-fdnc/tableau-fdnc.component";
import {CnousTableExporterDirective} from "../../commons/directives/cnous-table-exporter.directive";
import {FdncEponaBna} from "../../model/epona-api/fdnc-epona-bna";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {FdncService} from "../../services/epona/fdnc.service";
import {FdncEponaSearch} from "../../model/epona-api/fdnc-epona-search";
import {MessageTool} from "../../commons/MessageTool";
import {BnaFdncService} from "../../services/bna/bna-fdnc.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogDataCreationFdnc} from "../dialog-creation-fdnc/dialog-data-creation-fdnc";
import {DialogCreationFdncComponent} from "../dialog-creation-fdnc/dialog-creation-fdnc.component";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {UserService} from "../../services/user.service";
import {StatutFdnc} from "../../model/bna-api/statut-fdnc";
import {EtapeFdnc} from "../../model/bna-api/etape-fdnc";
import {ContexteFdnc} from '../../model/bna-api/contexte-fdnc';

@Component({
  selector: 'epona-fdnc-commande-livraison',
  templateUrl: './fdnc-commande-livraison.component.html',
  styleUrls: ['./fdnc-commande-livraison.component.css']
})
export class FdncCommandeLivraisonComponent implements OnChanges {

  @Input() commande: CommandeEntete|null = null;
  @Input() livraison: EntreeSortieEntete|null = null;

  listeFDNC: Array<FdncEponaBna> = [];
  droitSaisie: boolean = false;

  colonnesAffichees: string[] = [
    'codeBna',
    'contexte',
    'bl',
    'codeArticle',
    'designationArticle',
    'statut',
    'etapeEnCours',
    'gravite',
    'actions',
  ];

  FDNC_EPONA_FIELDS: string = "" +
    "idFdnc," +
    "idContexte," +
    "entreeSortieEntete.idEntreeSortieEntete," +
    "entreeSortieEntete.codeBordereauLivraison," +
    "entreeSortieLigne.article.codeArticleAchat," +
    "entreeSortieLigne.article.designationAchat," +
    "commandeLigne.article.codeArticleAchat," +
    "commandeLigne.article.designationAchat," +
    "idFdncBna" +
    "";

  // Exporter inclus dans le composant TableauFdnc
  cnousTableExporter!: CnousTableExporterDirective;

  @ViewChild(TableauFdncComponent)
  set element(element: TableauFdncComponent) {
    this.cnousTableExporter = element.cnousTableExporter;
  }

  constructor(private fdncService: FdncService,
              private bnaFdncService: BnaFdncService,
              public dialog: MatDialog,
              private userService: UserService,
              private messageTool: MessageTool) {
    this.droitSaisie = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_SAISIE);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['commande'] && this.commande) {
      this.loadListe();
    }
    if (changes['livraison'] && this.livraison) {
      this.loadListe();
    }
  }

  loadListe() {
    const search = new FdncEponaSearch();
    search.idCommandeEntete     = this.commande?.idCommandeEntete;
    search.idEntreeSortieEntete = this.livraison?.idEntreeSortieEntete;
    search.fields               = this.FDNC_EPONA_FIELDS;
    this.fdncService.getListeFdnc(search).subscribe({
      next: (data) => {
        this.listeFDNC = [];
        data.forEach(fdncEpona => {
          const fdnc = new FdncEponaBna();

          fdnc.idFdnc             = fdncEpona.idFdnc;
          let contexteFdnc = new ContexteFdnc();
          contexteFdnc.idContexteFdnc = fdncEpona.idContexte;
          fdnc.contexte           = contexteFdnc;
          fdnc.bl                 = fdncEpona.entreeSortieEntete;

          const article = this.fdncService.extractArticle(fdncEpona);
          fdnc.codeArticle        = article?.codeArticleAchat;
          fdnc.designationArticle = article?.designationAchat;

          if (fdncEpona.idFdncBna) {
            this.bnaFdncService.getFdnc(fdncEpona.idFdncBna, 'code,statut,etapeEnCours,gravite').subscribe({
              next: fdncBna => {
                fdnc.codeBna      = fdncBna.code;
                fdnc.statut       = fdncBna.statut;
                fdnc.etapeEnCours = fdncBna.etapeEnCours;
                fdnc.gravite      = fdncBna.gravite === 0 ? 'Mineure' : 'Majeure';
              },
              error: err => {
                this.messageTool.sendError(err);
              }
            })
          } else {
            fdnc.statut       = StatutFdnc.STATUT_EPONA;
            fdnc.etapeEnCours = EtapeFdnc.ETAPE_EPONA;
          }

          this.listeFDNC.push(fdnc);
        });
      },
      error: err => {
        this.messageTool.sendError(err);
      }
    });
  }

  openDialogCreationFdnc(): void {
    let dialogConfig = new MatDialogConfig<DialogDataCreationFdnc>();
    dialogConfig.data = new DialogDataCreationFdnc();
    dialogConfig.data.commande  = this.commande;
    dialogConfig.data.livraison = this.livraison;
    dialogConfig.minWidth = '850px';
    // dialogConfig.maxWidth = 'none';
    dialogConfig.position = {top: '20px'}; // Pour éviter que la fenêtre se repositionne verticalement lors du changement d'étape
    dialogConfig.disableClose = true;

    this.dialog.open<DialogCreationFdncComponent, DialogDataCreationFdnc, boolean>(DialogCreationFdncComponent, dialogConfig);
  }
}
