import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Fdnc} from '../../model/epona-api/fdnc';
import {FdncService} from '../../services/epona/fdnc.service';
import {CanDeactivateComponent} from '../../intercepteurs/can-deactivate/can-deactivate.component';
import {FdncComponent} from '../fdnc/fdnc.component';
import {ClearMessages, MessageTool} from '../../commons/MessageTool';
import {Lieu} from '../../model/epona-api/Lieu';
import {UserService} from "../../services/user.service";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {TypeMetaData} from "../../commons/enum/TypeMetaData";
import {EchangeFdnc} from "../../model/bna-api/echange-fdnc";
import {BnaFdncService} from "../../services/bna/bna-fdnc.service";
import {FdncBna} from "../../model/bna-api/fdnc-bna";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogDataAjoutEchange} from "../dialog-ajout-echange/dialog-data-ajout-echange";
import {DialogAjoutEchangeComponent} from "../dialog-ajout-echange/dialog-ajout-echange.component";
import {RouterService} from "../../services/router.service";

@Component({
  selector: 'epona-fiche-fdnc',
  templateUrl: './fiche-fdnc.component.html',
  styleUrls: ['./fiche-fdnc.component.css']
})
export class FicheFdncComponent extends CanDeactivateComponent {

  idFdnc: number|null = null;
  fdnc: Fdnc|null = null;
  lieuCourant: Lieu|null = null;
  droitSaisieFdnc: boolean = false;

  fdncBna: FdncBna|null = null;

  listeEchanges: EchangeFdnc[] = [];
  colonnesEchangesAffichees: string[] = ['date', 'auteur', 'message', 'listeTypesAction', 'incidenceQualiteProduit'];
  droitSaisieEchange: boolean = false;

  readonly TypeMetaData = TypeMetaData;

  private readonly FIELDS_FDNC_EPONA = '' +
    'idFdnc,' +
    'commandeEntete.idCommandeEntete,' +
    'commandeEntete.numeroEj,' +
    'commandeEntete.lieuDemandeur.idLieu,' +
    'commandeEntete.fournisseur.nom,' +
    'commandeLigne.article.articleAchat,' +
    'commandeLigne.article.designationAchat,' +
    'entreeSortieEntete.idEntreeSortieEntete,' +
    'entreeSortieEntete.codeBordereauLivraison,' +
    'entreeSortieLigne.article.articleAchat,' +
    'entreeSortieLigne.article.designationAchat,' +
    'idContexte,' +
    'dateConstat,' +
    'numeroLotProduit,' +
    'listeTypesNonConformite,' +
    'observationsNonConformite,' +
    'listeTraitementsNonConformite,' +
    'idTempsTraitement,' +
    'observationsTraitement,' +
    'emise,' +
    'idFdncBna,' +
    'dateCreation,' +
    'utilisateurCreation,' +
    'dateModification,' +
    'utilisateurModification,' +
    'dateEmission,' +
    'utilisateurEmission' +
    '';

  private readonly FIELDS_FDNC_BNA = '' +
    'idFdnc,' +
    'code,' +
    'gravite,' +
    'contexte,' +
    'tempsTraitementNonConformite,' +
    'statut.libelle,' +
    'etapeEnCours.idEtapeFdnc,' +
    'etapeEnCours.libelle' +
    '';

  private readonly FIELDS_ECHANGES = '' +
    'dateEnregistrement,' +
    'type,' +
    'detailAction,' +
    'analyseCauses,' +
    'incidenceQualiteProduit,' +
    'actionAMettreEnOeuvre,' +
    'commentaire,' +
    'resultat,' +
    'valide,' +
    'nomAuteur,' +
    'listeTypesAction.libelle' +
    '';

  // Permet d'accèder aux données du composant enfant afin de contrôler s'il y a eu des modifications si changement de page
  // ViewChild est une réquête dynamique : il sera toujours à jour du template !
  @ViewChild(FdncComponent)
  set element(element: FdncComponent) {
    this.formGroupDirective = element.formGroupDirective;
  }

  constructor(private fdncService: FdncService,
              private bnaFdncService: BnaFdncService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private routerService: RouterService,
              private dialog: MatDialog,
              private messageTool: MessageTool) {
    super();
  }

  ngOnInit(): void {

    // Récupération de l'id de la ressource dans l'url.
    const idParam = this.activatedRoute.snapshot.paramMap.get('id');

    this.droitSaisieFdnc    = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_SAISIE);
    this.droitSaisieEchange = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_ECHANGE_SAISIE);

    if (idParam != null) {
      this.idFdnc = parseInt(idParam);
      this.loadFdnc();
    }
  }

  /* ****************** */
  /* Gestion de la FDNC */
  /* ****************** */

  private loadFdnc(): void {
    this.fdncService.getFdnc(this.idFdnc!, this.FIELDS_FDNC_EPONA).subscribe({
      next: data => {
        this.fdnc = data;
        this.lieuCourant = data.commandeEntete.lieuDemandeur;

        if (this.fdnc.idFdncBna) {
          this.loadFdncBna();
          this.loadEchanges();
        }
      },
      error: err => {
        this.messageTool.sendError(err);
      }
    });
  }

  doSave(fdnc: Fdnc): void {
    if (this.idFdnc === null) {
      this.create(fdnc);
    } else {
      this.update(fdnc);
    }
  }

  private create(fdnc: Fdnc): void {
    this.fdncService.postFdnc(fdnc).subscribe({
      next: data => {
        this.messageTool.sendSuccess("La FDNC a été créée avec succès", ClearMessages.TRUE);
        this.resetFormStates();
        this.router.navigate(['fdnc', data.idFdnc]);
      },
      error: err => {
        this.messageTool.sendError(err);
      }
    });
  }

  private update(fdnc: Fdnc): void {
    if (this.idFdnc !== null) {
      fdnc.idFdnc = this.idFdnc;
      this.fdncService.putFdnc(fdnc).subscribe({
        next: () => {
          this.loadFdnc();
          this.messageTool.sendSuccess("La FDNC a été modifiée avec succès", ClearMessages.TRUE);
          this.resetFormStates();
        },
        error: err => {
          this.messageTool.sendError(err);
        }
      });
    }
  }

  doEmission(fdnc: Fdnc) {
    this.fdncService.emission(fdnc.idFdnc).subscribe(res => {
      this.messageTool.sendSuccess('La FDNC a été émise avec succès', ClearMessages.TRUE);
      this.fdncService.getFdnc(fdnc.idFdnc, null).subscribe(data => {
        this.loadFdnc();
      })
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  doSuppression(fdnc: Fdnc) {
    this.fdncService.deleteFdnc(fdnc).subscribe(res => {
      this.messageTool.sendSuccess('La FDNC a été supprimée avec succès', ClearMessages.TRUE);
      this.resetFormStates();


      // Redirection vers la page précédente (fiche de la commande, fiche du BL ou recherche de FDNC),
      //  vers la recherche de FDNC par défaut
      const previousUrl = this.routerService.getPreviousUrl();
      if (previousUrl !== '/') {
        this.router.navigateByUrl(previousUrl);
      } else {
        this.router.navigate(['/liste-fdnc']);
      }

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  /* *********************************** */
  /* Gestion de la FDNC venant de la BNA */
  /* *********************************** */

  private loadFdncBna(): void {
    this.bnaFdncService.getFdnc(this.fdnc.idFdncBna, this.FIELDS_FDNC_BNA).subscribe({
      next: data => {
        this.fdncBna = data;
      },
      error: err => {
        this.messageTool.sendError(err);
      }
    });
  }

  /* ******************** */
  /* Gestion des échanges */
  /* ******************** */

  private loadEchanges(): void {
    this.bnaFdncService.getListeEchanges(this.fdnc.idFdncBna, this.FIELDS_ECHANGES).subscribe({
      next: data => {
        this.listeEchanges = data.filter(e => e.valide); // Seuls les échanges valides sont affichés
      },
      error: err => {
        this.messageTool.sendError(err);
      }
    });
  }

  openDialogAjoutEchange(): void {
    const dialogConfig = new MatDialogConfig<DialogDataAjoutEchange>();
    dialogConfig.data = new DialogDataAjoutEchange();
    dialogConfig.data.fdncBna = this.fdncBna;
    dialogConfig.minWidth = '400px';

    const dialogRef = this.dialog.open<DialogAjoutEchangeComponent, DialogDataAjoutEchange, EchangeFdnc|null>(DialogAjoutEchangeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(echangeFdnc => {
      if (echangeFdnc !== null) {
        this.loadFdnc();
      }
    });
  }
}
