import {Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {SelectItem} from "../select-boolean/SelectItem";
import {Subject} from "rxjs";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {NumberComparison} from "../../../model/epona-ui/NumberComparison";
import {NumberComparisonOperator} from "../../enum/NumberComparisonOperator";

@Component({
  selector: 'epona-number-comparison',
  templateUrl: './number-comparison.component.html',
  styleUrls: ['./number-comparison.component.css'],
  providers: [{provide: MatFormFieldControl, useExisting: NumberComparisonComponent}]
})
export class NumberComparisonComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<NumberComparison> {

  operatorCtrl: UntypedFormControl;
  valueCtrl: UntypedFormControl;
  liste: SelectItem[];

  NumberComparisonOperator = NumberComparisonOperator;

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.operatorCtrl = new UntypedFormControl('EQ');
    this.operatorCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
    this.valueCtrl = new UntypedFormControl(null);
    this.valueCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: NumberComparison): void {
    this.setCurrentItem(obj)
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.operatorCtrl.disable() : this.operatorCtrl.enable();
    this._disabled ? this.valueCtrl.disable() : this.valueCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): NumberComparison {
    return this.getCurrentItem();
  }
  set value(value: NumberComparison) {
    this.setCurrentItem(value)
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.valueCtrl.value === null;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): NumberComparison {
    if (typeof this.valueCtrl.value === 'number') {
      return new NumberComparison(this.operatorCtrl.value, this.valueCtrl.value);
    } else {
      return null;
    }
  }

  setCurrentItem(item: NumberComparison) {
    if (item) {
      this.operatorCtrl.setValue(item.operator);
      this.valueCtrl.setValue(item.value);
    }
  }

  private loadListe() {
    this.liste = [];
    this.liste.push(new SelectItem(NumberComparisonOperator.LT, '<'))
    this.liste.push(new SelectItem(NumberComparisonOperator.LE, '≤'))
    this.liste.push(new SelectItem(NumberComparisonOperator.EQ, '='))
    this.liste.push(new SelectItem(NumberComparisonOperator.GE, '≥'))
    this.liste.push(new SelectItem(NumberComparisonOperator.GT, '>'))
    this.liste.push(new SelectItem(NumberComparisonOperator.NE, '≠'))
  }
}
