import {
  Component,
  ElementRef,
  HostBinding,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self, SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Subject} from "rxjs";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Tools} from "../../Tools";
import {MatAutocompleteActivatedEvent} from "@angular/material/autocomplete";
import {ArticleMarche} from "../../../model/epona-api/ArticleMarche";
import {Fournisseur} from "../../../model/epona-api/Fournisseur";
import {SousLotZg} from "../../../model/epona-api/SousLotZg";
import {EponaService} from "../../../services/epona/epona.service";
import {ArticleMarcheSearch} from "../../../model/epona-api/ArticleMarcheSearch";
import {Lieu} from "../../../model/epona-api/Lieu";

@Component({
  selector: 'epona-autocomplete-article-marche',
  templateUrl: './autocomplete-article-marche.component.html',
  styleUrls: ['./autocomplete-article-marche.component.css'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompleteArticleMarcheComponent
    }
  ]
})
export class AutocompleteArticleMarcheComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, MatFormFieldControl<ArticleMarche> {
  @ViewChild('article') articleElement: ElementRef;

  articleCtrl: UntypedFormControl;

  listeArticles: Array<ArticleMarche> = [];
  listeArticlesAffiches: Array<ArticleMarche> = [];
  multiZG: boolean = false;
  readonly nbMaxResults = 30;

  @Input() fournisseur: Fournisseur;
  @Input() sousLotZg: SousLotZg;
  @Input() sousLotZgReference: SousLotZg;
  @Input() lieu: Lieu;
  @Input() listeIdArticleInclus: number[] = null;
  @Input() listeIdArticleExclus: number[] = [];
  @Input() optionSelectedHandler: (article: ArticleMarche) => boolean;

  // Cache de la dernière recherche sur un sous-lot
  private static lastIdSousLotZg: number = null;
  private static lastListeArticles: Array<ArticleMarche> = null;

  // MatFormFieldControl
  stateChanges = new Subject<void>();
  focused: boolean = false;
  // Ajout d'une classe qui permettra de faciliter l'application des styles
  controlType: string = 'autocomplete-article-marche';
  propagateChange = (_: any) => { };
  onTouched = () => {};

  static nextId = 0;
  @HostBinding()
  id = `autocomplete-article-marche-${AutocompleteArticleMarcheComponent.nextId++}`;

  @Input()
  set focus(value: boolean) {
    if (this.articleElement) {
      if (value ) {
        this.articleElement.nativeElement.focus();
      } else {
        this.articleElement.nativeElement.blur();
      }
    }
  }

  @Input()
  get value(): ArticleMarche {
    return this.articleCtrl.value;
  }
  set value(value: ArticleMarche) {
    this.articleCtrl.setValue(value);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  // Affichage du label en position flottant lorsque le champ a le focus
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;


  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.articleCtrl.disable() : this.articleCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  get empty(): boolean {
    return !this.articleCtrl.value;
  }

  // Affichage du champ en erreur et permet de gérer le mat-error
  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  constructor(private eponaService: EponaService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    // Affichage du champ avec un soulignement si le champ a le focus
    // origin values : mouse, keyboard, null, ...
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.articleCtrl = new UntypedFormControl(null);
  }

  ngOnInit() {
      this.getListeArticles();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listeIdArticleInclus'] && this.listeIdArticleExclus !== undefined
      || changes['listeIdArticleExclus'] && this.listeIdArticleInclus !== undefined) {
      this.prepareListeArticles();
    }
  }

  getListeArticles() {
    // Si un sous-lot est renseigné,
    //  qu'il s'agit du dernier sous-lot mis en cache
    //  et qu'il y a bien des articles en cache
    //  alors la liste des articles est récupérée du cache
    if (this.sousLotZg
      && AutocompleteArticleMarcheComponent.lastIdSousLotZg === this.sousLotZg.idSousLotZg
      && AutocompleteArticleMarcheComponent.lastListeArticles !== null) {

      // Copie de la liste en cache par valeur (.slice()) car elle risque d'être modifiée par prepareListeArticles()
      this.listeArticles = AutocompleteArticleMarcheComponent.lastListeArticles.slice();
      this.listeArticlesAffiches = this.listeArticles.slice();
      this.prepareListeArticles();

    // Sinon (recherche sans sous-lot ou pas de cache)
    //  alors la liste des articles est récupérée de l'API
    } else {

      const search = new ArticleMarcheSearch();
      search.date = new Date();
      if (this.sousLotZg) {
        search.idSousLotZg = this.sousLotZg.idSousLotZg;
      } else {
        search.idCrous = +localStorage.getItem('idCrous');
        search.idFournisseur = this.fournisseur ? this.fournisseur.idFournisseur : null;
        search.idLieu = this.lieu ? this.lieu.idLieu : null;
      }
      search.fields = 'sousLotZg,idArticle,codeArticleAchat,designationAchat,reference,prixHt,listeMercuriales.prixHt';

      this.eponaService.getListeArticlesMarche(search).subscribe(data => {
        this.listeArticles = data;

        // Mise en cache de la dernière recherche (en cas de sous-lot renseigné)
        if (this.sousLotZg) {
          AutocompleteArticleMarcheComponent.lastIdSousLotZg   = this.sousLotZg.idSousLotZg;
          AutocompleteArticleMarcheComponent.lastListeArticles = this.listeArticles;
        }

        this.prepareListeArticles();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private prepareListeArticles(): void {
    if (this.listeIdArticleInclus) {
      // Conservation uniquement des articles inclus
      this.listeArticles = this.listeArticles.filter((articleMarche: ArticleMarche) =>
        this.listeIdArticleInclus.find(idArticle => idArticle === articleMarche.idArticle)
      );
    }

    if (this.listeIdArticleExclus && this.listeIdArticleExclus.length > 0) {
      // Conservation uniquement des articles non exclus
      this.listeArticles = this.listeArticles.filter((articleMarche: ArticleMarche) =>
        !this.listeIdArticleExclus.find(idArticle => idArticle === articleMarche.idArticle)
      );
    }

    this.tri(this.listeArticles);

    this.listeArticlesAffiches = this.listeArticles.slice();

    // Autocomplétion
    this.articleCtrl.valueChanges.subscribe({
      next: value => {
        const name = !value || typeof value === 'string' ? value : value.name;
        this.listeArticlesAffiches = name ? this.filterArticle(name) : this.listeArticles.slice();
      }
    })

    this.determineSiMultiZoneGeographique();
  }

  private determineSiMultiZoneGeographique(): void {
    this.multiZG = false;
    let idZoneGeographique: number;
    for (let article of this.listeArticles) {
      if (idZoneGeographique === undefined) {
        idZoneGeographique = AutocompleteArticleMarcheComponent.getIdZoneGeographique(article);
      } else if (idZoneGeographique !== AutocompleteArticleMarcheComponent.getIdZoneGeographique(article)) {
        this.multiZG = true;
        break;
      }
    }
  }

  private static getIdZoneGeographique(article: ArticleMarche): number {
    return article.sousLotZg.zoneGeographique ? article.sousLotZg.zoneGeographique.idZoneGeographique : null;
  }

  private filterArticle(name: string): ArticleMarche[] {
    // Le filtre ne tient pas compte des accents
    const filterValue = Tools.removeDiacritics(name.toLowerCase());
    const filterWords = filterValue.split(' ');

    let listeArticlesFilter: Array<ArticleMarche> = this.listeArticles.filter(article => {
      for (let word of filterWords) {
        if (!this.articleContientMot(article, word)) {
          return false;
        }
      }
      return true;
    });

    this.tri(listeArticlesFilter);

    return listeArticlesFilter;
  }

  articleContientMot(article: ArticleMarche, word: string): boolean {
    // Si un sous-lot est précisé en paramètre du composant
    //  alors les recherches dans le fournisseur, la zone géographique et le marché sont inutiles
    const sousLotNonForce:boolean = !this.sousLotZg;

    return Tools.removeDiacritics(article.designationAchat).toLowerCase().indexOf(word) >= 0
    || article.codeArticleAchat.toLowerCase().indexOf(word) === 0
    || article.reference.toLowerCase().indexOf(word) >= 0
    || sousLotNonForce && Tools.removeDiacritics(article.sousLotZg.fournisseur.nom).toLowerCase().indexOf(word) >= 0
    || sousLotNonForce && article.sousLotZg.zoneGeographique && article.sousLotZg.zoneGeographique.libelle.toLowerCase().indexOf(word) >= 0
    || sousLotNonForce && article.sousLotZg.codeMarcheOrion.toLowerCase().indexOf(word) === 0
    ;
  }

  // Tri d'un tableau d'articles-marché par désignation de l'article
  tri(listeArticles: Array<ArticleMarche>) {
    listeArticles.sort((a, b) => {
      return a.designationAchat.localeCompare(b.designationAchat);
    });
  }

  //ControlValueAccessor
  registerOnChange(fn: any): void {
    this.propagateChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.articleCtrl.setValue(obj);
  }

  optionSelected() {
    if (this.optionSelectedHandler) {
      if (this.optionSelectedHandler(this.articleCtrl.value)) {
        this.propagateChange(this.articleCtrl.value);

      } else {
        this.articleCtrl.setValue(null);
        this.propagateChange(null);
      }

    } else {
      this.propagateChange(this.articleCtrl.value);
    }
  }

  optionActivated(activatedEvent: MatAutocompleteActivatedEvent) {
    // Procédure qui est appélee lorsqu'une option est sélectionnée par le clavier
    if (activatedEvent && activatedEvent.option) {
      this.articleCtrl.setValue(activatedEvent.option.value);
      this.propagateChange(this.articleCtrl.value);
    }
  }

  // Touche relâchée : l'article vaut null car seul la sélection d'un article doit retourner un article
  // Envoi que si ce n'est pas un objet car l'événement touche relâchée est aussi appelé lors de la sélection d'une option par le clavier
  keyup() {
    if (!(this.articleCtrl.value instanceof Object)) {
      this.propagateChange(null);
    }
  }

  displayArticle(article?: ArticleMarche): string | undefined {
    if (!article) {
      return undefined
    }
    return article.designationAchat ? article.designationAchat : article.codeArticleAchat;
  }


  //MatFormFieldControl
  onContainerClick(event: MouseEvent): void {
  }

  // Pour la propriété aria-describedby
  setDescribedByIds(ids: string[]): void {
  }

}
