import {Lieu} from "./Lieu";
import {LieuPUT} from "./LieuPUT";

export class LieuDexie {

  idBatimentBns: number;
  idLieu: number;

  idLieuBns: number;

  lieuLivraison: boolean
  idCrous : number;
  idUg : number;
  idRu : number;
  idUd : number;

  nomRu: string;
  nomUd: string;

  constructor(lieu: Lieu) {
    this.idBatimentBns = lieu.idBatimentBns;
    this.idLieu = lieu.idLieu;
    this.idLieuBns = lieu.idLieuBns;
    this.lieuLivraison = lieu.lieuLivraison;
    this.idCrous = lieu.idCrous;
    this.idUg = lieu.idUg;
    this.idRu = lieu.idRu;
    this.idUd = lieu.idUd;
    this.nomRu = lieu.nomRu;
    this.nomUd = lieu.nomUd;
  }
}
