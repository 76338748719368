import {Fournisseur} from "../epona-api/Fournisseur";
import {Article} from "../epona-api/Article";
import {Marche} from "../epona-api/Marche";

export class LotFilter {
  fournisseur: Fournisseur;
  marche: Marche;
  numeroLot: string;
  article: Article;
}
