import {Etablissement} from "../model/bns-api/etablissement";

export class LocalStorageHelper {

  static getIdEtablissement(): number|null {
    const storedValue = localStorage.getItem('idEtablissement');
    return storedValue === null ? null : +storedValue;
  }

  static getNomEtablissement(): string|null {
    return localStorage.getItem('nomEtablissement');
  }

  static getEtablissement(): Etablissement|null {
    const idEtablissement = LocalStorageHelper.getIdEtablissement();
    const nomEtablissement = LocalStorageHelper.getNomEtablissement();

    if (idEtablissement !== null && nomEtablissement !== null) {
      const etablissement = new Etablissement();
      etablissement.idEtablissement = idEtablissement;
      etablissement.nom = nomEtablissement;
      return etablissement;
    } else {
      return null;
    }
  }

  static setEtablissement(etablissement: Etablissement): void {
    const idEtablissementAvant = LocalStorageHelper.getIdEtablissement();

    localStorage.setItem('idEtablissement', '' + etablissement.idEtablissement);
    localStorage.setItem('idCrous', '' + etablissement.idEtablissement);
    localStorage.setItem('nomEtablissement', etablissement.nom);
    localStorage.setItem('nomCrous', etablissement.nom);

    // Si l'idEtablissement a changé,
    //  le lieu par défaut est effacé
    if (idEtablissementAvant !== LocalStorageHelper.getIdEtablissement()) {
      localStorage.removeItem('idBatimentBns');
      localStorage.removeItem('idLieuBns');
      localStorage.removeItem('idRu');
      localStorage.removeItem('idUd');
    }
  }

  static getEppn(): string|null {
    return localStorage.getItem('eppn');
  }
  static setEppn(eppn: string) {
    localStorage.setItem('eppn', eppn);
  }

  static getDevToken(): string|null {
    return localStorage.getItem('devToken');
  }
  static setDevToken(devToken: string) {
    localStorage.setItem('devToken', devToken);
  }
}
