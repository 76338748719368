import {EntreeSortieLigne} from "./EntreeSortieLigne";

export class EntreeSortieLigneLot {
  idEntreeSortieLigneLot: number;
  numeroLot: string;
  quantite: number;
  entreeSortieLigne: EntreeSortieLigne;

  // pour les besoins de vérification
  nouveau: boolean;
}
