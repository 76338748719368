import {CommandeLignePUT} from "./CommandeLignePUT";
import {ArticleDTO} from "./ArticleDTO";
import {CommandeLigne} from "./CommandeLigne";

export class CommandeLignePOST extends CommandeLignePUT {
  article: ArticleDTO;

  constructor(commandeLigne: CommandeLigne) {
    super(commandeLigne);
    this.article = new ArticleDTO(commandeLigne.article);
  }
}
