<epona-select-lieu [lieu]="lieuCourant"
                   [livraisonUniquement]="true"
                   lieuLabel="Lieu de livraison"
                   (lieuSubmitted)="onLieuSubmitted($event)"
                   (lieuCorrected)="onLieuCorrected($event)"></epona-select-lieu>

<div class="titre">
  <h1>Paniers</h1>

  <button type="button"
          mat-raised-button color="primary"
          class="revision-tarifaire-btn"
          (click)="openProchainesRevisionsTarifaires()">Révisions tarifaires</button>
</div>


<div class="crous-recherche">
  <epona-filtre-paniers [filter]="filter"
                          class="crous-recherche-filtre"
                          (filterSubmitted)="onFilterSubmitted($event)"></epona-filtre-paniers>


  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Liste des paniers</mat-card-title>
      </mat-card-header>

      <mat-divider></mat-divider>

      <div class="table-actions">
        <a routerLink="/creation-panier" matTooltip="Créer un nouveau panier" *ngIf="droitSaisie">
          <button type="button" mat-raised-button color="primary"><fa-icon icon="plus"></fa-icon> Créer</button>
        </a>

        <button type="button"
                mat-raised-button color="primary"
                (click)="exporter.exportTable({nomFichier: 'paniers.xlsx'})"
                [disabled]="dataSource.data.length === 0">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                [storeCode]="COLUMNS_STORE_CODE"
                                [(displayedColumns)]="displayedColumns"></form-displayed-columns>
      </div>

      <table mat-table [dataSource]="dataSource"
             cnousTableExporter #exporter="cnousTableExporter" [columns]="COLUMNS"
             class="mat-elevation-z8"
             matSort matSortActive="idPanierEntete" matSortDirection="desc" matSortDisableClear="true"
             aria-label="Liste de Paniers">

        <ng-container matColumnDef="idPanierEntete">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['idPanierEntete'].tooltip">{{ COLUMNS['idPanierEntete'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.idPanierEntete}}</td>
        </ng-container>

        <ng-container matColumnDef="batiment">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['batiment'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieuDemandeur.nomRu}}</td>
        </ng-container>

        <ng-container matColumnDef="lieuDemandeur">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['lieuDemandeur'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieuDemandeur.nomUd}}</td>
        </ng-container>

        <ng-container matColumnDef="fournisseur">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['fournisseur'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.fournisseur?.nom}}</td>
        </ng-container>

        <ng-container matColumnDef="nbCommandes">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['nbCommandes'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.nbCommandes}}</td>
        </ng-container>

        <ng-container matColumnDef="dateCreation">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['dateCreation'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.dateCreation | dateFormat }}</td>
        </ng-container>

        <ng-container matColumnDef="utilisateurCreation">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['utilisateurCreation'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.utilisateurCreation }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['actions'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/panier', element.idPanierEntete]" matTooltip="Modifier le panier" *ngIf="droitModification">
              <button type="button" mat-button><fa-icon icon="edit"></fa-icon></button>
            </a>
            <a [routerLink]="['/panier', element.idPanierEntete]" matTooltip="Consulter le panier" *ngIf="!droitModification">
              <button type="button" mat-button><fa-icon icon="folder-open"></fa-icon></button>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>

      </table>
    </mat-card>
  </div>
</div>
