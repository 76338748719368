import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpEvent} from '@angular/common/http';
import {HttpService} from "../http.service";
import {Observable} from "rxjs";
import {Fdnc} from "../../model/epona-api/fdnc";
import {FdncEponaSearch} from "../../model/epona-api/fdnc-epona-search";
import {FdncPOST} from "../../model/epona-api/fdnc-post";
import {FdncPUT} from "../../model/epona-api/fdnc-put";
import {DocumentJoint} from '../../model/epona-api/DocumentJoint';
import {DocumentPOST} from '../../model/epona-api/document-post';
import {DocumentPUT} from '../../model/epona-api/document-put';
import {Article} from "../../model/epona-api/Article";

@Injectable({
  providedIn: 'root'
})
export class FdncService {

  private env = environment;
  private baseUrl: string;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) {
    this.baseUrl = this.env.eponaApiUrl + '/fdnc';
  }

  getListeFdnc(search: FdncEponaSearch): Observable<Fdnc[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<Fdnc[]>(this.baseUrl, {params});
  }

  getFdnc(idFdnc: number, fields: string): Observable<Fdnc> {
    const params = this.httpService.buildParams({fields});
    return this.httpClient.get<Fdnc>(this.baseUrl + '/' + idFdnc, {params});
  }

  postFdnc(fdnc: Fdnc): Observable<Fdnc> {
    return this.httpClient.post<Fdnc>(this.baseUrl, new FdncPOST(fdnc))
  }

  putFdnc(fdnc: Fdnc): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + fdnc.idFdnc, new FdncPUT(fdnc));
  }

  deleteFdnc(fdnc: Fdnc): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/' + fdnc.idFdnc);
  }

  emission(idFdnc: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idFdnc + '/emission', null);
  }

  extractArticle(fdnc: Fdnc): Article {
    if (fdnc.entreeSortieLigne) {
      return fdnc.entreeSortieLigne.article;
    }
    if (fdnc.commandeLigne) {
      return fdnc.commandeLigne.article;
    }
    return null;
  }

  extractReference(fdnc: Fdnc): string {
    if (fdnc.entreeSortieLigne) {
      return fdnc.entreeSortieLigne.referenceFournisseur;
    }
    if (fdnc.commandeLigne) {
      return fdnc.commandeLigne.reference;
    }
    return null;
  }

  /* ************************** */
  /*   Gestion des documents    */
  /* ************************** */

  getListeDocuments(id: number, fields: string): Observable<DocumentJoint[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<any>(this.baseUrl + '/' + id + '/documents', {params});
  }

  getDocument(id: number, idDocument: number, fields: string): Observable<DocumentJoint> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<any>(this.baseUrl + '/' + id + '/documents/' + idDocument, {params});
  }

  deleleDocument(id: number, idDocument: number): Observable<any> {
    return this.httpClient.delete<HttpEvent<any>>(this.baseUrl + '/' + id +'/documents/' + idDocument);
  }

  postDocument(id: number, document: DocumentJoint, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('document', JSON.stringify(new DocumentPOST(document)));
    return this.httpClient.post<HttpEvent<any>>(this.baseUrl + '/' + id +'/documents', formData);
  }

  putDocument(id: number, document: DocumentJoint, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('document', JSON.stringify(new DocumentPUT(document)));
    return this.httpClient.put<HttpEvent<any>>(this.baseUrl + '/' + id +'/documents/' + document.idDocument, formData);
  }

  getFichier(id: number, idDocument: number): Observable<any> {
    const headers = { 'responseType': 'blob' as 'json'};
    return this.httpClient.get<Blob>(this.baseUrl + '/' + id + '/documents/' + idDocument + '/fichier', headers);
  }
}
