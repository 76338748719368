import {DTOWithLieu} from "../epona-ui/DTOWithLieu";

export class EntreeSortieSearch extends DTOWithLieu {
  idEntreeSortie: number;
  codesTypeMouvement: string[];
  valide: boolean;
  codesStatut: string[];
  codeBL: string;
  dateMin: Date;
  lieuEvenement: string;
  dateEvenement: Date;
  referenceEvenement: string;
  utilisateur: string;

  idEntreeSortieOrigine: number;
  idCommande: number;
  numeroCommande: string;

  // TODO :  faire classe séparée TdbNbEntreesSortiesParLieuTypeSearch
  dateMinValidation: Date;
  codeTypeMouvement: string;

  fields: string;
}
