<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Bordereau de livraison no. {{data.idEntreeSortieEntete}}</span>
    <span class="space"></span>
    <button type="button" mat-icon-button mat-dialog-close aria-pressed="false">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <div class="fiche">
    <epona-entree-sortie-entete [params]="BORDEREAUX_LIVRAISON"
                                [entete]="entete"
                                [lieuCourant]="lieuCourant"
                                [modeConsultation]="true"
                                class="entete">
    </epona-entree-sortie-entete>

    <epona-entree-sortie-lignes [params]="BORDEREAUX_LIVRAISON"
                                [entete]="entete"
                                [lignes]="lignes"
                                [modeConsultation]="true"
                                *ngIf="data.idEntreeSortieEntete"
                                class="lignes">
    </epona-entree-sortie-lignes>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Fermer</button>
  <button mat-raised-button color="primary" (click)="modifier()">Modifier</button>
</mat-dialog-actions>
