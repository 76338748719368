import {Component, Inject} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {LieuService} from "../../services/epona/lieu.service";
import {DialogDataModificationLieu} from "../../model/epona-ui/DialogDataModificationLieu";
import {Lieu} from "../../model/epona-api/Lieu";
import {LieuBns as LieuBns} from "../../model/bns-api/lieu-bns";
import {TypeReferentielOrion} from "../../commons/constants/TypeReferentielOrion";
import {forkJoin, Observable} from "rxjs";
import {CacheService} from "../../services/cache.service";
import {finalize} from "rxjs/operators";
import {BatimentBns} from "../../model/bns-api/batiment-bns";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
  selector: 'epona-dialog-ajout-modification-lieu',
  templateUrl: './dialog-ajout-modification-lieu.component.html',
  styleUrls: ['./dialog-ajout-modification-lieu.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class DialogAjoutModificationLieuComponent {

  lieuExterne: Lieu|null = null;
  form: UntypedFormGroup;
  estAjout: boolean = false;
  multiple: boolean = true;
  sauvegardeEnCours: boolean = false;

  TypeReferentielOrion = TypeReferentielOrion;

  private fields = ['masque', 'priseEnCompteVentes', 'lieuLivraison', 'serviceEmetteur', 'destination', 'serviceGestionnaire', 'typeTva'];

  constructor(private dialog: MatDialogRef<DialogAjoutModificationLieuComponent>,
              private fb: FormBuilder,
              private lieuService: LieuService,
              private cachService: CacheService,
              private messageTool: MessageTool,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataModificationLieu) {

    this.estAjout = !this.data.listeLieux || this.data.listeLieux.length === 0;
    this.multiple = this.data.listeLieux && this.data.listeLieux.length > 1;

    this.lieuExterne = null;

    this.form = fb.group({
      masque:              fb.control({value: false, disabled: this.multiple}, Validators.required),
      priseEnCompteVentes: fb.control({value: false, disabled: this.multiple}, Validators.required),
      lieuLivraison:       fb.control({value: false, disabled: this.multiple}, Validators.required),
      nomLieuLivraison:    fb.control({value: null, disabled: true}),
      serviceEmetteur:     fb.control({value: null, disabled: this.multiple}),
      destination:         fb.control({value: null, disabled: this.multiple}),
      serviceGestionnaire: fb.control({value: null, disabled: this.multiple}),
      typeTva:             fb.control({value: null, disabled: this.multiple}),
    });

    // Contrôles indiquant si le champ associé doit être pris en compte lors de l'enregistrement
    //  masqués et toujours vrais s'il ne s'agit pas d'une modification multiple
    //  affichés et décochés par défaut s'il s'agit d'une modification multiple
    for (const name of this.fields) {
      this.form.addControl(name + 'Check', fb.control(!this.multiple));
    }

    if (!this.multiple) {
      this.form.addControl('batimentBns', fb.control<BatimentBns>({value: null, disabled: !this.estAjout}, this.estAjout ? Validators.required : null));
      this.form.addControl('lieuBns', fb.control<LieuBns>({value: null, disabled: true}, this.estAjout ? Validators.required : null));
    }

    this.initValues();
    this.initFormChanges();
  }

  private initValues(): void {
    if (!this.estAjout) {
      const first = this.data.listeLieux[0];

      if (!this.multiple) {
        const batimentBns = first.idBatimentBns === null ? null : BatimentBns.buildWithId(first.idBatimentBns);
        this.form.get('batimentBns').setValue(batimentBns);
        const lieuBns = first.idLieuBns === null ? null : LieuBns.buildWithId(first.idLieuBns);
        this.form.get('lieuBns').setValue(lieuBns);
      }

      for (const name of this.fields) {
        if (!this.multiple || this.data.listeLieux.filter(lieu => !DialogAjoutModificationLieuComponent.identique(lieu[name], first[name])).length === 0) {
          this.form.get(name).setValue(first[name]);
        }
      }

      if (!this.multiple) {
        this.form.get('nomLieuLivraison').setValue(first.nomLieuLivraison);
        if (first.lieuLivraison) {
          this.form.get('nomLieuLivraison').enable();
        }
      }
    }
  }

  private static identique(v1, v2): boolean {
    if (typeof v1 === 'object' && 'code' in v1 && typeof v2 === 'object' && 'code' in v2) {
      return v1.code === v2.code;
    } else {
      return v1 === v2;
    }
  }

  private initFormChanges(): void {
    for (const name of this.fields) {
      this.form.get(name + 'Check').valueChanges.subscribe(checked => {
        if (checked) {
          if (name === 'masque' || name === 'priseEnCompteVentes' || name === 'lieuLivraison') {
            this.form.get(name).setValidators(Validators.required);
          }
          this.form.get(name).enable();

        } else {
          if (name === 'masque' || name === 'priseEnCompteVentes' || name === 'lieuLivraison') {
            this.form.get(name).clearValidators();
          }
          this.form.get(name).disable();
        }
      });
    }

    this.form.get('lieuLivraison').valueChanges.subscribe(checked => {
      if (checked && !this.multiple) {
        this.form.get('nomLieuLivraison').enable();
      } else {
        this.form.get('nomLieuLivraison').disable();
      }
    });
  }

  onLieuExterneSubmitted(lieuExterne: Lieu|LieuBns) {
    if (lieuExterne != undefined) {
      if (lieuExterne instanceof Lieu) {
        this.lieuExterne = lieuExterne;
      } else {
        this.lieuExterne = new Lieu();
        this.lieuExterne.idLieuBns = lieuExterne.idLieu;
        this.lieuExterne.nom = lieuExterne.nom;
      }
    } else {
      this.lieuExterne = null;
    }
  }

  annuler() {
    this.dialog.close(null);
  }

  sauvegarder(): void {
    this.sauvegardeEnCours = true;

    if (this.estAjout) {
      let lieuExistant: Lieu = this.data.listeLieuxExistants.find(l => l.idLieuBns === this.lieuExterne.idLieuBns);

      if (lieuExistant) {
        this.data.listeLieux = [lieuExistant];
        this.messageTool.sendErrorMessage(`Ce lieu existe déjà dans Épona (identifiant interne : ${lieuExistant.idLieu})`);
        this.sauvegardeEnCours = false;
        return;
      } else {
        this.data.listeLieux = [this.lieuExterne];
      }
    }

    const listeObservables = new Array<Observable<any>>();

    for (const lieu of this.data.listeLieux) {
      for (const name of this.fields) {
        if (this.form.get(name + 'Check').value) {
          lieu[name] = this.form.get(name).value;
        }
      }
      if (!this.multiple) {
        lieu.nomLieuLivraison = this.form.get('nomLieuLivraison').value;
      }

      if (this.estAjout) {
        listeObservables.push(this.lieuService.postLieu(lieu));
      } else {
        listeObservables.push(this.lieuService.putLieu(lieu));
      }
    }

    if (this.estAjout) {
      listeObservables[0].pipe(
        finalize(() => {
          this.sauvegardeEnCours = false;
        })
      ).subscribe(res => {
        this.messageTool.sendSuccess("Le lieu a été ajouté avec succès", ClearMessages.TRUE);
        this.dialog.close(res.idLieu);
        this.cachService.delete('lieux');
      }, err => {
        this.messageTool.sendError(err);
      });

    } else {
      forkJoin(listeObservables).pipe(
        finalize(() => {
          this.sauvegardeEnCours = false;
          this.cachService.delete('lieux');
        })
      ).subscribe(() => {
        let message: string;
        if (this.multiple) {
          message = "Les lieux ont été enregistrés avec succès";
        } else {
          message = "Le lieu a été enregistré avec succès";
        }
        this.messageTool.sendSuccess(message, ClearMessages.TRUE);
        this.dialog.close(true);

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }
}
