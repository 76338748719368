<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>{{ data.title }}</span>
    <span class="space"></span>
    <button type="button" mat-icon-button mat-dialog-close aria-pressed="false">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content class="mat-typography">
  <div class='alert' [ngClass]="data.textClass" *ngIf="data.text" [innerHTML]="data.text"></div>

  <ul>
    <li *ngFor="let error of data.errors">{{ error.error_description }}</li>
  </ul>
</mat-dialog-content>
