<div class="table-actions">
    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogAjoutDocument()"
            [disabled]="!droitSaisie || !modifiable || nbDocumentsMax !== undefined && dataSource.data.length >= nbDocumentsMax">
        <fa-icon icon="plus"></fa-icon> Ajouter…
    </button>
</div>

<div class="table mat-elevation-z8">
  <table mat-table
         [dataSource]="dataSource"
         aria-label="Liste de documents">

    <ng-container matColumnDef="libelle">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ columns['libelle'].label }}</th>
        <td mat-cell *matCellDef="let element;let i = dataIndex">
            <div>{{ element.libelle }}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="visualisation">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ columns['visualisation'].label }}</th>

      <td mat-cell *matCellDef="let element;let i = dataIndex">
        <button type="button"
                mat-button
                matTooltip="Visualiser le fichier"
                (click)="viewFile(element)"><mat-icon>preview</mat-icon></button>
      </td>
    </ng-container>

    <ng-container matColumnDef="telechargement">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ columns['telechargement'].label }}</th>

      <td mat-cell *matCellDef="let element;let i = dataIndex">
        <button type="button"
                mat-button
                matTooltip="Télécharger le fichier"
                (click)="downloadFile(element)"><mat-icon>download</mat-icon></button>
      </td>
    </ng-container>

    <ng-container matColumnDef="modification">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ columns['modification'].label }}</th>

      <td mat-cell *matCellDef="let element;let i = dataIndex">
        <button type="button"
                mat-button
                matTooltip="Modifier le document"
                (click)="openDialogModificationDocument(element)" [disabled]="!droitSaisie || !modifiable"><mat-icon>edit</mat-icon></button>
      </td>
    </ng-container>

    <ng-container matColumnDef="suppression">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ columns['suppression'].label }}</th>

      <td mat-cell *matCellDef="let element;let i = dataIndex">
        <button type="button"
                mat-button
                matTooltip="Supprimer le document"
                (click)="openDialogSuppressionDocument(element)" [disabled]="!droitSaisie || !modifiable"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="colonnesAffichees; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: colonnesAffichees;"></tr>

      <!-- Ligne de pied de tableau en cas de liste vide -->
      <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="colonnesAffichees.length">Liste vide</td>
      </ng-container>

      <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']"
          [hidden]="dataSource.data.length > 0" class="no-record"></tr>
  </table>
</div>
