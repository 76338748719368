<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>{{ data.title }}</span>
    <span class="space"></span>
    <button type="button" mat-icon-button mat-dialog-close aria-pressed="false">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content class="mat-typography">
  <div [innerHTML]="data.body"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div *ngIf="!data.validationWithObservable">
    <button mat-button        color="accent" [mat-dialog-close]="false">{{data.noLabel}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">{{data.yesLabel}}</button>
  </div>

  <div *ngIf="data.validationWithObservable">
    <button mat-button        color="accent"  (click)="action(false)">{{data.noLabel}}</button>
    <button mat-raised-button color="primary" (click)="action(true)">{{data.yesLabel}}</button>
  </div>
</mat-dialog-actions>
