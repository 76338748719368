<mat-card class="card-width tdb-matcard">
  <mat-card-header class="card-title">
    <mat-card-title>
      <label>
        Mouvements validés depuis le
        <input matInput
               id="dateMinESValidees"
               [formControl]="dateCtrl"
               [matDatepicker]="picker"
               (click)="picker.open()"
               (focus)="picker.open()"
               (dateChange)="rempliListeTypes()"
               autocomplete="off">
        <mat-datepicker #picker></mat-datepicker>
      </label>
    </mat-card-title>
  </mat-card-header>

  <table mat-table class="mat-elevation-z8 tdb-table" [dataSource]="dataSource"
         matSort matSortActive="typeMouvement" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Nombre de mouvements validés par type de mouvement">

    <ng-container matColumnDef="typeMouvement">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let element">
        <abbr matTooltip="{{element.typeMouvement.libelle}}">{{element.typeMouvement.codeTypeMouvement}}</abbr>
      </td>
    </ng-container>

    <ng-container matColumnDef="nombreValide">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Nombre de mouvements validés" mat-sort-header>Nb.</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="[getRouterLink(element)]" [queryParams]="getQueryParams(element)">{{element.nombreValide}}</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
  </table>

  <mat-paginator class="tdb-table" #paginator [pageSize]="3" hidePageSize></mat-paginator>
  <!--  decommente si besoin [pageSizeOptions]="[3, 5, 10]">-->
</mat-card>
