import {Component, OnInit, ViewChild} from '@angular/core';
import {Lieu} from "../../model/epona-api/Lieu";
import {PanierFilter} from "../../model/epona-ui/panier-filter";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {PanierQueryParams} from "../../model/epona-ui/panier-query-params";
import {MatTableDataSource} from "@angular/material/table";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {Tools} from "../../commons/Tools";
import {PanierService} from "../../services/epona/panier.service";
import {MatSort} from "@angular/material/sort";
import {PanierSearch} from "../../model/epona-api/panier-search";
import {MessageTool} from "../../commons/MessageTool";
import {PanierEntete} from "../../model/epona-api/PanierEntete";
import {FiltrePaniersComponent} from "../filtre-paniers/filtre-paniers.component";
import {FilterTools} from "../../commons/FilterTools";
import {SortHelper} from "../../helpers/sort-helper";
import {RevisionTarifaireService} from "../../services/epona/revision-tarifaire.service";
import {MatDialog} from "@angular/material/dialog";
import {
  DialogRevisionTarifaireComponent
} from "../../revisions-tarifaires/dialog-revision-tarifaire/dialog-revision-tarifaire.component";
import {CnousTableExporterDirective} from "../../commons/directives/cnous-table-exporter.directive";

@Component({
  selector: 'app-liste-paniers',
  templateUrl: './liste-paniers.component.html',
  styleUrls: ['./liste-paniers.component.css']
})
export class ListePaniersComponent implements OnInit {

  dataSource = new MatTableDataSource<PanierEntete>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    idPanierEntete:      new TableColumn({label: 'Id.',            tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
    batiment:            new TableColumn({label: 'Bâtiment'}),
    lieuDemandeur:       new TableColumn({label: 'Lieu de livraison'}),
    fournisseur:         new TableColumn({label: 'Fournisseur'}),
    dateCreation:        new TableColumn({label: 'Date création', default: false, exportFormat: 'date'}),
    utilisateurCreation: new TableColumn({label: 'Créé par',      default: false}),
    nbCommandes:         new TableColumn({label: 'Nb. commandes', default: false, exportFormat: 'integer'}),
    actions:             new TableColumn({label: 'Actions',       export: false, hiddable: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.PANIERS;

  displayedColumns: string[] = [];

  droitModification: boolean;
  lieuCourant: Lieu;

  @ViewChild(FiltrePaniersComponent)
  filterComponent: FiltrePaniersComponent;

  @ViewChild('tableauPaniers') tableauPaniers: CnousTableExporterDirective;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  filter: PanierFilter;
  droitSaisie: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private panierService: PanierService,
              public dialog: MatDialog,
              private revisionTarifaireService: RevisionTarifaireService,
              private messageTool: MessageTool) { }

  ngOnInit(): void {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMAp pour que le ngOnInit soit rappelé si une recherche est lancé depuis la liste des commandes
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.initDefaultFilters();
      }

      // Lancement de la recherche
      this.recherchePaniers();
    });

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;

    this.droitSaisie = this.panierService.droitSaisie();
    this.droitModification = this.panierService.droitModification();

  }


  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre de commandes
    this.filter = new PanierFilter();
    this.filter.numeroPanier = params.get('numeroPanier') ? params.get('numeroPanier') : null;
    this.filter.utilisateur  = params.get('utilisateur') ? params.get('utilisateur') : null;
    this.filter.fournisseur  = FilterTools.buildFournisseurFromURL(params);
  }

  // Initialisation des filtres à partir des valeurs par défaut
  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre de commandes
    this.filter = new PanierFilter();
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche de commande pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: PanierFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['liste-paniers'], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): PanierQueryParams {
    const params = new PanierQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.numeroPanier = this.filter.numeroPanier;
    params.utilisateur = this.filter.utilisateur;
    params.idFournisseur  = this.filter.fournisseur ? this.filter.fournisseur.idFournisseur : null;

    return params;
  }

  private recherchePaniers() {
    this.dataSource.data = [];

    const search = this.buildSearchFromFilters();
    search.fields = '' +
      'idPanierEntete,' +
      'dateCreation,' +
      'utilisateurCreation,' +
      'lieuDemandeur.nomRu,' +
      'lieuDemandeur.nomUd,' +
      'fournisseur.nom,' +
      'nbCommandes';

    this.panierService.getListeEntetes(search).subscribe(data => {
      this.dataSource.data = data;
      this.setDisplayedColumns();
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  private buildSearchFromFilters(): PanierSearch {
    const search = new PanierSearch();

    search.setIdsLieu(this.lieuCourant);

    search.utilisateur  = this.filter.utilisateur;
    search.numeroPanier  = this.filter.numeroPanier;
    search.idFournisseur   = this.filter.fournisseur ? this.filter.fournisseur.idFournisseur: null;

    return search;
  }

  sortingDataAccessor(data: PanierEntete, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'idPanierEntete'      : return data.idPanierEntete;
      case 'batiment'            : return SortHelper.stringToString(data.lieuDemandeur?.nomRu);
      case 'lieuDemandeur'       : return SortHelper.stringToString(data.lieuDemandeur?.nomUd);
      case 'fournisseur'         : return SortHelper.stringToString(data.fournisseur?.nom);
      case 'dateCreation'        : return SortHelper.dateToNumber(data.dateCreation);
      case 'utilisateurCreation' : return SortHelper.stringToString(data.utilisateurCreation);
      default                    : return data[sortHeaderId];
    }
  }

  openProchainesRevisionsTarifaires() {
    this.dialog.open(DialogRevisionTarifaireComponent);
  }
}
