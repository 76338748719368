import {Pipe, PipeTransform} from '@angular/core';
import {SousFamille} from "../../model/SousFamille";

@Pipe({
  name: 'sousFamille'
})
export class SousFamillePipe implements PipeTransform {
  transform(sousFamille: SousFamille): string {
    return SousFamillePipe.buildLibelle(sousFamille);
  }

  static buildLibelle(sousFamille: SousFamille): string {
    if (sousFamille) {
      return sousFamille.famille.departement.libelle
        + ' > ' + sousFamille.famille.libelle
        + ' > ' + sousFamille.libelle;
    } else {
      return '';
    }
  }
}
