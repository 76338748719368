import {TypeAction} from "./type-action";

export class EchangeFdnc {
  dateEnregistrement: Date;
  type: number;
  detailAction: string;
  analyseCauses: string;
  incidenceQualiteProduit: boolean;
  actionAMettreEnOeuvre: string;
  commentaire: string;
  resultat: number;
  valide: boolean;
  nomAuteur: string;
  listeTypesAction: TypeAction;
}
