<mat-select [formControl]="formCtrl"
            (selectionChange)="selectionChanged()"
            panelClass="select-panel"
            #selectGroupe>
  <mat-select-trigger>
    {{ selectGroupe.value !== DEFAULT ? getCurrentItem()?.libelle : defaultLabel }}
  </mat-select-trigger>

  <mat-option [value]="DEFAULT">{{ defaultLabel }}</mat-option>
  <mat-option *ngFor="let item of liste" [value]="item.code">
    {{ item.code === UNDEFINED ? item.libelle : item.code + ' - ' + item.libelle }}
  </mat-option>
</mat-select>
