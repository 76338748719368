import {Component, Inject} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Article} from "../../model/epona-api/Article";
import {DialogDataAjoutArticleInventaire} from "../../model/epona-ui/DialogDataAjoutArticleInventaire";
import {MessageTool} from "../../commons/MessageTool";
import {InventaireService} from "../../services/epona/inventaire.service";
import {ArticleTools} from "../../commons/ArticleTools";

@Component({
  selector: 'app-dialog-ajout-article',
  templateUrl: './dialog-ajout-article.component.html',
  styleUrls: ['./dialog-ajout-article.component.css']
})
export class DialogAjoutArticleComponent {
  articleCtrl: UntypedFormControl;
  listeIdArticleDejaPresents: number[];

  constructor(public dialog: MatDialogRef<DialogAjoutArticleComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataAjoutArticleInventaire,
              private inventaireService: InventaireService,
              private messageTool: MessageTool) {

    this.listeIdArticleDejaPresents = data.lignes.map<number>(ligne => ligne.article.idArticle);

    this.articleCtrl = new UntypedFormControl(null, Validators.required);
  }

  close() {
    this.dialog.close();
  }

  save() {
    const article = new Article();
    article.idArticle = this.articleCtrl.value.idArticle;

    this.inventaireService.postInventaireArticles(this.data.entete.idInventaireEntete, [article]).subscribe(() => {
      const designation = ArticleTools.getDesignation(this.articleCtrl.value);
      this.messageTool.sendSuccess(`L'article ${ designation } a été ajouté avec succès`);
      this.data.article = article;
      this.dialog.close();
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  disableBoutonAjouter() {
    return !this.articleCtrl.value;
  }
}
