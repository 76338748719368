<table mat-table [dataSource]="dataSource"
       matSort matSortActive="codeMarcheOrion" matSortDirection="asc" matSortDisableClear="true"
       class="mat-elevation-z8"
       aria-label="Liste de révisions tarifaires à venir">

    <ng-container matColumnDef="codeMarcheOrion">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['codeMarcheOrion'].label }}</th>
        <td mat-cell *matCellDef="let element">{{element.codeMarcheOrion}}</td>
    </ng-container>

    <ng-container matColumnDef="fournisseur">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['fournisseur'].label }}</th>
        <td mat-cell *matCellDef="let element">{{element.fournisseur?.nom}}</td>
    </ng-container>

    <ng-container matColumnDef="dateRevision">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['dateRevision'].label }}</th>
        <td mat-cell *matCellDef="let element">{{element.dateRevision | dateFormat}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="colonnesAffichees"></tr>
    <tr mat-row *matRowDef="let row; columns: colonnesAffichees;"></tr>


    <!-- Ligne de pied de tableau en cas de liste vide -->
    <ng-container matColumnDef="noRecord">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="colonnesAffichees.length">Liste vide</td>
    </ng-container>

    <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
</table>
