import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Groupe} from "../../model/epona-api/Groupe";
import {SousGroupe} from "../../model/epona-api/SousGroupe";
import {ZoneGeographique} from "../../model/epona-api/zone-geographique";
import {HttpService} from "../http.service";
import {LocalStorageHelper} from "../../helpers/local-storage-helper";

@Injectable()
export class BnaService {
  private env = environment;
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) {
    this.baseUrl = this.env.eponaApiUrl + '/bna';
  }

  /**
   * Méthode permettant de récupérer la liste des groupes d'articles
   */
  getGroupes(): Observable<Groupe[]> {
    return this.httpClient.get<Groupe[]>(this.baseUrl + '/groupes-articles');
  }

  /**
   * Méthode permettant de récupérer les sous-groupes d'un groupe d'articles
   * @param codeGroupeArticles
   */
  getSousGroupes(codeGroupeArticles: string): Observable<SousGroupe[]>{
    return this.httpClient.get<SousGroupe[]>(this.baseUrl + '/groupes-articles/' + codeGroupeArticles + '/sous-groupes-articles');
  }

  /**
   * Méthode permettant de récupérer la liste des zones géographiques du Crous courant
   */
  getZonesGeographiques(): Observable<ZoneGeographique[]> {
    const params = this.httpService.buildParams({idCrous: LocalStorageHelper.getIdEtablissement()});
    return this.httpClient.get<ZoneGeographique[]>(this.baseUrl + '/zones-geographiques', {params});
  }
}
