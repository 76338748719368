<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Historique d'une ligne</span>

    <span class="space"></span>

    <button type="button" mat-icon-button (click)="dialog.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" aria-label="Historique des corrections d'une ligne d'entrée/sortie">
    <ng-container matColumnDef="action">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Action</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.dateCreation ? 'Correction' : 'Validation' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Date</th>
      <td mat-cell *matCellDef="let ligne">
        {{ (ligne.dateCreation ? ligne.dateCreation : data.entete.dateValidation) | dateTimeFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="utilisateur">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Utilisateur</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.utilisateurCreation ? ligne.utilisateurCreation : data.entete.utilisateurValidation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="designation">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Article</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.article | designation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dlc">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">DLC</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.dlc | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="quantite">
      <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col">{{data.params.editQuantiteLabel}}</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.quantite > 0 ? ligne.quantite : - ligne.quantite }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="dialog.close()">Fermer</button>
</mat-dialog-actions>
