import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {UserService} from '../services/user.service';
import {Utilisateur} from '../model/Utilisateur';
import {UtilisateurAuth} from "../model/UtilisateurAuth";
import {environment} from "../../environments/environment";
import {TopbarComponent} from "../topbar/topbar.component";
import {MessageTool} from "../commons/MessageTool";

@Injectable()
export class AuthGuard {

  private user:Utilisateur;

  /**
   * Constructeur par défaut de la classe AuthGuard
   * @param userService
   * @param router instance de Router
   * @param messageTool service d'aide à l'affichage de messages
   */
  constructor(private userService: UserService, private router: Router, private messageTool: MessageTool) {
  }

  /**
   * Méthode permettant de renvoyer l'utilisateur vers la page de login si il apparait qu'il n'est pas connecté
   * @param next  Route à laquelle l'utilisateur veut accèder
   * @param state Route actuelle
   */
  canActivate(
    next: ActivatedRouteSnapshot | null,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    TopbarComponent.setActivatedMenu(next);

    // Droit nécessaire pour pouvoir accéder à la route
    let droitNecessaire = next.data["droit"] as string;

    // Si l'utilisateur n'est pas authentifié
    return this.isAuth(droitNecessaire);

  }

  handleActivation(droitNecessaire: string): boolean {
      // Si aucun droit n'est nécessaire
      if (droitNecessaire === undefined) {
        return true;

        // Sinon, vérification que le droit fait partie des droits de l'utilisateur connecté
      } else {
        if (Array.isArray(droitNecessaire)){
          for (const d of droitNecessaire) {
            if (this.user.possedeDroit(d)) {
              return true;
            }
          }

        } else {
            if (this.user.possedeDroit(droitNecessaire)) {
              return true;
            }
        }

        this.messageTool.sendErrorMessage('Accés refusé : vous ne disposez pas des droits suffisants');
        console.error(`Droit nécessaire : ${droitNecessaire}`);
        return false;
      }
  }

  isAuth(droitNecessaire:string) {

    if (this.isDevOrInt()) {
      // Connexion par EPPN
      if (!this.userService.utilisateurCourant && sessionStorage.getItem('rememberMe')) {
        this.user = this.userService.rememberMeToUserEpona();
        this.userService.storeLoggedInUser(this.user);

      // Connexion Json
      } else if (this.userService.utilisateurCourant) {
        this.user = this.userService.utilisateurCourant;
      }
    }

    if (this.user) {
      return this.handleActivation(droitNecessaire);
    }

    return this.userService.me().pipe(
      tap((user: UtilisateurAuth) => {
        this.user = this.userService.userAuthToUserEpona(user);
        this.userService.storeLoggedInUser(this.user)
      }),
      map(res => {
        // Si l'utilisateur n'a aucun droit, il est déconnecté
        if (!res.listeIdDroit || res.listeIdDroit.length === 0) {
          this.messageTool.sendErrorMessage(`Aucun droit trouvé pour l\'utilisateur ${res.eppn}`);
          this.userService.logOut();
          return false;
        }

        if (res) {
          return this.handleActivation(droitNecessaire);
        } else {
          console.error("/me error");
          return false;
        }
      }),
      catchError((err) => {
        console.error(err);
        return of(false);
      })
    );
  }

  isDevOrInt(): boolean {
    return environment.env === 'DEV' || environment.env === 'INT';
  }
}
