<mat-card style="width: 90%">
  <form class="form" (ngSubmit)="onSubmit()" [formGroup]="fournisseurGlobalForm" *ngIf="fournisseurGlobalForm">
    <mat-grid-list cols="2" rowHeight="90px">

      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Nom</mat-label>
          <input matInput formControlName="nom">
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-form-field style="width: 90%">
          <mat-label>Numéro de rue</mat-label>
          <input matInput type="number" formControlName="adresseNumero">
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Siret</mat-label>
          <input matInput formControlName="siret">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Bis/Ter/Quater</mat-label>
          <input matInput formControlName="adresseBTQ">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Code-tiers Orion</mat-label>
          <input matInput formControlName="codeTiersOrion">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Rue</mat-label>
          <input matInput formControlName="adresseRue">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Code-client du Crous chez le fournisseur</mat-label>
          <input matInput formControlName="codeClient">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Complément d'adresse</mat-label>
          <input matInput formControlName="adresseComplement">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Téléphone</mat-label>
              <input matInput formControlName="telephone">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field style="width: 90%">
          <mat-label>Code postal</mat-label>
          <input matInput type="number" formControlName="adresseCodePostal">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Fax</mat-label>
              <input matInput formControlName="fax">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Ville</mat-label>
          <input matInput formControlName="adresseVille">
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="mail">
          <mat-error *ngIf="fournisseurGlobalForm.get('mail').hasError('email')">Format email incorrect</mat-error>
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile>
        <mat-form-field  style="width: 90%">
          <mat-label>Pays</mat-label>
          <input matInput formControlName="adressePays">
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
      <div class="form-actions">
        <button type="submit"
                mat-raised-button color="primary"
                [disabled]="disabledForm">
          <fa-icon icon="save"></fa-icon> Enregistrer
        </button>
      </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</mat-card>
