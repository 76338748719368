import {BatimentBns} from "./batiment-bns";

export class LieuBns {

  batiment!: BatimentBns;
  idLieu!: number;
  nom!: string;
  actif!: boolean;

  static buildWithId(id: number): LieuBns {
    const lieu = new LieuBns();
    lieu.idLieu = id;
    return lieu;
  }

  static sort(a: LieuBns, b: LieuBns): number {
    return a.nom.localeCompare(b.nom);
  }
}
