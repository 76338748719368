export class CodeDroit {
  public static VISUALISATION_VENTES                = '2016';
  public static GESTION_INVENTAIRES                 = '2020';
  public static GESTION_PARAMETRAGES_LIEUX_ARTICLES = '2022';
  public static GESTION_TRANSFERTS                  = '2024';
  public static GESTION_PERTES                      = '2025';
  public static GESTION_CONSOMMATIONS_UTILISATIONS  = '2026';
  public static GESTION_FOURNISSEURS_GLOBALE        = '2032';
  public static GESTION_FOURNISSEURS_LOCALE         = '2033';
  public static GESTION_ARTICLES                    = '2041';
  public static GESTION_REINTEGRATIONS              = '2044';
  public static GESTION_LIEUX                       = '2047';

  public static STOCKS_CONSULTATION = '2017';

  public static COMMANDES_CONSULTATION          = '2034';
  public static COMMANDES_SAISIE                = '2035';
  public static COMMANDES_ENVOI_MAGASIN         = '2036';
  public static COMMANDES_PREPARATION           = '2037';
  public static COMMANDES_FINALISATION_COMMANDE = '2039';
  public static COMMANDES_PREPARATION_EJ        = '2042';
  public static COMMANDES_ENVOI_EJ              = '2043';

  public static LIVRAISONS_CONSULTATION   = '2018';
  public static LIVRAISONS_SAISIE         = '2019';
  public static LIVRAISONS_VALIDATION     = '2023';
  public static LIVRAISONS_PREPARATION_SF = '2049';
  public static LIVRAISONS_ENVOI_SF       = '2048';

  public static FDNC_CONSULTATION   = "2056";
  public static FDNC_SAISIE         = "2057";
  public static FDNC_EMISSION       = "2058";
  public static FDNC_ECHANGE_SAISIE = "2059";

  public static PANIERS_CONSULTATION = '2045';
  public static PANIERS_SAISIE       = '2046';
}
