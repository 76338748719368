import {PanierEntete} from "./PanierEntete";

export class PanierEntetePUT {

  commentaire: string

  constructor(panierEntete: PanierEntete) {
    this.commentaire            = panierEntete.commentaire;
  }
}
