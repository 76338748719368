import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self, SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {OrionService} from "../../../services/epona/orion.service";
import {CacheService} from "../../../services/cache.service";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {ReferentielOrionSearch} from "../../../model/epona-api/ReferentielOrionSearch";
import {ReferentielOrion} from "../../../model/epona-api/ReferentielOrion";
import {TypeReferentielOrion} from "../../constants/TypeReferentielOrion";

@Component({
  selector: 'epona-select-referentiel-orion',
  templateUrl: './select-referentiel-orion.component.html',
  styleUrls: ['./select-referentiel-orion.component.css'],
  providers: [{provide: MatFormFieldControl, useExisting: SelectReferentielOrionComponent}]
})
export class SelectReferentielOrionComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, MatFormFieldControl<ReferentielOrion> {

  @Input() defaultLabel: string = '- Aucun -';
  @Input() type: TypeReferentielOrion;
  @Output() readonly valueChanged = new EventEmitter<ReferentielOrion>();

  public readonly DEFAULT = Const.DEFAULT;
  private CACHE_KEY: string;
  private static loadInProgress: {[key: string]: boolean} = {};
  private static listLoaded: {[key: string]: Subject<ReferentielOrion[]>} = {};

  formCtrl: UntypedFormControl;
  liste: ReferentielOrion[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private orionService: OrionService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.CACHE_KEY = 'orion-' + this.type.urlPart;
    this.loadListe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type'] && changes['type'].currentValue) {
      if (SelectReferentielOrionComponent.loadInProgress[this.type.urlPart] === undefined) {
        SelectReferentielOrionComponent.loadInProgress[this.type.urlPart] = false;
      }
      if (SelectReferentielOrionComponent.listLoaded[this.type.urlPart] === undefined) {
        SelectReferentielOrionComponent.listLoaded[this.type.urlPart] = new Subject<ReferentielOrion[]>();
      }
    }
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: ReferentielOrion): void {
    this.formCtrl.setValue(obj ? obj[this.type.idFieldName] : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
    this.valueChanged.emit(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): ReferentielOrion {
    return this.getCurrentItem();
  }
  set value(value: ReferentielOrion) {
    this.formCtrl.setValue(value ? value[this.type.idFieldName] : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): ReferentielOrion {
    return this.liste.find(item => item[this.type.idFieldName] === this.formCtrl.value)
  }

  private loadListe() {
    // Si la liste a déjà été chargée
    if (this.cacheService.has(this.CACHE_KEY)) {
      this.setAndSortListeFromCache();

    // Sinon, si le chargement est en cours, attente de la fin du chargement
    } else if (SelectReferentielOrionComponent.loadInProgress[this.type.urlPart]) {
      SelectReferentielOrionComponent.listLoaded[this.type.urlPart].subscribe(list => {
        this.setAndSortListeFromCache();
        SelectReferentielOrionComponent.listLoaded[this.type.urlPart].unsubscribe();
      });

    // Sinon récupération de la liste via l'API
    } else {
      SelectReferentielOrionComponent.loadInProgress[this.type.urlPart] = true;

      const search = new ReferentielOrionSearch();
      search.idCrous = +localStorage.getItem('idCrous');
      search.actif = true;
      search.fields = this.type.idFieldName + ',code,libelle';

      this.orionService.getListeReferentielOrion(this.type, search).subscribe(data => {
        this.cacheService.set(this.CACHE_KEY, data);
        SelectReferentielOrionComponent.loadInProgress[this.type.urlPart] = false;
        SelectReferentielOrionComponent.listLoaded[this.type.urlPart].next(data);
        this.setAndSortListeFromCache();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = this.cacheService.get(this.CACHE_KEY);
    this.tri(this.liste);
  }

  tri(liste: Array<ReferentielOrion>) {
    liste.sort((a, b) => {
      return a.code.localeCompare(b.code);
    });
  }
}
