import {SousLotZg} from "./SousLotZg";

export class Marche {
  codeMarcheOrion: string;
  dateDebut: Date;
  dateFin: Date;

  constructor(sousLot?: SousLotZg) {
    if (sousLot) {
      this.codeMarcheOrion = sousLot.codeMarcheOrion;
      this.dateDebut = new Date(sousLot.dateDebut);
      this.dateFin = new Date(sousLot.dateFin);
    }
  }
}
