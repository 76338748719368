import {DTOWithLieu} from "./DTOWithLieu";

export class StockCompactQueryParams extends DTOWithLieu {
  codeGroupe: string;
  idSousGroupe: number;
  idArticle: number;
  masquerQuantitesNulles: boolean;
  stockFaible: boolean;
  nombreJoursAvantDlc: number;
}
