<epona-select-lieu [lieu]="lieuCourant"
                   [livraisonUniquement]="true"
                   lieuLabel="Lieu de livraison"
                   [disabled]="true"></epona-select-lieu>

<h1 *ngIf="fdnc === null">Création d'une FDNC</h1>
<h1 *ngIf="fdnc !== null && droitSaisieFdnc">Modification d'une FDNC</h1>
<h1 *ngIf="fdnc !== null && !droitSaisieFdnc">Consultation d'une FDNC</h1>

<div class="fiche">
  <mat-card class="entete">
    <mat-card-header>
      <mat-card-title>FDNC</mat-card-title>
    </mat-card-header>

    <epona-fdnc [fdnc]="fdnc"
                [fdncBna]="fdncBna"
                (fdncSubmitted)="doSave($event)"
                (emissionSubmitted)="doEmission($event)"
                (suppressionSubmitted)="doSuppression($event)"></epona-fdnc>

    <mat-expansion-panel #metaDataPanel class="meta-data" *ngIf="fdnc">
      <mat-expansion-panel-header>
        <mat-panel-description>
          {{ metaDataPanel.expanded ? 'Masquer' : 'Afficher' }}
          les informations supplémentaires
        </mat-panel-description>
      </mat-expansion-panel-header>

      <epona-meta-data [type]="TypeMetaData.CREATION"
                       [date]="fdnc.dateCreation"
                       [utilisateur]="fdnc.utilisateurCreation"
                       *ngIf="fdnc?.dateCreation">
      </epona-meta-data>

      <epona-meta-data [type]="TypeMetaData.MODIFICATION"
                       [date]="fdnc.dateModification"
                       [utilisateur]="fdnc.utilisateurModification"
                       *ngIf="fdnc?.dateModification">
      </epona-meta-data>

      <epona-meta-data [type]="TypeMetaData.EMISSION"
                       [date]="fdnc.dateEmission"
                       [utilisateur]="fdnc.utilisateurEmission"
                       *ngIf="fdnc?.dateEmission">
      </epona-meta-data>
    </mat-expansion-panel>
  </mat-card>

  <mat-card class="lignes" *ngIf="fdnc">
    <mat-card-header>
      <mat-card-title>Échanges</mat-card-title>
    </mat-card-header>

    <div class="table-actions">
      <button type="button"
              mat-raised-button color="primary"
              (click)="openDialogAjoutEchange()"
              [disabled]="!fdncBna || fdncBna.etapeEnCours.idEtapeFdnc !== 4"
              *ngIf="droitSaisieEchange">
        <fa-icon icon="plus"></fa-icon> Ajouter…
      </button>
    </div>

    <epona-tableau-echanges-fdnc [listeEchanges]="listeEchanges"
                                 [colonnesAffichees]="colonnesEchangesAffichees">
    </epona-tableau-echanges-fdnc>
  </mat-card>
</div>
