import {CommandeEntete} from './CommandeEntete';
import {EntreeSortieEntete} from './EntreeSortieEntete';
import {EtapeFdnc} from "../bna-api/etape-fdnc";
import {StatutFdnc} from "../bna-api/statut-fdnc";
import {ContexteFdnc} from "../bna-api/contexte-fdnc";

export class FdncEponaBna {
  idFdnc: number;
  codeBna: string;
  commande: CommandeEntete;
  bl: EntreeSortieEntete;
  codeArticle: string;
  designationArticle: string;
  referenceFournisseur: string;
  numeroLotProduit: string;
  emission: Date;
  declarant: string;
  contexte: ContexteFdnc;
  dateConstat: Date;
  dateDerniereModification: Date;
  gravite: string;
  statut: StatutFdnc;
  etapeEnCours: EtapeFdnc;
  batiment: string;
  lieu: string;
}
