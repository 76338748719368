import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogDataLignesCommandeCumul} from "../../model/epona-ui/DialogDataLignesCommandeCumul";
import {MatTableDataSource} from "@angular/material/table";
import {CommandeLigne} from "../../model/epona-api/CommandeLigne";
import {MatSort} from "@angular/material/sort";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";

@Component({
  selector: 'app-dialog-lignes-commande-cumul',
  templateUrl: './dialog-lignes-commande-cumul.component.html',
  styleUrls: ['./dialog-lignes-commande-cumul.component.css']
})
export class DialogLignesCommandeCumulComponent implements OnInit {

  dataSource = new MatTableDataSource<CommandeLigne>([]);

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  readonly HI_CODE_ARTICLE = 'codeArticle';
  readonly HI_DESIGNATION  = 'designation';
  readonly HI_QUANTITE     = 'quantite';
  readonly HI_VALEUR_HT    = 'valeurHt';

  UA = UtilisationArticle;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataLignesCommandeCumul) {
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.data.lignes;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  private sortingDataAccessor(data: CommandeLigne, sortHeaderId: string): string|number {
    switch(sortHeaderId) {
      case this.HI_CODE_ARTICLE: return data.article.codeArticleAchat;
      case this.HI_DESIGNATION:  return data.article.designationAchat;
      case this.HI_QUANTITE:     return data.quantite;
      case this.HI_VALEUR_HT:    return data.valeurHt;
    }
  }
}
