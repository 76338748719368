import {EntreeSortieEntete} from "./EntreeSortieEntete";
import {LieuDTO} from "./LieuDTO";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";

export class EntreeSortieEntetePUT {
  idEntreeSortieEntete: number;
  codeBordereauLivraison: string;
  dateBordereauLivraison: Date;
  fraisPort: number;
  temperatureConforme: boolean;
  lieuDestination: LieuDTO;
  lieuEvenement: string;
  dateEvenement: Date;
  referenceEvenement: string;
  commentaire: string;

  constructor(entreeSortieEntete: EntreeSortieEntete) {
    this.idEntreeSortieEntete = entreeSortieEntete.idEntreeSortieEntete;

    // Seules les APP permettent la modification des champs concernant les BL
    if (entreeSortieEntete.typeMouvement
      && entreeSortieEntete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.APPROVISIONNEMENT) {
      this.codeBordereauLivraison = entreeSortieEntete.codeBordereauLivraison;
      this.dateBordereauLivraison = entreeSortieEntete.dateBordereauLivraison;
      this.fraisPort              = entreeSortieEntete.fraisPort;
      this.temperatureConforme    = entreeSortieEntete.temperatureConforme;
    }

    this.lieuDestination = entreeSortieEntete.lieuDestination ? new LieuDTO(entreeSortieEntete.lieuDestination) : null;

    // Seules les SOR permettent la modification des champs "évènementiels"
    if (entreeSortieEntete.typeMouvement
      && entreeSortieEntete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.SORTIE_EVENEMENTIELLE) {
      this.lieuEvenement      = entreeSortieEntete.lieuEvenement;
      this.dateEvenement      = entreeSortieEntete.dateEvenement;
      this.referenceEvenement = entreeSortieEntete.referenceEvenement;
    }

    this.commentaire = entreeSortieEntete.commentaire;
  }
}
