import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MouvementStock} from "../../model/epona-api/MouvementStock";
import {DialogDataStocks} from "../../model/epona-ui/DialogDataStocks";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {StockService} from "../../services/epona/stock.service";
import {MessageTool} from "../../commons/MessageTool";
import {MouvementStockSearch} from "../../model/epona-api/MouvementStockSearch";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";
import {Observable} from "rxjs";
import {ValorisationStock} from "../../model/epona-api/ValorisationStock";
import {ValorisationStockSearch} from "../../model/epona-api/ValorisationStockSearch";

@Component({
  selector: 'app-dialog-historique-mouvements-stocks',
  templateUrl: './dialog-historique-mouvements-stocks.component.html',
  styleUrls: ['./dialog-historique-mouvements-stocks.component.css']
})
export class DialogHistoriqueMouvementsStocksComponent implements OnInit {

  dataSourceMvt = new MatTableDataSource<MouvementStock>([]);
  displayedColumnsMvt: string[] = ['dlc', 'date', 'type', 'quantite', 'origine'];

  dataSourceVal = new MatTableDataSource<ValorisationStock>([]);
  displayedColumnsVal: string[] = ['dlc', 'date', 'montantHt', 'montantTtc', 'origine'];

  constructor(public dialog: MatDialogRef<DialogHistoriqueMouvementsStocksComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataStocks,
              private stockService: StockService,
              private messageTool: MessageTool) {
  }

  ngOnInit(): void {
    this.loadHistorique();

    // S'il ne s'agit pas d'un article avec DLC, la colonne DLC n'est pas affichée
    if (this.data.stockCompact.article.articleDlc !== true) {
      this.displayedColumnsMvt.splice(this.displayedColumnsMvt.indexOf('dlc'), 1);
      this.displayedColumnsVal.splice(this.displayedColumnsVal.indexOf('dlc'), 1);
    }
  }

  private loadHistorique(): void {
    this.buildObservableMouvements().subscribe({
      next: data => {
        data.sort(MouvementStock.sort)
        this.dataSourceMvt.data = data;
      },
      error: err => {
        this.messageTool.sendError(err);
      }
    });

    this.buildObservableValorisations().subscribe({
      next: data => {
        data.sort(ValorisationStock.sort)
        this.dataSourceVal.data = data;
      },
      error: err => {
        this.messageTool.sendError(err);
      }
    });
  }

  private buildObservableMouvements(): Observable<MouvementStock[]> {
    const fields = '' +
      'dlc,' +
      'dateMouvement,' +
      'typeMouvement,' +
      'quantite,' +
      'entreeSortieLigne.entete.idEntreeSortieEntete,' +
      'inventaireLigne.inventaire.idInventaireEntete';

    const search = new MouvementStockSearch();
    search.idLieu = this.data.stockCompact.lieu.idLieu;
    search.idArticle = this.data.stockCompact.article.idArticle;
    search.typesExclus = [CodeTypeMouvement.VENTE];
    search.fields = fields;

    return this.stockService.getListeMouvementsStocks(search);
  }

  private buildObservableValorisations(): Observable<ValorisationStock[]> {
    const fields = '' +
      'dlc,' +
      'dateValorisation,' +
      'pmpHt,' +
      'pmpTtc,' +
      'inventaireLigne.inventaire.idInventaireEntete';

    const search = new ValorisationStockSearch();
    search.idLieu = this.data.stockCompact.lieu.idLieu;
    search.idArticle = this.data.stockCompact.article.idArticle;
    search.fields = fields;

    return this.stockService.getListeValorisationsStocks(search);
  }

  getParametrage(element: MouvementStock): EntreeSortieParametrage {
    return EntreeSortieParametrage.getParametrageFromType(element.typeMouvement);
  }
}
