import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EngagementJuridiqueEntete} from "../../model/epona-api/EngagementJuridiqueEntete";
import {OrionSuivi} from "../../model/epona-api/OrionSuivi";
import {CommandeService} from "../../services/epona/commande.service";
import {MessageTool} from "../../commons/MessageTool";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {MatTableDataSource} from "@angular/material/table";
import {TableColumn} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {HttpService} from "../../services/http.service";

@Component({
  selector: 'epona-engagement-juridique-suivis-envois',
  templateUrl: './engagement-juridique-suivis-envois.component.html',
  styleUrl: './engagement-juridique-suivis-envois.component.css'
})
export class EngagementJuridiqueSuivisEnvoisComponent implements OnChanges {

  @Input() commande: CommandeEntete;
  @Input() entete: EngagementJuridiqueEntete;

  @Output() closeDialog = new EventEmitter<boolean>();

  dataSource = new MatTableDataSource<OrionSuivi>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    date:           new TableColumn({label: 'Date',      exportFormat: 'datetime', tooltip: 'Date de l\'opération'}),
    typeOperations: new TableColumn({label: 'Opération',                           tooltip: 'Type l\'opérations'}),
    piece:          new TableColumn({label: 'Pièce',                               tooltip: 'EJ / SF'}),
    numeroLigne:    new TableColumn({label: 'Ligne',     exportFormat: 'integer',  tooltip: 'Numéro de ligne de l\'EJ'}),
    origine:        new TableColumn({label: 'Origine',                             tooltip: 'Orgine de l\'intervention sur la ligne'}),
    montant:        new TableColumn({label: 'Montant',   exportFormat: 'decimal'}),
    agent:          new TableColumn({label: 'Code agent',                          tooltip: 'Code de l\'agent ayant effectué l\'opération'}),
    erreur:         new TableColumn({label: 'Erreur',                              tooltip: 'Erreur survenue lors de l\'envoi'}),
    debug:          new TableColumn({label: 'Débug',     export: false,            tooltip: 'Fichier nécessaire au support niveau 2'}),
  };

  readonly displayedColumns = [
    'date',
    'typeOperations',
    'numeroLigne',
    'origine',
    'montant',
    'agent',
    'erreur',
    'debug'
  ];

  constructor(private commandeService: CommandeService,
              private httpService: HttpService,
              private messageTool: MessageTool) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['commande']) {
      this.loadSuivisEnvois();
    }

    // S'il s'agit d'un rechargement de l'engagement juridique (suite à l'envoi vers Orion par exemple)
    if (changes['entete'] && !changes['entete'].firstChange && changes['entete'].previousValue) {
      this.loadSuivisEnvois();
    }
  }

  private loadSuivisEnvois() {
    const fields = '' +
      'idSuivi,' +
      'typeOperations.code,' +
      'typeOperations.libelle,' +
      'engagementJuridique.numeroEj,' +
      'engagementJuridiqueLigne.numeroLigne,' +
      'idEntreeSortieEntete,' +
      'montantHt,' +
      'dateEnvoi,' +
      'utilisateurEnvoi,' +
      'dateErreurEnvoi,' +
      'utilisateurErreurEnvoi,' +
      'messageErreurEnvoi' +
      '';

    this.commandeService.getEngagementJuridiqueSuivis(this.commande.idCommandeEntete, fields).subscribe(data => {
      data.sort(OrionSuivi.sort);
      this.dataSource.data = data;
    }, error => {
      this.messageTool.sendError(error);
    });
  }

  public downloadFile(suivi: OrionSuivi): void {
    const url = this.commandeService.buildUrlFichierSuivi(this.commande.idCommandeEntete, suivi.idSuivi);
    this.httpService.downloadFile(url);
  }

  close(): void {
    this.closeDialog.emit(true);
  }
}
