import {NumberComparisonOperator} from "../../commons/enum/NumberComparisonOperator";

export class NumberComparison {

  operator: NumberComparisonOperator;
  value: number;

  constructor(operator: NumberComparisonOperator|string, value: number) {
    this.operator = typeof operator === 'string' ? NumberComparisonOperator[operator] : NumberComparisonOperator.EQ;
    this.value = value;
  }
}
