<table mat-table [dataSource]="dataSource"
       matSort matSortActive="codeBna" matSortDirection="asc" matSortDisableClear="true"
       cnousTableExporter [columns]="columns"
       class="mat-elevation-z8"
       aria-label="Liste des fiches de non-conformité">

  <ng-container matColumnDef="codeBna">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['codeBna'].tooltip">{{ columns['codeBna'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.codeBna ? element.codeBna : element.idFdnc }}</td>
  </ng-container>

  <ng-container matColumnDef="batiment">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['batiment'].label }}</th>
    <td mat-cell *matCellDef="let element">{{element.batiment}}</td>
  </ng-container>

  <ng-container matColumnDef="lieu">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ columns['lieu'].label }}</th>
    <td mat-cell *matCellDef="let element">{{element.lieu}}</td>
  </ng-container>

  <ng-container matColumnDef="commande">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['commande'].tooltip">{{ columns['commande'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <a
      [routerLink]="['/commande', element.commande?.idCommandeEntete]"
      matTooltip="Consulter la commande {{ element.commande?.numeroEj }}">
       {{ element.commande?.numeroEj }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="fournisseur">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['fournisseur'].tooltip">{{ columns['fournisseur'].label }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.commande?.fournisseur?.nom }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lotMarche">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['lotMarche'].tooltip">{{ columns['lotMarche'].label }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.commande?.sousLotZg?.codeMarcheOrion }}
    </td>
  </ng-container>

  <ng-container matColumnDef="bl">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['bl'].tooltip">{{ columns['bl'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <a
        [routerLink]="['/bordereau-livraison', element.bl?.idEntreeSortieEntete]"
        matTooltip="Consulter le BL {{ element.bl | bordereauLivraison }}">
        {{ element.bl | bordereauLivraison }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="codeArticle">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['codeArticle'].tooltip">{{ columns['codeArticle'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.codeArticle }}</td>
  </ng-container>

  <ng-container matColumnDef="designationArticle">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['designationArticle'].tooltip">{{ columns['designationArticle'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.designationArticle }}</td>
  </ng-container>

  <ng-container matColumnDef="referenceFournisseur">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['referenceFournisseur'].tooltip">{{ columns['referenceFournisseur'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.referenceFournisseur }}</td>
  </ng-container>

  <ng-container matColumnDef="numeroLotProduit">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['numeroLotProduit'].tooltip">{{ columns['numeroLotProduit'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.numeroLotProduit }}</td>
  </ng-container>

  <ng-container matColumnDef="contexte">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['contexte'].tooltip">{{ columns['contexte'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.contexte.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="dateConstat">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['dateConstat'].tooltip">{{ columns['dateConstat'].label }}</th>
    <td mat-cell *matCellDef="let element">{{element.dateConstat | dateFormat}}</td>
  </ng-container>

  <ng-container matColumnDef="dateEmission">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['dateEmission'].tooltip">{{ columns['dateEmission'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.emission | dateTimeFormat }}</td>
  </ng-container>

  <ng-container matColumnDef="utilisateurEmission">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['utilisateurEmission'].tooltip">{{ columns['utilisateurEmission'].label }}</th>
    <td mat-cell *matCellDef="let element">{{element.declarant}}</td>
  </ng-container>

  <ng-container matColumnDef="dateDerniereModification">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['dateDerniereModification'].tooltip">{{ columns['dateDerniereModification'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.dateDerniereModification | dateFormat }}</td>
  </ng-container>

  <ng-container matColumnDef="gravite">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['gravite'].tooltip">{{ columns['gravite'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.gravite }}</td>
  </ng-container>

  <ng-container matColumnDef="statut">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['statut'].tooltip">{{ columns['statut'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.statut?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="etapeEnCours">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="columns['etapeEnCours'].tooltip">{{ columns['etapeEnCours'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.etapeEnCours?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ columns['actions'].label }}</th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="['/fdnc', element.idFdnc]" matTooltip="Modifier la FDNC" *ngIf="droitSaisie">
        <button type="button" mat-button><fa-icon icon="edit"></fa-icon></button>
      </a>
      <a [routerLink]="['/fdnc', element.idFdnc]" matTooltip="Consulter la FDNC" *ngIf="!droitSaisie && droitConsultation">
        <button type="button" mat-button><fa-icon icon="folder-open"></fa-icon></button>
      </a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="colonnesAffichees"></tr>
  <tr mat-row *matRowDef="let row; columns: colonnesAffichees;"></tr>


  <!-- Ligne de pied de tableau en cas de liste vide -->
  <ng-container matColumnDef="noRecord">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="colonnesAffichees.length">Liste vide</td>
  </ng-container>

  <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
</table>
