<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>Lignes de commande du cumul no. {{ data.numeroCumul }}</span>

    <span class="space"></span>

    <button type="button" mat-icon-button matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <table mat-table [dataSource]="dataSource"
         matSort matSortActive="designation" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Lignes de commande correspondant au cumul">

    <ng-container [matColumnDef]="HI_CODE_ARTICLE">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Code</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.article | codeArticle:UA.ACHAT:true }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="HI_DESIGNATION">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Article</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.article | designation:UA.ACHAT:true }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="HI_QUANTITE">
      <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Quantité</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.quantite | decimal4 }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="HI_VALEUR_HT">
      <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Valeur HT</th>
      <td mat-cell *matCellDef="let ligne">
        {{ ligne.valeurHt | decimal4 }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="[HI_CODE_ARTICLE, HI_DESIGNATION, HI_QUANTITE, HI_VALEUR_HT]"></tr>
    <tr mat-row *matRowDef="let row; columns: [HI_CODE_ARTICLE, HI_DESIGNATION, HI_QUANTITE, HI_VALEUR_HT];"></tr>
  </table>
</mat-dialog-content>
