<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>{{ data.title }}</span>
    <span class="space"></span>
    <button type="button" mat-icon-button [mat-dialog-close]="false" aria-pressed="false">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <div class="body" [innerHTML]="data.body"></div>

  <div class="form">
    <mat-form-field>
      <mat-label>{{ data.commentFieldName }}</mat-label>

      <textarea matInput cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="6"
                [formControl]="commentCtrl"
                [required]="data.commentRequired"
                [maxlength]="data.commentMaxLength">
      </textarea>

      <mat-error *ngIf="commentCtrl.hasError('required')">{{ data.commentFieldName }} obligatoire</mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="cancel()" >{{ data.cancelLabel }}</button>
  <button mat-raised-button color="primary" (click)="confirm()" [disabled]="!commentCtrl.valid">{{ data.confirmLabel }}</button>
</mat-dialog-actions>
