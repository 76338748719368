import {DTOWithLieu} from "../epona-ui/DTOWithLieu";

export class CommandeSearch extends DTOWithLieu {
  numeroCommande: string;
  dateLivraison: Date;
  codesEtat: number[];
  externe: boolean;
  idFournisseur: number;
  idSousLotZg: number;
  codeMarcheOrion: string;

  dateDernierChangementEtat: Date;

  fields: string = null;
}
