<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>Historique des mouvements (hors ventes) et valorisations</span>

    <span class="space"></span>

    <button type="button" mat-icon-button (click)="dialog.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <div class="stock-affiche">
    <table class="mat-mdc-table mat-elevation-z8" aria-label="Rappel du stock de l'article en cours de consultation">
      <thead>
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-header-cell title-container" scope="col">Lieu</th>
        <th class="mat-mdc-header-cell title-container" scope="col">Code</th>
        <th class="mat-mdc-header-cell title-container" scope="col">Désignation</th>
        <th class="mat-mdc-header-cell title-container quantite" scope="col">Stock</th>
        <th class="mat-mdc-header-cell title-container quantite" scope="col">HT</th>
        <th class="mat-mdc-header-cell title-container quantite" scope="col">TTC</th>
      </tr>
      </thead>

      <tbody>
      <tr class="mat-mdc-row">
        <td class="mat-mdc-cell">{{ data.stockCompact.lieu.nom }}</td>
        <td class="mat-mdc-cell">{{ data.stockCompact.article | codeArticle }}</td>
        <td class="mat-mdc-cell">{{ data.stockCompact.article | designation }}</td>
        <td class="mat-mdc-cell quantite">{{ data.stockCompact.quantite | decimal4 }}</td>
        <td class="mat-mdc-cell quantite">{{ data.stockCompact.quantite * data.stockCompact.pmpHt | decimal4 }}</td>
        <td class="mat-mdc-cell quantite">{{ data.stockCompact.quantite * data.stockCompact.pmpTtc | decimal4 }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <mat-tab-group [mat-stretch-tabs]="false">
    <mat-tab label="Mouvements">
      <table mat-table [dataSource]="dataSourceMvt" aria-label="Historique des mouvements de stock d'un article">
        <ng-container matColumnDef="dlc">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">DLC</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dlc | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dateMouvement | dateTimeFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Type</th>
          <td mat-cell *matCellDef="let element">
            <abbr matTooltip="{{ element.typeMouvement.libelle }}">{{ element.typeMouvement.codeTypeMouvement }}</abbr>
          </td>
        </ng-container>

        <ng-container matColumnDef="origine">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Origine</th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="element.entreeSortieLigne" (click)="dialog.close()"
               [routerLink]="[getParametrage(element).commonEditPath, element.entreeSortieLigne.entete.idEntreeSortieEntete]"
            >{{ getParametrage(element).commonLabel }} no. {{ element.entreeSortieLigne.entete.idEntreeSortieEntete }}</a>

            <a *ngIf="element.inventaireLigne" (click)="dialog.close()"
               [routerLink]="['/inventaire', element.inventaireLigne.inventaire.idInventaireEntete]"
            >Inventaire no. {{ element.inventaireLigne.inventaire.idInventaireEntete }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantite">
          <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col">Quantité</th>
          <td class="quantite" mat-cell *matCellDef="let element">
            {{ element.quantite | decimal4 }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMvt"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMvt;"></tr>
      </table>
    </mat-tab>

    <mat-tab label="Valorisations">
      <table mat-table [dataSource]="dataSourceVal" aria-label="Historique des valorisations de stock d'un article">
        <ng-container matColumnDef="dlc">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">DLC</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dlc | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dateValorisation | dateTimeFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="origine">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Origine</th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="element.inventaireLigne" (click)="dialog.close()"
               [routerLink]="['/inventaire', element.inventaireLigne.inventaire.idInventaireEntete]"
            >Inventaire no. {{ element.inventaireLigne.inventaire.idInventaireEntete }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantite">
          <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col">Quantité</th>
          <td class="quantite" mat-cell *matCellDef="let element">
            {{ element.quantite | decimal4 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="montantHt">
          <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col">PMP HT</th>
          <td class="quantite" mat-cell *matCellDef="let element">
            {{ element.pmpHt | decimal4 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="montantTtc">
          <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col">PMP TTC</th>
          <td class="quantite" mat-cell *matCellDef="let element">
            {{ element.pmpTtc | decimal4 }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsVal"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsVal;"></tr>
      </table>
    </mat-tab>
  </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="dialog.close()">Fermer</button>
</mat-dialog-actions>
