<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>Ajout d'articles</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <mat-radio-group [formControl]="typeArticleCtrl" class="radio-group" (change)="changeStateFieldArticle()" [hidden]="!estBrouillon() || estExterne()">
    <mat-radio-button [value]="typeAjoutArticles.PROPOSITION_REAPPRO">Proposition de réapprovisionnement</mat-radio-button>
    <mat-radio-button [value]="typeAjoutArticles.ARTICLE">Ajouter l'article suivant :</mat-radio-button>
  </mat-radio-group>

  <mat-form-field>
    <mat-label>{{ label() }}</mat-label>

    <epona-autocomplete-article-marche [formControl]="articleCtrl"
                                       [fournisseur]="data.entete.fournisseur"
                                       [sousLotZgReference]="data.entete.sousLotZg"
                                       [lieu]="data.entete.lieuDemandeur"
                                       [listeIdArticleExclus]="listeIdArticleDejaPresents"
                                       [optionSelectedHandler]="handleArticleSelection"
                                       required
                                       (click)="enableFieldArticle()"
                                       [focus]="focusFieldArticle"
                                       *ngIf="estExterne()"></epona-autocomplete-article-marche>

    <epona-autocomplete-article [formControl]="articleCtrl"
                                [utilisation]="UA.VENTE"
                                [listeIdArticleExclus]="listeIdArticleDejaPresents"
                                required
                                (click)="enableFieldArticle()"
                                [focus]="focusFieldArticle"
                                *ngIf="estInterne()"></epona-autocomplete-article>

    <mat-error *ngIf="articleCtrl.hasError('required')">Article obligatoire</mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Annuler</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="disableBoutonAjouter()">Ajouter</button>
</mat-dialog-actions>
