<mat-card *ngIf="listeCommandes.length != 0">
  <mat-card-header>
    <mat-card-title>{{label}}</mat-card-title>
  </mat-card-header>

  <table mat-table [dataSource]="dataSource"
         class="mat-elevation-z8"
         aria-label="Liste des commandes avec ce panier">

    <ng-container matColumnDef="text">
      <td mat-cell *matCellDef="let data">
        No. {{data | commande}}
        <span *ngIf="data.dateCommande">du {{data.dateCommande | dateFormat}}</span></td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let data" class="">
        <a
           [routerLink]="['/commande', data.idCommandeEntete]"
           matTooltip="Consulter la commande no. {{data.idCommandeEntete}}">
          <mat-icon class="mat-icon-router-link">open_in_browser</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['text', 'action'];"></tr>
  </table>
</mat-card>
