export class TypeReferentielOrion {
  public static SERVICE_EMETTEUR: TypeReferentielOrion = {
    urlPart: 'services-emetteurs',
    idFieldName: 'idServiceEmetteur',
  }

  public static DESTINATION: TypeReferentielOrion = {
    urlPart: 'destinations',
    idFieldName: 'idDestination',
  }

  public static SERVICE_GESTIONNAIRE: TypeReferentielOrion = {
    urlPart: 'services-gestionnaires',
    idFieldName: 'idServiceGestionnaire',
  }

  public static TYPE_TVA: TypeReferentielOrion = {
    urlPart: 'types-tva',
    idFieldName: 'idTypeTva',
  }

  public static OPERATION: TypeReferentielOrion = {
    urlPart: 'operations',
    idFieldName: 'idOperation',
  }

  public static ELEMENT_AXE_COMPLEMENTAIRE: TypeReferentielOrion = {
    urlPart: 'elements-axe-complementaire',
    idFieldName: 'idElementAxeComplementaire',
  }

  public static EVENEMENT: TypeReferentielOrion = {
    urlPart: 'evenements',
    idFieldName: 'idEvenement',
  }

  public static FAMILLE_MARCHES: TypeReferentielOrion = {
    urlPart: 'familles-marches',
    idFieldName: 'idFamilleBudgetaire',
  }

  public static SOUS_GROUPE: TypeReferentielOrion = {
    urlPart: 'sous-groupes-articles',
    idFieldName: 'idSousGroupeArticles',
  }

  urlPart: string;
  idFieldName: string;
}
