import {Lieu} from "./Lieu";
import {LieuPUT} from "./LieuPUT";

export class LieuPOST extends LieuPUT {

  idLieuBns: number;
  idCrous : number;
  idUg : number;
  idRu : number;
  idUd : number;

  constructor(lieu: Lieu) {
    super(lieu);

    this.idLieuBns = lieu.idLieuBns;
    this.idCrous = lieu.idCrous;
    this.idUg = lieu.idUg;
    this.idRu = lieu.idRu;
    this.idUd = lieu.idUd;
  }
}
