import {Lieu} from './Lieu';
import {Fournisseur} from "./Fournisseur";
import {Extra} from "./Extra";

export class PanierEntete {
  idPanierEntete: number;

  lieuDemandeur: Lieu;
  fournisseur: Fournisseur;
  nbCommandes: number;
  nbLignes: number;
  dateCreation: Date;
  utilisateurCreation: string;
  dateModification: Date;
  utilisateurModification: string;

  commentaire: string;

  extra: Extra;
}
