export enum TopbarMenu {
  ACCUEIL,
  STOCKS,
  COMMANDES,
  LIVRAISONS,
  MOUVEMENTS,
  INVENTAIRES,
  PARAMETRAGES,
  VENTES,
  E_S
}
