<div class="table-actions">
  <button type="button"
          mat-raised-button color="primary"
          (click)="exporter.exportTable({nomFichier: 'liste-lignes-service-fait.xlsx'})"
          [disabled]="dataSource.data.length === 0">
    <fa-icon icon="file-excel"></fa-icon> Exporter
  </button>

  <form-displayed-columns [availableColumns]="COLUMNS"
                                [storeCode]="COLUMNS_STORE_CODE"
                                [(displayedColumns)]="displayedColumns"></form-displayed-columns>
</div>

<table mat-table [dataSource]="dataSource"
       cnousTableExporter #exporter="cnousTableExporter" [columns]="COLUMNS"
       class="mat-elevation-z8"
       matSort matSortActive="numeroLigneEj" matSortDirection="asc" matSortDisableClear="true"
       aria-label="Liste des lignes du service fait">

  <ng-container matColumnDef="numeroLigneEj">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['numeroLigneEj'].tooltip">{{ COLUMNS['numeroLigneEj'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.numeroLigne | number }}</td>
  </ng-container>

  <ng-container matColumnDef="familleMarches">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['familleMarches'].tooltip">{{ COLUMNS['familleMarches'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.cumul.familleMarches.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="compteComptable">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['compteComptable'].tooltip">{{ COLUMNS['compteComptable'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.cumul.compteComptable.numero }}</td>
  </ng-container>

  <ng-container matColumnDef="natureBudgetaire">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['natureBudgetaire'].tooltip">{{ COLUMNS['natureBudgetaire'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.cumul.natureBudgetaire.code }}</td>
  </ng-container>

  <ng-container matColumnDef="tauxTva">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['tauxTva'].tooltip">{{ COLUMNS['tauxTva'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.cumul.tva.taux | decimal1 }}</td>
  </ng-container>

  <ng-container matColumnDef="destination">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['destination'].tooltip">{{ COLUMNS['destination'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.destination?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="operation">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['operation'].tooltip">{{ COLUMNS['operation'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.operation?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="serviceGestionnaire">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['serviceGestionnaire'].tooltip">{{ COLUMNS['serviceGestionnaire'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.serviceGestionnaire?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="elementAxeComplementaire">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['elementAxeComplementaire'].tooltip">{{ COLUMNS['elementAxeComplementaire'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.elementAxeComplementaire?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="evenement">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['evenement'].tooltip">{{ COLUMNS['evenement'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.evenement?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="typeTva">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['typeTva'].tooltip">{{ COLUMNS['typeTva'].label }}</th>
    <td mat-cell *matCellDef="let element">{{ element.ligneEj.typeTva?.libelle }}</td>
  </ng-container>

  <ng-container matColumnDef="montantHt">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['montantHt'].tooltip">{{ COLUMNS['montantHt'].label }}</th>
    <td mat-cell *matCellDef="let element" class="montant">{{ element.montantHt | decimal2 }}</td>
  </ng-container>

  <ng-container matColumnDef="montantTva">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['montantTva'].tooltip">{{ COLUMNS['montantTva'].label }}</th>
    <td mat-cell *matCellDef="let element" class="montant">{{ element.montantTva | decimal2 }}</td>
  </ng-container>

  <ng-container matColumnDef="montantTtc">
    <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['montantTtc'].tooltip">{{ COLUMNS['montantTtc'].label }}</th>
    <td mat-cell *matCellDef="let element" class="montant">{{ element.montantHt + element.montantTva | decimal2 }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  <!-- Ligne de pied de tableau pour le total -->
  <ng-container matColumnDef="totalLibelle">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length-3">
      Totaux
    </td>
  </ng-container>

  <ng-container matColumnDef="totalMontantHt">
    <td mat-footer-cell *matFooterCellDef class="montant">
      {{ totalMontantHt | decimal2 }}
    </td>
  </ng-container>

  <ng-container matColumnDef="totalMontantTva">
    <td mat-footer-cell *matFooterCellDef class="montant">
      {{ totalMontantTva | decimal2 }}
    </td>
  </ng-container>

  <ng-container matColumnDef="totalMontantTtc">
    <td mat-footer-cell *matFooterCellDef class="montant">
      {{ (totalMontantHt + totalMontantTva) | decimal2 }}
    </td>
  </ng-container>

  <tr mat-footer-row *matFooterRowDef="['totalLibelle', 'totalMontantHt', 'totalMontantTva', 'totalMontantTtc']" class="ligne-totaux"></tr>
</table>
