import {Article} from "./Article";
import {Lieu} from "./Lieu";
import {TypeMouvement} from "../TypeMouvement";
import {InventaireLigne} from "./InventaireLigne";
import {EntreeSortieLigne} from "./EntreeSortieLigne";

export class MouvementStock {
  dlc: Date;
  quantite: number;
  dateMouvement: Date;
  commentaire: string;

  dateCreation: Date;
  utilisateurCreation: string;

  lieu: Lieu;
  article: Article;
  typeMouvement: TypeMouvement;
  entreeSortieLigne: EntreeSortieLigne;
  inventaireLigne: InventaireLigne;

  static sort(a: MouvementStock, b: MouvementStock): number {
    return a.dateMouvement > b.dateMouvement ? -1 : 1;
  }
}
