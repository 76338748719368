import {SousFamille} from "../SousFamille";
import {SousLotZg} from "./SousLotZg";
import {ILigneModifiable} from "./ILigneModifiable";
import {UniteMesure} from "./UniteMesure";
import {SousGroupe} from "./SousGroupe";
import {FamilleMarches} from "./FamilleMarches";
import {ArticleMarche} from "./ArticleMarche";

export class Article implements ILigneModifiable {
  idArticle: number;

  idCrous: number;
  articleAchat: boolean;
  articleVente: boolean;
  codeArticleAchat: string;
  codeArticleVente: number;
  actifAchat : boolean;
  actifVente : boolean;

  origineAchat: string;
  designationAchat: string;
  designationVente: string;
  codeEtatValidation: string;

  tauxTva: number;
  pcb: number;
  articleDlc: boolean;
  delaiReceptionDlc : number;
  commandableExterne: boolean;
  stockGere: boolean;
  stockGereOperationnellement: boolean;
  commentaireParametrage: string;

  sousFamille: SousFamille;
  sousLotZg: SousLotZg;
  familleMarches: FamilleMarches;
  sousGroupe: SousGroupe;
  uniteExploitation: UniteMesure;
  listeCodesBarres: Array<CodeBarres>;

  dateCreation: Date;
  utilisateurCreation: string;
  dateModification: Date;
  utilisateurModification: string;

  listeInformationsMarche: ArticleMarche[];

  parametragesNationauxOk: boolean;
  parametrageFamilleMarchesOk: boolean;
  parametrageCompteComptableOk: boolean;

  success: boolean = null;
}

export class CodeBarres {
  code: string;
}


