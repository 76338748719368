import { Pipe, PipeTransform } from '@angular/core';
import {CommandeEntete} from '../../model/epona-api/CommandeEntete';
import {EntreeSortieEntete} from '../../model/epona-api/EntreeSortieEntete';

@Pipe({
  name: 'bordereauLivraison'
})
export class BordereauLivraisonPipe implements PipeTransform {

  transform(bl: EntreeSortieEntete): string {
    return BordereauLivraisonPipe.buildNumero(bl);
  }

  static buildNumero(bl: EntreeSortieEntete): string {
    if (bl) {
      if (bl.codeBordereauLivraison) {
        return bl.codeBordereauLivraison
      } else {
        return '' + bl.idEntreeSortieEntete;
      }
    } else {
      return '';
    }
  }

}
