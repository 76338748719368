<mat-card class="card-width tdb-matcard">
  <mat-card-header class="card-title">
    <mat-card-title>Mouvements non validés</mat-card-title>
  </mat-card-header>

  <table mat-table class="mat-elevation-z8 tdb-table" [dataSource]="dataSource"
         matSort matSortActive="batiment" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Nombre de mouvements non validés par lieu et par type">

    <ng-container matColumnDef="batiment">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bâtiment</th>
      <td mat-cell *matCellDef="let element">{{element.lieu?.nomRu}}</td>
    </ng-container>

    <ng-container matColumnDef="lieu">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Lieu</th>
      <td mat-cell *matCellDef="let element">{{element.lieu.nomUd}}</td>
    </ng-container>

    <ng-container matColumnDef="typeMouvement">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let element">
        <abbr matTooltip="{{element.type.libelle}}">{{element.type.codeTypeMouvement}}</abbr>
      </td>
    </ng-container>

    <ng-container matColumnDef="nombreNonValide">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Nombre de mouvements non validés" mat-sort-header>Nb.</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="[getRouterLink(element)]" [queryParams]="getQueryParams(element)">{{element.nombre}}</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator class="tdb-table" #paginator [pageSize]="3" hidePageSize></mat-paginator>
  <!--  decommente si besoin [pageSizeOptions]="[3, 5, 10]">-->
</mat-card>

