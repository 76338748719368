<mat-card *ngIf="lignes !== undefined">
  <mat-card-header>
    <mat-card-title>Lignes</mat-card-title>
  </mat-card-header>

  <div class="table-actions">
    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogAjoutArticles()"
            *ngIf="droitSaisie">
      <fa-icon icon="plus"></fa-icon> Ajouter…
    </button>

    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogSuppression()"
            *ngIf="droitSaisie">
      <fa-icon icon="trash"></fa-icon> Supprimer…
    </button>

    <button type="button"
            mat-raised-button color="primary"
            [matMenuTriggerFor]="menu"
            [disabled]="dataSource.data.length === 0">
      <fa-icon icon="file-excel"></fa-icon> Exporter
    </button>

    <mat-menu #menu="matMenu">
      <button type="button"
              mat-menu-item
              (click)="exporter.exportTable({nomFichier: 'lignes-panier-' + entete.fournisseur.nom + '-' + entete.idPanierEntete + '.xlsx'})">
        … les colonnes affichées
      </button>

      <button type="button"
              color="primary"
              mat-menu-item
              (click)="exporterFournisseur.exportTable( {nomFichier: 'panier-fournisseur-' + entete.fournisseur.nom + '-' + entete.idPanierEntete + '.csv'})">
        … pour le site du fournisseur
      </button>
    </mat-menu>

    <form-displayed-columns [availableColumns]="COLUMNS"
                            [storeCode]="COLUMNS_STORE_CODE"
                            [(displayedColumns)]="displayedColumns"></form-displayed-columns>

  </div>

  <form [formGroup]="form">
    <table mat-table [dataSource]="dataSource"
           cnousTableExporter #exporter="cnousTableExporter" [columns]="COLUMNS"
           multiTemplateDataRows
           formArrayName="lignes"
           class="mat-elevation-z8"
           aria-label="Liste des lignes d'un panier">

      <ng-container matColumnDef="checkBox">
        <th mat-header-cell *matHeaderCellDef scope="col">
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let data;let i = dataIndex">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(data) : null"
                        [checked]="selection.isSelected(data)">
          </mat-checkbox>

          <div class="update-result">
            <span class="success"><fa-icon icon="check-circle"></fa-icon></span>
            <span class="failure"><fa-icon icon="times-circle"></fa-icon></span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="codeArticle">
        <th class="title-container first" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['codeArticle'].tooltip">{{ COLUMNS['codeArticle'].label }}</th>
        <td class="first" mat-cell *matCellDef="let data;let i = dataIndex" [ngClass]="{'erreur' : data.article == null}">
          {{ data.codeArticle }}

          <aide icon="error_outline" class="aide-contextuelle" *ngIf="data.erreur">
            {{ data.erreur }}
          </aide>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['description'].tooltip">{{ COLUMNS['description'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex"
            [ngClass]="{'erreur' : data.article && (data.article.commandableExterne === false || data.article.actifAchat === false)}">
          {{ data.descriptionCourte }}

          <aide icon="error_outline" class="aide-contextuelle" *ngIf="data.article && data.article.actifAchat === false">
            <p>Le paramétrage de cet article indique qu'il est inactif et ne peut donc être commandé.</p>
          </aide>
          <aide icon="error_outline" class="aide-contextuelle" *ngIf="data.article && data.article.commandableExterne === false">
            <p>Le paramétrage de cet article indique qu'il ne doit pas être commandé.</p>

            <p *ngIf="data.article.commentaireParametrage">
              <strong>Commentaire&nbsp;:</strong>
              {{ data.article.commentaireParametrage }}
            </p>
          </aide>
        </td>
      </ng-container>

      <ng-container matColumnDef="colisage">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['colisage'].tooltip">{{ COLUMNS['colisage'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.quantiteParPrixUnitaire | decimal4 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantite">
        <th class="title-container first quantite" mat-header-cell *matHeaderCellDef scope="col">Quantité</th>
        <td class="first" mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
          <mat-form-field>
            <input matInput type="number"
                   (keydown)="debutUpdate(index, 'quantite')"
                   (blur)="updateLigne(index, 'quantite')"
                   formControlName="quantite"
                   min="0" max="1000000">

            <mat-error *ngIf="hasError(index, 'quantite', 'min')">Qtté. négative interdite</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'zeroInterdit')">Qtté. nulle interdite</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'pcb')">Multiple de {{ data.article.pcb | number }} attendu</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'network')">Erreur réseau</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', '5xx')">Erreur serveur</mat-error>
          </mat-form-field>

          <div class="cdk-visually-hidden">{{ data.quantite }}</div>
      </ng-container>

      <ng-container matColumnDef="conditionnement">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['conditionnement'].tooltip">{{ COLUMNS['conditionnement'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.conditionnement }}
        </td>
      </ng-container>

      <ng-container matColumnDef="prixHT">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['prixHT'].tooltip">{{ COLUMNS['prixHT'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex"
            [ngClass]="{'erreur' : !data.prixUnitaire,
                        'avertissement' : data.estPrixRecent === false}">
          {{ (data.prixUnitaire / data.quantiteParPrixUnitaire) | decimal4 }}

          <aide class="aide-contextuelle" icon="warning" *ngIf="!data.prixUnitaire">
            Le prix de cet article a été supprimé d'Épona suite à la dernière révision tarifaire et doit être récupéré depuis le site du fournisseur.
          </aide>

          <aide icon="report_problem" class="aide-contextuelle" *ngIf="data.estPrixRecent === false">
            Le prix de cet article est possiblement obsolète (dernière mise à jour le {{ data.dateMajPrix | dateFormat }}).
          </aide>
        </td>
      </ng-container>

      <ng-container matColumnDef="tauxTva">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['tauxTva'].tooltip">{{ COLUMNS['tauxTva'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.tauxTaxe !== undefined && data.tauxTaxe !== null ? ((data.tauxTaxe * 100 | decimal1 ) + ' %') : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="prixTTC">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['prixTTC'].tooltip">{{ COLUMNS['prixTTC'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.prixUnitaire + (data.prixUnitaire * data.tauxTaxe) | decimal4 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="descriptionLongue">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['descriptionLongue'].tooltip">{{ COLUMNS['descriptionLongue'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.description }}
        </td>
      </ng-container>

      <ng-container matColumnDef="delaiLivraison">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['delaiLivraison'].tooltip">{{ COLUMNS['delaiLivraison'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.delaisLivraisonEnJours !== undefined && data.delaisLivraisonEnJours !== null && data.delaisLivraisonEnJours == 1 ? (data.delaisLivraisonEnJours  + ' jour') : '' }}
          {{ data.delaisLivraisonEnJours !== undefined && data.delaisLivraisonEnJours !== null && data.delaisLivraisonEnJours > 1 ? (data.delaisLivraisonEnJours  + ' jours') : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lotMarcheOrion">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['lotMarcheOrion'].tooltip">{{ COLUMNS['lotMarcheOrion'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.sousLotZg | lotMarcheOrion }}
        </td>
      </ng-container>

      <ng-container matColumnDef="typeLotMarche">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['typeLotMarche'].tooltip">{{ COLUMNS['typeLotMarche'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.sousLotZg?.typeSousLot }}
        </td>
      </ng-container>

      <ng-container matColumnDef="codeArticleAchat">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['codeArticleAchat'].tooltip">{{ COLUMNS['codeArticleAchat'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.article?.codeArticleAchat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="designationBna">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['designationBna'].tooltip">{{ COLUMNS['designationBna'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.article?.origineAchat === 'BNA' || data.article?.codeEtatValidation === 'A' ? data.article?.designationAchat : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="colisageBna">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['colisageBna'].tooltip">{{ COLUMNS['colisageBna'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ (data.article?.origineAchat === 'BNA' || data.article?.codeEtatValidation === 'A' ? data.colisageBna : null) | decimal4 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="conditionnementBna">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['conditionnementBna'].tooltip">{{ COLUMNS['conditionnementBna'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.article?.origineAchat === 'BNA' || data.article?.codeEtatValidation === 'A' ? data.article?.uniteExploitation.abreviation : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="prixHtBna">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['prixHtBna'].tooltip">{{ COLUMNS['prixHtBna'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ (data.article?.origineAchat === 'BNA' || data.article?.codeEtatValidation === 'A' ? data.prixHtBna : null) | decimal4 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dateMajPrix">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['dateMajPrix'].tooltip">{{ COLUMNS['dateMajPrix'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex" [ngClass]="{'avertissement' : data.estPrixRecent === false}">
          {{ data.dateMajPrix | dateTimeFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="erreur">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['erreur'].tooltip">{{ COLUMNS['erreur'].label }}</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex" [ngClass]="{'erreur' : data.article == null}">
          {{ data.erreur }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Ligne de pied de tableau en cas de liste vide -->
      <ng-container matColumnDef="noRecord">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
      </ng-container>

      <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
    </table>

    <!-- Tableau pour la génération du CSV permettant d'importer un panier sur le site du fournisseur -->
    <table mat-table [dataSource]="dataSource"
           cnousTableExporter #exporterFournisseur="cnousTableExporter" [columns]="COLUMNS"
           class="cdk-visually-hidden"
           aria-label="Panier pour l'export d'un fournisseur">

      <ng-container matColumnDef="codeArticle">
        <td mat-cell *matCellDef="let data">{{ data.codeArticle }}</td>
      </ng-container>

      <ng-container matColumnDef="quantite">
        <td mat-cell *matCellDef="let data">{{ data.quantite }}</td>
      </ng-container>

      <th scope="col"></th>
      <tr mat-row *matRowDef="let row; columns: ['codeArticle', 'quantite'];"></tr>
    </table>
  </form>

  <epona-panier-totaux [entete]="entete"></epona-panier-totaux>
</mat-card>

