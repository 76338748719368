export class TypeNonConformite {
  code!: number;
  libelle!: string;
  description!: string;
  actif!: boolean;
  ordreAffichage!: number;

  static sort(a: TypeNonConformite, b: TypeNonConformite): number {
    return a.ordreAffichage < b.ordreAffichage ? -1 : a.ordreAffichage === b.ordreAffichage ? 0 : 1;
  }
}
