import {Article} from "./Article";
import {SousGroupeDTO} from "./SousGroupeDTO";
import {FamilleMarchesDTO} from "./FamilleMarchesDTO";

export class ArticlePUT {

  actifVente : boolean;
  pcb: number;
  articleDlc: boolean;
  delaiReceptionDlc: number;
  familleMarches: FamilleMarchesDTO;
  sousGroupe: SousGroupeDTO;
  commandableExterne: boolean;
  stockGere: boolean;
  stockGereOperationnellement: boolean;
  commentaireParametrage: string;
  utilisation: string;

  constructor(article: Article, utilisation: string) {
    this.actifVente = article.actifVente;
    this.pcb = article.pcb;
    this.articleDlc = article.articleDlc;
    this.delaiReceptionDlc = article.delaiReceptionDlc;
    this.familleMarches = article.familleMarches ? new FamilleMarchesDTO(article.familleMarches) : null;
    this.sousGroupe = article.sousGroupe ? new SousGroupeDTO(article.sousGroupe) : null;
    this.commandableExterne = article.commandableExterne;
    this.stockGere = article.stockGere;
    this.stockGereOperationnellement = article.stockGereOperationnellement;
    this.commentaireParametrage = article.commentaireParametrage;
    this.utilisation = utilisation;
  }
}
