import {ParamMap} from "@angular/router";
import {Departement} from "../model/Departement";
import {Famille} from "../model/Famille";
import {SousFamille} from "../model/SousFamille";
import {Article} from "../model/epona-api/Article";
import {Marche} from "../model/epona-api/Marche";
import {Fournisseur} from "../model/epona-api/Fournisseur";
import {TypeMouvement} from "../model/TypeMouvement";
import {Groupe} from "../model/epona-api/Groupe";
import {SousGroupe} from "../model/epona-api/SousGroupe";

export class FilterTools {
  static buildDepartementFromURL(params: ParamMap): Departement {
    if (params.get('codeDepartement')) {
      const obj = new Departement();
      obj.codeDepartement = +params.get('codeDepartement');
      return obj;
    } else {
      return null;
    }
  }

  static buildFamilleFromURL(params: ParamMap): Famille {
    if (params.get('codeFamille')) {
      const obj = new Famille();
      obj.codeFamille = +params.get('codeFamille');
      return obj;
    } else {
      return null;
    }
  }

  static buildSousFamilleFromURL(params: ParamMap): SousFamille {
    if (params.get('codeSousFamille')) {
      const obj = new SousFamille();
      obj.codeSousFamille = +params.get('codeSousFamille');
      return obj;
    } else {
      return null;
    }
  }

  static buildArticleFromURL(params: ParamMap): Article {
    if (params.get('idArticle')) {
      const obj = new Article();
      obj.idArticle = +params.get('idArticle');
      return obj;
    } else {
      return null;
    }
  }

  static buildMarcheFromURL(params: ParamMap): Marche {
    if (params.get('codeMarcheOrion')) {
      const obj = new Marche();
      obj.codeMarcheOrion = params.get('codeMarcheOrion');
      return obj;
    } else {
      return null;
    }
  }

  static buildFournisseurFromURL(params: ParamMap): Fournisseur {
    if (params.get('idFournisseur')) {
      const fournisseur = new Fournisseur();
      fournisseur.idFournisseur = +params.get('idFournisseur');
      return fournisseur;
    } else {
      return null;
    }
  }

  static buildTypeMouvementFromURL(params: ParamMap): TypeMouvement {
    if (params.get('codeTypeMouvement')) {
      const typeMouvement = new TypeMouvement();
      typeMouvement.codeTypeMouvement = params.get('codeTypeMouvement');
      return typeMouvement;
    } else {
      return null;
    }
  }

  static buildGroupeArticlesFromURL(params: ParamMap): Groupe {
    if (params.get('codeGroupe')) {
      const obj = new Groupe();
      obj.code = params.get('codeGroupe');
      return obj;
    } else {
      return null;
    }
  }

  static buildSousGroupeArticlesFromURL(params: ParamMap): SousGroupe {
    if (params.get('idSousGroupe')) {
      const obj = new SousGroupe();
      obj.idSousGroupeArticles = +params.get('idSousGroupe');
      return obj;
    } else {
      return null;
    }
  }
}
