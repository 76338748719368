import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogueDataParametrageLieuFournisseur} from "../../model/epona-ui/dialogue-data-parametrage-lieu-fournisseur";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Lieu} from "../../model/epona-api/Lieu";
import {ParametrageLieuFournisseurPost} from "../../model/epona-api/parametrage-lieu-fournisseur-post";
import {ParametrageLieuFournisseurService} from "../../services/epona/parametrage-lieu-fournisseur.service";
import {MessageTool} from "../../commons/MessageTool";
import {FournisseurDTO} from "../../model/epona-api/FournisseurDTO";
import {ParametrageLieuFournisseurPut} from "../../model/epona-api/parametrage-lieu-fournisseur-put";
import {LieuDTO} from "../../model/epona-api/LieuDTO";

@Component({
  selector: 'app-dialog-parametrage-lieu-fournisseur',
  templateUrl: './dialog-parametrage-lieu-fournisseur.component.html',
  styleUrls: ['./dialog-parametrage-lieu-fournisseur.component.css']
})
export class DialogParametrageLieuFournisseurComponent implements OnInit {

  form: UntypedFormGroup;
  lieuCourant: Lieu;
  desactiveLieu=true;


  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogueDataParametrageLieuFournisseur,
               private formBuilder: UntypedFormBuilder,
               private parametrageLieuFournisseurService:ParametrageLieuFournisseurService,
               private messageTool: MessageTool,
               public dialog: MatDialogRef<DialogParametrageLieuFournisseurComponent>) { }

  ngOnInit(): void {

    this.lieuCourant = this.data.lieu;
    if (!this.data.parametrageLieuFournisseur.idParametrageLieuFournisseur){
      this.desactiveLieu = false;
    }

    this.form = this.formBuilder.group({
      codeClient: [this.data.parametrageLieuFournisseur.codeClient],
      mail: [this.data.parametrageLieuFournisseur.mail, [Validators.email]]
    });
  }

  save() {
    if (this.data.parametrageLieuFournisseur.idParametrageLieuFournisseur) {
      this.update();
    } else {
      this.insert();
    }

  }

  close() {
    this.dialog.close();
  }

  loadLieu(lieu) {
    this.lieuCourant = lieu;
  }

  private insert() {

    const p = new ParametrageLieuFournisseurPost()
    p.lieu = new LieuDTO(this.lieuCourant);
    p.fournisseur = new FournisseurDTO(this.data.fournisseur);
    p.mail = this.form.get('mail').value;
    p.codeClient = this.form.get('codeClient').value;

    this.parametrageLieuFournisseurService.postParametrageLieuFournisseur(p).subscribe( res=> {
      this.data.parametrageLieuFournisseur = res;
      this.messageTool.sendSuccess(`Le paramétrage a été ajouté avec succès`);
      this.dialog.close();
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private update() {

    const p = new ParametrageLieuFournisseurPut()
    p.mail = this.form.get('mail').value;
    p.codeClient = this.form.get('codeClient').value;

    this.parametrageLieuFournisseurService.putParametrageLieuFournisseur(this.data.parametrageLieuFournisseur.idParametrageLieuFournisseur, p).subscribe( res=> {
      this.data.parametrageLieuFournisseur = res;
      this.messageTool.sendSuccess(`Le paramétrage a été ajouté avec succès`);
      this.dialog.close();
    }, err => {
      this.messageTool.sendError(err);
    });
  }
}
