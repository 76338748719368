<epona-select-lieu [lieu]="lieu" (lieuSubmitted)="onLieuSubmitted($event)"></epona-select-lieu>

<h1>Tableau de bord</h1>

<div class="tdb-root">
  <epona-card-commandes [lieuCourant]="lieu" *ngIf="visuCommandes()"></epona-card-commandes>
  <epona-card-sorties-non-validee [lieuCourant]="lieu" *ngIf="visuSorties()"></epona-card-sorties-non-validee>
  <epona-card-stock-alert [lieuCourant]="lieu" *ngIf="visuStocks()"></epona-card-stock-alert>
  <epona-card-bordereaux-livraisons [lieuCourant]="lieu" *ngIf="visuLivraisons()"></epona-card-bordereaux-livraisons>
  <epona-card-entrees-sorties-validee [lieuCourant]="lieu" *ngIf="visuSorties()"></epona-card-entrees-sorties-validee>
  <epona-card-inventaires [lieuCourant]="lieu" *ngIf="visuInventaires()"></epona-card-inventaires>
</div>


