import {CommandeCourriel} from "./CommandeCourriel";

export class CommandeCourrielDTO {
  mailDemandeur: string;
  mailsDemandeursSupp: string;
  mailFournisseur: string;
  sujet: string;
  message: string;

  constructor(commandeCourriel: CommandeCourriel) {
    this.mailDemandeur       = commandeCourriel.mailDemandeur;
    this.mailsDemandeursSupp = commandeCourriel.mailsDemandeursSupp;
    this.mailFournisseur     = commandeCourriel.mailFournisseur;
    this.sujet               = commandeCourriel.sujet;
    this.message             = commandeCourriel.message;
  }
}
