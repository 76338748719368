import {ServiceEmetteur} from "./ServiceEmetteur";
import {Destination} from "./Destination";
import {ServiceGestionnaire} from "./ServiceGestionnaire";
import {TypeTva} from "./TypeTva";
import {ILigneModifiable} from "./ILigneModifiable";
import {ParamMap} from "@angular/router";
import {DTOWithLieu} from "../epona-ui/DTOWithLieu";

export class Lieu extends DTOWithLieu implements ILigneModifiable {
  nomRu: string;
  nomUd: string;
  nom: string;
  adresse1: string;
  adresse2: string;
  codePostal: string;
  ville: string;

  masque: boolean;
  priseEnCompteVentes: boolean;

  lieuLivraison: boolean;
  nomLieuLivraison: string;
  serviceEmetteur: ServiceEmetteur;
  destination: Destination;
  serviceGestionnaire: ServiceGestionnaire;
  typeTva: TypeTva;

  success: boolean = null;

  initWithLocalStorage() {
    this.idCrous       = Lieu.toNumberOrNull(localStorage.getItem('idCrous'));
    this.idBatimentBns = Lieu.toNumberOrNull(localStorage.getItem('idBatimentBns'));
    this.idLieuBns     = Lieu.toNumberOrNull(localStorage.getItem('idLieuBns'));
    this.idLieu        = Lieu.toNumberOrNull(localStorage.getItem('idLieu'));
  }

  initWithURL(params: ParamMap) {
    this.idCrous       = Lieu.toNumberOrNull(params.get('idCrous'));
    this.idBatimentBns = Lieu.toNumberOrNull(params.get('idBatimentBns'));
    this.idLieuBns     = Lieu.toNumberOrNull(params.get('idLieuBns'));
    this.idLieu        = Lieu.toNumberOrNull(params.get('idLieu'));
  }

  private static toNumberOrNull(param: string): number|null {
    if (param === null || isNaN(+param)) {
      return null;
    }
    return +param;
  }
}
