import {Component, OnInit, ViewChild} from '@angular/core';
import {InventaireLigne} from "../../model/epona-api/InventaireLigne";
import {InventaireEntete} from "../../model/epona-api/InventaireEntete";
import {InventaireService} from "../../services/epona/inventaire.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {Article} from "../../model/epona-api/Article";
import {Lieu} from "../../model/epona-api/Lieu";
import {InventaireEnteteComponent} from "../inventaire-entete/inventaire-entete.component";
import {FormGroupDirective} from "@angular/forms";
import {CanDeactivateComponent} from "../../intercepteurs/can-deactivate/can-deactivate.component";
import {InventaireSearch} from "../../model/epona-api/InventaireSearch";
import {Tools} from "../../commons/Tools";

@Component({
  selector: 'app-inventaire',
  templateUrl: './inventaire.component.html',
  styleUrls: ['./inventaire.component.css']
})
export class InventaireComponent extends CanDeactivateComponent implements OnInit {
  idInventaire: number;
  lieuCourant: Lieu;
  inventaireEntete: InventaireEntete;
  inventaireLignes: Array<InventaireLigne>;
  validationLignesErreur: boolean;

  public static readonly FIELDS_ENTETE = 'idInventaireEntete,' +
    'libelle,' +
    'commentaire,' +
    'dateCreation,' +
    'utilisateur,' +
    'dateModification,' +
    'utilisateurModification,' +
    'dateCloture,' +
    'utilisateurCloture,' +
    'lieu.idLieu,' +
    'extra.editable,' +
    'extra.deletable,' +
    'extra.lockable';

  public static readonly FIELDS_LIGNES = 'idInventaireLigne,' +
    'dlc,' +
    'quantiteStockTheorique,' +
    'pmpHtTheorique,' +
    'pmpTtcTheorique,' +
    'quantiteStockConstatee,' +
    'pmpHtConstate,' +
    'pmpTtcConstate,' +
    'commentaire,' +
    'article.idArticle,' +
    'article.articleAchat,' +
    'article.articleVente,' +
    'article.codeArticleAchat,' +
    'article.codeArticleVente,' +
    'article.designationAchat,' +
    'article.designationVente,' +
    'article.uniteExploitation.abreviation,' +
    'article.articleDlc,' +
    'article.sousGroupe.libelle,' +
    'article.sousGroupe.groupe.libelle';

  private readonly ONLY_META_DATA = true;
  static clotureEnCours = false;

  formGroupDirective: FormGroupDirective;

  // Permet d'accèder aux données du composant enfant afin de contrôler s'il y a eu des modifications si changement de page
  // ViewChild est une réquête dynamique : il sera toujours à jour du template !

  @ViewChild(InventaireEnteteComponent)
  set element(element: InventaireEnteteComponent) {
    this.formGroupDirective = element.form;
  }

  constructor(private inventaireService: InventaireService,
              private route: ActivatedRoute,
              private messageTool: MessageTool,
              private router: Router) {
    super();
  }

  ngOnInit() {
    // Récupération du paramètre idInventaire dans l'url.
    // Pas besoin d'utiliser l'observable paramMap car on n'a pas besoin que le ngOnInit soit rappelé
    this.idInventaire = parseInt(this.route.snapshot.paramMap.get('id'));

    if(this.idInventaire) {
      this.loadInventaire();
    } else {
      this.lieuCourant = new Lieu();
      this.lieuCourant.initWithLocalStorage();
    }
  }

  loadInventaire(onlyMetaData: boolean = false) {
    this.getInventaireEntete(onlyMetaData);
    this.getInventaireLignes();
  }

  getInventaireEntete(onlyMetaData: boolean = false) {
    this.inventaireService.getInventaireEntete(this.idInventaire, InventaireComponent.FIELDS_ENTETE).subscribe(data => {
      if(onlyMetaData) {
        this.inventaireEntete.dateModification = data.dateModification;
        this.inventaireEntete.utilisateurModification = data.utilisateurModification;
        this.inventaireEntete.dateCloture = data.dateCloture;
        this.inventaireEntete.utilisateurCloture = data.utilisateurCloture;
        this.inventaireEntete.extra = data.extra;

      } else {
        this.inventaireEntete = data;
        this.lieuCourant = data.lieu;
      }

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  getInventaireLignes() {
    this.inventaireService.getListeLignesInventaire(this.idInventaire, InventaireComponent.FIELDS_LIGNES).subscribe(data => {
      this.inventaireLignes = data
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  // Récupération du composant enfant (entête) si on doit faire la clôture de l'inventaire
  doCloture(doCloture: boolean) {
    if (doCloture) {
      InventaireComponent.clotureEnCours = true;

      // Contrôle des lignes de l'inventaire
      this.inventaireService.getListeLignesInventaire(this.idInventaire, InventaireComponent.FIELDS_LIGNES).subscribe(data => {
        this.inventaireLignes = data;

        if (this.checkLignes()) {
          this.postCloture();
        }

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private checkLignes(): boolean {
    for (let inventaireLigne of this.inventaireLignes) {
      if ((inventaireLigne.dlc &&  Tools.isEmpty(inventaireLigne.quantiteStockConstatee))
        || (!inventaireLigne.dlc && Tools.isEmpty(inventaireLigne.quantiteStockConstatee) && inventaireLigne.quantiteStockTheorique != 0)) {

        // Au moins une ligne en erreur on sort de la boucle
        this.validationLignesErreur = true;
        this.messageTool.sendErrorMessage("Des lignes avec une DLC ou une quantité théorique ont des quantités constatées non renseignées");
        return false;
      }
    }

    this.validationLignesErreur = false;
    return true;
  }

  private postCloture() {
    // Si pas d'erreur on clôture l'inventaire
    this.inventaireService.postClotureInventaire(this.idInventaire).subscribe(() => {
      this.getInventaireEntete();

      this.messageTool.sendSuccess("L'inventaire a été clôturé avec succès");
      this.resetFormStates();

      // Actualisation des lignes car certaines lignes peuvent avoir été supprimées lors de la clôture
      this.inventaireService.getListeLignesInventaire(this.idInventaire, InventaireComponent.FIELDS_LIGNES).subscribe(data => {
        this.inventaireLignes = data;

        }, err => {
        this.messageTool.sendError(err);
      });
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  // Récupération du composant enfant (entete) de l'entête à créer ou à modifier
  doActionEntete(inventaireEntete: InventaireEntete) {
    if(this.idInventaire) {
      this.doModificationEntete(inventaireEntete);
    } else {
      this.doCreationEntete(inventaireEntete);
    }
  }

  private doCreationEntete(inventaireEntete: InventaireEntete) {
    this.inventaireService.postInventaireEntete(inventaireEntete).subscribe(data => {
      this.messageTool.sendSuccess("L'inventaire a été enregistré avec succès", ClearMessages.TRUE);
      this.resetFormStates();

      //redirection vers la page de modification de l'inventaire
      this.router.navigate(['inventaire', data.idInventaireEntete]);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private doModificationEntete(inventaireEntete: InventaireEntete) {
    inventaireEntete.idInventaireEntete = this.inventaireEntete.idInventaireEntete;

    this.inventaireService.putInventaireEntete(inventaireEntete).subscribe(() => {
      this.getInventaireEntete();

      this.messageTool.sendSuccess("L'inventaire a été modifié avec succès");
      this.resetFormStates();

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  // Récupération du composant enfant (entête) si on doit faire la suppression de l'inventaire
  doSuppression(doSuppression: boolean) {
    if (doSuppression) {
      this.inventaireService.deleteInventaire(this.idInventaire).subscribe(() => {
        this.messageTool.sendSuccess("L'inventaire a été supprimé avec succès", ClearMessages.TRUE);
        this.resetFormStates();

        // Redirection vers la liste des inventaires
        this.router.navigate(['/liste-inventaires']);

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  // Contrôle qu'un inventaire en cours (non clôturé) n'existe pas.
  doCheckInventaireEnCours(lieu: Lieu) {
    this.lieuCourant = lieu;

    if(this.lieuCourant.idLieu) {
      const inventaireSearch = new InventaireSearch();
      inventaireSearch.idLieu = this.lieuCourant.idLieu;
      inventaireSearch.cloture = false;
      inventaireSearch.fields = 'idInventaireEntete';

      this.inventaireService.getListeInventaires(inventaireSearch).subscribe(data => {
        if(data.length > 0) {
          this.messageTool.sendInfo('Un inventaire est déjà en cours sur cette UD');
        }
      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  // Récupération du composant enfant (lignes) qu'un article a été ajouté
  doHandleArticleInserted(article: Article) {
    this.loadInventaire(this.ONLY_META_DATA);
  }

  // Récupération du composant enfant (lignes) qu'une ligne a été ajouté
  doHandleLigneInserted(ligne: InventaireLigne) {
    this.loadInventaire(this.ONLY_META_DATA);
  }

  // Récupération du composant enfant (lignes) qu'une ligne a été modifié
  doHandleLigneUpdated(ligne: InventaireLigne) {
    this.getInventaireEntete(this.ONLY_META_DATA);
  }
}
