import {LieuFilter} from "./lieu-filter";

export class LieuQueryParams {

  idCrous: number;

  affiche: boolean;
  priseEnCompteVentes: boolean;
  lieuLivraison: boolean;
  parametrePourOrion: boolean;

  static fabric(filter: LieuFilter) : LieuQueryParams {
    const params = new LieuQueryParams();

    params.idCrous = +localStorage.getItem('idCrous');

    params.affiche             = (filter.masque === true || filter.masque === false) ? !filter.masque : null;
    params.priseEnCompteVentes = filter.priseEnCompteVentes;
    params.lieuLivraison       = filter.lieuLivraison;
    params.parametrePourOrion  = filter.parametrePourOrion;

    return params;
  }
}
