import {ServiceEmetteurDTO} from "./ServiceEmetteurDTO";
import {EngagementJuridiqueEntete} from "./EngagementJuridiqueEntete";

export class EngagementJuridiqueEntetePUT {
  serviceEmetteur: ServiceEmetteurDTO;
  objet: string;
  observation: string;

  constructor(engagementJuridiqueEntete: EngagementJuridiqueEntete) {
    this.serviceEmetteur = engagementJuridiqueEntete.serviceEmetteur ? new ServiceEmetteurDTO(engagementJuridiqueEntete.serviceEmetteur) : null;
    this.objet           = engagementJuridiqueEntete.objet;
    this.observation     = engagementJuridiqueEntete.observation;
  }
}
