import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Lieu} from '../../model/epona-api/Lieu';
import {Const} from '../../commons/constants/Const';
import {InventaireService} from "../../services/epona/inventaire.service";
import {MessageTool} from "../../commons/MessageTool";
import {InventaireFilter} from "../../model/epona-ui/InventaireFilter";
import {Tools} from "../../commons/Tools";
import {OnlineService} from "../../services/online.service";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
  selector: 'epona-inventaires-filter',
  templateUrl: './inventaires-filter.component.html',
  styleUrls: ['./inventaires-filter.component.css'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class InventairesFilterComponent implements OnInit, OnChanges {
  readonly DEFAULT = Const.DEFAULT;

  @Input() lieuCourant: Lieu;
  @Input() filter: InventaireFilter;
  @Output() readonly filterSubmitted = new EventEmitter<InventaireFilter>();

  form: UntypedFormGroup;

  utilisateurCtrl: UntypedFormControl;
  clotureCtrl: UntypedFormControl;
  libelleCtrl: UntypedFormControl;
  anneeCtrl: UntypedFormControl;
  moisCtrl: UntypedFormControl;
  idInventaireCtrl: UntypedFormControl;

  listeUtilisateurs: Array<string>;
  listeAnnees: Array<number>;
  listeMois= [
    {value: 1, label: "Janvier"},
    {value: 2, label: "Février"},
    {value: 3, label: "Mars"},
    {value: 4, label: "Avril"},
    {value: 5, label: "Mai"},
    {value: 6, label: "Juin"},
    {value: 7, label: "Juillet"},
    {value: 8, label: "Août"},
    {value: 9, label: "Septembre"},
    {value: 10, label: "Octobre"},
    {value: 11, label: "Novembre"},
    {value: 12, label: "Décembre"}
  ];

  @ViewChild('ngForm') ngForm: NgForm;


  constructor(private fb: UntypedFormBuilder,
              private inventaireService: InventaireService,
              private messageTool: MessageTool,
              public onlineService: OnlineService) {

    this.utilisateurCtrl = fb.control(this.DEFAULT);
    this.clotureCtrl = fb.control(null);
    this.libelleCtrl = fb.control(null);
    this.anneeCtrl = fb.control(this.DEFAULT);
    this.moisCtrl = fb.control(this.DEFAULT);
    this.idInventaireCtrl = fb.control(null);

    this.form = this.fb.group({
      utilisateur: this.utilisateurCtrl,
      cloture: this.clotureCtrl,
      libelle: this.libelleCtrl,
      annee: this.anneeCtrl,
      mois: this.moisCtrl,
      idInventaire: this.idInventaireCtrl
    });

    onlineService.isOnline.subscribe(r => this.changeFormState(r));
  }

  ngOnInit() {
    this.initListeAnnee();
  }

  changeFormState(online: boolean){
    this.initFieldsFilter();
    if (!online) {
      this.form && this.form.disable();
    } else {
      this.form && this.form.enable();
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }

    if (changes['lieuCourant']) {
      this.initListeUtilisateurs();
    }
  }

  private initListeUtilisateurs() {
    // TODO : passage du lieu en paramètre plutôt que l'idLieu
    this.inventaireService.getListeUtilisateursInventaires(this.lieuCourant.idLieu).subscribe(data => {
      this.listeUtilisateurs = data;
      this.listeUtilisateurs.sort();

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private initFieldsFilter() {
    this.anneeCtrl.setValue(this.filter && this.filter.annee ? this.filter.annee : this.DEFAULT);
    this.moisCtrl.setValue(this.filter && this.filter.mois ? this.filter.mois : this.DEFAULT);
    this.utilisateurCtrl.setValue(this.filter && this.filter.utilisateur ? this.filter.utilisateur : this.DEFAULT);
    this.libelleCtrl.setValue(this.filter && this.filter.libelle);
    this.clotureCtrl.setValue(this.filter && this.filter.cloture);
    this.idInventaireCtrl.setValue(this.filter && this.filter.idInventaire);

    this.onAnneeSelected();
  }

  private initListeAnnee() {
    this.listeAnnees = new Array<number>();

    for(let i = new Date().getFullYear(); i >= 2020 ; i--) {
      this.listeAnnees.push(i);
    }
  }

  onAnneeSelected() {
    if (this.anneeCtrl.value === this.DEFAULT) {
      this.moisCtrl.disable();
      this.moisCtrl.setValue(this.DEFAULT);
    } else {
      this.moisCtrl.enable();
    }
  }

  onSubmit() {
    const newFilter = new InventaireFilter();

    newFilter.idInventaire = this.idInventaireCtrl.value;
    newFilter.libelle      = this.libelleCtrl.value;
    newFilter.utilisateur  = this.utilisateurCtrl.value === this.DEFAULT ? null : this.utilisateurCtrl.value;
    newFilter.cloture      = this.clotureCtrl.value;
    newFilter.annee        = this.anneeCtrl.value === this.DEFAULT ? null : this.anneeCtrl.value;
    newFilter.mois         = this.moisCtrl.value === this.DEFAULT ? null : this.moisCtrl.value;

    this.filterSubmitted.emit(newFilter);
  }
}
