import {Article} from "../epona-api/Article";
import {Groupe} from "../epona-api/Groupe";
import {SousGroupe} from "../epona-api/SousGroupe";

export class StockCompactFilter {
  groupe: Groupe;
  sousGroupe: SousGroupe;
  article: Article;
  masquerQuantitesNulles: boolean;
  stockFaible: boolean;
  nombreJoursAvantDlc: number;
}
