export class DateHelper {

  // Utile pour l'ordonnancement des tableaux
  public static timestampToJsTime(date: number|string|Date|null): number {
    return date ? new Date(date).getTime() : 0
  }

  // Utile pour valoriser le formCtrl d'un DatePicker
  public static timestampToJsDate(date: any): Date|null {
    return date ? new Date(date) : null;
  }

  // Utile pour valoriser un objet à partir d'un formCtrl d'un DatePicker
  public static jsDateToJsonDate(date: string|Date|null|undefined): string|null {
    if (!date) return null;
    if (date instanceof Date) {
      date.setHours(12); // par défaut l'heure d'une Date est 00:00:00, ce qui peut faire passer la date à la veille avec les fuseaux horaires
      return date.toJSON().substring(0, 10); // Seule la date est conservée
    }
    return date.substring(0, 10);
  }

  public static tomorrow(): Date {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }
}
