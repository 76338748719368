<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span *ngIf="creationMode">Ajouter un document</span>
    <span *ngIf="!creationMode">Modifier un document</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <form class="form" [formGroup]="formDocument">
    <mat-form-field>
      <mat-label>Libellé</mat-label>
      <input matInput formControlName="libelle" maxlength="50" required/>
      <mat-error *ngIf="formDocument.get('libelle').hasError('required')">Le libellé est obligatoire</mat-error>
    </mat-form-field>

    <ng-container *ngIf="creationMode; else choisirBtn">
      <div style="display: flex;">
        <div>
          <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">Sélectionner un fichier…</button>
          <input type="file"
                 hidden
                 (change)="selectFile($event)"
                 #fileInput
                 formControlName="document"
                 accept=".png, .jpeg, .jpg, .pdf">
        </div>
        <div class="fichier-selectionne">{{ currentFile ? currentFile.name : "Aucun fichier sélectionné" }}</div>
      </div>
    </ng-container>
    <ng-template #choisirBtn>
      <div *ngIf="selectedFiles === undefined">
        <mat-form-field>
          <mat-label>Fichier</mat-label>
          <input matInput [value]="selectedFilename" maxlength="255" disabled/>
        </mat-form-field>
      </div>
      <div style="display: flex;">
        <div>
          <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">Modifier le fichier…</button>
          <input type="file"
                 hidden
                 (change)="selectFile($event)"
                 #fileInput
                 formControlName="document"
                 accept=".png, .jpeg, .jpg, .pdf">
        </div>
        <div class="fichier-selectionne">{{ currentFile ? currentFile.name : "Aucun fichier sélectionné" }}</div>
      </div>
    </ng-template>
    <div>
      <mat-error *ngIf="!isFileSelected">Le fichier est obligatoire</mat-error>
    </div>

    <div *ngIf="isLoading">
      <mat-progress-spinner diameter="25" mode="indeterminate" value="50"></mat-progress-spinner>
    </div>

    <div *ngIf="isPdfFile">
      <pdf-viewer [src]="preview"
                  [render-text]="true"
                  [original-size]="false"></pdf-viewer>
    </div>
    <div *ngIf="preview && !isPdfFile">
      <img [src]="preview" class="preview" alt="Prévisualisation de l'image sélectionnée" />
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button        color="accent"  (click)="close()">Annuler</button>
  <button mat-raised-button color="primary" (click)="upload()" *ngIf="creationMode">Télécharger</button>
  <button mat-raised-button color="primary" (click)="update()" *ngIf="!creationMode">Enregistrer</button>
</mat-dialog-actions>
