<mat-card [ngClass]="{'editable': isEditable()}">
  <mat-card-header>
    <mat-card-title>Lignes</mat-card-title>
  </mat-card-header>

  <div class="table-actions">
    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogAjoutArticle()"
            [disabled]="!isEditable()">
      <fa-icon icon="plus"></fa-icon> Ajouter…
    </button>

    <button type="button"
            mat-raised-button color="accent"
            (click)="openDialogHandleConnexion(true)"
            *ngIf="(onlineService.isOnline | async)"
            [disabled]="!isEditable()">
      <fa-icon icon="plug"></fa-icon> Déconnecter…
    </button>

    <button type="button"
            mat-raised-button color="accent"
            (click)="openDialogHandleConnexion(false)"
            *ngIf="!(onlineService.isOnline | async)"
            [disabled]="!isEditable()">
      <fa-icon icon="plug"></fa-icon> Reconnecter…
    </button>

    <button type="button"
            mat-raised-button color="primary"
            (click)="exporter.exportTable({nomFichier: 'lignes-inventaire-' + inventaireEntete.idInventaireEntete + '.xlsx'})"
            [disabled]="dataSource.data.length === 0 || !(onlineService.isOnline | async)">
      <fa-icon icon="file-excel"></fa-icon> Exporter
    </button>

    <form-displayed-columns [availableColumns]="COLUMNS"
                            [storeCode]="COLUMNS_STORE_CODE"
                            [(displayedColumns)]="displayedColumns"></form-displayed-columns>
  </div>
  <form [formGroup]="formGroup" autocomplete="off">
  <table mat-table
         [dataSource]="dataSource"
         multiTemplateDataRows="true"
         formArrayName="formArray"
         cnousTableExporter #exporter="cnousTableExporter" [columns]="COLUMNS"
         class="mat-elevation-z8"
         aria-label="Liste des lignes d'un inventaire">

    <ng-container matColumnDef="groupe">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['groupe'].label }}</th>
      <td mat-cell *matCellDef="let data;let i = dataIndex" [attr.rowspan]="getRowSpan('Article', i)"
          [style.display]="getRowSpan('Article', i) ? '' : 'none'">
        {{ data.article.sousGroupe?.groupe.libelle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sousGroupe">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['sousGroupe'].label }}</th>
      <td mat-cell *matCellDef="let data;let i = dataIndex" [attr.rowspan]="getRowSpan('Article', i)"
          [style.display]="getRowSpan('Article', i) ? '' : 'none'">
        {{ data.article.sousGroupe?.libelle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="codeArticleAchat">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['codeArticleAchat'].label }}</th>
      <td mat-cell *matCellDef="let data;let i = dataIndex" [attr.rowspan]="getRowSpan('Article', i)"
          [style.display]="getRowSpan('Article', i) ? '' : 'none'">
        {{ data.article | codeArticle:UA.ACHAT:true }}
      </td>
    </ng-container>

    <ng-container matColumnDef="codeArticleVente">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['codeArticleVente'].label }}</th>
      <td mat-cell *matCellDef="let data;let i = dataIndex" [attr.rowspan]="getRowSpan('Article', i)"
          [style.display]="getRowSpan('Article', i) ? '' : 'none'">
        {{ data.article | codeArticle:UA.VENTE:true }}
      </td>
    </ng-container>

    <ng-container matColumnDef="designation">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['designation'].label }}</th>
      <td mat-cell *matCellDef="let data;let i = dataIndex" [attr.rowspan]="getRowSpan('Article', i)"
          [style.display]="getRowSpan('Article', i) ? '' : 'none'">
        {{ data.article | designation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ajoutLigne">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['ajoutLigne'].tooltip">{{ COLUMNS['ajoutLigne'].label }}</th>
      <td mat-cell *matCellDef="let data;let i = dataIndex" [attr.rowspan]="getRowSpan('Article',i)"
          [style.display]="getRowSpan('Article', i) ? '' : 'none'">
        <button *ngIf="data.article.articleDlc" type="button"
                mat-button
                matTooltip="Ajouter une ligne pour l'article {{ data.article | designation }}"
                (click)="openDialogAjoutLigne(data.article)"
                [disabled]="!isEditable()"><fa-icon icon="plus"></fa-icon></button>
      </td>
    </ng-container>

    <ng-container matColumnDef="dlc">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['dlc'].label }}</th>
      <td mat-cell *matCellDef="let data">{{data.dlc | dateFormat}}</td>
    </ng-container>

    <ng-container matColumnDef="quantiteStockTheorique">
      <th class="title-container quantite-theorique" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['quantiteStockTheorique'].tooltip">

        <button type="button"
                mat-button
                (click)="openDialogConfirmationCopie()"
                *ngIf="isEditable()">{{ COLUMNS['quantiteStockTheorique'].label }}</button>

        <span *ngIf="!isEditable()">{{ COLUMNS['quantiteStockTheorique'].label }}</span>
      </th>

      <td mat-cell *matCellDef="let data;let i = dataIndex" class="quantite-theorique">
        <button type="button"
                mat-button
                (click)="copieTheoriqueDansConstatee(data, i)"
                *ngIf="isEditable()">{{ data.quantiteStockTheorique | decimal4 }}</button>

        <span *ngIf="!isEditable()">{{ data.quantiteStockTheorique | decimal4 }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="pmpHtTheorique">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['pmpHtTheorique'].tooltip">{{ COLUMNS['pmpHtTheorique'].label }}</th>
      <td mat-cell *matCellDef="let data"> {{ data.pmpHtTheorique | decimal4 }} </td>
    </ng-container>

    <ng-container matColumnDef="pmpTtcTheorique">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['pmpTtcTheorique'].tooltip">{{ COLUMNS['pmpTtcTheorique'].label }}</th>
      <td mat-cell *matCellDef="let data"> {{ data.pmpTtcTheorique | decimal4 }} </td>
    </ng-container>

    <ng-container matColumnDef="valeurTotaleHtTheorique">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['valeurTotaleHtTheorique'].tooltip">{{ COLUMNS['valeurTotaleHtTheorique'].label }}</th>
      <td mat-cell *matCellDef="let data"> {{ data.pmpHtTheorique * data.quantiteStockTheorique | decimal2 }} </td>
    </ng-container>

    <ng-container matColumnDef="valeurTotaleTtcTheorique">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['valeurTotaleTtcTheorique'].tooltip">{{ COLUMNS['valeurTotaleTtcTheorique'].label }}</th>
      <td mat-cell *matCellDef="let data"> {{ data.pmpTtcTheorique * data.quantiteStockTheorique | decimal2 }} </td>
    </ng-container>


    <ng-container matColumnDef="quantiteStockConstatee">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['quantiteStockConstatee'].label }}</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <mat-form-field appearance="fill" subscriptSizing="dynamic">
          <input matInput type="number"
                 #quantiteStockConstateeInput
                 (blur)="updateLigne(index, 'quantiteStockConstatee')"
                 (keyup.enter)="updateLigne(index, 'quantiteStockConstatee', true)"
                 formControlName="quantiteStockConstatee"
                 min="0">

          <mat-error *ngIf="hasError(index, 'quantiteStockConstatee', 'min')">Qtté. négative interdite</mat-error>
          <mat-error *ngIf="hasError(index, 'quantiteStockConstatee', 'nbMaxDecimals')">Maximum {{ NB_MAX_DECIMALES_QUANTITE }} décimales</mat-error>
          <mat-error *ngIf="hasError(index, 'quantiteStockConstatee', 'required')">Quantité obligatoire</mat-error>
          <mat-error *ngIf="hasError(index, 'quantiteStockConstatee', 'network')">Erreur réseau</mat-error>
          <mat-error *ngIf="hasError(index, 'quantiteStockConstatee', '5xx')">Erreur serveur</mat-error>
        </mat-form-field>

        <!-- Version texte uniquement pour l'export -->
        <div class="cdk-visually-hidden">{{ data.quantiteStockConstatee | decimal4 }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="uniteExploitation">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['uniteExploitation'].tooltip">{{ COLUMNS['uniteExploitation'].label }}</th>
      <td mat-cell *matCellDef="let data"> {{ data.article.uniteExploitation?.abreviation }} </td>
    </ng-container>

    <ng-container matColumnDef="pmpHtConstate">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['pmpHtConstate'].tooltip">{{ COLUMNS['pmpHtConstate'].label }}</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <mat-form-field appearance="fill" subscriptSizing="dynamic">
          <input matInput type="number"
                 #pmpHtConstateInput
                 (blur)="updateLigne(index, 'pmpHtConstate')"
                 (keyup.enter)="updateLigne(index, 'pmpHtConstate', true)"
                 formControlName="pmpHtConstate"
                 min="0">

          <mat-error *ngIf="hasError(index, 'pmpHtConstate', 'min')">Valeur négative interdite</mat-error>
          <mat-error *ngIf="hasError(index, 'pmpHtConstate', 'nbMaxDecimals')">Maximum {{ NB_MAX_DECIMALES_PMP }} décimales</mat-error>
          <mat-error *ngIf="hasError(index, 'pmpHtConstate', 'ttc_inferieur_ht')">TTC inférieur au HT</mat-error>
          <mat-error *ngIf="hasError(index, 'pmpHtConstate', 'network')">Erreur réseau</mat-error>
          <mat-error *ngIf="hasError(index, 'pmpHtConstate', '5xx')">Erreur serveur</mat-error>
        </mat-form-field>

        <!-- Version texte uniquement pour l'export -->
        <div class="cdk-visually-hidden">{{ data.pmpHtConstate | decimal4 }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="pmpTtcConstate">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['pmpTtcConstate'].tooltip">{{ COLUMNS['pmpTtcConstate'].label }}</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
        <mat-form-field appearance="fill" subscriptSizing="dynamic">
          <input matInput type="number"
                 #pmpTtcConstateInput
                 (blur)="updateLigne(index, 'pmpTtcConstate')"
                 (keyup.enter)="updateLigne(index, 'pmpTtcConstate', true)"
                 formControlName="pmpTtcConstate"
                 min="0">

          <mat-error *ngIf="hasError(index, 'pmpTtcConstate', 'min')">Valeur négative interdite</mat-error>
          <mat-error *ngIf="hasError(index, 'pmpTtcConstate', 'nbMaxDecimals')">Maximum {{ NB_MAX_DECIMALES_PMP }} décimales</mat-error>
          <mat-error *ngIf="hasError(index, 'pmpTtcConstate', 'ttc_inferieur_ht')">TTC inférieur au HT</mat-error>
          <mat-error *ngIf="hasError(index, 'pmpTtcConstate', 'network')">Erreur réseau</mat-error>
          <mat-error *ngIf="hasError(index, 'pmpTtcConstate', '5xx')">Erreur serveur</mat-error>
        </mat-form-field>

        <!-- Version texte uniquement pour l'export -->
        <div class="cdk-visually-hidden">{{ data.pmpTtcConstate | decimal4 }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="valeurTotaleHtConstatee">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['valeurTotaleHtConstatee'].tooltip">{{ COLUMNS['valeurTotaleHtConstatee'].label }}</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex"> {{ calculValeurTotaleHtConstatee(index) | decimal2 }} </td>
    </ng-container>

    <ng-container matColumnDef="valeurTotaleTtcConstatee">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['valeurTotaleTtcConstatee'].tooltip">{{ COLUMNS['valeurTotaleTtcConstatee'].label }}</th>
      <td mat-cell *matCellDef="let data;let index = dataIndex"> {{ calculValeurTotaleTtcConstatee(index) | decimal2 }} </td>
    </ng-container>

    <ng-container matColumnDef="commentaire">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['commentaire'].label }}</th>
      <td mat-cell *matCellDef="let data">
        <button type="button"
                mat-button
                matTooltip="Ajouter un commentaire"
                (click)="openDialogCommentaire(data)"
                [disabled]="!isEditable()"
                *ngIf="isEditable() && estCommentaireVide(data)"><fa-icon icon="comment-dots"></fa-icon></button>

        <a href="#"
           matTooltip="Consulter / modifier le commentaire"
           class="commentaire-ligne"
           (click)="$event.preventDefault();openDialogCommentaire(data)"
           *ngIf="isEditable() && !estCommentaireVide(data)">{{ data.commentaire }}</a>

        <div class="commentaire-ligne"
             *ngIf="!isEditable() && !estCommentaireVide(data)">{{ data.commentaire }}</div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    <!-- Ligne de pied de tableau en cas de liste vide -->
    <ng-container matColumnDef="noRecord">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns ? displayedColumns.length : 1">Liste vide</td>
    </ng-container>

    <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
  </table>
  </form>
</mat-card>



